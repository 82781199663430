import { generateEmailBodyHtmlForPasswordResetCompleted, generateEmailBodyPlainTextForPasswordResetCompleted, generateEmailSubjectForPasswordResetCompleted } from ".";
import { enumAppPlatformType, enumEmailRequestType, enumLocale, enumObjectPersistenceState } from "../../../../../dataObjects/enums";
import { EmailRequest, IEmailRequest } from "../../../../../dataObjects/models/emailRequests";
import { IUserPersistenceData, UserPersistenceData } from "../../../../../dataObjects/models/persistence/UserPersistenceData";
import { typeUniqueId } from "../../../../../dataObjects/types";
import { generateIdForEmailRequest } from "../generateIdForEmailRequest";

/**
 * @function generateEmailRequestForPasswordResetCompleted Generates an email request with a notification email indicating that a password reset request has been completed
 * @param {typeUniqueId} userId The userId of the user requesting the password reset.
 * @param {string} emailAddress The email address of the user requesting the password reset.
 * @param {string} firstName The first name of the user requesting the password reset.
 * @param {string} firstName The last name of the user requesting the password reset.
 * @returns {Promise<IEmailRequest>} A Promise with the generated Email Request, providing for an asynchronous process.
 */
export function generateEmailRequestForPasswordResetCompleted(userId: typeUniqueId, emailAddress: string, firstName: string, lastName: string): Promise<IEmailRequest> {
  return new Promise<IEmailRequest>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    const requestorName: string = `${firstName} ${lastName}`;

    displayConsoleLogs && console.log(`%c Entered generateEmailRequestForPasswordResetCompleted`, 'background: #550; color: #fff');

    try {
      // get the base domain of the running application
      const baseDomain: string = window.location.hostname;

      displayConsoleLogs && console.log(`%c generateEmailRequestForPasswordResetCompleted. Ready to create objects to submit an email request.`, 'background: #550; color: #fff');

      // create User Persistence data
      const userPersistenceData: IUserPersistenceData =
        new UserPersistenceData(userId, requestorName, enumAppPlatformType.Web, enumLocale.English);

      // call methods to generate the email subject, html body, and plain text body
      const emailSubject: string = generateEmailSubjectForPasswordResetCompleted();
      const emailBodyHtml: string = generateEmailBodyHtmlForPasswordResetCompleted(emailAddress, firstName, lastName);
      const emailBodyPlainText: string = generateEmailBodyPlainTextForPasswordResetCompleted(emailAddress, firstName, lastName);

      // the Email Request Id will be a concatenation of the userId, the Request Type, and a unique Id
      const emailRequestId: typeUniqueId = await generateIdForEmailRequest(userId, enumEmailRequestType.PasswordResetCompleted);
      const emailRequestSubmittedTimestamp: string = new Date().toUTCString();
      const emailRequest: IEmailRequest = new EmailRequest(userId, emailRequestId, undefined, undefined, enumObjectPersistenceState.New,
        enumEmailRequestType.PasswordResetCompleted, userId, requestorName, userId, emailAddress, requestorName,
        undefined, enumAppPlatformType.Web, emailSubject, emailBodyHtml, emailBodyPlainText, baseDomain, undefined, emailRequestSubmittedTimestamp, userPersistenceData);

      displayConsoleLogs && console.log(`%c generateEmailRequestForAccountVerification. Finished creating email request.`, 'background: #550; color: #fff');

      resolve(emailRequest);
    } catch (error: any) {
      displayConsoleLogs && console.log(`%c generateEmailRequestForAccountVerification. Error encountered: ${error}`, 'background: #550; color: #fff');

      reject(error);
    }
  });
}