import React, { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { listViewStyles } from '../../../styles';
import { INote } from '../../../../dataObjects/models/digitalMedia/Note';
import NotesListViewItem from './NotesListViewItem';
import { INoteViewModel } from '../../../../dataObjects/viewModels/noteViewModel';


export interface INotesListViewProps extends PropsWithChildren<unknown> {
  noteViewModels: Array<INoteViewModel>;
  onDelete: (note: INote) => void;
}

export const NotesListView: React.FC<INotesListViewProps> = (props: INotesListViewProps) => {
  NotesListView.displayName = 'Notes List View';

  // eslint-disable-next-line no-console
  // console.info(`Render NotesListView`);

  const classes: Record<string, string> = listViewStyles();

  const { noteViewModels, onDelete } = props;

  return (
    <>
      {/* overall grid for list. There will be a header row and two data rows: 
          - Header Row
          - Data Row 1: Title & Action Buttons
          - Data Row 2: Note Content
      */}
      <Grid container direction="column" >
        {/* Header Row */}
        <Grid key='tableHeader' container className={classes.tableHeader} size={12} direction="row">
          <Grid key='titleAndContentLabel' size={{ xs: 12, md: 10 }} >
            <Typography variant='h6'>
              Title / Note Content
            </Typography>
          </Grid>
          <Grid container size={{ md: 2 }} direction="row" justifyContent="center" alignContent="flex-start">
            <Grid key='editLabel' container className={classes.actionButtonLabelContainer} size={{ md: 4 }} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Edit
                </Typography>
              </Box>
            </Grid>
            <Grid key='deleteLabel' container className={classes.actionButtonLabelContainer} size={{ md: 4 }} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Delete
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {
          noteViewModels.map((noteViewModel, idx) => (
            <NotesListViewItem
              key={noteViewModel.note.id}
              noteViewModel={noteViewModel}
              onDelete={onDelete}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default NotesListView;
