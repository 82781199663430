import React, { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { IThemeSpecsViewModel } from '../../../../dataObjects/viewModels/themeSpecsViewModel';
import { listViewStyles } from '../../../styles';
import ThemeSpecsListViewItem from './ThemeSpecsListViewItem';


export interface IThemeSpecsListViewProps extends PropsWithChildren<unknown> {
  themeSpecsViewModels: Array<IThemeSpecsViewModel>;
  onDelete: (themeSpecs: IThemeSpecsViewModel) => void;
}

export const ThemeSpecsListView: React.FC<IThemeSpecsListViewProps> = (props: IThemeSpecsListViewProps) => {
  ThemeSpecsListView.displayName = 'ThemeSpecs List View';

  // eslint-disable-next-line no-console
  // console.info(`Render ThemeSpecsListView`);

  const classes: Record<string, string> = listViewStyles();

  const { themeSpecsViewModels, onDelete } = props;

  return (
    <>
      {/* overall grid for list. There will be a header row and two data rows: 
          - Header Row
          - Data Row 1: Name & Action Buttons
          - Data Row 2: Description
      */}
      <Grid container direction="column" >
        {/* Header Row */}
        <Grid container className={classes.tableHeader} size={12} direction="row">
          <Grid size={{ xs: 12, md: 10 }} >
            <Typography variant='h6'>
              Name / Description
            </Typography>
          </Grid>
          <Grid container className={classes.actionButtonLabelContainer} size={{ md: 1 }} justifyContent='center' >
            {/* Only display the next label if the screen size is Medium or larger */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }} >
              <Typography variant='h6'>
                Edit
              </Typography>
            </Box>
          </Grid>
          <Grid container className={classes.actionButtonLabelContainer} size={{ md: 1 }} justifyContent='center' >
            {/* Only display the next label if the screen size is Medium or larger */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }} >
              <Typography variant='h6'>
                Delete
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {
          themeSpecsViewModels.map((themeSpecs, idx) => (
            <ThemeSpecsListViewItem
              key={themeSpecs.id}
              themeSpecs={themeSpecs}
              onDelete={onDelete}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default ThemeSpecsListView;
