import React from 'react';
import Grid from '@mui/material/Grid2';
import { IDocumentLink } from '../../../../dataObjects/models/digitalMedia/DocumentLink';
import { DocumentLinksCardGridViewItem } from './DocumentLinksCardGridViewItem';
import { IDocumentLinkViewModel } from '../../../../dataObjects/viewModels/documentLinkViewModel';


// The next line is preserved for update to React 18, once peer dependencies will allow the update
// export interface IDocumentLinksCardGridViewProps extends PropsWithChildren<unknown> {
export interface IDocumentLinksCardGridViewProps {
  documentLinkViewModels: Array<IDocumentLinkViewModel>;
  onDelete: (documentLink: IDocumentLink) => void;
}


export const DocumentLinksCardGridView: React.FC<IDocumentLinksCardGridViewProps> = (props: IDocumentLinksCardGridViewProps) => {
  DocumentLinksCardGridView.displayName = 'Document Links Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render DocumentLinksCardGridView`);

  const { documentLinkViewModels, onDelete } = props;

  return (
    <>
      <Grid container spacing={2} >
        {
          documentLinkViewModels.map(documentLinkViewModel =>
            <Grid key={documentLinkViewModel.documentLink.id} size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
              <DocumentLinksCardGridViewItem documentLinkViewModel={documentLinkViewModel} onDelete={onDelete} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}