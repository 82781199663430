import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";

/**
 * @function generateEmailSubjectForUserEmailAddressChangedTo Generates the Subject of a notification email indicating that the user's email address setting has been changed
 * @param {string} oldEmailAddress The old (prior) email address of the user (and the target of the email message).
 * @param {string} newEmailAddress The new email address of the user.
 * @returns {string} A string with the Subject of the email message to be sent.
 */
export function generateEmailSubjectForUserEmailAddressChangedTo(oldEmailAddress: string, newEmailAddress: string): string {

  // *** Compose the email Subject ***
  const emailSubject = `(${ServerSubstitutionKeywordsStringAssets.ProjectName}) Account Email Address Change (from ${oldEmailAddress} to ${newEmailAddress})`;

  // return the email content
  return emailSubject;
}
