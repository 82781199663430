import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid2';
import { IChannel } from '../../../../dataObjects/models/channels/Channel';
import { ChannelsCardGridViewItem } from './ChannelsCardGridViewItem';
import { IChannelViewModel } from '../../../../dataObjects/viewModels/channelViewModel';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { IUser } from '../../../../dataObjects/models/users/User';
import { UserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';


export interface IChannelsCardGridViewProps extends PropsWithChildren<unknown> {
  channelViewModels: Array<IChannelViewModel> | undefined;
  onDelete: (channel: IChannel) => void;
}


export const ChannelsCardGridView: React.FC<IChannelsCardGridViewProps> = (props: IChannelsCardGridViewProps) => {
  ChannelsCardGridView.displayName = 'Channels Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render ChannelsList`);

  const { channelViewModels } = props;

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  const currentUser: IUser | undefined = currentUserContextData.currentUser;

  return (
    <>
      {/* There must be a current user to display any channels */}
      {currentUser &&
        <Grid container spacing={2} >
          {channelViewModels &&
            channelViewModels.map(channelViewModel =>
              <Grid key={channelViewModel.channel.id} size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
                <ChannelsCardGridViewItem
                  currentUser={currentUser}
                  userPermissions={new UserAccessPermissionsForObject(currentUser.id, channelViewModel.channel)}
                  channelViewModel={channelViewModel}
                  onDelete={props.onDelete}
                />
              </Grid>
            )
          }
        </Grid>
      }
    </>
  );

}