import { enumAppPlatformType, enumEmailRequestType, enumLocale, enumObjectPersistenceState } from "../../../../../dataObjects/enums";
import { EmailRequest, IEmailRequest } from "../../../../../dataObjects/models/emailRequests";
import { IUserPersistenceData, UserPersistenceData } from "../../../../../dataObjects/models/persistence/UserPersistenceData";
import { IUserFeedback } from "../../../../../dataObjects/models/userFeedback";
import { typeUniqueId } from "../../../../../dataObjects/types";
import { generateIdForEmailRequest } from "../generateIdForEmailRequest";
import { generateEmailBodyHtmlForUserFeedback } from "./generateEmailBodyHtmlForUserFeedback";
import { generateEmailBodyPlainTextForUserFeedback } from "./generateEmailBodyPlainTextForUserFeedback";
import { generateEmailSubjectForUserFeedback } from "./generateEmailSubjectForUserFeedback";

/**
 * @function generateEmailRequestForUserFeedback Generates an email request when a user has submitted feedback
 * @param {IUserFeedback} userFeedback User Feedback information with details needed for an email message
 * @returns {Promise<IEmailRequest>} A Promise with the generated Email Request, providing for an asynchronous process.
 */
export function generateEmailRequestForUserFeedback(userFeedback: IUserFeedback): Promise<IEmailRequest> {
  return new Promise<IEmailRequest>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log(`%c Entered generateEmailRequestForUserFeedback`, 'background: #550; color: #fff');

    try {
      // get the base domain of the running application
      const baseDomain: string = window.location.hostname;

      displayConsoleLogs && console.log(`%c generateEmailRequestForUserFeedback. Ready to create objects to submit an email request.`, 'background: #550; color: #fff');

      // create User Persistence data
      const userPersistenceData: IUserPersistenceData =
        new UserPersistenceData(userFeedback.userId, userFeedback.submitterName, enumAppPlatformType.Web, enumLocale.English);

      // call methods to generate the email subject, html body, and plain text body
      const emailSubject: string = generateEmailSubjectForUserFeedback(userFeedback);
      const emailBodyHtml: string = generateEmailBodyHtmlForUserFeedback(userFeedback);
      const emailBodyPlainText: string = generateEmailBodyPlainTextForUserFeedback(userFeedback);

      // the Email Request Id will be a concatenation of the UserFeedback Id, the Request Type, and a unique Id
      const emailRequestId: typeUniqueId = await generateIdForEmailRequest(userFeedback.id, enumEmailRequestType.UserFeedbackNotification);
      const requestorUserName: string = userFeedback.submitterName;
      const supportEmailAddress = process.env.REACT_APP_MYDIGIBRAIN_SUPPORT_EMAIL_ADDRESS ?? 'support@mydigibrain.com';
      const emailRequestSubmittedTimestamp: string = new Date().toUTCString();
      const emailRequest: IEmailRequest = new EmailRequest(userFeedback.userId, emailRequestId, undefined, undefined, enumObjectPersistenceState.New,
        enumEmailRequestType.UserFeedbackNotification, userFeedback.userId, requestorUserName, undefined, supportEmailAddress, 'MyDigiBrain Support',
        undefined, enumAppPlatformType.Web, emailSubject, emailBodyHtml, emailBodyPlainText, baseDomain, undefined, emailRequestSubmittedTimestamp, 
        userPersistenceData);

      displayConsoleLogs && console.log(`%c generateEmailRequestForUserFeedback. Finished creating email request.`, 'background: #550; color: #fff');

      resolve(emailRequest);
    } catch (error: any) {
      displayConsoleLogs && console.log(`%c generateEmailRequestForUserFeedback. Error encountered: ${error}`, 'background: #550; color: #fff');

      reject(error);
    }
  });
}