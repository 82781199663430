import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";
import { generateStandardEmailClosingHtml, generateStandardEmailOpeningHtml } from "../commonEmailBodyFragments";

/**
 * @function generateEmailBodyHtmlForUserEmailAddressChangedFrom. Generates the Html body of a notification email indicating that the user's email address setting has been changed
 * @param {string} oldEmailAddress The old (prior) email address of the user (and the target of the email message).
 * @param {string} firstName The first name of the target of the email.
 * @param {string} lastName The last name of the target of the email.
 * @param {string} newEmailAddress The new email address of the user.
 * @returns {string} A string with the Html body of the email message to be sent.
 */
export function generateEmailBodyHtmlForUserEmailAddressChangedFrom(oldEmailAddress: string, firstName: string, lastName: string, newEmailAddress: string): string {

  const recipientUserName: string = `${firstName} ${lastName}`;

  // *** Compose the email Body in HTML format ***
  let emailBodyHtml = generateStandardEmailOpeningHtml(recipientUserName);

  // user's email address has changed...
  emailBodyHtml += `<p>We have received a request to change the email address for your ${ServerSubstitutionKeywordsStringAssets.ProjectName} account from ${oldEmailAddress} to ${newEmailAddress}. `;

  emailBodyHtml += `<p>If you did not request this change in email address, you will have received another email message with instructions for recovering (reinstating) `;
  emailBodyHtml += `${oldEmailAddress} to be the email address for your account.</p>`;

  emailBodyHtml += `<p>We hope you continue to enjoy using ${ServerSubstitutionKeywordsStringAssets.ProjectName}! </p>`;

  emailBodyHtml += generateStandardEmailClosingHtml();

  // return the email content
  return emailBodyHtml;
}
