import React, { PropsWithChildren } from 'react';
import { Box, BoxProps, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { listViewItemStyles } from '../../../styles';
import { IUserRedemptionCodeRequest } from '../../../../dataObjects/models/userRedemptionCodeRequest';
import { TooltipStringAssets } from '../../../../assets/stringAssets';
import { enumSharingPermission } from '../../../../dataObjects/enums';
import { styled } from '@mui/styles';


// a styled Box (equivalent to a <div>), providing an area for the overall view of the application
const StyledDivForTypographySegments = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export interface IUserRedemptionCodeRequestsListViewItemProps extends PropsWithChildren<unknown> {
  userRedemptionCodeRequest: IUserRedemptionCodeRequest;
  userPermission: enumSharingPermission;
  onDelete: (userRedemptionCodeRequest: IUserRedemptionCodeRequest) => void;
}

export const UserRedemptionCodeRequestsListViewItem: React.FC<IUserRedemptionCodeRequestsListViewItemProps> = (props: IUserRedemptionCodeRequestsListViewItemProps) => {
  UserRedemptionCodeRequestsListViewItem.displayName = 'UserRedemptionCodeRequests List View Item';

  // eslint-disable-next-line no-console
  // console.info(`Render UserRedemptionCodeRequestsListViewItem`);

  const classes: Record<string, string> = listViewItemStyles();

  const { userRedemptionCodeRequest, userPermission, onDelete } = props;
  const { id, redemptionCode, recipientName, recipientEmailAddress, authorizerEmailAddress, startDate, endDate } = userRedemptionCodeRequest;

  // we're going to apply the 'useNavigate' hook to navigate the browser
  const navigate = useNavigate();

  const userHasPermission: boolean = userPermission === enumSharingPermission.Admin;

  return (
    <>
      <div className={classes.tableRow} key={id}>
        {/* Data Row 1: Recipient Name & Action Buttons  */}
        <Grid container size={12} direction="row" >
          {/* Name */}
          <Grid container size={{ xs: 9, sm: 10 }} >
            <Typography className={classes.title} variant="h6">
              {recipientName}
            </Typography>
          </Grid>
          {/* Action Buttons */}
          <Grid container size={{ xs: 3, sm: 2 }} direction="row" justifyContent="center" alignContent="flex-start">
            <Grid container className={classes.actionButtonContainer} size={4} >
              <Tooltip
                // the tooltip for the edit button will either be "Edit" (if user has sufficient permission) or "Edit disallowed" (insufficient permission)
                title={!userHasPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.editIconButton}`}
                    disabled={!userHasPermission}
                    onClick={() => id && navigate(`/userRedemptionCodeRequest/${id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {/* Delete button */}
            <Grid container className={classes.actionButtonContainer} size={4} >
              <Tooltip
                // the tooltip for the delete button will either be "Delete" (if user has sufficient permission) or "Delete disallowed" (insufficient permission)
                title={!userHasPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.deleteIconButton}`}
                    disabled={!userHasPermission}
                    onClick={() => userRedemptionCodeRequest && onDelete(userRedemptionCodeRequest)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* Data Row 2: Redemption Code  */}
        <Grid container size={12} >
          <StyledDivForTypographySegments>
            <Typography className={classes.fieldLabel} variant="body1">
              {'Redemption code:'}
            </Typography>
            <Typography className={classes.description} variant="body1">
              {redemptionCode}
            </Typography>
          </StyledDivForTypographySegments>
        </Grid>
        {/* Data Row 3: Recipient Email Address  */}
        <Grid container size={12} >
          <StyledDivForTypographySegments>
            <Typography className={classes.fieldLabel} variant="body1">
              {'Recipient email:'}
            </Typography>
            <Typography className={classes.description} variant="body1">
              {recipientEmailAddress}
            </Typography>
          </StyledDivForTypographySegments>
        </Grid>
        {/* Data Row 4: Authorizer Email Address  */}
        <Grid container size={12} >
          <StyledDivForTypographySegments>
            <Typography className={classes.fieldLabel} variant="body1">
              {'Authorizer email:'}
            </Typography>
            <Typography className={classes.description} variant="body1">
              {authorizerEmailAddress}
            </Typography>
          </StyledDivForTypographySegments>
        </Grid>
        {/* Data Row 5: Start Date  */}
        <Grid container size={12} >
          <StyledDivForTypographySegments>
            <Typography className={classes.fieldLabel} variant="body1">
              {'Start date:'}
            </Typography>
            <Typography className={classes.description} variant="body1">
              {startDate && moment(startDate).format('YYYY-MM-DD')}
            </Typography>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Typography className={classes.fieldLabel} variant="body1">
              {'End date:'}
            </Typography>
            <Typography className={classes.description} variant="body1">
              {endDate && moment(endDate).format('YYYY-MM-DD')}
            </Typography>
          </StyledDivForTypographySegments>
        </Grid>

        {/* Add a dividing line after each item */}
        <Divider />
      </div>
    </>
  );

}

export default UserRedemptionCodeRequestsListViewItem;