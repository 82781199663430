import React, { PropsWithChildren } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/styles';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Typography for the Page Subtitle
const StyledTypographyForObjectSpecificPageHeaderCategory = styled((props: TypographyProps) => (
  <Typography
    variant="h6"
    {...props}
  />
))(({ theme }) => ({
  color: theme.page.titleFontColor,
  fontWeight: 'bold',

  [theme.breakpoints.up('xs')]: {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.0rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.1rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.2rem',
  },
}));

const StyledTypographyForObjectSpecificPageHeaderText = styled((props: TypographyProps) => (
  <Typography
    variant="h6"
    {...props}
  />
))(({ theme }) => ({
  color: theme.page.titleFontColor,
  fontWeight: 'normal',

  [theme.breakpoints.up('xs')]: {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.0rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.1rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.2rem',
  },
}));


/**
 * @interface IObjectSpecificPageHeaderProps declares any input properties required for this component.
 */
export interface IObjectSpecificPageHeaderProps extends PropsWithChildren<unknown> {
  /**
   * @property {string} objectSpecificPageHeaderCategory If provided, represents an object-specific page header category to be 
   *                                                     displayed above the page title.
   * @example "Channel" in "Channel: Private Channel"
   * @requires If this this objectSpecificPageHeaderCategory is provided, the objectSpecificPageHeaderText must be also be provided 
   *           in order to show both. If neither is provided, or only one is provided, then neither will be displayed.
   */
  objectSpecificPageHeaderCategory?: string;
  /**
   * @property {string} objectSpecificPageHeaderText If provided, represents text for an object-specific page header to be 
   *                                                 displayed above the page title.
   * @example "Private Channel" in "Channel: Private Channel"
   * @requires If this this objectSpecificPageHeaderText is provided, the objectSpecificPageHeaderCategory must be also be provided 
   *           in order to show both. If neither is provided, or only one is provided, then neither will be displayed.
   */
  objectSpecificPageHeaderText?: string;
}

/**
 * @function ObjectSpecificPageHeader is a React functional component, representing a subtitle that will appear on a page.
 * @param {IObjectSpecificPageHeaderProps} props are the properties passed into the component and, in this case.
 */
export const ObjectSpecificPageHeader: React.FC<IObjectSpecificPageHeaderProps> = (props: IObjectSpecificPageHeaderProps) => {
  ObjectSpecificPageHeader.displayName = 'Page Subtitle';

  const { objectSpecificPageHeaderCategory, objectSpecificPageHeaderText } = props;

  const objectSpecificPageHeader: React.JSX.Element =
    // if both category and text are provided, create an element that includes both
    (objectSpecificPageHeaderCategory && objectSpecificPageHeaderText) ?
      <>
        <span>
          <StyledTypographyForObjectSpecificPageHeaderText>
            {`(`}
          </StyledTypographyForObjectSpecificPageHeaderText>
        </span>
        <span>
          <StyledTypographyForObjectSpecificPageHeaderCategory>
            {objectSpecificPageHeaderCategory}
          </StyledTypographyForObjectSpecificPageHeaderCategory>
        </span>
        <span>
          <StyledTypographyForObjectSpecificPageHeaderText>
            {`: ${objectSpecificPageHeaderText})`}
          </StyledTypographyForObjectSpecificPageHeaderText>
        </span>
      </>
      // otherwise, just supply an empty element
      : <></>;

  return (
    <>
      {objectSpecificPageHeader}
    </>
  )
}