import { enumAudioLinkType, enumDigitalMediaType, enumDocumentLinkType, enumImageLinkType, enumLocale, enumSharingPermission, enumSubscriptionType, enumTheme, enumThemeType, enumUserFeedbackType, enumVideoLinkType } from "../../dataObjects/enums";

/**
 * @class KeyValuePairsStringAssets A collection of string assets with key/value pairs
 */
export class KeyValuePairsStringAssets {

  static audioLinkTypeValuePairs: Array<{ key: string, value: string }> = [
    {
      key: enumAudioLinkType.GoogleCloudStorage,
      value: "Audio Upload"
    },
    {
      key: enumAudioLinkType.ExternalSource,
      value: "External Source"
    }
  ]

  static documentLinkTypeValuePairs: Array<{ key: string, value: string }> = [
    {
      key: enumDocumentLinkType.GoogleCloudStorage,
      value: "Document Upload"
    },
    {
      key: enumDocumentLinkType.ExternalSource,
      value: "External Source"
    }
  ]

  static imageLinkTypeValuePairs: Array<{ key: string, value: string }> = [
    {
      key: enumImageLinkType.GoogleCloudStorage,
      value: "Image Upload"
    },
    {
      key: enumImageLinkType.ExternalSource,
      value: "External Source"
    }
  ]

  static videoLinkTypeValuePairs: Array<{ key: string, value: string }> = [
    {
      key: enumVideoLinkType.GoogleCloudStorage,
      value: "Video Upload"
    },
    {
      key: enumVideoLinkType.ExternalSource,
      value: "External Source"
    }
  ]

  static subscriptionTypeValuePairs: Array<{ key: string, value: string }> = [
    {
      key: enumSubscriptionType.BetaTesting,
      value: "Beta Testing"
    },
    // {
    //   key: enumSubscriptionType.Group,
    //   value: "Group"
    // },
    // {
    //   key: enumSubscriptionType.Individual,
    //   value: "Individual"
    // },
]

  static themeTypeValuePairs: Array<{ key: string, value: string }> = [
    {
      key: enumThemeType.BuiltIn,
      value: "Built-in"
    },
    {
      key: enumThemeType.Custom,
      value: "Custom"
    },
  ]

  static themeKeyValuePairs: Array<{ key: string, value: string }> = [
    {
      key: enumTheme.Default,
      value: "Default"
    },
    {
      key: enumTheme.UsOpen,
      value: "US Open"
    },
    {
      key: enumTheme.Wimbledon,
      value: "Wimbledon"
    }
  ]

  static userFeedbackTypeValuePairs: Array<{ key: string, value: string }> = [
    {
      key: enumUserFeedbackType.Compliment,
      value: "Compliment"
    },
    {
      key: enumUserFeedbackType.Problem,
      value: "Problem"
    },
    {
      key: enumUserFeedbackType.Suggestion,
      value: "Suggestion"
    },
  ]

  static digitalMediaTypeNamePluralizedKeyValuePairs: Array<{ key: string, value: string }> = [
    {
      key: enumDigitalMediaType.Audio,
      value: "Audio Clips"
    },
    {
      key: enumDigitalMediaType.Document,
      value: "Documents"
    },
    {
      key: enumDigitalMediaType.HyperLink,
      value: "Hyperlinks"
    },
    {
      key: enumDigitalMediaType.Image,
      value: "Images"
    },
    {
      key: enumDigitalMediaType.Note,
      value: "Notes"
    },
    {
      key: enumDigitalMediaType.SocialMedia,
      value: "Social Media Posts"
    },
    {
      key: enumDigitalMediaType.Video,
      value: "Videos"
    },
  ]

  static localeKeyValuePairs: Array<{ key: string, value: string }> = [
    {
      key: enumLocale.English_US,
      value: "English (US)"
    },
    {
      key: enumLocale.English_UK,
      value: "English (UK)"
    },
    {
      key: enumLocale.French,
      value: "French"
    },
    {
      key: enumLocale.German,
      value: "German"
    },
    {
      key: enumLocale.Italian,
      value: "Italian"
    },
  ]

  static sharingPermissionKeyValuePairs: Array<{ key: string, value: string }> = [
    {
      key: enumSharingPermission.None,
      value: "None"
    },
    {
      key: enumSharingPermission.Admin,
      value: "Administrator"
    },
    {
      key: enumSharingPermission.EditContent,
      value: "Edit Content"
    },
    {
      key: enumSharingPermission.ViewContent,
      value: "View Content"
    }
  ]

  static getValueFromKey(keyValuePairArray: Array<{ key: string, value: string }>, key: string): string | undefined {
    let valueToReturn: string | undefined = undefined;

    // // a 'for' loop approach
    // for (let idx = 0; idx < keyValuePairArray.length; idx++) {
    //   if (keyValuePairArray[idx].key === key) {
    //     valueToReturn = keyValuePairArray[idx].value;
    //     break;
    //   }
    // }

    // an array 'find' approach (more efficient with larger arrays)
    const keyValuePair: { key: string, value: string } | undefined =
      keyValuePairArray.find((keyValuePairElement: { key: string, value: string }) => keyValuePairElement.key === key);
    if (keyValuePair !== undefined) {
      valueToReturn = keyValuePair.value;
    }

    return valueToReturn;
  }

}