import React, { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { IImageLink } from '../../../../dataObjects/models/digitalMedia/ImageLink';
import { listViewStyles } from '../../../styles';
import ImageLinksListViewItem from './ImageLinksListViewItem';
import { IImageLinkViewModel } from '../../../../dataObjects/viewModels/imageLinkViewModel';


export interface IImageLinksListViewProps extends PropsWithChildren<unknown> {
  imageLinkViewModels: Array<IImageLinkViewModel>;
  onDelete: (imageLink: IImageLink) => void;
}

export const ImageLinksListView: React.FC<IImageLinksListViewProps> = (props: IImageLinksListViewProps) => {
  ImageLinksListView.displayName = 'ImageLinks List View';

  // eslint-disable-next-line no-console
  // console.info(`Render ImageLinksListView`);

  const classes: Record<string, string> = listViewStyles();

  const { imageLinkViewModels, onDelete } = props;

  return (
    <>
      {/* overall grid for list. There will be a header row and either one or two data rows, based on a media query: 
          - Header Row
          - Data Row 1: Image & Action Buttons (also, the Description for larger screen sizes)
          - Data Row 2: Description (this row is only shown for smaller screen sizes)
      */}
      <Grid container direction="column" >
        {/* Header Row */}
        <Grid key='tableHeader' container className={classes.tableHeader} size={12} direction="row">
          <Grid key='nameAndDescriptionLabel' size={{ xs: 12, md: 10 }}>
            <Typography variant='h6'>
              Image / Description
            </Typography>
          </Grid>
          <Grid container size={{ md: 2 }} direction="row" justifyContent="center" alignContent="flex-start">
            <Grid key='editLabel' container className={classes.actionButtonLabelContainer} size={{ md: 6 }} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Edit
                </Typography>
              </Box>
            </Grid>
            <Grid key='deleteLabel' container className={classes.actionButtonLabelContainer} size={{ md: 6 }} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Delete
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {
          imageLinkViewModels.map((imageLinkViewModel, idx) => (
            <ImageLinksListViewItem
              key={imageLinkViewModel.imageLink.id}
              imageLinkViewModel={imageLinkViewModel}
              onDelete={onDelete}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default ImageLinksListView;