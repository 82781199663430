import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/styles';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ControlsStringAssets, KeyValuePairsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { IUserFeedback } from '../../../dataObjects/models/userFeedback';
import { enumUserFeedbackTypeConvert } from '../../../dataObjects/enums';

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled TextField that is serving as a Select control
const StyledTextFieldForSelectControl = styled((props: TextFieldProps) => (
  <TextField
    select
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

interface IUserFeedbackFormValues {
  title: string;
  userFeedbackType: string;
  description: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  title: yup
    .string()
    .required(ControlsStringAssets.userFeedbackTitleRequired),
  userFeedbackType: yup
    .string()
    .required(ControlsStringAssets.userFeedbackTypeRequired),
  description: yup
    .string()
    .required(ControlsStringAssets.userFeedbackDescriptionRequired),
});

export interface IUserFeedbackFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {IUserFeedback} userFeedback The UserFeedback details for the form (will have blank properties values if we're creating a new record)
   */
  userFeedback: IUserFeedback,
  /**
   * @property {boolean} saveRequestInProgress Whether a save request is in progress
   */
  saveRequestInProgress?: boolean,
  /**
   * @property {(userFeedback: IUserFeedback) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (userFeedback: IUserFeedback) => Promise<void>,
}

const UserFeedbackForm: React.FC<IUserFeedbackFormProps> = (props: IUserFeedbackFormProps) => {
  UserFeedbackForm.displayName = 'UserFeedback Form';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = true;

  displayConsoleLogs && console.log(`UserFeedbackForm. Entered UserFeedbackForm`);

  // get required arguments from props
  const { userFeedback, onSubmit } = props;

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit } = useForm<IUserFeedbackFormValues>({
    defaultValues: {
      userFeedbackType: userFeedback.userFeedbackType,
      title: userFeedback.title,
      description: userFeedback.description
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: userFeedbackTypeReg, ...userFeedbackTypeProps } = register("userFeedbackType", { required: true });
  const { ref: titleReg, ...titleProps } = register("title", { required: true });
  const { ref: descriptionReg, ...descriptionProps } = register("description", { required: true });

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid: formIsValid } = formState;

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  const [userFeedbackTypesForDropdown, setUserFeedbackTypesForDropdown] = useState<Array<React.JSX.Element>>([]);

  const [userFeedbackTypeKey, setUserFeedbackTypeKey] = useState<string>("");

  // useEffect to be executed upon mounting of this component
  useEffect(() => {
    displayConsoleLogs && console.log(`UserFeedbackForm. In useEffect upon mounting component`);

    // prepare an array of UserFeedbackType values from the enumUserFeedbackType enumerator that can be used to populate MenuItem components for the UserFeedbackType <Select> component
    let userFeedbackTypeMenuItems: Array<React.JSX.Element> = [];
    KeyValuePairsStringAssets.userFeedbackTypeValuePairs.forEach((keyValuePair: { key: string, value: string }) => {
      userFeedbackTypeMenuItems.push(<MenuItem key={keyValuePair.key} value={keyValuePair.key}>{keyValuePair.value}</MenuItem>);
    });

    setUserFeedbackTypesForDropdown(userFeedbackTypeMenuItems);
  }, []);

  // useEffect to be executed when the userFeedback object cvhanges
  useEffect(() => {
    displayConsoleLogs && console.log(`UserFeedbackForm. In useEffect for [userFeedback]`);

    if (userFeedbackTypeKey !== userFeedback.userFeedbackType) {
      setUserFeedbackTypeKey(userFeedback.userFeedbackType)
    }
  }, [userFeedback]);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    displayConsoleLogs && console.log(`UserFeedbackForm. In useEffect for [saveRequestInProgress]`);

    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);

  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: IUserFeedbackFormValues) => {

    setSaveInProgress(true);

    // fill in feedbackType, title & description of the UserFeedback object passed in
    userFeedback.userFeedbackType = enumUserFeedbackTypeConvert.fromString(userFeedbackTypeKey);
    userFeedback.title = data.title;
    userFeedback.description = data.description;

    displayConsoleLogs && console.log(`UserFeedbackForm. In handleSaveSubmit(). Ready to call onSubmit(). userFeedback: ${JSON.stringify(userFeedback)}`);

    // call the onSubmit handler passed in, supplying the UserFeedback object
    await onSubmit(userFeedback);
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={MessagesStringAssets.userFeedback_SaveRequested}
        formIsValid={formIsValid}
      >

        <StyledTextFieldForSelectControl
          inputRef={userFeedbackTypeReg}
          autoFocus
          label={ControlsStringAssets.userFeedbackTypeLabel}
          margin='normal'
          value={userFeedbackTypeKey}
          onChange={e => setUserFeedbackTypeKey(e.target.value)}
        >
          {userFeedbackTypesForDropdown}
        </StyledTextFieldForSelectControl>


        <TextField
          inputRef={titleReg}
          {...titleProps}
          name="title"
          label={ControlsStringAssets.userFeedbackTitleLabel}
          margin='normal'
          fullWidth
          error={!!errors.title}
          helperText={errors?.title?.message}
          slotProps={{
            inputLabel: {
              required: true // this will cause an asterisk ('*') to appear at the end of the label text
            }
          }}
    />

        <TextField
          inputRef={descriptionReg}
          {...descriptionProps}
          label={ControlsStringAssets.userFeedbackDescriptionLabel}
          margin='normal'
          fullWidth
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.description}
          helperText={errors?.description?.message}
          slotProps={{
            inputLabel: {
              required: true // this will cause an asterisk ('*') to appear at the end of the label text
            }
          }}
    />

      </FormWithActionBar>
    </>

  );
}

export default UserFeedbackForm;