import React, { useEffect, useState, PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { ControlsStringAssets, KeyValuePairsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { IRegistrationData } from '../../../dataObjects/models/registerAndLogin/RegistrationData';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, BoxProps, Button, ButtonProps, Checkbox, FormControlLabel, LinearProgress, LinearProgressProps, MenuItem, TextField, TextFieldProps, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import SubmitButton from '../../controls/buttons/SubmitButton/SubmitButton';
import Form from '../Form/Form';
import { normalizePhoneNumber } from '../../utilities';
import isEmail from 'validator/lib/isEmail';
import { enumLocale, enumSubscriptionType, enumSubscriptionTypeConvert, enumTheme } from '../../../dataObjects/enums';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/styles';
import { RegularExpressions } from '../../../assets/regularExpressionAssets';
import { getUserRedemptionCodeRequestsForEmailAddressViaHttp } from '../../../firebaseServices/dataServices/dataServiceActions/userRedemptionCodeRequestActions';
import { IUserRedemptionCodeRequest, IUserRedemptionCodeRequestAsJson } from '../../../dataObjects/models/userRedemptionCodeRequest';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the overall Progress Bar Area
const StyledBoxForProgressBarArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
}));

// a styled LinearProgress for the Progress Bar
const StyledLinearProgressForProgressBar = styled((props: LinearProgressProps) => (
  <LinearProgress
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
}));

// a styled Box (equivalent to a <div>), providing an area to display the Progress Label Area
const StyledBoxForProgressLabelArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",
}));

// a styled Box (equivalent to a <div>), providing an area to display the Action Buttons Area
const StyledBoxForActionButtonsArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
  // flexDirection: 'column',
}));

// a styled Box (equivalent to a <div>), providing an area to display a Pseudo Horizontal Rule
const StyledBoxForPseudoHorizontalRule = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  textAlign: 'center',
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  lineHeight: '0.1em',
  margin: '20px 0px',
}));

// a styled Box (equivalent to a <div>), providing an area to display Text within the Pseudo Horizontal Rule
const StyledBoxForTextInPseudoHorizontalRule = styled((props: BoxProps) => (
  <Box
    component='span'
    {...props}
  />
))(({ theme }) => ({
  background: '#fff',
  padding: '0 10px',
}));

// a styled Button for the Register Button
const StyledButtonForLoginButton = styled((props: ButtonProps) => (
  <Button
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.secondaryButton.textColor,
  border: theme.secondaryButton.border,
  background: theme.secondaryButton.background,
  '&:hover': {
    border: theme.secondaryButton.hoverBorder,
    background: theme.secondaryButton.hoverBackground,
    color: theme.secondaryButton.hoverTextColor,
  },
  '&:disabled': {
    border: theme.secondaryButton.disabledBorder,
    background: theme.secondaryButton.disabledBackground,
    color: theme.secondaryButton.disabledTextColor,
  },
}));

// a styled TextField that is serving as a Select control
const StyledTextFieldForSelectControl = styled((props: TextFieldProps) => (
  <TextField
    select
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));


interface IRegistrationFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  mobilePhone: string;
  theme: string;
  preferredLocale: string;
  membershipType: string;
  registrationCode: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email(ControlsStringAssets.emailInvalid)
    .required(ControlsStringAssets.emailRequired),
  password: yup
    .string()
    .min(8, ControlsStringAssets.passwordMinLength)
    .matches(RegularExpressions.Password, ControlsStringAssets.passwordPattern)
    .required(ControlsStringAssets.passwordRequired),
  confirmPassword: yup
    .string()
    .required(ControlsStringAssets.confirmPasswordRequired)
    .min(8, ControlsStringAssets.passwordMinLength)
    .oneOf([yup.ref('password')], ControlsStringAssets.confirmPasswordMustMatch) // validates that the confirmation password matches the original password
    .matches(RegularExpressions.Password, ControlsStringAssets.passwordPattern),
  firstName: yup
    .string()
    .required(ControlsStringAssets.firstNameRequired),
  lastName: yup
    .string()
    .required(ControlsStringAssets.lastNameRequired),
  mobilePhone: yup
    .string()
    .matches(RegularExpressions.PhoneNumber, ControlsStringAssets.phoneNumberInvalid)
    .required(ControlsStringAssets.phoneNumberRequired),
  theme: yup
    .string()
    .required(),
  preferredLocale: yup
    .string()
    .required(),
  membershipType: yup
    .string()
    .required(ControlsStringAssets.membershipTypeRequired),
  registrationCode: yup
    .string()
    .required(ControlsStringAssets.registrationCodeRequired),
});

export interface IRegistrationFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {IRegistrationData} registrationData The RegistrationData details for the form (in production, the fields will be blank; in development, default info may be provided)
   */
  registrationData: IRegistrationData,
  /**
  * @property {boolean} registrationRequestInProgress Whether a registration request is in progress
  */
  registrationRequestInProgress?: boolean,
  /**
   * @property {(registrationData: IRegistrationData) => Promise<void>} onSubmit Method to call for submitting the form for a registration operation
   */
  onSubmit: (registrationData: IRegistrationData) => Promise<void>,
}

const RegistrationForm: React.FC<IRegistrationFormProps> = (props: IRegistrationFormProps) => {
  RegistrationForm.displayName = 'Register Form';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const { registrationData, onSubmit } = props;

  const navigate = useNavigate();

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit, setError, setValue, clearErrors } = useForm<IRegistrationFormValues>({
    defaultValues: {
      email: registrationData.email ?? '',
      password: registrationData.password ?? '',
      confirmPassword: registrationData.password ?? '',
      firstName: registrationData.firstName ?? '',
      lastName: registrationData.lastName ?? '',
      mobilePhone: registrationData.mobilePhone ?? '',
      theme: (registrationData.theme !== undefined && registrationData.theme !== '') ? registrationData.theme : enumTheme.Default,
      preferredLocale: (registrationData.preferredLocale !== undefined && registrationData.preferredLocale !== '') ? registrationData.preferredLocale : enumLocale.English,
      membershipType: enumSubscriptionType.BetaTesting, // registrationData.membershipType,
      registrationCode: registrationData.registrationCode ?? '',
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: emailReg, ...emailProps } = register("email", { required: true });
  const { ref: passwordReg, ...passwordProps } = register("password", { required: true });
  const { ref: confirmPasswordReg, ...confirmPasswordProps } = register("confirmPassword", { required: true });
  const { ref: firstNameReg, ...firstNameProps } = register("firstName", { required: true });
  const { ref: lastNameReg, ...lastNameProps } = register("lastName", { required: true });
  const { ref: mobilePhoneReg, ...mobilePhoneProps } = register("mobilePhone", { required: true });
  const { ref: themeReg } = register("theme", { required: true });
  const { ref: preferredLocaleReg } = register("preferredLocale", { required: true });
  const { ref: membershipTypeReg, ...membershipTypeProps } = register("membershipType", { required: true });
  const { ref: registrationCodeReg, ...registrationCodeProps } = register("registrationCode", { required: true });

  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);

  // for testing whether the form is in a valid state
  const { isValid } = formState;

  const [formIsValid, setFormIsValid] = useState(false);

  // Will be the current value of the email address field, as the email address is being entered. This will be used
  // to trigger a search for userRedemptionCodes (aka registrationCodes) for the email address
  const [currentEmailAddress, setCurrentEmailAddress] = useState<string>('');

  const [themesForDropdown, setThemesForDropdown] = useState<Array<React.JSX.Element>>([]);

  const [themeKey, setThemeKey] = useState<string>("");

  const [localesForDropdown, setLocalesForDropdown] = useState<Array<React.JSX.Element>>([]);

  const [preferredLocaleKey, setPreferredLocaleKey] = useState<string>("");

  // currently selected membershipType (membershipTypeKey is the selected item's value)
  const [membershipTypeKey, setSubscriptionTypeKey] = useState<string>(enumSubscriptionTypeConvert.toString(enumSubscriptionType.BetaTesting));

  const [membershipTypesForDropdown, setSubscriptionTypesForDropdown] = useState<Array<React.JSX.Element>>([]);

  const [registrationCodes, setRegistrationCodes] = useState<Array<string>>([]);

  const [userRedemptionCodeRequests, setUserRedemptionCodeRequests] = useState<Array<IUserRedemptionCodeRequest>>([]);

  const [registrationCodeFieldValue, setRegistrationCodeFieldValue] = useState<string>("");

  const [registrationCodeIsValid, setRegistrationCodeIsValid] = useState<boolean>(false);

  // useEffect to be executed upon mounting of this component
  useEffect(() => {
    // prepare an array of Theme values from the enumTheme enumerator that can be used to populate MenuItem components for the Theme <Select> component
    let themeMenuItems: Array<React.JSX.Element> = [];
    KeyValuePairsStringAssets.themeKeyValuePairs.forEach((keyValuePair: { key: string, value: string }) => {
      themeMenuItems.push(<MenuItem key={keyValuePair.key} value={keyValuePair.key}>{keyValuePair.value}</MenuItem>);
    });

    setThemesForDropdown(themeMenuItems);
    setThemeKey(enumTheme.Default);

    // prepare an array of Locale values from the enumLocale enumerator that can be used to populate MenuItem components for the PreferredLocale <Select> component
    let localeMenuItems: Array<React.JSX.Element> = [];
    KeyValuePairsStringAssets.localeKeyValuePairs.forEach((keyValuePair: { key: string, value: string }) => {
      localeMenuItems.push(<MenuItem key={keyValuePair.key} value={keyValuePair.key}>{keyValuePair.value}</MenuItem>);
    });

    setLocalesForDropdown(localeMenuItems);
    setPreferredLocaleKey(enumLocale.English_US);

    // prepare an array of SubscriptionType values from the enumSubscriptionType enumerator that can be used to populate MenuItem components for the SubscriptionType <Select> component
    let membershipTypeMenuItems: Array<React.JSX.Element> = [];
    KeyValuePairsStringAssets.subscriptionTypeValuePairs.forEach((keyValuePair: { key: string, value: string }) => {
      membershipTypeMenuItems.push(<MenuItem key={keyValuePair.key} value={keyValuePair.key}>{keyValuePair.value}</MenuItem>);
    });

    setSubscriptionTypesForDropdown(membershipTypeMenuItems);
  }, []);

  // useEffect to establish whether the form contains valid data such that the user will be allowed to proceed
  useEffect(() => {
    // determine whether the form is valid (has met all requirements) based on the 'isValid' flag (a reflection of the react-hook-form state) and whether the registration code requirements have been met.
    const isFormValid: boolean = isValid && registrationCodeIsValid;

    setFormIsValid(isFormValid);
    // console.info(`RegistrationForm.useEffect: formIsValid being set to ${isFormValid}`);

    // // if the registration code is NOT valid, set an error for this field
    // if (!registrationCodeIsValid) {
    //   setError('registrationCode', { type: 'custom', message: ControlsStringAssets.registrationCodeInvalid });
    // } else {
    //   // the registration code IS valid, so clear the errors
    //   clearErrors('registrationCode');
    // }

  }, [isValid, registrationCodeIsValid]);

  // useEffect to determine whether the registration code is valid
  useEffect(() => {
    displayConsoleLogs && console.log(`RegistrationForm. useEffect for [registrationCodes, registrationCodeFieldValue]. registrationCodes: ${JSON.stringify(registrationCodes)}; registrationCodeFieldValue: ${registrationCodeFieldValue}.`);

    // see if the registration code field value (trimmed of leading and trailing spaces) matches one of the legitimate registration codes
    const isRegistrationCodeValid: boolean = registrationCodes.includes(registrationCodeFieldValue.trim());

    // set the flag to indicate whether the registration code is valid
    setRegistrationCodeIsValid(isRegistrationCodeValid);

    // // if the registration code is NOT valid, set an error for this field
    // if (!isRegistrationCodeValid) {
    //   setError('registrationCode', { type: 'custom', message: ControlsStringAssets.registrationCodeInvalid });
    // } else {
    //   // the registration code IS valid, so clear the errors
    //   clearErrors('registrationCode');
    // }

  }, [registrationCodes, registrationCodeFieldValue]);

  // useEffect for changes to the currentEmailAddress. If it's a valid email address, we'll get a snapshot of userRedemptionCodes associated
  // with the email address
  useEffect(() => {
    // if the email address value is a valid email address...
    if (isEmail(currentEmailAddress)) {

      getUserRedemptionCodeRequestsForEmailAddressViaHttp(currentEmailAddress).then((userRedemptionCodeRequests: Array<IUserRedemptionCodeRequestAsJson>) => {
        displayConsoleLogs && console.log(`RegistrationForm. useEffect for currentEmailAddress. Results of getUserRedemptionCodeRequestsForEmailAddressViaHttp: ${JSON.stringify(userRedemptionCodeRequests)}.`);

        // for now, if there are any redemption codes for the given email address, get the first code and set its value in the Redemption (Registration) Code field
        if (userRedemptionCodeRequests.length > 0) {
          const registrationCodeToBeSet: string = userRedemptionCodeRequests[0].redemptionCode ?? 'unknown';
          setRegistrationCodeFieldValue(registrationCodeToBeSet);
          setValue('registrationCode', registrationCodeToBeSet);

          // Capture an array of registrationCodes (redemptionCodes) found for the user. Only capture defined values.
          const registrationCodesForUser: Array<string> = [];
          for (let idx = 0; idx < userRedemptionCodeRequests.length; idx++) {
            const redemptionCode: string | undefined = userRedemptionCodeRequests[idx].redemptionCode;
            if (redemptionCode !== undefined) {
              registrationCodesForUser.push(redemptionCode);
            }
          }
          setRegistrationCodes(registrationCodesForUser);
        } else {
          // clear out the value of the 'registrationCode' (redemptionCode) field
          setValue('registrationCode', '');

          // set other registration/redemption code state variables to empty arrays
          setRegistrationCodes([]);
          setUserRedemptionCodeRequests([]);
        }
      });
    } else {
      // reset the collection of UserRedemptionCodeRequests to an empty array
      setUserRedemptionCodeRequests([]);
    }
  }, [currentEmailAddress])


  // capture whether a registration is currently being submitted
  const registrationRequestInProgress: boolean = props.registrationRequestInProgress ?? false;

  // state value indicating whether a registration is in progress
  const [registrationInProgress, setRegistrationInProgress] = useState<boolean>(registrationRequestInProgress);

  // useEffect hook for setting the 'registrationInProgress' local state based on whether a registration is currently in progress
  useEffect(() => {
    setRegistrationInProgress(registrationRequestInProgress);
  }, [registrationRequestInProgress]);


  // handles a registration (submit) request from the form
  const handleRegistrationSubmit = async (data: IRegistrationFormValues) => {

    setRegistrationInProgress(true);

    // fill in all fields within the registrationData object passed in
    registrationData.email = data.email.toLowerCase();
    registrationData.password = data.password;
    registrationData.firstName = data.firstName;
    registrationData.lastName = data.lastName;
    registrationData.mobilePhone = data.mobilePhone;
    registrationData.theme = data.theme;
    registrationData.preferredLocale = data.preferredLocale;
    registrationData.subscriptionType = enumSubscriptionTypeConvert.fromString(data.membershipType);
    registrationData.subscriptionStartDate = new Date();
    registrationData.subscriptionEndDate = undefined;
    registrationData.registrationCode = data.registrationCode;

    // call the onSubmit handler passed in, supplying the Channel object
    await onSubmit(registrationData);
  }


  // An 'onChange' handler for the Email field
  function handleEmailChange(emailAddressFieldValue: string): void {
    displayConsoleLogs && console.log(`%c RegistrationForm.handleEmailChange. emailAddressFieldValue: ${emailAddressFieldValue}`, 'background: #055; color: #fff');

    // if (isEmail(emailAddressFieldValue)) {
    //   if (currentEmailAddress !== emailAddressFieldValue) {
    //     setCurrentEmailAddress(emailAddressFieldValue);
    //   }
    // }

    // // if it's a valid email address, trigger request to get a snapshot of all userRedemptionCodes associated with the email address
    // if (isEmail(emailAddressFieldValue)) {

    // } else {
    //   // clear the userRedemptionCode (registrationCode) field
    // }

    // set the current value of the email address field
    setCurrentEmailAddress(emailAddressFieldValue.toLowerCase());
  }

  // An 'onChange' handler for the RegistrationCode field
  function handleRegistrationCodeChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    setRegistrationCodeFieldValue(event.target.value); // capture changed value of registrationCode
  }


  // prepare an array of Locale values from the enumLocale enumerator that can be used to populate MenuItem components for the Preferred Locale <Select> component
  let localeMenuItems: Array<React.JSX.Element> = [];
  Object.values(enumLocale).forEach((localeValue: string) => {
    localeMenuItems.push(<MenuItem key={localeValue} value={localeValue}>{localeValue}</MenuItem>);
  });

  // prepare an array of Theme values from the enumTheme enumerator that can be used to populate MenuItem components for the Theme <Select> component
  let themeMenuItems: Array<React.JSX.Element> = [];
  Object.values(enumTheme).forEach((themeValue: string) => {
    themeMenuItems.push(<MenuItem key={themeValue} value={themeValue}>{themeValue}</MenuItem>);
  });

  return (

    <Form
      onSubmit={handleSubmit(handleRegistrationSubmit)}
    >
      <Grid container >
        <Grid container key='emailAndPhone' size={12} spacing={1} justifyContent='space-between' >
          <Grid key='email' size={{ xs: 12, sm: 6 }} >
            <TextField
              inputRef={emailReg}
              {...emailProps}
              autoFocus
              label={ControlsStringAssets.emailLabel}
              margin='normal'
              fullWidth
              error={!!errors.email}
              helperText={errors?.email?.message}
              slotProps={{
                inputLabel: {
                  required: true // this will cause an asterisk ('*') to appear at the end of the label text
                }
              }}
              onChange={e => handleEmailChange(e.target.value)}
            />
          </Grid>
          <Grid key='mobilePhone' size={{ xs: 12, sm: 6 }} >
            <TextField
              type="tel"
              inputRef={mobilePhoneReg}
              {...mobilePhoneProps}
              label={ControlsStringAssets.mobilePhoneLabel}
              margin='normal'
              fullWidth
              error={!!errors.mobilePhone}
              helperText={errors?.mobilePhone?.message}
              slotProps={{
                inputLabel: {
                  required: true // this will cause an asterisk ('*') to appear at the end of the label text
                }
              }}
              // we need a custom onChange handler to normalize (format) the phone number as the user is entering the digits
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.currentTarget.value = normalizePhoneNumber(event.currentTarget.value)
              }}
            />
          </Grid>
        </Grid>
        <Grid container key='passwordAndConfirmPassword' size={12} spacing={1} justifyContent='space-between' >
          <Grid key='password' size={{ xs: 12, sm: 6 }} >
            <TextField
              inputRef={passwordReg}
              {...passwordProps}
              type="password"
              label={ControlsStringAssets.passwordLabel}
              margin='normal'
              fullWidth
              error={!!errors.password}
              helperText={errors?.password?.message}
              slotProps={{
                inputLabel: {
                  required: true // this will cause an asterisk ('*') to appear at the end of the label text
                }
              }}
            />
          </Grid>
          <Grid key='confirmPassword' size={{ xs: 12, sm: 6 }} >
            <TextField
              inputRef={confirmPasswordReg}
              {...confirmPasswordProps}
              type="password"
              label={ControlsStringAssets.confirmPasswordLabel}
              margin='normal'
              fullWidth
              error={!!errors.confirmPassword}
              helperText={errors?.confirmPassword?.message}
              slotProps={{
                inputLabel: {
                  required: true // this will cause an asterisk ('*') to appear at the end of the label text
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container key='firstNameAndLastName' size={12} spacing={1} justifyContent='space-between' >
          <Grid key='firstName' size={{ xs: 12, sm: 6 }} >
            <TextField
              inputRef={firstNameReg}
              {...firstNameProps}
              label="First Name"
              margin='normal'
              fullWidth
              error={!!errors.firstName}
              helperText={errors?.firstName?.message}
              slotProps={{
                inputLabel: {
                  required: true // this will cause an asterisk ('*') to appear at the end of the label text
                }
              }}
            />
          </Grid>
          <Grid key='lastName' size={{ xs: 12, sm: 6 }} >
            <TextField
              inputRef={lastNameReg}
              {...lastNameProps}
              label="Last Name"
              margin='normal'
              fullWidth
              error={!!errors.lastName}
              helperText={errors?.lastName?.message}
              slotProps={{
                inputLabel: {
                  required: true // this will cause an asterisk ('*') to appear at the end of the label text
                }
              }}
            />
          </Grid>
        </Grid>
        {/* For now, we are going to completely hide (via 'display: none') the next row in the grid. Default values will be used for Theme & Language*/}
        <Grid style={{ display: 'none' }} container key='themeAndLocale' size={12} spacing={1} justifyContent='space-between' >
          <Grid key='theme' size={{ xs: 12, sm: 6 }} >

            {/* We use a TextField with 'select' attribute as a pseudo <Select> (or dropdown) control */}
            <StyledTextFieldForSelectControl
              sx={{ visibility: 'hidden' }}  // This Theme field will be hidden for now. The user will be able to change theme in 'Settings'.
              inputRef={themeReg}
              label="Theme"
              margin='normal'
              value={themeKey}
              onChange={e => setThemeKey(e.target.value)}
            >
              {themesForDropdown}
            </StyledTextFieldForSelectControl>

          </Grid>
          <Grid key='locale' size={{ xs: 12, sm: 6 }} >
            {/* We use a TextField with 'select' attribute as a pseudo <Select> (or dropdown) control */}
            <StyledTextFieldForSelectControl
              sx={{ visibility: 'hidden' }}  // This Locale field will be hidden for now, until we are ready to enable internationalization features
              inputRef={preferredLocaleReg}
              label="Preferred Locale"
              margin='normal'
              value={preferredLocaleKey}
              onChange={e => setPreferredLocaleKey(e.target.value)}
            >
              {localesForDropdown}
            </StyledTextFieldForSelectControl>
          </Grid>
        </Grid>
        <Grid container key='membershipTypeAndRegistrationCode' size={12} spacing={1} justifyContent='space-between' >
          <Grid key='locale' size={{ xs: 12, sm: 6 }} >
            {/* We use a TextField with 'select' attribute as a pseudo <Select> (or dropdown) control */}
            <StyledTextFieldForSelectControl
              // sx={{ visibility: 'hidden' }}  // This Membership Type field will be hidden for now, until we are ready to enable Membership features
              inputRef={membershipTypeReg}
              label={ControlsStringAssets.membershipTypeLabel}
              margin='normal'
              value={membershipTypeKey}
              onChange={e => setSubscriptionTypeKey(e.target.value)}
            >
              {membershipTypesForDropdown}
            </StyledTextFieldForSelectControl>
          </Grid>
          <Grid key='registrationCode' size={{ xs: 12, sm: 6 }} >
            <TextField
              inputRef={registrationCodeReg}
              {...registrationCodeProps}
              label="Registration Code"
              margin='normal'
              fullWidth
              error={!!errors.registrationCode}
              helperText={errors?.registrationCode?.message || (!registrationCodeIsValid && ControlsStringAssets.registrationCodeInvalid)}
              slotProps={{
                inputLabel: {
                  required: true, // this will cause an asterisk ('*') to appear at the end of the label text
                  shrink: true // this will force the label to automatically shrink for the field
                }
              }}
    
              onChange={event => handleRegistrationCodeChange(event)} // 'Change' handler for the field
            />
          </Grid>
        </Grid>
        <Grid container key='agreeToTermsAnd???' size={12} >
          <Grid key='agreeToTerms' size={12} >
            {/* We use a FormControlLabel to bundle a Material-UI Checkbox with a label */}
            <FormControlLabel
              name="agreeToTerms"
              control={
                <Checkbox
                  name="agreeToTerms"
                  required
                  color='primary'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAgreeToTerms(event.currentTarget.checked);
                  }}
                />
              }
              label={ControlsStringAssets.termsAndConditionsLabel}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* if there's a registration in progress, display the progress bar area */}
      {registrationInProgress &&
        <StyledBoxForProgressBarArea>
          <StyledLinearProgressForProgressBar />
          <StyledBoxForProgressLabelArea>
            {/* if an action progress label has been provided, display it */}
            <Typography variant="caption">{MessagesStringAssets.registration_Requested}</Typography>
          </StyledBoxForProgressLabelArea>
        </StyledBoxForProgressBarArea>
      }
      {/* display the action buttons ("Create New Account" & "Login") */}

      <StyledBoxForActionButtonsArea>
        <SubmitButton
          fullWidth={true}
          disabled={!formIsValid || !agreeToTerms || registrationInProgress}
        >
          {ControlsStringAssets.createNewAccountButtonText}
        </SubmitButton>

        <StyledBoxForPseudoHorizontalRule>
          <StyledBoxForTextInPseudoHorizontalRule>OR</StyledBoxForTextInPseudoHorizontalRule>
        </StyledBoxForPseudoHorizontalRule>

        <StyledButtonForLoginButton
          disabled={registrationInProgress}
          onClick={() => navigate('/login')}
        >
          {ControlsStringAssets.loginButtonText}
        </StyledButtonForLoginButton>
      </StyledBoxForActionButtonsArea>

    </Form>

  );
}

export default RegistrationForm;