import { generateEmailBodyHtmlForRecoverUserEmailAddress, generateEmailBodyPlainTextForRecoverUserEmailAddress, generateEmailSubjectForRecoverUserEmailAddress } from ".";
import { enumAppPlatformType, enumEmailRequestType, enumLocale, enumObjectPersistenceState } from "../../../../../dataObjects/enums";
import { EmailRequest, IEmailRequest } from "../../../../../dataObjects/models/emailRequests";
import { IUserPersistenceData, UserPersistenceData } from "../../../../../dataObjects/models/persistence/UserPersistenceData";
import { typeUniqueId } from "../../../../../dataObjects/types";
import { generateIdForEmailRequest } from "../generateIdForEmailRequest";

/**
 * @function generateEmailRequestForRecoverUserEmailAddress Generates an email request with a notification email allowing to recover (reverted) from a changed email address
 * @param {typeUniqueId} userId The Id of the user who is the target of the email.
 * @param {string} recoveredToEmailAddress The new (recovered to) email address of the user.
 * @param {string} firstName The first name of the target of the email.
 * @param {string} lastName The last name of the target of the email.
 * @param {string} priorEmailAddress The old (prior) email address prior to recovery (reverting).
 * @returns {Promise<IEmailRequest>} A Promise with the generated Email Request, providing for an asynchronous process.
 */
export function generateEmailRequestForRecoverUserEmailAddress(userId: typeUniqueId, recoveredToEmailAddress: string, firstName: string, lastName: string, priorEmailAddress: string): Promise<IEmailRequest> {
  return new Promise<IEmailRequest>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    const requestorName: string = `${firstName} ${lastName}`;

    displayConsoleLogs && console.log(`%c Entered generateEmailRequestForRecoverUserEmailAddress`, 'background: #550; color: #fff');

    try {
      // get the base domain of the running application
      const baseDomain: string = window.location.hostname;

      displayConsoleLogs && console.log(`%c generateEmailRequestForRecoverUserEmailAddress. Ready to create objects to submit an email request.`, 'background: #550; color: #fff');

      // create User Persistence data
      const userPersistenceData: IUserPersistenceData =
        new UserPersistenceData(userId, requestorName, enumAppPlatformType.Web, enumLocale.English);

      // call methods to generate the email subject, html body, and plain text body
      const emailSubject: string = generateEmailSubjectForRecoverUserEmailAddress(recoveredToEmailAddress, priorEmailAddress);
      const emailBodyHtml: string = generateEmailBodyHtmlForRecoverUserEmailAddress(recoveredToEmailAddress, firstName, lastName, priorEmailAddress);
      const emailBodyPlainText: string = generateEmailBodyPlainTextForRecoverUserEmailAddress(recoveredToEmailAddress, firstName, lastName, priorEmailAddress);

      // the Email Request Id will be a concatenation of the userId, the Request Type, and a unique Id
      const emailRequestId: typeUniqueId = await generateIdForEmailRequest(userId, enumEmailRequestType.RecoverUserEmailAddress);
      const emailRequestSubmittedTimestamp: string = new Date().toUTCString();
      const emailRequest: IEmailRequest = new EmailRequest(userId, emailRequestId, undefined, undefined, enumObjectPersistenceState.New,
        enumEmailRequestType.RecoverUserEmailAddress, userId, requestorName, userId, recoveredToEmailAddress, requestorName,
        priorEmailAddress, enumAppPlatformType.Web, emailSubject, emailBodyHtml, emailBodyPlainText, baseDomain, undefined, emailRequestSubmittedTimestamp, userPersistenceData);

      displayConsoleLogs && console.log(`%c generateEmailRequestForAccountVerification. Finished creating email request.`, 'background: #550; color: #fff');

      resolve(emailRequest);
    } catch (error: any) {
      displayConsoleLogs && console.log(`%c generateEmailRequestForAccountVerification. Error encountered: ${error}`, 'background: #550; color: #fff');

      reject(error);
    }
  });
}