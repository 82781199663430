import { enumAppPlatformType, enumEmailRequestType, enumLocale, enumObjectPersistenceState } from "../../../../../dataObjects/enums";
import { EmailRequest, IEmailRequest } from "../../../../../dataObjects/models/emailRequests";
import { IUserPersistenceData, UserPersistenceData } from "../../../../../dataObjects/models/persistence/UserPersistenceData";
import { typeUniqueId } from "../../../../../dataObjects/types";
import { generateIdForEmailRequest } from "../generateIdForEmailRequest";
import { generateEmailBodyHtmlForAccountVerification } from "./";
import { generateEmailBodyPlainTextForAccountVerification } from "./";
import { generateEmailSubjectForAccountVerification } from "./generateEmailSubjectForAccountVerification";

/**
 * @function generateEmailRequestForAccountVerification Generates an email request with a notification email to verify a new user account
 * @param {typeUniqueId} newAccountUserId The Id of the new user .
 * @param {string} newAccountEmailAddress The email address of the new user.
 * @param {string} firstName The first name of the user for whom to send the new user account verification email.
 * @param {string} lastName The last name of the user for whom to send the new user account verification email.
 * @returns {Promise<IEmailRequest>} A Promise with the generated Email Request, providing for an asynchronous process.
 */
export function generateEmailRequestForAccountVerification(newAccountUserId: typeUniqueId, newAccountEmailAddress: string, firstName: string, lastName: string): Promise<IEmailRequest> {
  return new Promise<IEmailRequest>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log(`%c Entered generateEmailRequestForAccountVerification`, 'background: #550; color: #fff');

    const newAccountUserName: string = `${firstName} ${lastName}`;

    try {
      // get the base domain of the running application
      const baseDomain: string = window.location.hostname;

      displayConsoleLogs && console.log(`%c generateEmailRequestForAccountVerification. Ready to create objects to submit an email request.`, 'background: #550; color: #fff');

      // create User Persistence data
      const userPersistenceData: IUserPersistenceData =
        new UserPersistenceData(newAccountUserId, newAccountUserName, enumAppPlatformType.Web, enumLocale.English);

      // call methods to generate the email subject, html body, and plain text body
      const emailSubject: string = generateEmailSubjectForAccountVerification();
      const emailBodyHtml: string = generateEmailBodyHtmlForAccountVerification(firstName, lastName);
      const emailBodyPlainText: string = generateEmailBodyPlainTextForAccountVerification(firstName, lastName);

      // the Email Request Id will be a concatenation of the new account userId, the Request Type, and a unique Id
      const emailRequestId: typeUniqueId = await generateIdForEmailRequest(newAccountUserId, enumEmailRequestType.EmailAddressVerification);
      const emailRequestSubmittedTimestamp: string = new Date().toUTCString();
      const emailRequest: IEmailRequest = new EmailRequest(newAccountUserId, emailRequestId, undefined, undefined, enumObjectPersistenceState.New,
        enumEmailRequestType.EmailAddressVerification, newAccountUserId, newAccountUserName, newAccountUserId, newAccountEmailAddress, newAccountUserName,
        undefined, enumAppPlatformType.Web, emailSubject, emailBodyHtml, emailBodyPlainText, baseDomain, undefined, emailRequestSubmittedTimestamp, 
        userPersistenceData);

      displayConsoleLogs && console.log(`%c generateEmailRequestForAccountVerification. Finished creating email request.`, 'background: #550; color: #fff');

      resolve(emailRequest);
    } catch (error: any) {
      displayConsoleLogs && console.log(`%c generateEmailRequestForAccountVerification. Error encountered: ${error}`, 'background: #550; color: #fff');

      reject(error);
    }
  });
}