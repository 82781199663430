import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { ITopic, ITopicAsJson } from '.';
import { ITopicMetadata, TopicMetadata } from '../TopicMetadata';
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';
import { JsonConverter } from '../../../utilities/JsonConverter';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';

// The Topic class (complete the description)
/**
 * @class Topic represent a topic (e.g., Event, Meeting, Resource, Program, etc.)
 * {@linkcode ITopic}
 */
// export default class Topic implements ITopic {
// export default class Topic implements ITopic, IVersionAwarePersistable {
export class Topic extends VersionAwarePersistable implements ITopic {

  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {string} name The name of the topic
   * @param {string} namePluralized The pluralized version of the name of the topic
   * @param {string} description (optional) A description for the topic
   * @param {IUserPersistenceData} userPersistenceData (optional) User-related persistence data
   * @param {string} topicItemNameAliasSingular (optional) The singular form of the alias for TopicItem members (eg, "Topic Item", "Segment" (Lesson Segment), "Activity" (Team or Tournament Activity))
   * @param {string} topicItemNameAliasPlural (optional) The plural form of the alias for TopicItem members (eg, "Topic Items", "Segments" (Lesson Segments), "Activities" (Team or Tournament Activities))
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    name: string,
    namePluralized: string,
    description?: string,
    userPersistenceData?: IUserPersistenceData,
    topicItemNameAliasSingular?: string,
    topicItemNameAliasPlural?: string
  ) {
    super(ownerId, enumPersistableObjectClassName.Topic, enumPersistableObjectType.Topic, id, parentObjectType, parentId, objectState, userPersistenceData);

    this.name = name;
    this.namePluralized = namePluralized;

    if (description) {
      this.description = description;
    }

    if (topicItemNameAliasSingular) {
      this.topicItemNameAliasSingular = topicItemNameAliasSingular;
    }

    if (topicItemNameAliasPlural) {
      this.topicItemNameAliasPlural = topicItemNameAliasPlural;
    }

    // initlalize the TopicMetadata
    this._topicMetadata = new TopicMetadata(this.ownerId, RandomId.newId(), this.objectType, this.id, enumObjectPersistenceState.New);
  }

  /*-----------------------------------------------*/  /**
     * @property {string} name represents the name of the topic
     */
  private _name: string = '';

  // getter for _propName
  get name() {
    return this._name;
  }

  // setter for _propName
  set name(value) {
    this._name = value;
  }

  /*-----------------------------------------------*/
  /**
   * @property {string} namePluralized represents a pluralized version of the name
   */
  private _namePluralized: string = '';

  // getter for _propName
  get namePluralized() {
    return this._namePluralized;
  }

  // setter for _propName
  set namePluralized(value) {
    this._namePluralized = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _description property is the description for the TopicItem
   */
  private _description: string = '';

  /**
   * @method description is an optional getter method for _description
   */
  get description() {
    return this._description;
  }

  /**
   * @method description is an optional setter method for _description
   * @param {string} value is the input value for setting _description
   */
  set description(value) {
    this._description = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _topicItemNameAliasSingular The singular form of the alias for TopicItem members (eg, "Topic Item", "Segment" (Lesson Segment), "Activity" (Team or Tournament Activity))
   */
  private _topicItemNameAliasSingular: string = '';

  /**
   * @method topicItemNameAliasSingular Getter method for _topicItemNameAliasSingular
   */
  get topicItemNameAliasSingular() {
    return this._topicItemNameAliasSingular;
  }

  /**
   * @method topicItemNameAliasSingular Setter method for _topicItemNameAliasSingular
   * @param {string} value Input value for setting _topicItemNameAliasSingular
   */
  set topicItemNameAliasSingular(value) {
    this._topicItemNameAliasSingular = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _topicItemNameAliasPlural The plural form of the alias for TopicItem members (eg, "Topic Items", "Segments" (Lesson Segments), "Activities" (Team or Tournament Activities))
   */
  private _topicItemNameAliasPlural: string = '';

  /**
   * @method topicItemNameAliasPlural Getter method for _topicItemNameAliasPlural
   */
  get topicItemNameAliasPlural() {
    return this._topicItemNameAliasPlural;
  }

  /**
   * @method topicItemNameAliasPlural Setter method for _topicItemNameAliasPlural
   * @param {string} value Input value for setting _topicItemNameAliasPlural
   */
  set topicItemNameAliasPlural(value) {
    this._topicItemNameAliasPlural = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {ITopicMetadata} _topicMetadata property (complete the description)
   */
  private _topicMetadata: ITopicMetadata;

  /**
   * @method topicMetadata is an optional getter method for _topicMetadata
   */
  get topicMetadata(): ITopicMetadata {
    return this._topicMetadata;
  }

  /**
   * @method topicMetadata is an optional setter method for _topicMetadata
   * @param {ITopicMetadata} value is the input value for setting _topicMetadata
   */
  set topicMetadata(value: ITopicMetadata) {
    // ensure that the 'parentObject', 'parentObjectType' and 'parentId' are set to this instance's 'this', 'objectType' and 'id', respectively.
    value.parentObject = this;
    value.parentObjectType = this.objectType;
    value.parentId = this.id;
    this._topicMetadata = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {ITopic} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): ITopic {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: ITopic = Object.create(Topic.prototype);
    Object.assign(copyOfObject, this);

    // copy the contained objects
    if (this.topicMetadata !== undefined) {
      copyOfObject.topicMetadata = Object.create(TopicMetadata.prototype);
      copyOfObject.topicMetadata = this.topicMetadata.copy();
    }

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): ITopicAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: ITopicAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.name = this.name;
      jsonObject.namePluralized = this.namePluralized;
      jsonObject.description = this.description;
      jsonObject.topicItemNameAliasSingular = this.topicItemNameAliasSingular;
      jsonObject.topicItemNameAliasPlural = this.topicItemNameAliasPlural;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        jsonObject.topicMetadata = this.topicMetadata.toJSON(true);
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {ITopicAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A ImageLink instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: ITopicAsJson, includeContainedObjects: boolean = true): ITopic {
    try {
      // create a new instance of this class
      let topicObject: Topic = Object.create(Topic.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      topicObject = super.fromJSONProtected(topicObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      topicObject.name = (jsonObject.name !== undefined) ? jsonObject.name : '';

      topicObject.namePluralized = (jsonObject.namePluralized !== undefined) ? jsonObject.namePluralized : '';

      topicObject.description = (jsonObject.description !== undefined) ? jsonObject.description : '';

      topicObject.topicItemNameAliasSingular = (jsonObject.topicItemNameAliasSingular !== undefined) ? jsonObject.topicItemNameAliasSingular : '';

      topicObject.topicItemNameAliasPlural = (jsonObject.topicItemNameAliasPlural !== undefined) ? jsonObject.topicItemNameAliasPlural : '';

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        if (jsonObject.topicMetadata) {
          topicObject.topicMetadata = JsonConverter.fromJSON(TopicMetadata, jsonObject.topicMetadata);
        }
      }

      return topicObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/


}
