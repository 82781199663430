import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/styles';
import { ISocialMediaPost } from '../../../dataObjects/models/digitalMedia/SocialMediaPost';
import { enumDigitalMediaDisplayEnvironment } from '../../../dataObjects/enums';
import { MdbError } from '../../../errorObjects/MdbError';
import { DigitalMediaNotAvailableDisplay } from '../DigitalMediaNotAvailableDisplay/DigitalMediaNotAvailableDisplay';
import { MessagesStringAssets } from '../../../assets/stringAssets';
import { urlIsAFacebookAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/facebookUtilities';
import { transformUrlAsNeededForInstagram, urlIsAnInstagramAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/instagramUtilities';
import { urlIsALinkedInAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/linkedInUtilities';
import { urlIsATruthSocialAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/truthSocialUtilities';
import { urlIsATwitterOrXAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/twitterOrXUtilities';
import { urlIsATiktokAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/tiktokUtilities';
import { FacebookEmbed, InstagramEmbed, LinkedInEmbed, PinterestEmbed, TikTokEmbed, XEmbed } from 'react-social-media-embed';
import { urlIsAPinterestAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/pinterestUtilities';
import { urlIsAGabAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/gabUtilities';

enum SocialMediaPostType {
  Undefined = 'Undefined',
  Facebook = 'Facebook',
  Gab = 'Gab',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  Pinterest = 'Pinterest',
  TikTok = 'TikTok',
  TruthSocial = 'TruthSocial',
  TwitterOrX = 'TwitterOrX'
}

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>) that will serve as a base container in a Card View
const StyledContainerForCardView = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(0, .5, 2, .5),
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '1px',
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '250px',
  width: 'auto'
})) as typeof Box; // added casting to 'as typeof Box' as workaround for a Material UI issue (see https://github.com/mui/material-ui/issues/38274)

// a styled Box (equivalent to a <div>) that will serve as a base container in a Form View
const StyledContainerForFormView = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(2, 0),
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '1px',
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '250px',
  width: 'auto'
})) as typeof Box; // added casting to 'as typeof Box' as workaround for a Material UI issue (see https://github.com/mui/material-ui/issues/38274)

// a styled Box (equivalent to a <div>) that will serve as a base container in a List View
const StyledContainerForListView = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(1, 2, 1, 0),
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '1px',
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '250px',
  width: 'auto',

  [theme.breakpoints.down('xs')]: {
    maxWidth: '300px',
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '350px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '500px',
  },
})) as typeof Box; // added casting to 'as typeof Box' as workaround for a Material UI issue (see https://github.com/mui/material-ui/issues/38274)

// a styled Box (equivalent to a <div>), providing a background for a not available message
const SocialMediaNotAvailableBox = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.light}, #BBB, #EEE, ${theme.palette.primary.light}, #BBB, #EEE, ${theme.palette.primary.light})`,
  display: 'flex',
  justifyContent: 'center',
}));

/**
 * @interface ISocialMediaPostDisplayProps declares any input properties required for this component.
 */
export interface ISocialMediaPostDisplayProps extends PropsWithChildren<unknown> {
  socialMediaPost: ISocialMediaPost;
  displayEnvironment: enumDigitalMediaDisplayEnvironment;
}

/**
 * @function SocialMediaPostDisplay is a React functional component for displaying a social media post (via an ISocialMediaPost).
 * @param {} props are the properties passed into the component (currently no input properties).
 */
export const SocialMediaPostDisplay: React.FC<ISocialMediaPostDisplayProps> = (props: ISocialMediaPostDisplayProps) => {
  SocialMediaPostDisplay.displayName = 'Social Media Post Display';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log(`SocialMediaPostDisplay. Entered SocialMediaPostDisplay`);

  const { socialMediaPost, displayEnvironment } = props;

  // whether the socialMediaPost source is supported
  const [supportedSocialMediaSource, setSupportedSocialMediaSource] = useState<boolean>(false);

  // the height of the socialMediaPost display component
  const [componentHeight, setComponentHeight] = useState<string>('3rem');

  // the URL that will be set for the socialMediaPost display object
  const [socialMediaUrl, setSocialMediaUrl] = useState<string>('');

  // the type of social media post (Facebook, TwitterX, etc.)
  const [socialMediaPostType, setSocialMediaPostType] = useState<SocialMediaPostType>(SocialMediaPostType.Undefined);

  const socialMediaNotAvailableWidth: string = `100%`;
  const socialMediaNotAvailableHeight: string = `100%`;

  // useEffect to establish container and socialMediaPost display component parameters based on the socialMediaPost and display environment
  useEffect(() => {
    // The variables that can change are the:
    //   - Whether the Url provided is from a supported social media source
    //   - Url of the social media post being displayed
    //   - Height of the container
    //   - Border radius of the container
    let localSupportedSocialMediaSource: boolean = false;
    let localSocialMediaPostUrl: string = socialMediaPost.url;
    let localSocialMediaPostType: SocialMediaPostType = SocialMediaPostType.Undefined;
    let localComponentHeight: string = '3rem'; // default height

    // if the Url is a Facebook address...
    if (urlIsAFacebookAddress(socialMediaPost.url)) {
      localSocialMediaPostType = SocialMediaPostType.Facebook;
      localComponentHeight = '100%';
    }

    // if the Url is a Gab address...
    if (urlIsAGabAddress(socialMediaPost.url)) {
      localSocialMediaPostType = SocialMediaPostType.Gab;
      localComponentHeight = '100%';
    }

    // if the Url is an Instagram address...
    if (urlIsAnInstagramAddress(socialMediaPost.url)) {
      localSocialMediaPostType = SocialMediaPostType.Instagram;
      localComponentHeight = '100%';
    }

    // if the Url is a LinkedIn address...
    if (urlIsALinkedInAddress(socialMediaPost.url)) {
      localSocialMediaPostType = SocialMediaPostType.LinkedIn;
      localComponentHeight = '100%';
    }

    // if the Url is a Pinterest address...
    if (urlIsAPinterestAddress(socialMediaPost.url)) {
      localSocialMediaPostType = SocialMediaPostType.Pinterest;
      localComponentHeight = '100%';
    }

    // if the Url is a Tik Tok address...
    if (urlIsATiktokAddress(socialMediaPost.url)) {
      localSocialMediaPostType = SocialMediaPostType.TikTok;
      localComponentHeight = '100%';
    }

    // if the Url is a Truth Social address...
    if (urlIsATruthSocialAddress(socialMediaPost.url)) {
      localSocialMediaPostType = SocialMediaPostType.TruthSocial;
      localComponentHeight = '100%';
    }

    // if the Url is a Twitter or X address...
    if (urlIsATwitterOrXAddress(socialMediaPost.url)) {
      localSocialMediaPostType = SocialMediaPostType.TwitterOrX;
      localComponentHeight = '100%';
    }

    displayConsoleLogs && console.log(`SocialMediaPostDisplay. useEffect for setting component specs. componentHeight to be set to: ${localComponentHeight}`);

    setSupportedSocialMediaSource(localSupportedSocialMediaSource);
    setSocialMediaUrl(localSocialMediaPostUrl);
    setSocialMediaPostType(localSocialMediaPostType);
    setComponentHeight(localComponentHeight);

  }, [socialMediaPost.url]);


  /**
   * @function renderSocialMediaPostElement Renders the JSX element of the socialMediaPost object to be displayed on the UI.
   * @param {ISocialMediaPost} socialMediaPost The SocialMediaPost object to be rendered.
   */
  function renderSocialMediaPostElement(): React.JSX.Element {

    // default the socialMediaPost element to displaying no socialMediaPost
    let socialMediaPostElement: React.JSX.Element =
      <>
        <SocialMediaNotAvailableBox
          sx={{ width: socialMediaNotAvailableWidth, height: socialMediaNotAvailableHeight }}
        />
      </>;

    try {

      displayConsoleLogs && console.log(`SocialMediaPostDisplay. renderSocialMediaPostElement(). socialMediaPostType: ${socialMediaPostType}; socialMediaUrl: ${socialMediaUrl}`);

      // if there's an established Url for the object, perform logic
      if (socialMediaUrl) {
        switch (socialMediaPostType) {
          case SocialMediaPostType.Facebook:
            socialMediaPostElement =
              <>
                {/* <FacebookEmbed url={socialMediaUrl} width={'100%'} /> */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <FacebookEmbed url="https://www.facebook.com/photo.php?fbid=451970492960733&set=a.234241234733661&type=3&ref=embed_post" width={'100%'} /> */}
                  {/* <FacebookEmbed url={"https://www.facebook.com/watch?v=777913787094638"} width={350} /> */}
                  <FacebookEmbed url={socialMediaUrl} width={350} />
                </div>
              </>
            break;

          // case SocialMediaPostType.Gab:
          //   socialMediaPostElement =
          //     <>
          //       <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          //         <a href={socialMediaUrl} />
          //       </div>
          //     </>
          //   break;

          case SocialMediaPostType.Instagram:
            const transformedUrlForInstagram: string = transformUrlAsNeededForInstagram(socialMediaUrl);
            socialMediaPostElement =
              <>
                {/* <InstagramEmbed url={socialMediaUrl} width={'100%'} /> */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <InstagramEmbed url={transformedUrlForInstagram} width={350} />
                </div>
              </>
            break;

          // case SocialMediaPostType.LinkedIn:
          //   socialMediaPostElement =
          //     <>
          //       <div style={{ display: 'flex', justifyContent: 'center' }}>
          //         <LinkedInEmbed url={socialMediaUrl} width={350} />
          //       </div>
          //     </>
          //   break;

          // case SocialMediaPostType.Pinterest:
          //   socialMediaPostElement =
          //     <>
          //       <div style={{ display: 'flex', justifyContent: 'center' }}>
          //         <PinterestEmbed url={socialMediaUrl} width={350} />
          //       </div>
          //     </>
          //   break;

          case SocialMediaPostType.TikTok:
            socialMediaPostElement =
              <>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <TikTokEmbed url={socialMediaUrl} width={'100%'} /> */}
                  <TikTokEmbed url={socialMediaUrl} width={350} />
                </div>
              </>
            break;

          // case SocialMediaPostType.TruthSocial:
          //   socialMediaPostElement =
          //     <>
          //       <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          //         <a href={socialMediaUrl} />
          //       </div>
          //     </>
          //   break;

          case SocialMediaPostType.TwitterOrX:
            socialMediaPostElement =
              <>
                <XEmbed url={socialMediaUrl} width={'100%'} />
              </>
            break;

          default:
            socialMediaPostElement =
              <>
                <DigitalMediaNotAvailableDisplay displayText={MessagesStringAssets.socialMediaPostSourceOrFormatNotSupported} />
              </>
            break;
        }
      }
    } catch (error) {
      // Do nothing for now. We just want to ensure that the app doesn't crash the page via an unhandled exception if a social media posting doesn't render correctly.
    }

    return socialMediaPostElement;
  }

  /**
   * @function renderSocialMediaPostContainerAndSocialMediaPostDisplay Renders the JSX element of the socialMediaPost element container and socialMediaPost element to be displayed on the UI.
   */
  function renderSocialMediaPostContainerAndSocialMediaPostDisplay(): React.JSX.Element {

    let socialMediaPostContainerAndSocialMediaPostDisplay: React.JSX.Element =
      <>
      </>

    displayConsoleLogs && console.log(`SocialMediaPostDisplay.renderSocialMediaPostContainerAndSocialMediaPostDisplay(). displayEnvironment: ${displayEnvironment}; componentHeight: ${componentHeight}`);

    switch (displayEnvironment) {
      case enumDigitalMediaDisplayEnvironment.CardView:
        socialMediaPostContainerAndSocialMediaPostDisplay =
          <StyledContainerForCardView sx={{ height: componentHeight }} >
            {renderSocialMediaPostElement()}
          </StyledContainerForCardView>
        break;

      case enumDigitalMediaDisplayEnvironment.FormView:
        socialMediaPostContainerAndSocialMediaPostDisplay =
          <StyledContainerForFormView sx={{ height: componentHeight }} >
            {renderSocialMediaPostElement()}
          </StyledContainerForFormView>
        break;

      case enumDigitalMediaDisplayEnvironment.ListView:
        socialMediaPostContainerAndSocialMediaPostDisplay =
          <StyledContainerForListView sx={{ height: componentHeight }} >
            {renderSocialMediaPostElement()}
          </StyledContainerForListView>
        break;

      default:
        throw new MdbError(`Unhandled display environoment (${displayEnvironment}) in SocialMediaPostDisplay`);
    }

    return socialMediaPostContainerAndSocialMediaPostDisplay;
  }


  return (
    <>
      {renderSocialMediaPostContainerAndSocialMediaPostDisplay()}
    </>
  )
}
