import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ControlsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { INote } from '../../../dataObjects/models/digitalMedia/Note';
import { ISaveNoteRequest } from '../../../dataObjects/models/serviceRequests/note/SaveNoteRequest';

interface INoteFormValues {
  title: string;
  noteContent: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  title: yup
    .string()
    .required(ControlsStringAssets.noteTitleRequired),
  noteContent: yup
    .string()
    .required(ControlsStringAssets.noteNoteContentRequired),
});

export interface INoteFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {INote} note The Note details for the form (will have blank properties values if we're creating a new record)
   */
  note: INote,
  /**
   * @property {boolean} saveRequestInProgress Whether a save request is in progress
   */
  saveRequestInProgress?: boolean,
  /**
   * @property {(note: INote) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (note: ISaveNoteRequest) => Promise<void>,
}

const NoteForm: React.FC<INoteFormProps> = (props: INoteFormProps) => {
  NoteForm.displayName = 'Note Form';

  // get required arguments from props
  const { note, onSubmit } = props;

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit } = useForm<INoteFormValues>({
    defaultValues: {
      title: note.title,
      noteContent: note.noteContent
    },
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: titleReg, ...titleProps } = register("title", { required: true });
  const { ref: noteContentReg, ...noteContentProps } = register("noteContent", { required: true });

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid: formIsValid } = formState;

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);

  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: INoteFormValues) => {

    setSaveInProgress(true);

    // fill in title & noteContent of the Note object passed in
    note.title = data.title;
    note.noteContent = data.noteContent;

    // prepare a request for saving, starting with just the videoLink
    let saveNoteRequest: ISaveNoteRequest = {
      note: note
    };

    // call the onSubmit handler passed in, supplying the Note object
    await onSubmit(saveNoteRequest);
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={MessagesStringAssets.note_SaveRequested}
        formIsValid={formIsValid}
      >
        <TextField
          inputRef={titleReg}
          {...titleProps}
          autoFocus
          label={ControlsStringAssets.noteTitleLabel}
          margin='normal'
          fullWidth
          error={!!errors.title}
          helperText={errors?.title?.message}
          slotProps={{
            inputLabel: {
              required: true // this will cause an asterisk ('*') to appear at the end of the label text
            }
          }}
        />

        <TextField
          inputRef={noteContentReg}
          {...noteContentProps}
          label={ControlsStringAssets.noteNoteContentLabel}
          margin='normal'
          fullWidth
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.noteContent}
          helperText={errors?.noteContent?.message}
          slotProps={{
            inputLabel: {
              required: true // this will cause an asterisk ('*') to appear at the end of the label text
            }
          }}
        />

      </FormWithActionBar>
    </>

  );
}

export default NoteForm;