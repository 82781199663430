import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IUserFeedback } from '../../../dataObjects/models/userFeedback';

const initialState: enumWorkflowState | null = null;

export const userFeedbackDeleteStatusSlice = createSlice({
  name: 'userFeedbackDeleteStatus',
  initialState,
  reducers: {
    // action has a payload with IUserFeedbacks
    userFeedbackDeleteRequest: (state, action: PayloadAction<IUserFeedback>) => {
      userFeedbackDeleteStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    userFeedbackDeleteStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer
export const { userFeedbackDeleteRequest, userFeedbackDeleteStatusChange } = userFeedbackDeleteStatusSlice.actions;

// export the core reducer for the slice
export const userFeedbackDeleteStatusReducer = userFeedbackDeleteStatusSlice.reducer;

export default userFeedbackDeleteStatusSlice.reducer;