import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router";
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { ActionCodeInfo, Auth, UserCredential, applyActionCode, checkActionCode, confirmPasswordReset, getAuth, isSignInWithEmailLink, signInWithEmailLink, verifyPasswordResetCode } from 'firebase/auth';
import { MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import ActivityIndicatorDialog from '../../../dialogs/ActivityIndicatorDialog/ActivityIndicatorDialog';
import EmailVerificationResultsForm from '../../../forms/EmailVerificationResultsForm/EmailVerificationResultsForm';
import SigninWithEmailLinkFailureForm from '../../../forms/SigninWithEmailLinkFailureForm/SigninWithEmailLinkFailureForm';
import { MdbError } from '../../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../../errorObjects/enums';
import { FirebaseAppSingleton } from '../../../../firebaseServices/cloudServices/googleFirebaseServices/FirebaseAppSingleton';
import EmailEntryForm from '../../../forms/EmailEntryForm/EmailEntryForm';
import PasswordWithConfirmationEntryForm from '../../../forms/PasswordWithConfirmationEntryForm/PasswordWithConfirmationEntryForm';
import PasswordResetFailureForm from '../../../forms/PasswordResetFailureForm/PasswordResetFailureForm';
import { ILoginData, LoginData } from '../../../../dataObjects/models/registerAndLogin/LoginData';
import { submitEmailRequestForPasswordResetCompleted, submitEmailRequestForRecoverUserEmailAddress, submitEmailRequestForUserEmailAddressChangedFrom, submitEmailRequestForUserEmailAddressChangedTo } from '../../../../firebaseServices/dataServices/dataServiceActions/emailRequestActions';
import { typeUniqueId } from '../../../../dataObjects/types';
import { IUserEmailIdAndNameViewModel } from '../../../../dataObjects/viewModels/userEmailIdAndName';
import { getUserAccountInfoFromEmailAddressViaHttp } from '../../../../firebaseServices/securityServices/userAccount/userAccountActions/getUserAccountInfoFromEmailAddressViaHttp';
import { Beacon } from '../../../../dataObjects/models/alerts/Beacon';
import { enumBeaconType, enumPersistableObjectType } from '../../../../dataObjects/enums';
import { User } from '../../../../dataObjects/models/users/User';
import { saveUser, updateUserEmailAddress } from '../../../../firebaseServices/dataServices/dataServiceActions/userActions';
import isEmail from 'validator/lib/isEmail';
import VerifyAndChangeEmailResultsForm from '../../../forms/VerifyAndChangeEmailResultsForm/VerifyAndChangeEmailResultsForm';
import { getSingleObjectById } from '../../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { IPersistable } from '../../../../dataObjects/models/persistence/Persistable';
import RecoverEmailResultsForm from '../../../forms/RecoverEmailResultsForm/RecoverEmailResultsForm';
import { IUserIdAndNameAsJson } from '../../../../dataObjects/models/users/UserIdAndName';
import { updateUserEmailAddressViaHttp } from '../../../../firebaseServices/securityServices/userAccount/userAccountActions/updateUserEmailAddressViaHttp';
import { beaconChange } from '../../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
// import { loginStatusChange } from '../../../../uiMiddleware-redux/slices/login/loginStatusSlice';
import { loginRequest } from '../../../../uiMiddleware-redux/slices/login/loginDataSlice';
import { userRegistrationStatusChange } from '../../../../uiMiddleware-redux/slices/registration/userRegistrationStatusSlice'
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';

enum enumQueryStringParams {
  mode = 'mode',
  oobCode = 'oobCode',
  email = 'email',
  apiKey = 'apiKey',
  continueUrl = 'continueUrl',
  lang = 'lang',
}

enum enumModeOptions {
  recoverEmail = 'recoverEmail',
  resetPassword = 'resetPassword',
  signinWithEmailLink = 'signIn',
  verifyAndChangeEmail = 'verifyAndChangeEmail',
  verifyEmail = 'verifyEmail',
}

// the minimum display time in milliseconds
const MIN_DISPLAY_TIME_MS: number = 5000;

/**
 * @interface IAuthActionHandlerPageProps Input properties for the AuthActionHandlerPage
 */
export interface IAuthActionHandlerPageProps extends PropsWithChildren<unknown> {
  // mode?: string  // the mode of the action code to be handled ('resetPassword', 'recoverEmail', 'verifyEmail')
}

/**
 * @module
 * @param props 
 * @returns 
 * @description This page handles actions requested by Firebase Authentication based on user activities. Examples:
 *              1) A user creating a new account will trigger an Email Address Verification action ('verifyEmail').
 *              2) A user requesting to reset his/her password will trigger a Reset Password action ('resetPassword').
 *              3) A user requesting to change his/her email address will trigger a Email Recovery action ('recoverEmail').
 */
const AuthActionHandlerPage: React.FC<IAuthActionHandlerPageProps> = (props: IAuthActionHandlerPageProps) => {

  // http://localhost:3000/action?mode=resetPassword&oobCode=ABC123&apiKey=AIzaSy

  // mode - The user management action to be completed
  // oobCode - A one-time code, used to identify and verify a request
  // apiKey - Your Firebase project's API key, provided for convenience
  // continueUrl - Depending on the mode (action to be performed), could either be where to navigate or could have some 
  //               state information that has been passed (as in the case of verifyAndChangeEmail mode)

  AuthActionHandlerPage.displayName = "Auth Action Handler Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log(`AuthActionHandlerPage. Entering component. `);

  // to be able to navigate to a different page (URL)
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  // The 'useSearchParams' hook will capture the QueryString parameters into a variable for us. We can then
  // request the parameters using the searchParams.get() method to fetch each that is available.
  const [searchParams] = useSearchParams();

  // pate title, which will change based on the authentication mode
  const [pageTitle, setPageTitle] = useState<string>(PageAndViewTitleStringAssets.pageTitle_AuthenticationVerification);

  // activity message to be presented along with an activity indicator
  const [activityMessage, setActivityMessage] = useState<string>('');

  // indicates when the minimum display time for this component has elapsed
  const [minDisplayTimeElapsed, setMinDisplayTimeElapsed] = useState<boolean>(false);

  // will be the value of the action handler 'mode' QueryString parameter
  const [mode, setMode] = useState<string | null>(null);

  // will be the value of the action handler 'oobCode' QueryString parameter
  const [oobCode, setOobCode] = useState<string | null>(null);

  // will be the value of the action handler 'apiKey' QueryString parameter
  const [apiKey, setApiKey] = useState<string | null>(null);

  // will be the value of the action handler 'continueUrl' QueryString parameter
  const [continueUrl, setContinueUrl] = useState<string | null>(null);

  // will be the value of the action handler 'lang' (language) QueryString parameter
  const [lang, setLang] = useState<string | null>(null);

  // whether the Recover Email Action has been completed (regardless of whether the actual email recovery went through or not)
  const [recoverEmailActionCompleted, setRecoverEmailActionCompleted] = useState<boolean>(false);

  // whether the Recover Email Action was successful
  const [recoverEmailSuccessful, setRecoverEmailSuccessful] = useState<boolean>(false);

  // whether the Password Reset Action has been completed (regardless of whether the user changed his/her account password)
  const [passwordResetActionCompleted, setPasswordResetActionCompleted] = useState<boolean>(false);

  // whether the Verify & Change Email Action has been completed 
  const [verifyAndChangeEmailActionCompleted, setVerifyAndChangeEmailActionCompleted] = useState<boolean>(false);

  // whether the Verify & Change Email Action was successful
  const [verifyAndChangeEmailSuccessful, setVerifyAndChangeEmailSuccessful] = useState<boolean>(false);

  // whether the Email Verification Action has been completed (regardless of whether the email address was successfully verified)
  const [emailVerificationActionCompleted, setEmailVerificationActionCompleted] = useState<boolean>(false);

  // whether the Email Verification was successful
  const [emailVerificationSuccessful, setEmailVerificationSuccessful] = useState<boolean>(false);

  // whether the Sign-in With Email Link Action has been completed 
  const [signinWithEmailLinkActionCompleted, setSigninWithEmailLinkActionCompleted] = useState<boolean>(false);

  const [auth] = useState<Auth>(getAuth(FirebaseAppSingleton.getInstance().firebaseApp));

  // for first-time initialization...
  useEffect(() => {
    // capture the QueryString parameters into individual local state variables
    setMode(searchParams.get(enumQueryStringParams.mode));
    setOobCode(searchParams.get(enumQueryStringParams.oobCode));
    setApiKey(searchParams.get(enumQueryStringParams.apiKey));
    setContinueUrl(searchParams.get(enumQueryStringParams.continueUrl));
    setLang(searchParams.get(enumQueryStringParams.lang));
  });

  // this method will reset any registration data in Redux state and clear and Alert Info (also in Redux state)
  const resetRegistrationDataAndAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
    dispatch(userRegistrationStatusChange(null));
  }, [dispatch])

  // useEffect to be executed whenever the emailVerificationActionCompleted flag changes state
  useEffect(() => {
    // if the Email Verification Action has completed, call resetRegistrationDataAndAlertInfo() to clear any Alert Message
    if (emailVerificationActionCompleted) {
      resetRegistrationDataAndAlertInfo();
    }
  }, [emailVerificationActionCompleted]);

  // useEffect to be executed whenever any value of the QueryString parameters changes
  useEffect(() => {
    displayConsoleLogs && console.log(
      `AuthActionHandlerPage. useEffect() based on changes to Query String Parms. `
      + `\n mode: ${mode}`
      + `\n oobCode: ${oobCode}`
      + `\n continueUrl: ${continueUrl}`
      + `\n lang: ${lang}`
    );

    // we can only proceed if we have the mode value (the type of authentication action to be handled)
    if (mode) {
      (async () => {
        try {

          // Handle the user management action.
          switch (mode) {
            case enumModeOptions.recoverEmail:
              // We want this component to be displayed for some minimum amount of time, so that the user can follow what is
              // taking place, so set a timer to trigger when the minimum display time for this component has elapsed.
              setTimeout(() => setMinDisplayTimeElapsed(true), MIN_DISPLAY_TIME_MS);

              displayConsoleLogs && console.log(`AuthActionHandlerPage. Handling recoverEmail`);

              setPageTitle(PageAndViewTitleStringAssets.pageTitle_RecoverEmail);

              if (oobCode) {
                setActivityMessage('MyDigiBrain is recovering your email address...');

                displayConsoleLogs && console.log(`AuthActionHandlerPage. Ready to call handleRecoverEmail()`);

                // call function to handle the RecoverEmail logic and capture the results (true or false)
                // setRecoverEmailSuccessful(await handleRecoverEmail(auth, oobCode));
                setRecoverEmailSuccessful(await handleRecoverEmail(getAuth(FirebaseAppSingleton.getInstance().firebaseApp), oobCode));

                // we have completed the action to attempt to recover the email address, whether it was successful or not
                setRecoverEmailActionCompleted(true);
              }
              break;

            case enumModeOptions.resetPassword:
              displayConsoleLogs && console.log(`AuthActionHandlerPage. Handling resetPassword`);

              setPageTitle(PageAndViewTitleStringAssets.pageTitle_ResetPassword);
              break;

            case enumModeOptions.signinWithEmailLink:
              displayConsoleLogs && console.log(`AuthActionHandlerPage. Handling signinWithEmailLink`);

              setPageTitle(PageAndViewTitleStringAssets.pageTitle_SigninWithEmailLink);
              break;

            case enumModeOptions.verifyAndChangeEmail:
              displayConsoleLogs && console.log(`AuthActionHandlerPage. Processing verifyAndChangeEmail... continueUrl: ${continueUrl}`);

              // We want this component to be displayed for some minimum amount of time, so that the user can follow what is
              // taking place, so set a timer to trigger when the minimum display time for this component has elapsed.
              setTimeout(() => setMinDisplayTimeElapsed(true), MIN_DISPLAY_TIME_MS);

              displayConsoleLogs && console.log(`AuthActionHandlerPage. Handling verifyEmail`);

              setPageTitle(PageAndViewTitleStringAssets.pageTitle_EmailChangeAndVerification);

              if (oobCode) {
                setActivityMessage('MyDigiBrain is verifying your new email address...');

                if (continueUrl) {
                  // need to fetch the email address from the continueUrl
                  const url = new URL(continueUrl);
                  const newEmailAddress: string | null = url.searchParams.get('email');
                  const userId: typeUniqueId | null = url.searchParams.get('userId');

                  displayConsoleLogs && console.log(
                    `AuthActionHandlerPage. Ready to call handleVerifyAndChangeEmail(). `
                    + ` continueUrl: ${continueUrl}`
                    + ` newEmailAddress: ${newEmailAddress}`
                  );

                  // call function to handle the VerifyAndChangeEmail logic and capture the results (true or false)
                  setVerifyAndChangeEmailSuccessful(await handleVerifyAndChangeEmail(auth, oobCode, newEmailAddress, userId));

                  // we have completed the action to Verify & Change Email, whether it was successful or not
                  setVerifyAndChangeEmailActionCompleted(true);
                }
              }
              break;

            case enumModeOptions.verifyEmail:
              // We want this component to be displayed for some minimum amount of time, so that the user can follow what is
              // taking place, so set a timer to trigger when the minimum display time for this component has elapsed.
              setTimeout(() => setMinDisplayTimeElapsed(true), MIN_DISPLAY_TIME_MS);

              displayConsoleLogs && console.log(`AuthActionHandlerPage. Handling verifyEmail`);

              setPageTitle(PageAndViewTitleStringAssets.pageTitle_EmailVerification);

              if (oobCode) {
                setActivityMessage('MyDigiBrain is verifying your email address...');

                displayConsoleLogs && console.log(`AuthActionHandlerPage. Ready to call handleVerifyEmail()`);

                // call function to handle the VerifyEmail logic and capture the results (true or false)
                setEmailVerificationSuccessful(await handleVerifyEmail(auth, oobCode));

                // we have completed the action to attempt to verify the email address, whether it was successful or not
                setEmailVerificationActionCompleted(true);
              }
              break;

            default:
              // Error: invalid mode.
              return (
                <div className="Action">
                  <h1>Error encountered</h1>
                  <p>The selected page mode is invalid.</p>
                </div>
              );

          }
        } catch (error: any) {
          displayConsoleLogs && console.error(`AuthActionHandlerPage. Error attempting to perform switch(mode) and other logic: ${error.toString()}`);
        }
      })();
    }
  }, [mode, oobCode, apiKey, continueUrl, lang]);

  /**
 * @method handlePasswordForPasswordResetSubmitted Handles submission of password pertaining to a 
 *                                                           Password Reset fulfillment operation
 * @param {string} newPassword The password for user for whom a Password Reset request will be attempted
 */
  function handlePasswordForPasswordResetSubmitted(newPassword: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        if (oobCode) {
          // We want this component to be displayed for some minimum amount of time, so that the user can follow what is
          // taking place, so set a timer to trigger when the minimum display time for this component has elapsed.
          setTimeout(() => setMinDisplayTimeElapsed(true), MIN_DISPLAY_TIME_MS);

          displayConsoleLogs && console.log(`%c Entered AuthActionHandlerPage.handlePasswordForPasswordResetSubmitted(). password: ${newPassword}`, 'background: #00B; color: #fff');

          setActivityMessage('MyDigiBrain is attempting to reset your password...');

          displayConsoleLogs && console.log(`AuthActionHandlerPage. Ready to call handlePasswordReset()`);

          // verify that the password reset code (actionCode) is valid via a call to verifyPasswordResetCode()
          displayConsoleLogs && console.log(`AuthActionHandlerPage.handlePasswordReset(). Ready to call verifyPasswordResetCode()`);
          const emailAddressInCode: string = await verifyPasswordResetCode(auth, oobCode);

          // call function to handle the Password Reset logic and capture the results (true or false)
          const passwordResetResults: boolean = await handlePasswordReset(auth, oobCode, newPassword);

          // get userId and name information for a User record that has the emailAddress
          const userEmaiIdAndName: IUserEmailIdAndNameViewModel = await getUserAccountInfoFromEmailAddressViaHttp(emailAddressInCode);

          displayConsoleLogs && console.log(`%c AuthActionHandlerPage.handlePasswordForPasswordResetSubmitted(). userEmaiIdAndName: ${JSON.stringify(userEmaiIdAndName)}`, 'background: #00B; color: #fff');
          displayConsoleLogs && console.log(`%c AuthActionHandlerPage.handlePasswordForPasswordResetSubmitted(). Ready to call generateEmailRequestForAccountVerification`, 'background: #00B; color: #fff');

          // send email to notify user that the password change has been completed
          await submitEmailRequestForPasswordResetCompleted(userEmaiIdAndName.userId, emailAddressInCode, userEmaiIdAndName.firstName, userEmaiIdAndName.lastName);

          // automatically sign-in the user using the email address and new password
          const loginData: ILoginData = new LoginData(emailAddressInCode, newPassword, undefined, undefined);
          dispatch(loginRequest(loginData));

          // we have completed the action to attempt to reset password, whether it was successful or not
          setPasswordResetActionCompleted(true);

          // if the password reset was successful, set a beacon to inform user and navigate to the app home page
          if (passwordResetResults) {
            // notify user via a Beacon notification that the password reset was successful
            // dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.login_PasswordResetResultsHeader, MessagesStringAssets.login_PasswordResetResultsSuccess)));
            const beacon: Beacon = new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.login_PasswordResetResultsHeader, MessagesStringAssets.login_PasswordResetResultsSuccess);
            dispatch(beaconChange(beacon));

            navigate('/');
          }

          resolve();
        } else {
          throw new MdbError('Invalid action code provided for password reset. Please contact support.');
        }
      } catch (error: any) {
        displayConsoleLogs && console.error(`AuthActionHandlerPage.handlePasswordForPasswordResetSubmitted(). Error attempting to reset password: ${error.toString()}`);
        reject(error);
      }
    });
  }

  /**
 * @method handlePasswordForPasswordResetCanceled Handles cancel of a request to Reset Password
 * @param {void} 
 */
  function handlePasswordForPasswordResetCanceled(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        displayConsoleLogs && console.log(`%c Entered AuthActionHandlerPage.handlePasswordForPasswordResetCanceled().`, 'background: #00B; color: #fff');

        // process for cancelation (navigate to LoginPage)

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
 * @method handleEmailAddressForSigninWithEmailLinkSubmitted Handles submission of an email address pertaining to a 
 *                                                           SigninWithEmailLink fulfillment operation
 * @param {string} emailAddress The email address for user for whom a SigninWithEmailLink request will be attempted
 */
  function handleEmailAddressForSigninWithEmailLinkSubmitted(emailAddress: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // We want this component to be displayed for some minimum amount of time, so that the user can follow what is
        // taking place, so set a timer to trigger when the minimum display time for this component has elapsed.
        setTimeout(() => setMinDisplayTimeElapsed(true), MIN_DISPLAY_TIME_MS);

        displayConsoleLogs && console.log(`%c Entered AuthActionHandlerPage.handleEmailAddressForSigninWithEmailLinkSubmitted(). emailAddress: ${emailAddress}`, 'background: #00B; color: #fff');

        setActivityMessage('MyDigiBrain is signing you in with an email link...');

        displayConsoleLogs && console.log(`AuthActionHandlerPage. Ready to call handleSigninWithEmailLink()`);

        // call function to handle the Sign-in logic and capture the results (true or false)
        const signInWithEmailLinkResults: boolean = await handleSigninWithEmailLink(auth, emailAddress);

        // we have completed the action to attempt to sign-in with email link, whether it was successful or not
        setSigninWithEmailLinkActionCompleted(true);

        // if the sign-in was successful, set a beacon to inform user and navigate to the app home page
        if (signInWithEmailLinkResults) {
          // notify user via a Beacon notification that sign-in via the email link has been successful
          // dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.login_SigninWithEmailLinkResultsHeader, MessagesStringAssets.login_SigninWithEmailLinkResultsSuccess)));
          const beacon: Beacon = new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.login_SigninWithEmailLinkResultsHeader, MessagesStringAssets.login_SigninWithEmailLinkResultsSuccess);
          dispatch(beaconChange(beacon));

          navigate('/');
        }

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
 * @method handleEmailAddressForSigninWithEmailLinkCanceled Handles cancel of a request to SigninWithEmailLink
 * @param {void} 
 */
  function handleEmailAddressForSigninWithEmailLinkCanceled(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        displayConsoleLogs && console.log(`%c Entered AuthActionHandlerPage.handleEmailAddressForSigninWithEmailLinkCanceled().`, 'background: #00B; color: #fff');

        // process for cancelation (navigate to LoginPage)

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  displayConsoleLogs && console.log(`AuthActionHandlerPage. Before 'return' call. mode: ${mode}`);
  (mode === enumModeOptions.verifyEmail) && displayConsoleLogs && console.log(`AuthActionHandlerPage. emailVerificationActionCompleted: ${emailVerificationActionCompleted}; emailVerificationSuccessful: ${emailVerificationSuccessful}`);

  return (
    <GenericPageContainer
      maxWidth="sm"
      showBackButton={false}
      pageTitle={pageTitle}
    >
      {/* If there is an activity message and the minimum time to display the activity indicator hasn't elapsed, 
          display the activity indicator dialog with the message */}
      {activityMessage && !minDisplayTimeElapsed &&
        <ActivityIndicatorDialog activityLabel={activityMessage} />
      }

      {/* If the action 'mode' is Email Verification -AND- the Email Verification action has completed, show the proper form */}
      {minDisplayTimeElapsed && (mode === enumModeOptions.verifyEmail) && emailVerificationActionCompleted &&
        <EmailVerificationResultsForm emailVerificationSuccessful={emailVerificationSuccessful} />
      }

      {/* If the action 'mode' is Recover Email -AND- the Recover Email action has completed, show the proper form */}
      {minDisplayTimeElapsed && (mode === enumModeOptions.recoverEmail) && recoverEmailActionCompleted &&
        <RecoverEmailResultsForm recoverEmailSuccessful={recoverEmailSuccessful} />
      }

      {/* If the action 'mode' is Verify And Change Email -AND- the verifyAndChangeEmail action has completed, show the proper form */}
      {minDisplayTimeElapsed && (mode === enumModeOptions.verifyAndChangeEmail) && verifyAndChangeEmailActionCompleted &&
        <VerifyAndChangeEmailResultsForm verifyAndChangeEmailSuccessful={verifyAndChangeEmailSuccessful} />
      }

      {(mode === enumModeOptions.resetPassword) &&
        <>
          {!passwordResetActionCompleted &&
            <>
              <PasswordWithConfirmationEntryForm instructions={MessagesStringAssets.login_PasswordResetInstructions}
                onSubmit={handlePasswordForPasswordResetSubmitted} onCancel={handlePasswordForPasswordResetCanceled}
              />
            </>
          }
          {minDisplayTimeElapsed && passwordResetActionCompleted &&
            <>
              <PasswordResetFailureForm />
            </>
          }
        </>
      }

      {(mode === enumModeOptions.signinWithEmailLink) &&
        <>
          {!signinWithEmailLinkActionCompleted &&
            <>
              <EmailEntryForm emailAddress='' instructions={MessagesStringAssets.login_SigninWithEmailLinkEmailInstructions}
                onSubmit={handleEmailAddressForSigninWithEmailLinkSubmitted} onCancel={handleEmailAddressForSigninWithEmailLinkCanceled}
              />
            </>
          }
          {minDisplayTimeElapsed && signinWithEmailLinkActionCompleted &&
            <>
              {/* <SigninWithEmailLinkResultsForm signinWithEmailLinkSuccessful={signinWithEmailLinkSuccessful} /> */}
              <SigninWithEmailLinkFailureForm />
            </>
          }
        </>
      }

    </GenericPageContainer>
  )
}

async function handlePasswordReset(auth: Auth, actionCode: string, newPassword: string): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;
    // displayConsoleLogs && console.log(`AuthActionHandlerPage. Entering handlePasswordReset(). password: ${newPassword}`);

    try {
      // call method to save the new password
      await confirmPasswordReset(auth, actionCode, newPassword);

      // if no exception was thrown from the above logic, it means that the the password change was successful;
      // thus, return (resolve to) 'true', indicating that the password change was successful
      resolve(true);
    } catch (error) {
      displayConsoleLogs && console.log(`AuthActionHandlerPage.handlePasswordReset(). error encountered: ${JSON.stringify(error)}`);
      // since an exception was thrown, the password change failed; thus, return (resolve to) 'false'
      resolve(false);
    }

  });
}

/**
 * @function handleRecoverEmail This function serves to recover (reinstate) an email address for a user account after the email
 *                              address for the account had been changed. Typically, this use case is performed if the user's 
 *                              account was hacked and the email address changed. Thus, it provides the user with the ability
 *                              to restore the email address prior to the email address change.
 * @param {Auth} auth Firebase Auth object
 * @param {string} actionCode An action code (oobCode) that was generated by Firebase
 * @returns {boolean} A boolean value indicating whether the function was successful.
 */
async function handleRecoverEmail(auth: Auth, actionCode: string): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log(`AuthActionHandlerPage. Entering handleRecoverEmail(). actionCode: ${actionCode}; auth: ${JSON.stringify(auth)}`);
    displayConsoleLogs && console.log(`AuthActionHandlerPage. In handleRecoverEmail(). auth.currentUser: ${JSON.stringify(auth.currentUser)}`);

    try {
      // Confirm the action code is valid
      displayConsoleLogs && console.log(`AuthActionHandlerPage. In handleRecoverEmail(). Ready to call checkActionCode().`);
      const actionCodeInfo: ActionCodeInfo = await checkActionCode(auth, actionCode);
      displayConsoleLogs && console.log(`AuthActionHandlerPage. In handleRecoverEmail(). After checkActionCode(), actionCodeInfo: ${JSON.stringify(actionCodeInfo)}`);

      // get the recovered email address from the ActionCodeInfo (the email address that will be assigned to the account upon recovery)
      const recoveredEmailAddress: string | null | undefined = actionCodeInfo['data']['email'];

      displayConsoleLogs && console.log(`AuthActionHandlerPage. In handleRecoverEmail(). recoveredEmailAddress: ${recoveredEmailAddress}`);

      // get the previous email address from the ActionCodeInfo (the email address that is currently assigned to the account, before recovery)
      const previousEmailAddress: string | null | undefined = actionCodeInfo['data']['previousEmail'];

      displayConsoleLogs && console.log(`AuthActionHandlerPage. In handleRecoverEmail(). previousEmailAddress: ${previousEmailAddress}`);

      let userIdAndName: IUserIdAndNameAsJson | undefined = undefined;

      if (recoveredEmailAddress && previousEmailAddress) {
        // request the UserIdAndName from the server
        userIdAndName = await getUserAccountInfoFromEmailAddressViaHttp(previousEmailAddress);
        displayConsoleLogs && console.log(`AuthActionHandlerPage. In handleRecoverEmail(). userIdAndName: ${JSON.stringify(userIdAndName)}`);

        // if we were able to obtain the userIdAndName, perform the rest of the steps
        if (!userIdAndName) {
          throw new MdbError(`Unable to retrieve user information from the server via email address (${previousEmailAddress})`, enumMdbErrorType.DataNotFound);
        } else {

          const userId = userIdAndName.userId;
          const firstName = userIdAndName.firstName;
          const lastName = userIdAndName.lastName;

          // apply the action code to trigger recovery of the email address for the account
          await applyActionCode(auth, actionCode);

          // if no exception was thrown from the applyActionCode() call, it means that the RecoverEmail was successful;
          // now, call a server function to update the email address in the DB user record
          await updateUserEmailAddressViaHttp(userId, recoveredEmailAddress);

          displayConsoleLogs && console.log(`AuthActionHandlerPage. In handleRecoverEmail(). Ready to call submitEmailRequestForRecoverUserEmailAddress().`);
          // submit request for an email message to the user
          await submitEmailRequestForRecoverUserEmailAddress(userId, recoveredEmailAddress, firstName, lastName, previousEmailAddress);
        }
      }

      // if no exception was thrown from the applyActionCode() call, it means that the recover email was successful;
      // thus, return (resolve to) 'true', indicating that the recover email was successful
      resolve(true);
    } catch (error) {
      displayConsoleLogs && console.log(`AuthActionHandlerPage.handleRecoverEmail(). error encountered: ${JSON.stringify(error)}`);
      // since an exception was thrown, the recover email failed; thus, return (resolve to) 'false'
      resolve(false);
    }

  });
}

async function handleSigninWithEmailLink(auth: Auth, userEmailAddress: string): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;
    displayConsoleLogs && console.log(`AuthActionHandlerPage. Entering handleSigninWithEmailLink(). userEmailAddress: ${userEmailAddress}`);

    // get the full URL (considered the emailLink)
    const emailLink: string = window.location.href;
    displayConsoleLogs && console.log(`AuthActionHandlerPage.handleSigninWithEmailLink(). emailLink (window.location.href): ${emailLink}`);

    try {
      displayConsoleLogs && console.log(`AuthActionHandlerPage.handleSigninWithEmailLink(). Ready to call isSignInWithEmailLink()`);

      const signinLinkValid: boolean = await isSignInWithEmailLink(auth, emailLink);
      displayConsoleLogs && console.log(`AuthActionHandlerPage.handleSigninWithEmailLink(). Response from isSignInWithEmailLink(): ${signinLinkValid}`);

      if (signinLinkValid) {
        displayConsoleLogs && console.log(`AuthActionHandlerPage.handleSigninWithEmailLink(). Ready to call signInWithEmailLink()`);
        // const userCredential: UserCredential = await signInWithEmailLink(auth, 'tkent_dna@hotmail.com', actionCode);
        const userCredential: UserCredential = await signInWithEmailLink(auth, userEmailAddress, emailLink);
        displayConsoleLogs && console.log(`AuthActionHandlerPage.handleSigninWithEmailLink(). Response from signInWithEmailLink(): ${JSON.stringify(userCredential)}`);
      } else {
        throw new MdbError(`Unable to sign-in with link provided (${emailLink})`, enumMdbErrorType.AuthenticationError);
      }

      // if no exception was thrown from the above logic, it means that the sign-in was successful;
      // thus, return (resolve to) 'true', indicating that the sign-in was successful
      resolve(true);
    } catch (error) {
      displayConsoleLogs && console.log(`AuthActionHandlerPage.handleSigninWithEmailLink(). error encountered: ${JSON.stringify(error)}`);
      // since an exception was thrown, the sign-in failed; thus, return (resolve to) 'false'
      resolve(false);
    }

  });
}

async function handleVerifyAndChangeEmail(auth: Auth, actionCode: string, newEmailAddress: string | null, userId: typeUniqueId | null): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log(`AuthActionHandlerPage. Entering handleVerifyAndChangeEmail(). actionCode: ${actionCode}; newEmailAddress: ${newEmailAddress}; userId: ${userId}`);
    displayConsoleLogs && console.log(`AuthActionHandlerPage. In handleVerifyAndChangeEmail(). auth.currentUser: ${JSON.stringify(auth.currentUser)}`);

    try {
      // validate newEmailAddress
      if (!newEmailAddress) {
        throw new MdbError(`AuthActionHandlerPage. A new email address is required.`);
      } else if (!isEmail(newEmailAddress)) {
        throw new MdbError(`AuthActionHandlerPage. The new email address value must be a valid email address.`);
      }

      // validate userId
      if (!userId) {
        throw new MdbError(`AuthActionHandlerPage. A userId is required.`);
      }

      displayConsoleLogs && console.log(`AuthActionHandlerPage. In handleVerifyAndChangeEmail(). Ready to call applyActionCode().`);

      await applyActionCode(auth, actionCode);

      displayConsoleLogs && console.log(`AuthActionHandlerPage. In handleVerifyAndChangeEmail(). After call to applyActionCode(). auth.currentUser?.email: ${auth.currentUser?.email}`);

      // if no exception was thrown from the applyActionCode() call, it means that the VerifyAndChangeEmail was successful;
      // Get the user object so that we can change the email address in the userSettings sub-object.
      displayConsoleLogs && console.log(`AuthActionHandlerPage. In handleVerifyAndChangeEmail(). Ready to call getSingleObjectById().`);
      await getSingleObjectById(userId, enumPersistableObjectType.User, async (user: IPersistable | undefined) => {
        displayConsoleLogs && console.log(`AuthActionHandlerPage. In handleVerifyAndChangeEmail(), returned from getSingleObjectById. user: ${JSON.stringify(user)}`);
        if (!user) {
          throw new MdbError(`AuthActionHandlerPage. In handleVerifyAndChangeEmail(). Unable to retrieve user.`)
        } else if (!(user instanceof User)) {
          throw new MdbError(`AuthActionHandlerPage. In handleVerifyAndChangeEmail(). Fetched user object that is not of type 'User'.`)
        } else {
          user.userSettings.email = newEmailAddress;
          displayConsoleLogs && console.log(`AuthActionHandlerPage. In handleVerifyAndChangeEmail(). Ready to call saveUser().`);
          await saveUser(user);

          // now, we will send emails to the old and new email addresses informing of the change in email address
          const firstName: string = user.userSettings.firstName;
          const lastName: string = user.userSettings.lastName;
          const oldEmailAddress: string | null | undefined = auth.currentUser?.email;

          if (firstName && lastName && oldEmailAddress) {
            // request to submit an email message to the old email address
            await submitEmailRequestForUserEmailAddressChangedFrom(userId, oldEmailAddress, firstName, lastName, newEmailAddress);

            // request to submit an email message to the new email address
            await submitEmailRequestForUserEmailAddressChangedTo(userId, newEmailAddress, firstName, lastName, oldEmailAddress);
          }

        }
      });

      // return (resolve to) 'true', indicating that the process was successful
      resolve(true);
    } catch (error) {
      displayConsoleLogs && console.log(`AuthActionHandlerPage.handleVerifyAndChangeEmail(). error encountered: ${JSON.stringify(error)}`);
      // since an exception was thrown, the VerifyAndChangeEmail failed; thus, return (resolve to) 'false'
      resolve(false);
    }

  });
}

async function handleVerifyEmail(auth: Auth, actionCode: string): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      await applyActionCode(auth, actionCode);

      // if no exception was thrown from the applyActionCode() call, it means that the email verification was successful;
      // thus, return (resolve to) 'true', indicating that the verification was successful
      resolve(true);
    } catch (error) {
      // since an exception was thrown, the email verification failed; thus, return (resolve to) 'false'
      resolve(false);
    }

  });
}

export default AuthActionHandlerPage;