import React, { PropsWithChildren, useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { listViewStyles } from '../../../styles';
import ChannelSharingUserListViewItem from './ChannelSharingUserListViewItem';
import { IChannelSharingUser } from '../../../../dataObjects/viewModels/channelSharing/ChannelSharingUser';
import { ICurrentUserChannelSharingViewModel } from '../../../../dataObjects/viewModels/channelSharing/CurrentUserChannelSharingViewModel';


export interface IChannelSharingUsersListViewProps extends PropsWithChildren<unknown> {
  users: Array<IChannelSharingUser> | undefined;
  currentUserChannelSharingViewModel: ICurrentUserChannelSharingViewModel;
  onRevoke: (user: IChannelSharingUser) => void;
}

export const ChannelSharingUsersListView: React.FC<IChannelSharingUsersListViewProps> = (props: IChannelSharingUsersListViewProps) => {
  ChannelSharingUsersListView.displayName = 'Channel Sharing User List View';

  // eslint-disable-next-line no-console
  // console.info(`Render ChannelSharingUsersListView`);

  const classes: Record<string, string> = listViewStyles();

  const { users, currentUserChannelSharingViewModel, onRevoke } = props;

  const usersSorted = _.sortBy(users, ['userLastName', 'userFirstName']);


  return (
    <>
      {/* overall grid for list. There will be a header row and two data rows: 
          - Header Row
          - Data Row 1: Name & Action Buttons
          - Data Row 2: Description
      */}
      <Grid container direction="column" >
        {/* Header Row */}
        <Grid container className={classes.tableHeader} size={12} direction="row">
          <Grid size={{ xs: 12, md: 10 }} >
            <Typography variant='h6'>
              User / Email (Permission)
            </Typography>
          </Grid>
          <Grid container className={classes.actionButtonLabelContainer} size={{ md: 1 }} justifyContent='center' >
            {/* Only display the next label if the screen size is Medium or larger */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }} >
              <Typography variant='h6'>
                Edit
              </Typography>
            </Box>
          </Grid>
          <Grid container className={classes.actionButtonLabelContainer} size={{ md: 1 }} justifyContent='center' >
            {/* Only display the next label if the screen size is Medium or larger */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }} >
              <Typography variant='h6'>
                Revoke
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {
          usersSorted &&
          usersSorted.map((user, idx) => (
            <ChannelSharingUserListViewItem
              key={user.userId}
              channelSharingUser={user}
              currentUserChannelSharingViewModel={currentUserChannelSharingViewModel}
              onRevoke={onRevoke}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default ChannelSharingUsersListView;
