/**
 * @enum enumPersistableObjectType provides an enumeration of class types that are
 * persistable.
 * (Note: Try to keep the enumerations in alphabetical order to simplify viewing.)
 */
export enum enumPersistableObjectType {
  AudioLink = 'AudioLink',
  Category = 'Category',
  CategoryAssociation = 'CategoryAssociation',
  CategoryMetadata = 'CategoryMetadata',
  Channel = 'Channel',
  ChannelMetadata = 'ChannelMetadata',
  DigitalMedia = 'DigitalMedia',
  DocumentLink = 'DocumentLink',
  EmailRequest = 'EmailRequest',
  HyperLink = 'HyperLink',
  ImageLink = 'ImageLink',
  LocaleTranslation = 'LocaleTranslation',
  Membership = 'Membership',
  MembershipSubscription = 'MembershipSubscription',
// NoParent = '',
  Note = 'Note',
  ObjectSharingRequestTracker = 'ObjectSharingRequestTracker',
  PersistenceMetadata = 'PersistenceMetadata',
  PersistenceCreateMetadata = 'PersistenceCreateMetadata',
  PersistenceDeleteMetadata = 'PersistenceDeleteMetadata',
  PersistenceUpdateMetadata = 'PersistenceUpdateMetadata',
  PersistenceUserDeviceMetadata = 'PersistenceUserDeviceMetadata',
  SocialMediaPost = 'SocialMediaPost',
  ThemeSpecs = 'ThemeSpecs',
  ThemeSpecsMetadata = 'ThemeSpecsMetadata',
  Topic = 'Topic',
  TopicItem = 'TopicItem',
  TopicItemMetadata = 'TopicItemMetadata',
  TopicMetadata = 'TopicMetadata',
  User = 'User',
  UserCategories = 'UserCategories',
  UserFeedback = 'UserFeedback',
  UserRedemptionCodeRequest = 'UserRedemptionCodeRequest',
  UserSettings = 'UserSettings',
  VideoLink = 'VideoLink'
}
