import { enumUserFeedbackType } from '..';
import { MdbError } from '../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../errorObjects/enums';

/**
 * @class enumUserFeedbackTypeConvert Provides to/from string conversions for enumUserFeedbackType values.
 */
export class enumUserFeedbackTypeConvert {
  public static toString(userFeedbackTypeEnum: enumUserFeedbackType): string {
    return userFeedbackTypeEnum.toString();
  }

  public static fromString(userFeedbackTypeString: string): enumUserFeedbackType {
    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = true;

    displayConsoleLogs && console.log(`enumUserFeedbackTypeConvert. Entered fromString(). userFeedbackTypeString: ${userFeedbackTypeString}`);

    let userFeedbackTypeEnum: enumUserFeedbackType = enumUserFeedbackType.Suggestion;

    switch (userFeedbackTypeString) {
      case enumUserFeedbackTypeConvert.toString(enumUserFeedbackType.Compliment):
        userFeedbackTypeEnum = enumUserFeedbackType.Compliment;
        break;

      case enumUserFeedbackTypeConvert.toString(enumUserFeedbackType.Problem):
        userFeedbackTypeEnum = enumUserFeedbackType.Problem;
        break;

      case enumUserFeedbackTypeConvert.toString(enumUserFeedbackType.Suggestion):
        userFeedbackTypeEnum = enumUserFeedbackType.Suggestion;
        break;

      default:
        throw new MdbError(`enumUserFeedbackTypeConvert.fromString. Unrecognized/unsupported enumUserFeedbackType string: ${userFeedbackTypeString}`, enumMdbErrorType.InvalidOperation);
    }

    return userFeedbackTypeEnum;
  }
}