import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import isEmail from 'validator/lib/isEmail';
import { useForm } from 'react-hook-form';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { ControlsStringAssets, KeyValuePairsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import * as yup from 'yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { IUser } from '../../../dataObjects/models/users/User';
import { IObjectSharingRequestTracker } from '../../../dataObjects/models/collaboration/ObjectSharingTracker';
import { enumSharingPermission, enumSharingPermissionConvert, enumSharingRequestStatus } from '../../../dataObjects/enums';
import { getUserAccountInfoFromEmailAddress } from '../../../firebaseServices/securityServices/userAccount/userAccountActions';
import { styled } from '@mui/styles';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import { yupResolver } from '@hookform/resolvers/yup';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled TextField that is serving as a Select control
const StyledTextFieldForSelectControl = styled((props: TextFieldProps) => (
  <TextField
    select
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

interface IChannelSharingRequestFormValues {
  recipientEmail: string;
  recipientName: string;
  sharingPermission: string;
}

let schema = yup.object().shape({});



/**
 * @interface IChannelSharingRequestFormProps Properties for the ChannelSharingRequestForm component
 */
export interface IChannelSharingRequestFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {IObjectSharingRequestTracker} channelSharingRequest The Channel Sharing Request details (an IObjectSharingRequestTracker object) 
   *                                        for the form (will have blank properties values if we're creating a new record)
   */
  channelSharingRequest: IObjectSharingRequestTracker,
  /**
   * @property {boolean} saveRequestInProgress Whether a save request is in progress
   */
  saveRequestInProgress?: boolean,
  /**
   * @property {(channelSharingRequest: IObjectSharingRequestTracker) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (channelSharingRequest: IObjectSharingRequestTracker) => Promise<void>,
}

const ChannelSharingRequestForm: React.FC<IChannelSharingRequestFormProps> = (props: IChannelSharingRequestFormProps) => {
  ChannelSharingRequestForm.displayName = 'Channel Sharing Request Form';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  // get required arguments from props
  const { channelSharingRequest, onSubmit } = props;

  displayConsoleLogs && console.log(`ChannelSharingRequestForm. After assignment from props. channelSharingRequest.recipientEmail: ${channelSharingRequest.recipientEmail}`);

  // use a copy of the channelSharingRequest during the processing of this form, so that we have the ability to revert to the 
  // contents of the original channelSharingRequest, as needed
  const [channelSharingRequestCopy, setChannelSharingRequestCopy] = useState<IObjectSharingRequestTracker>(channelSharingRequest);

  // indicates whether the value in the Recipient Email Address field matches the email of the current user
  const [recipientEmailMatchesCurrentUser, setRecipientEmailMatchesCurrentUser] = useState<boolean>(false);

  const schema = yup.object().shape({
    recipientEmail: yup
      .string()
      .email(ControlsStringAssets.emailInvalid)
      .required(ControlsStringAssets.channelSharingRecipientEmailRequired)
      .test("is-valid", (message) => `${message.path} is invalid`, (value) => value ? isEmail(value) : new yup.ValidationError(ControlsStringAssets.channelSharingRecipientEmailRequired))
      .test("is-recipient-email-same-as-current-user", ControlsStringAssets.channelSharingRecipientEmailCantMatchCurrentUser, () => {
        return (!recipientEmailMatchesCurrentUser);
      }),
    recipientName: yup
      .string()
      .required(ControlsStringAssets.channelSharingRecipientNameRequired),
    sharingPermission: yup
      .string()
      .required(),
  });

  // set up details for ReactHookForm
  // const { register, formState, formState: { errors }, handleSubmit, reset } = useForm<IChannelSharingRequestFormValues>({
  const { register, formState, formState: { errors }, handleSubmit, reset } = useForm<IChannelSharingRequestFormValues>({
    defaultValues: {
      recipientEmail: channelSharingRequestCopy.recipientEmail,
      recipientName: channelSharingRequestCopy.recipientName,
      sharingPermission: (channelSharingRequestCopy.sharingPermission !== undefined) ? channelSharingRequestCopy.sharingPermission : enumSharingPermission.ViewContent,
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: recipientEmailReg, ...recipientEmailProps } = register("recipientEmail", { required: true });
  const { ref: recipientNameReg, ...recipientNameProps } = register("recipientName", { required: true });
  const { ref: sharingPermissionReg, ...sharingPermissionProps } = register("sharingPermission", { required: true });


  const [sharingPermissionsForDropdown, setSharingPermissionsForDropdown] = useState<Array<React.JSX.Element>>([]);

  // const [sharingPermissionsKey, setSharingPermissionsKey] = useState<string>("");
  const [sharingPermissionsKey, setSharingPermissionsKey] = useState<string>(channelSharingRequestCopy.sharingPermission);

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c ChannelSharingRequestForm. currentUser: \n${JSON.stringify(currentUser)}`, 'background: #600; color: #fff');

  // will hold the current value of the Recipient Email Address field
  // const [recipientEmailCurrentValue, setRecipientEmailCurrentValue] = useState<string>("");
  const [recipientEmailCurrentValue, setRecipientEmailCurrentValue] = useState<string>(channelSharingRequestCopy.recipientEmail);

  // will hold the current value of the Recipient Name field
  // const [recipientNameCurrentValue, setRecipientNameCurrentValue] = useState<string>("");
  const [recipientNameCurrentValue, setRecipientNameCurrentValue] = useState<string>(channelSharingRequestCopy.recipientName);

  // if the email changes from a user to a non-user, will hold whatever value had been in the Recipient Name field
  const [recipientNameForNonUser, setRecipientNameForNonUser] = useState<string>(channelSharingRequestCopy.recipientName);

  // the User object for the Recipient User (means that the Recipient Email belongs to an existing user)
  const [recipientUser, setRecipientUser] = useState<IUser | undefined>(undefined);

  // // A function to dynamically set the Yup schema on demand
  // const setYupSchema: () => void = useCallback((): void => {
  //   displayConsoleLogs && console.log(`In ChannelSharingRequestForm.setYupSchema. recipientEmailMatchesCurrentUser: ${recipientEmailMatchesCurrentUser}`);

  //   const formSchema = yup.object().shape({
  //     recipientEmail: yup
  //       .string()
  //       .email(ControlsStringAssets.emailInvalid)
  //       .required(ControlsStringAssets.channelSharingRecipientEmailRequired)
  //       .test("is-valid", (message) => `${message.path} is invalid`, (value) => value ? isEmail(value) : new yup.ValidationError(ControlsStringAssets.channelSharingRecipientEmailRequired))
  //       .test("is-recipient-email-same-as-current-user", ControlsStringAssets.channelSharingRecipientEmailCantMatchCurrentUser, () => {
  //         return (!recipientEmailMatchesCurrentUser);
  //       }),
  //     recipientName: yup
  //       .string()
  //       .required(ControlsStringAssets.channelSharingRecipientNameRequired),
  //     sharingPermission: yup
  //       .string()
  //       .required(),
  //   });

  //   // schema = object({
  //   //   test: yup.object().shape(formSchema)
  //   // });

  //   formSchema.validateSyncAt('recipientEmail', recipientEmailCurrentValue);

  // }, [currentUser, recipientEmailMatchesCurrentUser]);

  // useEffect to be executed upon mounting of this component
  useEffect(() => {
    // if the channelSharingRequestCopy.recipientEmail has a non-empty value upon the mounting, it means that an existing request
    // has been passed in for editing; thus, at this time, we need to call method to have this form perform appropriate handling
    // upon a change to the recipientEmail
    if (channelSharingRequestCopy.recipientEmail.length > 0) {
      handleRecipientEmailChange(channelSharingRequestCopy.recipientEmail);
    }

    // prepare an array of SharingPermissions values from the enumSharingPermission enumerator that can be used to 
    // populate MenuItem components for the SharingPermissions <Select> component
    let sharingPermissionsMenuItems: Array<React.JSX.Element> = [];
    KeyValuePairsStringAssets.sharingPermissionKeyValuePairs.forEach((keyValuePair: { key: string, value: string }) => {
      // don't add 'None' to the list of options
      if (keyValuePair.key !== enumSharingPermission.None) {
        sharingPermissionsMenuItems.push(<MenuItem key={keyValuePair.key} value={keyValuePair.key}>{keyValuePair.value}</MenuItem>);
      }
    });

    setSharingPermissionsForDropdown(sharingPermissionsMenuItems);
    setSharingPermissionsKey((channelSharingRequestCopy.sharingPermission !== undefined) ? channelSharingRequestCopy.sharingPermission : enumSharingPermission.ViewContent);
  }, []);

  // // useEffect dependent on changes to the currentUser and whether the Recipient Email matches that of the current user
  // useEffect(() => {
  //   setYupSchema();
  // }, [currentUser, recipientEmailMatchesCurrentUser]);

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid: formIsValid } = formState;

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);


  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: IChannelSharingRequestFormValues) => {

    setSaveInProgress(true);

    // fill in recipient information for the ChannelSharingRequest object passed in
    displayConsoleLogs && console.log(`In ChannelSharingRequestForm.handleSaveSubmit. data values... recipientEmail: ${data.recipientEmail}; recipientName: ${data.recipientName}; sharingPermission: ${data.sharingPermission}`);
    channelSharingRequest.recipientEmail = data.recipientEmail;
    channelSharingRequest.recipientName = data.recipientName;
    if (recipientUser !== undefined) {
      channelSharingRequest.recipientUserId = recipientUser.id;
    }
    channelSharingRequest.sharingPermission = enumSharingPermissionConvert.fromString(data.sharingPermission);

    displayConsoleLogs && console.log(`In ChannelSharingRequestForm.handleSaveSubmit. After setting channelSharingRequest. channelSharingRequest:: ${JSON.stringify(channelSharingRequest)}`);

    // call the onSubmit handler passed in, supplying the ChannelSharingRequest object
    await onSubmit(channelSharingRequest);
  }


  // useEffect that is dependent on changes to the recipientUser object
  useEffect(() => {
    // if the recipientUser has been changed and set to an existing user in the DB, ...
    if (recipientUser !== undefined) {
      // set the Recipient Email & Name values of the ChannelSharingRequest object used to drive this form
      channelSharingRequestCopy.recipientEmail = recipientUser.userSettings.email;
      channelSharingRequestCopy.recipientName = `${recipientUser.userSettings.firstName} ${recipientUser.userSettings.lastName}`;

      // reset the status of the request to "New"
      channelSharingRequestCopy.status = enumSharingRequestStatus.New;

      // capture the value that is currently in the Recipient Name field, in case we need to reset that Name value (if the user here
      // changes the Recipient Email to a non-user)
      setRecipientNameForNonUser(recipientNameCurrentValue);
      setRecipientNameCurrentValue(channelSharingRequestCopy.recipientName);
    } else {
      // given that the recipientUser object has changed and has been set to 'undefined', we want to reset the Recipient Email value to 
      // whatever is currently in the field, and set the Recipient Name value
      // to the value that was there before we had set it to the name of an existing user (above)
      channelSharingRequestCopy.recipientEmail = recipientEmailCurrentValue;
      channelSharingRequestCopy.recipientName = recipientNameForNonUser;

      // reset the status of the request to "New"
      channelSharingRequestCopy.status = enumSharingRequestStatus.New;

      setRecipientNameCurrentValue(recipientNameForNonUser);
    }

    // reset the default values of the data behind form fields to have them appear in the form fields
    reset(channelSharingRequestCopy);
  }, [recipientUser]);

  // An 'onChange' handler for the Recipient Email field
  function handleRecipientEmailChange(recipientEmail: string): void {
    displayConsoleLogs && console.log(`In ChannelSharingRequestForm.handleRecipientEmailChange. recipientEmail: ${recipientEmail}`);

    setRecipientEmailCurrentValue(recipientEmail);

    const isRecipientEmailSameAsCurrentUser: boolean = recipientEmail.trim().toLowerCase() === currentUser?.userSettings.email.trim().toLowerCase();

    setRecipientEmailMatchesCurrentUser(isRecipientEmailSameAsCurrentUser);

    // assume that a User has not been found with the recipient email address 
    let userFoundForRecipientEmailAddress: boolean = false;

    // if the Recipient Email Address does NOT match the email address for the current user...
    if (!isRecipientEmailSameAsCurrentUser) {
      // use an IIFE (Immediately Invoked Function Expression) to perform inline operation using async/await
      (async () => {
        try {
          // we're going to determine whether the given email address is associated with an existing user account
          // default is that there is NOT an existing user account associated with the email address
          let userFoundForRecipientEmailAddress: boolean = false;

          // if the recipientEmail address is a valid email address...
          if (isEmail(recipientEmail)) {
            // launch a request to search for a user with the given email address
            const userAccount: IUser | undefined = await getUserAccountInfoFromEmailAddress(recipientEmail);

            if (userAccount !== undefined) {
              // userFoundForRecipientEmailAddress = true;
              displayConsoleLogs && console.log(`ChannelSharingRequestForm. Found a user account associated with email address: ${recipientEmail}`);

              // capture the value of the User record fetched from the database into a local useState variable
              setRecipientUser(userAccount);

              // ... set boolean to indicate that the email address specified for the Recipient Email Address is that of the current user
              userFoundForRecipientEmailAddress = true;
            } else {
              // the recipient email, as processed above, does not belong to an existing user in the database, so set the RecipientUser state to undefined
              if (recipientUser !== undefined) {
                setRecipientUser(undefined);
              }
            }
          }

        } catch (error: any) {
          displayConsoleLogs && console.error(`ChannelSharingRequestForm. Error attempting to get user account for a given email address: ${error.toString()}`);
        }
      })();
    } else {
      // if we made it to here, it means that the Recipient Email Address matches the email for the current user, so we want to 
      // set the RecipientUser to undefined, if it's not already undefined
      if (recipientUser !== undefined) {
        setRecipientUser(undefined);
      }
    }

  }

  // An 'onChange' handler function for the Recipient Name field
  function handleRecipientNameChange(recipientName: string): void {
    displayConsoleLogs && console.log(`In ChannelSharingRequestForm.handleRecipientNameChange. recipientName: ${recipientName}`);

    setRecipientNameCurrentValue(recipientName);
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={MessagesStringAssets.channelSharing_SaveRequested}
        formIsValid={formIsValid}
      >
        <TextField
          inputRef={recipientEmailReg}
          {...recipientEmailProps}
          autoFocus
          type="text"
          label={ControlsStringAssets.channelSharingRecipientEmailLabel}
          margin='normal'
          fullWidth
          error={!!errors.recipientEmail}
          helperText={errors?.recipientEmail?.message}
          slotProps={{
            inputLabel: {
              required: true // this will cause an asterisk ('*') to appear at the end of the label text
            }
          }}
          onChange={e => handleRecipientEmailChange(e.target.value)}
        />

        <TextField
          inputRef={recipientNameReg}
          {...recipientNameProps}
          type="text"
          label={ControlsStringAssets.channelSharingRecipientNameLabel}
          disabled={recipientEmailMatchesCurrentUser || (recipientUser !== undefined)} // disable if the Recipient is an existing user or email matches current user
          margin='normal'
          fullWidth
          error={!!errors.recipientName}
          helperText={errors?.recipientName?.message}
          slotProps={{
            inputLabel: {
              required: true, // this will cause an asterisk ('*') to appear at the end of the label text
              shrink: (recipientNameCurrentValue.length > 0) // If there's any value currently in the Recipient Name field, we want the field label to shrink
              // and appear at the top of the field. This property setting is necessary because the field value
              // can be set dynamically and, in that case, Material UI requires specifying the 'shrink' property
              // to 'TRUE' in order to have the field label appear above the field.
            }
          }}
          onChange={e => handleRecipientNameChange(e.target.value)}
        />

        {/* We use a TextField with 'select' attribute as a pseudo <Select> (or dropdown) control */}
        {/* Only display the field if the dropdown options have been created */}
        {(sharingPermissionsForDropdown.length > 0) &&
          <StyledTextFieldForSelectControl
            inputRef={sharingPermissionReg}
            {...sharingPermissionProps}
            label="Permission"
            margin='normal'
            value={sharingPermissionsKey}
            onChange={e => setSharingPermissionsKey(e.target.value)}
          >
            {sharingPermissionsForDropdown}
          </StyledTextFieldForSelectControl>
        }

      </FormWithActionBar>
    </>

  );
}

export default ChannelSharingRequestForm;
