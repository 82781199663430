import { IFirestoreUserFeedbackRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreUserFeedbackRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IUserFeedback, IUserFeedbackAsJson } from "../../../../dataObjects/models/userFeedback";


export function deleteUserFeedback(userFeedback: IUserFeedback): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a UserFeedback repository from the repository factory
      const firestoreUserFeedbackRepository: IFirestoreUserFeedbackRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.UserFeedback) as
        IFirestoreBaseRepository<IUserFeedback, IUserFeedbackAsJson>;

      // call the repository delete() method to delete the object
      await firestoreUserFeedbackRepository.delete(userFeedback.id);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}