import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { IThemeSpecs, IThemeSpecsAsJson } from '.';
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { ThemeSpecsMetadata, IThemeSpecsMetadata } from '../ThemeSpecsMetadata';
import { JsonConverter } from '../../../utilities/JsonConverter';
import { IThemeBaseColors, ThemeBaseColors } from '../ThemeBaseColors';

// const UNCATEGORIZED_CATEGORY_SUFFIX: string = '-Uncategorized';

/**
 * @class ThemeSpecs Represents a set of specifications for a UI theme
 * {@linkcode IThemeSpecs}
 */
export class ThemeSpecs extends VersionAwarePersistable implements IThemeSpecs {

  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or themeSpecs) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {string} name The name of the themeSpecs
   * @param {IThemeBaseColors} themeBaseColors Base colors that will be used to derive the theme's UI color scheme
   * @param {string} description (optional) A description for the themeSpecs
   * @param {IUserPersistenceData} userPersistenceData (optional) User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    name: string,
    themeBaseColors: IThemeBaseColors,
    description?: string,
    userPersistenceData?: IUserPersistenceData,
  ) {
    super(ownerId, enumPersistableObjectClassName.ThemeSpecs, enumPersistableObjectType.ThemeSpecs, id, parentObjectType, parentId, objectState, userPersistenceData);

    this.name = name;

    if (description) {
      this.description = description;
    }

    this._themeBaseColors = themeBaseColors;

    // initlalize the ThemeSpecsMetadata
    this._themeSpecsMetadata = new ThemeSpecsMetadata(this.ownerId, RandomId.newId(), this.objectType, this.id, enumObjectPersistenceState.New);
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} name represents the name of the themeSpecs
   */
  private _name: string = '';

  // getter for _propName
  get name() {
    return this._name;
  }

  // setter for _propName
  set name(value) {
    this._name = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _description property is the description for the ThemeSpecsItem
   */
  private _description: string = '';

  /**
   * @method description is an optional getter method for _description
   */
  get description() {
    return this._description;
  }

  /**
   * @method description is an optional setter method for _description
   * @param {string} value is the input value for setting _description
   */
  set description(value) {
    this._description = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IThemeBaseColors} _themeBaseColors Bbase colors that will drive a theme's color presentation
   */
  private _themeBaseColors: IThemeBaseColors;

  /**
   * @method themeBaseColors Getter method for _themeBaseColors
   */
  get themeBaseColors() {
    return this._themeBaseColors;
  }

  /**
   * @method themeBaseColors Setter method for _themeBaseColors
   * @param {string} value Input value for setting _themeBaseColors
   */
  set themeBaseColors(value) {
    this._themeBaseColors = value;
  }
  /*-----------------------------------------------*/
 
  /*-----------------------------------------------*/
  /**
   * @property {IThemeSpecsMetadata} _themeSpecsMetadata property (complete the description)
   */
  private _themeSpecsMetadata: IThemeSpecsMetadata;

  /**
   * @method themeSpecsMetadata is an optional getter method for _themeSpecsMetadata
   */
  get themeSpecsMetadata(): IThemeSpecsMetadata {
    return this._themeSpecsMetadata;
  }

  /**
   * @method themeSpecsMetadata is an optional setter method for _themeSpecsMetadata
   * @param {IThemeSpecsMetadata} value is the input value for setting _themeSpecsMetadata
   */
  set themeSpecsMetadata(value: IThemeSpecsMetadata) {
    // ensure that the 'parentObject', 'parentObjectType' and 'parentId' are set to this instance's 'this', 'objectType' and 'id', respectively.
    value.parentObject = this;
    value.parentObjectType = this.objectType;
    value.parentId = this.id;
    this._themeSpecsMetadata = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IThemeSpecs} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IThemeSpecs {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IThemeSpecs = Object.create(ThemeSpecs.prototype);
    Object.assign(copyOfObject, this);

    if (this.themeSpecsMetadata !== undefined) {
      copyOfObject.themeSpecsMetadata = Object.create(ThemeSpecsMetadata.prototype);
      copyOfObject.themeSpecsMetadata = this.themeSpecsMetadata.copy();
    }

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IThemeSpecsAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IThemeSpecsAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.name = this.name;
      jsonObject.description = this.description;
      jsonObject.themeBaseColors = this.themeBaseColors.toJSON();

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        jsonObject.themeSpecsMetadata = this.themeSpecsMetadata.toJSON(true);
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IThemeSpecsAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A ThemeSpecs instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IThemeSpecsAsJson, includeContainedObjects: boolean = true): IThemeSpecs {
    try {
      // create a new instance of this class
      let themeSpecsObject: ThemeSpecs = Object.create(ThemeSpecs.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      themeSpecsObject = super.fromJSONProtected(themeSpecsObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.name !== undefined) {
        themeSpecsObject.name = jsonObject.name;
      }

      if (jsonObject.description !== undefined) {
        themeSpecsObject.description = jsonObject.description;
      }

      if (jsonObject.themeBaseColors !== undefined) {
        themeSpecsObject.themeBaseColors = JsonConverter.fromJSON(ThemeBaseColors, jsonObject.themeBaseColors);
      }

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        if (jsonObject.themeSpecsMetadata !== undefined) {
          themeSpecsObject.themeSpecsMetadata = JsonConverter.fromJSON(ThemeSpecsMetadata, jsonObject.themeSpecsMetadata);
        }
      }

      return themeSpecsObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/
}
