import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router";
import { typeUniqueId } from '../../../dataObjects/types';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import { IUser } from '../../../dataObjects/models/users/User';
import { INote, Note } from '../../../dataObjects/models/digitalMedia/Note';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { getSingleObjectById } from '../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { enumBeaconType, enumObjectPersistenceState, enumPersistableObjectType, enumWorkflowState } from '../../../dataObjects/enums';
import { IUserSettings } from '../../../dataObjects/models/users/UserSettings';
import { RandomId } from '../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../dataObjects/models/persistence/UserPersistenceData';
import { MessagesStringAssets, NotificationStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import NoteForm from '../../forms/NoteForm/NoteForm';
import { ISaveNoteWorkflowState } from '../../../uiMiddleware-redux/store/SaveNoteWorkflowState';
import { ISaveNoteRequest } from '../../../dataObjects/models/serviceRequests/note/SaveNoteRequest';
import { AlertInfo, IAlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { enumAlertType } from '../../enums';
import { Beacon } from '../../../dataObjects/models/alerts/Beacon';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import { useAppDispatch, useAppSelector } from '../../../uiMiddleware-redux/store/configureStore';
import { noteSaveRequest, noteSaveStatusClear, noteSaveWorkflowStateChange } from '../../../uiMiddleware-redux/slices/note/noteSaveStatusSlice';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { beaconChange } from '../../../uiMiddleware-redux/slices/beacon/beaconSlice';

/**
 * @interface IManageNotePageProps Input properties for the ManageNotePage
 */
export interface IManageNotePageProps extends PropsWithChildren<unknown> {
  id?: typeUniqueId,  // if the Id is given, it means that the Note already exists
  parentId?: typeUniqueId  // if the parentId is given, it means that a new Note is to be created
}

/**
 * @function ManageNotePage A React Function Component for manageing (creating or editing) a Note.
 * @param {IManageNotePageProps} props Input properties
 */
const ManageNotePage: React.FC<IManageNotePageProps> = (props: IManageNotePageProps) => {

  ManageNotePage.displayName = "Manage Note Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const params = useParams();

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [noteId, setNoteId] = useState<typeUniqueId | undefined>(params.id);

  // define an effect that will set the noteId anytime the params.id changes
  useEffect(() => {
    setNoteId(params.id);
  }, [params.id]);

  // define an effect based on a change to the noteId. Having a defined noteId means that we will 
  // be editing an existing Note
  useEffect(() => {
    if (noteId !== undefined) {
      // try to find an Note object with the Id given in the URL
      getSingleObjectById(noteId, enumPersistableObjectType.Note, onNoteFetched);
    }
  }, [noteId]);

  // will be set to the 'parentId' provided in the URL parms. If a parentId was provided, it means that we will be creating a new object.
  const [noteParentId, setNoteParentId] = useState<typeUniqueId | undefined>(params.parentId);

  // define an effect that will set the noteParentId anytime the params.parentId changes
  useEffect(() => {
    setNoteParentId(params.parentId);
  }, [params.parentId]);

  // will be set to the note to be managed, either a new note or an existing one
  const [note, setNote] = useState<INote | undefined>(undefined);

  // whether we are going to be managing a new note
  const [isNewObject, setIsNewObject] = useState<boolean>(true);

  const resetSaveNoteStatus: () => void = useCallback(() => {
    dispatch(noteSaveStatusClear());
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetSaveNoteStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveNoteStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveNoteStatus]);

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();

  // workflow state related to saving a Note
  const [saveNoteWorkflowState, setSaveNoteWorkflowState] = useState<ISaveNoteWorkflowState | null | undefined>(undefined);

  const [successfullyCompletedPage, setSuccessfullyCompletedPage] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedPage state changes
  useEffect(() => {
    if (successfullyCompletedPage) {
      // return to previous page
      navigate(-1);

      // dispatch to clear the note save status store state variable
      dispatch(noteSaveWorkflowStateChange(null));

      // notify user of the successful operation
      // ensuring that it's not null or undefined, append the Note Title to the end of the success message
      const successMessage: string = MessagesStringAssets.note_SaveSuccess + (note ? ` (${note.title})` : '');
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, NotificationStringAssets.heading_SaveNote, successMessage)));
    }
  }, [successfullyCompletedPage, dispatch, note, navigate]);

  // get the Redux workflow state for saving an Note
  const currentSaveNoteWorkflowState: ISaveNoteWorkflowState | null | undefined = useAppSelector((state: IStoreState) => state.saveNoteStatus);

  // whenever the workflow state changes, set the object's value into the local state
  useEffect(() => {
    setSaveNoteWorkflowState(currentSaveNoteWorkflowState);
  }, [currentSaveNoteWorkflowState]);

  /**
   * @function prepareNewNote Prepares/creates a new note that will be used if user is requesting to create/add a new note.
   * @param parentChannel 
   */
  const prepareNewNote: (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => INote = useCallback(
    (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => {
      let newNote: INote;

      const newNoteId: typeUniqueId = RandomId.newId();
      const userName: string = `${userSettings.firstName} ${userSettings.lastName}`;
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(user.id, userName);
      newNote = new Note(user.id, newNoteId, enumPersistableObjectType.DigitalMedia, parentId, enumObjectPersistenceState.New,
        '', '', userPersistenceData);

      return newNote;
    }, []
  );

  // define an effect based on a change to the noteParentId & currentUser. Having a defined noteParentId means that we will
  // be creating a new Note
  useEffect(() => {
    if (noteParentId !== undefined && currentUser) {
      setNote(prepareNewNote(noteParentId, currentUser, currentUser.userSettings));
    }
  }, [noteParentId, currentUser, prepareNewNote]);

  /**
   * @function onNoteFetched A callback function that will be used when requesting a data object to be managed
   * @param {INote | undefined} note The data object to be managed
   */
  function onNoteFetched(note: INote | undefined): void {
    if (note !== undefined) {
      // set the returned Note to be the one we are editing
      setNote(note);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing object (default was set to 'true')
      setIsNewObject(false);
    }
  }

  // function that embedded form component is to call when submitting for a save operation
  function handleFormSubmit(saveNoteRequest: ISaveNoteRequest): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch an action to request saving the note
        dispatch(noteSaveRequest(saveNoteRequest));

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  // prepare a variable that may hold alert info
  let alertInfo: IAlertInfo | null = null;

  // prepare a variable to indicate whether a save is in progress
  let saveInProgress: boolean = false;
  if (saveNoteWorkflowState !== null && saveNoteWorkflowState !== undefined) {

    if (saveNoteWorkflowState.workflowState === enumWorkflowState.Success) {
      if (!successfullyCompletedPage) {
        // set flag to indicate that the page has been successfully completed, which will force a re-render to allow for finalizing work for the page
        setSuccessfullyCompletedPage(true);
      }
    } else if (saveNoteWorkflowState.workflowState === enumWorkflowState.InProgress || saveNoteWorkflowState.workflowState === enumWorkflowState.Requested) {
      // indicate that a save is in progress
      saveInProgress = true;
    } else if (saveNoteWorkflowState.workflowState === enumWorkflowState.Failure) {
      let failureMessage = 'Unknown error';
      if (saveNoteWorkflowState.workflowError && saveNoteWorkflowState.workflowError instanceof Error) {
        failureMessage = saveNoteWorkflowState.workflowError.message;
      }
      // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
      alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
      dispatch(alertInfoChange(alertInfo));
    }
  }

  return (
    <GenericPageContainer
      maxWidth="sm"
      showBackButton={true}
      pageTitle={isNewObject ? PageAndViewTitleStringAssets.pageTitle_ManageNote_New : PageAndViewTitleStringAssets.pageTitle_ManageNote_Existing}
      onCloseAlert={resetSaveNoteStatus}
    >
      {note &&
        <NoteForm
          note={note}
          saveRequestInProgress={saveInProgress}
          onSubmit={handleFormSubmit}
        />
      }

    </GenericPageContainer>
  );

} // const ManageNotePage...

export default ManageNotePage;
