import { IUserFeedback } from "../../../../../dataObjects/models/userFeedback";
import { generateStandardEmailClosingHtml, generateStandardEmailOpeningHtml } from "../commonEmailBodyFragments";

/**
 * @function generateEmailBodyHtmlForUserFeedback Generates the Html body of a notification email based on a user submitting feedback
 * @param {IUserFeedback} userFeedback UserFeedback information.
 * @returns {string} A string with the Html body of the email message to be sent.
 */
export function generateEmailBodyHtmlForUserFeedback(userFeedback: IUserFeedback): string {

  // *** Compose the email Body in HTML format ***
  // who submitted the feedback (and UserId)
  let emailBodyHtml = `<p><b>${userFeedback.submitterName}</b>&nbsp;(UserId: ${userFeedback.userId}) has submitted user feedback.</p>`;

  // type of feedback
  emailBodyHtml += `<p>Feeback type: <b>${userFeedback.userFeedbackType}</b></p>`;

  // submission timestamp
  emailBodyHtml += `<p>Submission timestamp: <b>${userFeedback.submissionTimestamp.toLocaleDateString()}</b></p>`;

  // title provided by user
  emailBodyHtml += `<p>Title: <b>${userFeedback.title}</b></p>`;

  // description provided by user
  emailBodyHtml += `<p>Description:</p>`;
  emailBodyHtml += `<p>${userFeedback.description}</p>`;

  emailBodyHtml += generateStandardEmailClosingHtml();

  // return the email content
  return emailBodyHtml;
}
