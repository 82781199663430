import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid2';
import { SharingRequestsCardGridViewItem } from './SharingRequestsCardGridViewItem';
import { ISharingRequestViewModel } from '../../../../dataObjects/viewModels/sharingRequestViewModel';


export interface ISharingRequestsCardGridViewProps extends PropsWithChildren<unknown> {
  sharingRequestViewModels: Array<ISharingRequestViewModel>;
  onAccept: (sharingRequestViewModel: ISharingRequestViewModel) => void;
  onDecline: (sharingRequestViewModel: ISharingRequestViewModel) => void;
}


export const SharingRequestsCardGridView: React.FC<ISharingRequestsCardGridViewProps> = (props: ISharingRequestsCardGridViewProps) => {
  SharingRequestsCardGridView.displayName = 'SharingRequests Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render SharingRequestsCardGridView`);

  const { sharingRequestViewModels, onAccept, onDecline } = props;

  return (
    <>
      <Grid container spacing={2} >
        {
          sharingRequestViewModels.map(sharingRequestViewModel =>
            <Grid key={sharingRequestViewModel.sharingRequest.id} size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
              <SharingRequestsCardGridViewItem sharingRequestViewModel={sharingRequestViewModel} onAccept={onAccept} onDecline={onDecline} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}