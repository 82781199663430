import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";
import { generateStandardEmailClosingHtml, generateStandardEmailOpeningHtml } from "../commonEmailBodyFragments";

/**
 * @function generateEmailBodyHtmlForPasswordResetCompleted. Generates the Html body of a notification email indicating that a password reset request has been completed
 * @param {string} emailAddress The email address of the user requesting the password reset.
 * @param {string} firstName The first name of the user requesting the password reset.
 * @param {string} firstName The last name of the user requesting the password reset.
 * @returns {string} A string with the Html body of the email message to be sent.
 */
export function generateEmailBodyHtmlForPasswordResetCompleted(emailAddress: string, firstName: string, lastName: string): string {

  const recipientUserName: string = `${firstName} ${lastName}`;

  // *** Compose the email Body in HTML format ***
  let emailBodyHtml = generateStandardEmailOpeningHtml(recipientUserName);

  // thank user for registering
  emailBodyHtml += `<p>Your ${ServerSubstitutionKeywordsStringAssets.ProjectName} password for your (${emailAddress}) email account has been successfully changed. </p>`;

  emailBodyHtml += `<p>If you did not request this password change, please go immediately to the <a href='${ServerSubstitutionKeywordsStringAssets.AppUrl}'>${ServerSubstitutionKeywordsStringAssets.ProjectName} application</a> `;
  emailBodyHtml += `and select the 'Forgot Password?' link to reset your password.</p>`;

  emailBodyHtml += `<p>We hope you enjoy using ${ServerSubstitutionKeywordsStringAssets.ProjectName}! </p>`;

  emailBodyHtml += generateStandardEmailClosingHtml();

  // return the email content
  return emailBodyHtml;
}
