import React, { PropsWithChildren, ReactElement, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Box, BoxProps, Container, ContainerProps, Fab as FloatingActionButton, FabProps, Typography, TypographyProps } from '@mui/material';
import Grid, { Grid2Props } from '@mui/material/Grid2';
import {
  ExpandMore as ExpandMoreIcon,
  Share as ShareIcon,
} from '@mui/icons-material';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import { enumAlertType } from '../../enums';
import { IChannel } from '../../../dataObjects/models/channels/Channel';
import { typeUniqueId } from '../../../dataObjects/types';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { enumBeaconType, enumCollectionViewType, enumPersistableObjectType, enumWorkflowState } from '../../../dataObjects/enums';
// import { deleteChannelRequested, deleteChannelStatusReset, setAlertInfo, setBeacon, setCurrentChannel } from '../../../uiMiddleware-redux/actions';
import ChannelsListView from '../../views/channels/ChannelsListView/ChannelsListView';
import { ChannelsCardGridView } from '../../views/channels/ChannelsCardGridView/ChannelsCardGridView';
import TwoButtonAcceptanceDialog from '../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { Beacon, IBeacon } from '../../../dataObjects/models/alerts/Beacon';
import ActivityIndicatorDialog from '../../dialogs/ActivityIndicatorDialog/ActivityIndicatorDialog';
import { IActivityIndicatorData } from '../../dialogs/ActivityIndicatorData';
import { AlertInfo, IAlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { useReduxWorkflowState } from '../../customHooks';
import DataViewControls from '../../controls/DataViewControls/DataViewControls';
import { useGetCollectionViewType } from '../../customHooks/useGetCollectionViewType';
import { ICategory } from '../../../dataObjects/models/categories/Category';
import { IChannelViewModel } from '../../../dataObjects/viewModels/channelViewModel';
import { styled } from '@mui/styles';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import { IUserCategoriesAndChannelsContextData, useUserCategoriesAndChannelsContext } from '../../providersAndContexts/userCategoriesAndChannels';
import { enumCategoriesAndChannelsDataPreparationStatus } from '../../enums';
import { IChannelViewModelsByCategory } from '../../../dataObjects/viewModels/channelViewModelsByCategory';
import { channelDeleteRequest, channelDeleteStatusChange } from '../../../uiMiddleware-redux/slices/channel/channelDeleteStatusSlice';
// import { RootState, useAppDispatch } from '../../../uiMiddleware-redux/store/configureStore';
import { beaconChange } from '../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { useAppDispatch, useAppSelector } from '../../../uiMiddleware-redux/store/configureStore';
// import { useAppDispatch } from '../../customHooks/useAppDispatch';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area of padding at the top of the page
const StyledBoxForPaddingAtopPage = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

// a styled Box (equivalent to a <div>), providing an area of padding above the Data View controls
const StyledBoxForPaddingAboveDataViewControls = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

// a styled Grid for the overall container of the Sharing Requests Floating Action Button Area
const StyledGridForSharingRequestsButtonContainer = styled((props: Grid2Props) => (
  <Grid
    container
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  marginTop: theme.spacing(-4), // we want to eliminate some top marging if the button is being displayed
}));

// a styled Grid for the Sharing Requests Floating Action Button Area
const StyledGridForSharingRequestsFloatingActionButtonArea = styled((props: Grid2Props) => (
  <Grid
    container
    {...props}
  />
))(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center'
}));

// a styled Floating Action Button
const StyledFloatingActionButton = styled((props: FabProps) => (
  <FloatingActionButton
    variant="extended"
    size="medium"
    color="primary"
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Box for a Horizontal Divider (a CSS Horizontal Rule <hr/>)
const StyledBoxForHorizontalDivider = styled((props: BoxProps) => (
  <Box
    component='hr'
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.palette.primary.light}`,
}));

// a styled Container for Data View
const StyledContainerForDataView = styled((props: ContainerProps) => (
  <Container
    disableGutters
    maxWidth={false}
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
}));

// a styled Accordion for Data View
const StyledAccordion = styled((props: any) => (
  <Accordion
    {...props}
  />
))(({ theme }) => ({
  width: '100%'
}));

// a styled AccordionSummary for Pending Users
const StyledAccordionSummary = styled((props: any) => (
  <AccordionSummary
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid for Accordian Summary Content
const StyledGridForAccordionSummaryContent = styled((props: Grid2Props) => (
  <Grid
    container
    size={12}
    direction="row"
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid for an Accordian Summary Header
const StyledGridForAccordionSummaryHeader = styled((props: Grid2Props) => (
  <Grid
    container
    size={12}
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Typography for an Accordian Summary Header
const StyledTypographyForAccordionSummaryHeader = styled((props: TypographyProps) => (
  <Typography
    variant='h6'
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid for an Accordian Summary Description
const StyledGridForAccordionSummaryDescription = styled((props: Grid2Props) => (
  <Grid
    container
    size={12}
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Typography for an Accordian Summary Description
const StyledTypographyForAccordionSummaryDescription = styled((props: TypographyProps) => (
  <Typography
    variant='body1'
    {...props}
  />
))(({ theme }) => ({
}));

// a styled AccordionDetails for Data View
const StyledAccordionDetails = styled((props: any) => (
  <AccordionDetails
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "column"
}));


export interface IHomePageProps extends PropsWithChildren<unknown> {

}

const HomePage: React.FC<IHomePageProps> = (props: IHomePageProps) => {

  HomePage.displayName = 'Home Page';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  // call custom hook to get the current view type, so the value can be used to initialize the View Type state variable
  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  // get UserCategoriesAndChannelsContextData from the UserCategoriesAndChannelsProvider using a custom hook (useUserCategoriesAndChannelsContext)
  const userCategoriesAndChannelsContext: IUserCategoriesAndChannelsContextData = useUserCategoriesAndChannelsContext();
  displayConsoleLogs && console.log(`%c HomePage. userCategoriesAndChannelsContext: \n${JSON.stringify(userCategoriesAndChannelsContext)}`, 'background: #600; color: #fff');

  // capture the status of the userCategoriesAndChannelsContext
  const [userCategoriesAndChannelsDataStatus, setUserCategoriesAndChannelsDataStatus] =
    useState<enumCategoriesAndChannelsDataPreparationStatus>(userCategoriesAndChannelsContext.state.status);

  // Channel View Models to be passed on to views within the page
  const [channelViewModels, setChannelViewModels] = useState<Array<IChannelViewModel>>([]);

  // the Categories associcated with the current user account
  const [categories, setCategories] = useState<Array<ICategory>>([]);

  // all ChannelViewModels for the current user account, organized by Category
  // const [allChannelViewModelsByCategory, setAllChannelViewModelsByCategory] = useState<{ categoryId: string, channelViewModels: IChannelViewModel[], channelViewModelCount: number }[]>([]);
  const [allChannelViewModelsByCategory, setAllChannelViewModelsByCategory] = useState<IChannelViewModelsByCategory[]>([]);

  // whether data is currently loading
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  // if user is deleting a Channel, the Channel to delete
  const [channelToDelete, setChannelToDelete] = useState<IChannel | undefined>(undefined);
  // whether to show the Delete Confirmation dialog
  const [showDeleteConfirmationDlg, setShowDeleteConfirmationDlg] = useState<boolean>(false);

  // for dispatching redux actions
  const dispatch = useAppDispatch();

  // for using browser router navigate
  const navigate = useNavigate();

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c HomePage. currentUser: \n${JSON.stringify(currentUserContextData)}`, 'background: #600; color: #fff');
  const userId: typeUniqueId | undefined = currentUserContextData.currentUser?.id;

  /**
   * @function resetDeleteChannelStatus Resets the Delete status in Redux state
   */
  const resetDeleteChannelStatus: () => void = useCallback(() => {
    // dispatch(deleteChannelStatusReset());
    dispatch(channelDeleteStatusChange(null));
  }, [dispatch])

  // a useEffect hook for the mounting of the component
  useEffect(() => {
    // Upon mounting of this HomePage, inform the Categories & Channels context that there should be no currentChannel.
    // A currentChannel will be set when a channel is selected
    if (userCategoriesAndChannelsContext.actions.setCurrentChannel !== undefined) {
      userCategoriesAndChannelsContext.actions.setCurrentChannel(undefined);
    }
  }, []);

  // a useEffect hook to respond to changes in the status of the Categories and Channels data
  useEffect(() => {
    // when the data preparation is complete, set all data variables
    if (userCategoriesAndChannelsContext.state.status === enumCategoriesAndChannelsDataPreparationStatus.DataPreparationComplete) {
      setCategories(userCategoriesAndChannelsContext.state.categories ? userCategoriesAndChannelsContext.state.categories : []);
      setChannelViewModels(userCategoriesAndChannelsContext.state.channelViewModels ? userCategoriesAndChannelsContext.state.channelViewModels : []);
      setAllChannelViewModelsByCategory(userCategoriesAndChannelsContext.state.channelsByCategories ? userCategoriesAndChannelsContext.state.channelsByCategories : []);

      // indicate that we are no longer loading data
      if (dataLoading) {
        setDataLoading(false);
      }
    } else {
      // if the data has changed and the data preparation is not complete, indicate that we're loading data
      if (!dataLoading) {
        setDataLoading(true);
      }
    }
  }, [userCategoriesAndChannelsContext, userCategoriesAndChannelsContext.state.status, dataLoading]);


  // use a custom hook to evaluate the redux state for the deleteChannel workflow and any deleteChannel error
  const { workflowState: deleteChannelStatus, errorState: deleteChannelFailure } = useReduxWorkflowState((state: IStoreState) => state.deleteChannelStatus, (state: IStoreState) => state.deleteChannelFailure);

  // get from app state whether to display items within Categories
  const displayCategorized: boolean = useAppSelector((state: IStoreState) => state.displayCategorized) ?? false;

  /**
   * @method handleChannelDelete Handles requests to delete a Channel
   * @param channel The Channel to be deleted
   */
  async function handleChannelDelete(channel: IChannel): Promise<void> {
    // set the channel to be deleted into local state
    setChannelToDelete(channel);

    // set flag to show the delete confirmation dialog
    setShowDeleteConfirmationDlg(true);
  }

  function handleChannelDeleteConfirmed(): void {
    // using the channel object to be deleted from the local state, dispatch an action to delete the channel object
    if (channelToDelete !== undefined) {
      dispatch(channelDeleteRequest(channelToDelete));
      dispatch(channelDeleteStatusChange(enumWorkflowState.Requested));
    }

    // call method to reset the channel delete local state details
    channelDeleteReset();
  }

  function handleChannelDeleteCanceled(): void {
    // call method to reset the channel delete local state details
    channelDeleteReset();
  }

  function channelDeleteReset() {
    // reset the channel to be deleted to 'undefined' in local state, since we're done with the object
    setChannelToDelete(undefined);

    // set flag to hide the delete confirmation dialog
    setShowDeleteConfirmationDlg(false);
  }

  // data to indicate whether an activity indicator should be displayed and, if so, the message
  let activityIndicatorData: IActivityIndicatorData = {
    showActivityIndicator: false,
    activityIndicatorMessage: ''
  }

  // data to indicate whether an alert should be displayed
  let alertInfo: IAlertInfo | null = null;


  // indicates whether the object has been successfully deleted
  const [successfullyDeletedObject, setSuccessfullyDeletedObject] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyDeletedObject state changes
  useEffect(() => {
    if (successfullyDeletedObject) {
      // dispatch an action to reset the deleteChannelStatus
      // dispatch(deleteChannelStatusReset());
      dispatch(channelDeleteStatusChange(null));

      // notify user via a Beacon notification that the channel has been deleted
      // dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.channel_DeleteConfirmationHeader, MessagesStringAssets.channel_DeleteSuccess)));
      const beacon: Beacon = new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.channel_DeleteConfirmationHeader, MessagesStringAssets.channel_DeleteSuccess);
      dispatch(beaconChange(beacon));

      // reset the successfullyDeletedObject flag
      setSuccessfullyDeletedObject(false);
    }
  }, [successfullyDeletedObject, dispatch]);

  if (dataLoading) {
    // display an activity indicator
    activityIndicatorData.showActivityIndicator = true;
    activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.channel_Loading;
  } else {
    // if the workflow is in the midst of performing deletion of a channel, prepare an alert message fragment to display progress message(s)
    if (deleteChannelStatus !== undefined && deleteChannelStatus !== null) {
      if (deleteChannelStatus === enumWorkflowState.Requested || deleteChannelStatus === enumWorkflowState.InProgress) {
        // display an activity indicator
        activityIndicatorData.showActivityIndicator = true;
        activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.channel_DeleteRequested;
      } else if (deleteChannelStatus === enumWorkflowState.Success) {
        // if the successfullyDeletedObject is curently in a 'false' state, we'll want the flag to indicate that the deletion was a success
        if (!successfullyDeletedObject) {
          // set flag to indicate that the object has been successfully deleted, which will force a re-render to allow for cleanup and user notification
          setSuccessfullyDeletedObject(true);
        }
      } else if (deleteChannelStatus === enumWorkflowState.Failure) {
        // display an alert message
        let failureMessage = 'Unknown error';
        if (deleteChannelFailure && deleteChannelFailure instanceof Error) {
          failureMessage = deleteChannelFailure.message;
        }
        // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
        alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
        dispatch(alertInfoChange(alertInfo));
      }
    }
  }

  function handleClickEditChannelCategories(): void {
    // navigate to CategoriesPage, indicating that the parent is a User and providing the UserId (in other words, categories at the top level, which
    // translates to Channel categories )
    navigate(`${userId}/User/categories`);
  }

  function handleClickSharingRequests(): void {
    // navigate to the SharingRequestsPage )
    navigate(`/sharingRequests`);
  }

  // set details for whether to display the Sharing Requests button (whether there are any invitations) and, if so, 
  // specify the text to display on the button
  let countOfSharingRequests: number = 0;
  let labelForSharingRequestsBtn: string = 'foo';
  if (
    (userCategoriesAndChannelsContext !== undefined) &&
    (userCategoriesAndChannelsContext.state.openSharingRequestViewModels !== undefined)) {
    countOfSharingRequests = userCategoriesAndChannelsContext.state.openSharingRequestViewModels.length;
    if (countOfSharingRequests === 1) {
      labelForSharingRequestsBtn = ControlsStringAssets.channelSharingRequest_Singular;
    } else {
      labelForSharingRequestsBtn = ControlsStringAssets.channelSharingRequests_Plural;
    }
  }
  const displaySharingRequestsBtn: boolean = countOfSharingRequests > 0;


  return (
    <>
      <StyledBoxForPaddingAtopPage />
      <GenericPageContainer
        // maxWidth="xl"
        showBackButton={false}
        pageTitle={PageAndViewTitleStringAssets.pageTitle_Channels}
        showCategoriesOption={true}
        onClickEditCategories={handleClickEditChannelCategories}
        onCloseAlert={resetDeleteChannelStatus}
        showTrainingVideosButton={true}
        trainingVideosUrl={''}
      // fixedAlertInfo={alertInfo}
      >

        {
          // if there are any open (live) Channel Sharing Requests, display a button that will inform the user
          // of the number of outstanding requests
          displaySharingRequestsBtn &&
          <StyledGridForSharingRequestsButtonContainer>
            <StyledGridForSharingRequestsFloatingActionButtonArea>
              <StyledFloatingActionButton
                aria-label="sharing invitations"
                onClick={() => handleClickSharingRequests()}
              >
                <ShareIcon />
                &nbsp;{`${countOfSharingRequests} ${labelForSharingRequestsBtn}`}
              </StyledFloatingActionButton>

            </StyledGridForSharingRequestsFloatingActionButtonArea>
          </StyledGridForSharingRequestsButtonContainer>
        }

        <StyledBoxForPaddingAboveDataViewControls />

        {/* Present the Data View Controls ("Add new" button & "View Type" toggle button) */}
        <DataViewControls
          addNewItemButtonText={ControlsStringAssets.channelNew}
          onClickAddNewItemButton={() => navigate('/channel')}
        >
        </DataViewControls>

        <StyledBoxForHorizontalDivider />

        {/* Container for embedded data view */}
        <StyledContainerForDataView>
          {
            // us an Immediately Invoked Function Expressions (IIFE) to streamline the logic and eliminate redundancies
            (() => {
              // default fragment to display is nothing, an empty React fragment
              let reactFragment: ReactElement = <></>;

              // if displaying the Channels as categorized...
              if (displayCategorized) {
                // if there are categories to display (there should always be at least 1 category: the 'Uncategorized' category)
                if (categories && categories.length > 0) {
                  reactFragment =
                    <>
                      {
                        categories.map(category =>
                          <StyledAccordion key={category.id}>
                            <StyledAccordionSummary
                              sx={{ color: category.displayFontColor, backgroundColor: category.displayBackground, border: `1px solid ${category.displayBorder}` }}
                              expandIcon={<ExpandMoreIcon sx={{ color: category.displayFontColor }} />}>
                              <StyledGridForAccordionSummaryContent>
                                <StyledGridForAccordionSummaryHeader>
                                  <StyledTypographyForAccordionSummaryHeader sx={{ color: category.displayFontColor }}>
                                    {category.name}
                                    {` (${allChannelViewModelsByCategory.find(channelViewModelsByCategory => channelViewModelsByCategory.category.id === category.id)?.channelCount})`}
                                  </StyledTypographyForAccordionSummaryHeader>
                                </StyledGridForAccordionSummaryHeader>
                                <StyledGridForAccordionSummaryDescription>
                                  <StyledTypographyForAccordionSummaryDescription sx={{ color: category.displayFontColor }}>
                                    {category.description}
                                  </StyledTypographyForAccordionSummaryDescription>
                                </StyledGridForAccordionSummaryDescription>
                              </StyledGridForAccordionSummaryContent>
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                              {
                                //  If there are no channels for this category, display a message 
                                (allChannelViewModelsByCategory.find(channelViewModelsByCategory => channelViewModelsByCategory.category.id === category.id)?.channelCount === 0)
                                  ?
                                  `${MessagesStringAssets.channel_NoChannelsForCategory}`
                                  :
                                  (
                                    // If the current view is CardGridView, display CardGridView
                                    (collectionViewType === enumCollectionViewType.CardsGridView)
                                      ?
                                      <ChannelsCardGridView channelViewModels={allChannelViewModelsByCategory.find(channelViewModelsByCategory => channelViewModelsByCategory.category.id === category.id)?.channelViewModels} onDelete={handleChannelDelete} />
                                      :
                                      // Otherwise, display ListView
                                      <ChannelsListView channelViewModels={allChannelViewModelsByCategory.find(channelViewModelsByCategory => channelViewModelsByCategory.category.id === category.id)?.channelViewModels} onDelete={handleChannelDelete} />
                                  )
                              }
                            </StyledAccordionDetails>
                          </StyledAccordion>
                        ) // end of 'categories.map()'
                      }
                    </>
                } // end of 'if (categories && categories.length > 0)'
              } else {
                // don't display categorized
                // if there are some ChannelViewModel objects to display (there should always be at least 1 channel: the user's private channel)
                if (channelViewModels && channelViewModels.length > 0) {
                  // if (channels && channels.length > 0) {
                  switch (collectionViewType) {
                    case enumCollectionViewType.CardsGridView:
                      reactFragment = <ChannelsCardGridView channelViewModels={channelViewModels} onDelete={handleChannelDelete} />;
                      break;

                    case enumCollectionViewType.ListView:
                      reactFragment = <ChannelsListView channelViewModels={channelViewModels} onDelete={handleChannelDelete} />;
                      break;
                  }
                }
              }

              return reactFragment;
            })()
          }

        </StyledContainerForDataView>

        {/* Delete Channel Confirmation Dialog */}
        <TwoButtonAcceptanceDialog
          showDialog={showDeleteConfirmationDlg}
          headerText={MessagesStringAssets.channel_DeleteConfirmationHeader}
          bodyText={MessagesStringAssets.channel_DeleteConfirmation}
          acceptanceButtonText={ControlsStringAssets.confirmButtonText}
          nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
          onAcceptance={handleChannelDeleteConfirmed}
          onNonAcceptance={handleChannelDeleteCanceled}
        />

        {activityIndicatorData.showActivityIndicator &&
          <ActivityIndicatorDialog activityLabel={activityIndicatorData.activityIndicatorMessage} />
        }

      </GenericPageContainer>
    </>
  );
}

export default HomePage;
