import React, { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { IChannel } from '../../../../dataObjects/models/channels/Channel';
import { listViewStyles } from '../../../styles';
import ChannelsListViewItem from './ChannelsListViewItem';
import { IChannelViewModel } from '../../../../dataObjects/viewModels/channelViewModel';
import { UserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';
import { IUser } from '../../../../dataObjects/models/users/User';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';


export interface IChannelsListViewProps extends PropsWithChildren<unknown> {
  channelViewModels: Array<IChannelViewModel> | undefined;
  onDelete: (channel: IChannel) => void;
}

export const ChannelsListView: React.FC<IChannelsListViewProps> = (props: IChannelsListViewProps) => {
  ChannelsListView.displayName = 'Channels List View';

  // eslint-disable-next-line no-console
  // console.info(`Render ChannelsListView`);

  const classes: Record<string, string> = listViewStyles();

  const { channelViewModels, onDelete } = props;

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  const currentUser: IUser | undefined = currentUserContextData.currentUser;

  return (
    <>
      {/* overall grid for list. There will be a header row and three data rows: 
          - Header Row
          - Data Row 1: Name & Action Buttons
          - Data Row 2: Description
          - Data Row 3: Owner
      */}
      <Grid container direction="column" >
        {/* Header Row */}
        <Grid container className={classes.tableHeader} size={12} direction="row">
          <Grid size={{ xs: 12, md: 9 }} >
            <Typography variant='h6'>
              Name / Description
            </Typography>
          </Grid>
          <Grid container className={classes.actionButtonLabelContainer} size={{ md: 1 }} justifyContent='center' >
            {/* Only display the next label if the screen size is Medium or larger */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }} >
              <Typography variant='h6'>
                Topics
              </Typography>
            </Box>
          </Grid>
          <Grid container className={classes.actionButtonLabelContainer} size={{ md: 1 }} justifyContent='center' >
            {/* Only display the next label if the screen size is Medium or larger */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }} >
              <Typography variant='h6'>
                Edit
              </Typography>
            </Box>
          </Grid>
          <Grid container className={classes.actionButtonLabelContainer} size={{ md: 1 }} justifyContent='center' >
            {/* Only display the next label if the screen size is Medium or larger */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }} >
              <Typography variant='h6'>
                Delete
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {currentUser && channelViewModels &&
          channelViewModels.map((channelViewModel, idx) => (
            <ChannelsListViewItem
              key={channelViewModel.channel.id}
              currentUser={currentUser}
              userPermissions={new UserAccessPermissionsForObject(currentUser.id, channelViewModel.channel)}
              channelViewModel={channelViewModel}
              onDelete={onDelete}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default ChannelsListView;
