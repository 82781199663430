import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";
import { generateStandardEmailClosingPlainText, generateStandardEmailOpeningPlainText } from "../commonEmailBodyFragments";

/**
 * @function generateEmailBodyPlainTextForUserEmailAddressChangedTo. Generates the Plain Text body of a notification email indicating that the user's email address setting has been changed
 * @param {string} oldEmailAddress The old (prior) email address of the user (and the target of the email message).
 * @param {string} firstName The first name of the target of the email.
 * @param {string} lastName The last name of the target of the email.
 * @param {string} newEmailAddress The new email address of the user.
 * @returns {string} A string with the Plain Text body of the email message to be sent.
 */
export function generateEmailBodyPlainTextForUserEmailAddressChangedTo(oldEmailAddress: string, firstName: string, lastName: string, newEmailAddress: string): string {

  const recipientUserName: string = `${firstName} ${lastName}`;

  // *** Compose the email Body in Plain Text format ***
  // salutation/greetings to the recipient
  let emailBodyPlainText = generateStandardEmailOpeningPlainText(recipientUserName);

  // thank user for registering
  emailBodyPlainText += `We have received a request to change the email address for your ${ServerSubstitutionKeywordsStringAssets.ProjectName} account from ${oldEmailAddress} to ${newEmailAddress}.`;

  emailBodyPlainText += `${newEmailAddress} is now the email address for your account.\n\n`;

  emailBodyPlainText += `We hope you continue to enjoy using ${ServerSubstitutionKeywordsStringAssets.ProjectName}! \n\n`;

  emailBodyPlainText += generateStandardEmailClosingPlainText();

  // return the email content
  return emailBodyPlainText;
}
