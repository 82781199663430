import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";
import { IObjectSharingRequestTracker } from "../../../../../dataObjects/models/collaboration/ObjectSharingTracker";
import { generateStandardEmailClosingHtml, generateStandardEmailOpeningHtml } from "../commonEmailBodyFragments";

/**
 * @function generateEmailBodyHtmlForObjectSharingRequest. Generates the Html body of a notification email indicating that an object (eg, a Channel) has been shared
 * @param {IObjectSharingRequestTracker} objectSharingRequestTracker Object Sharing information with details needed for an email message
 * @returns {string} A string with the Html body of the email message to be sent.
 */
export function generateEmailBodyHtmlForObjectSharingRequest(objectSharingRequestTracker: IObjectSharingRequestTracker): string {

  // *** Compose the email Body in HTML format ***
  let emailBodyHtml = generateStandardEmailOpeningHtml(objectSharingRequestTracker.recipientName);

  // inform that an object has been shared with you
  emailBodyHtml += `<p><b>${objectSharingRequestTracker.requestorName}</b> has shared the <b>${objectSharingRequestTracker.sharingObjectName}</b> ${objectSharingRequestTracker.sharingObjectType} with you in ${ServerSubstitutionKeywordsStringAssets.ProjectName}! `;

  // if it was detected that the recipient does not currently have a user account, add message about registering
  if (!objectSharingRequestTracker.recipientUserId || (objectSharingRequestTracker.recipientUserId === 'undefined') || objectSharingRequestTracker.recipientUserId.trim().length === 0) {
    emailBodyHtml += `If you are not an existing user of ${ServerSubstitutionKeywordsStringAssets.ProjectName}, you may create a new user account at the 
      <a href='${ServerSubstitutionKeywordsStringAssets.AppUrl}/createNewAccount'>${ServerSubstitutionKeywordsStringAssets.ProjectName} application user registration page</a>. `;
  } else {
    // inform existing users where to login
    emailBodyHtml += `Existing users of ${ServerSubstitutionKeywordsStringAssets.ProjectName} may login at the <a href='${ServerSubstitutionKeywordsStringAssets.AppUrl}'>${ServerSubstitutionKeywordsStringAssets.ProjectName} application</a>. </p>`;
  }

  // enjoy sharing ...
  emailBodyHtml += `<p>Enjoy the <b>${objectSharingRequestTracker.sharingObjectName}</b> ${objectSharingRequestTracker.sharingObjectType} that has been shared with you by 
  <b>${objectSharingRequestTracker.requestorName}</b>! </p>`;

  emailBodyHtml += generateStandardEmailClosingHtml();


  // return the email content
  return emailBodyHtml;
}
