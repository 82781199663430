import { RandomId } from '../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName, enumUserFeedbackType, enumUserFeedbackTypeConvert } from '../../enums';
import { IUserFeedback, IUserFeedbackAsJson } from '.';
import { IUserPersistenceData } from '../persistence/UserPersistenceData';
import { VersionAwarePersistable } from '../persistence/VersionAwarePersistable';

/** 
 * @class UserFeedback An individual membership subscription
 */
export class UserFeedback extends VersionAwarePersistable implements IUserFeedback {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {typeUniqueId} userId The unique identifier for the user that is associated with this subscription
   * @param {string} submitterName Name of the submitter
   * @param {string} submitterMobilePhone Mobile phone number of the submitter
   * @param {enumUserFeedbackType} userFeedbackType The type of feedback being submitted
   * @param {string} title Feedback title
   * @param {string} description Feedback description
   * @param {string} response Feedback response
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    userId: typeUniqueId,
    submissionTimestamp: Date,
    submitterName: string,
    submitterMobilePhone: string,
    userFeedbackType: enumUserFeedbackType,
    title: string,
    description: string,
    response: string,
    userPersistenceData?: IUserPersistenceData
  ) {
    super(ownerId, enumPersistableObjectClassName.UserFeedback, enumPersistableObjectType.UserFeedback, id, parentObjectType, parentId, objectState, userPersistenceData);

    this._userId = userId;
    this._submissionTimestamp = submissionTimestamp;
    this._submitterName = submitterName;
    this._submitterMobilePhone = submitterMobilePhone;
    this._userFeedbackType = userFeedbackType;
    this._title = title;
    this._description = description;
    this._response = response;
  }


  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _userId The unique identifier for the user that is associated with this subscription
   */
  private _userId: typeUniqueId;

  /**
   * @method userId Getter method for _userId
   */
  get userId(): string {
    return this._userId;
  }

  /**
   * @method userId Setter method for _userId
   * @param {typeUniqueId} value The input value for setting _userId
   */
  set userId(value: typeUniqueId) {
    this._userId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property Date _submissionTimestamp Timestamp when feedback was submitted
   */
  private _submissionTimestamp: Date;

  /**
   * @method submissionTimestamp is an optional getter method for _submissionTimestamp
   */
  get submissionTimestamp() {
    return this._submissionTimestamp;
  }

  /**
   * @method submissionTimestamp is an optional setter method for _submissionTimestamp
   * @param {Date} value is the input value for setting _submissionTimestamp
   */
  set submissionTimestamp(value) {
    this._submissionTimestamp = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _submitterName Name of the submitter
   */
  private _submitterName: string;

  /**
   * @method submitterName Getter method for _submitterName
   */
  get submitterName(): string {
    return this._submitterName;
  }

  /**
   * @method submitterName Setter method for _submitterName
   * @param {string} value The input value for setting _submitterName
   */
  set submitterName(value: string) {
    this._submitterName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _submitterMobilePhone The frequency of payment for the subscription
   */
  private _submitterMobilePhone: string;

  /**
   * @method submitterMobilePhone Getter method for _submitterMobilePhone
   */
  get submitterMobilePhone(): string {
    return this._submitterMobilePhone;
  }

  /**
   * @method submitterMobilePhone Setter method for _submitterMobilePhone
   * @param {string} value The input value for setting _submitterMobilePhone
   */
  set submitterMobilePhone(value: string) {
    this._submitterMobilePhone = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumUserFeedbackType} _userFeedbackType The type of feedback
   */
  private _userFeedbackType: enumUserFeedbackType;

  /**
   * @method userFeedbackType Getter method for _userFeedbackType
   */
  get userFeedbackType(): enumUserFeedbackType {
    return this._userFeedbackType;
  }

  /**
   * @method userFeedbackType Setter method for _userFeedbackType
   * @param {enumUserFeedbackType} value The input value for setting _userFeedbackType
   */
  set userFeedbackType(value: enumUserFeedbackType) {
    this._userFeedbackType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _title Feedback title
   */
  private _title: string;

  /**
   * @method title Getter method for _title
   */
  get title(): string {
    return this._title;
  }

  /**
   * @method title Setter method for _title
   * @param {string} value The input value for setting _title
   */
  set title(value: string) {
    this._title = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _description Feedback description
   */
  private _description: string;

  /**
   * @method description Getter method for _description
   */
  get description(): string {
    return this._description;
  }

  /**
   * @method description Setter method for _description
   * @param {string} value The input value for setting _description
   */
  set description(value: string) {
    this._description = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _response Feedback response
   */
  private _response: string;

  /**
   * @method response Getter method for _response
   */
  get response(): string {
    return this._response;
  }

  /**
   * @method response Setter method for _response
   * @param {string} value The input value for setting _response
   */
  set response(value: string) {
    this._response = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IUserFeedback} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IUserFeedback {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IUserFeedback = Object.create(UserFeedback.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IUserFeedbackAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IUserFeedbackAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.userId = this.userId;
      jsonObject.submissionTimestamp = this.submissionTimestamp.toISOString();
      jsonObject.submitterName = this.submitterName;
      jsonObject.submitterMobilePhone = this.submitterMobilePhone;
      jsonObject.userFeedbackType = this.userFeedbackType;
      jsonObject.title = this.title;
      jsonObject.description = this.description;
      jsonObject.response = this.response;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // this class is currently designed to have no contained objects
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IUserFeedbackAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns An instance of 'this' class with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IUserFeedbackAsJson, includeContainedObjects: boolean = true): IUserFeedback {
    try {
      // create a new instance of this class
      let UserFeedbackObject: UserFeedback = Object.create(UserFeedback.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      UserFeedbackObject = super.fromJSONProtected(UserFeedbackObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.userId) {
        UserFeedbackObject.userId = jsonObject.userId;
      }

      if (jsonObject.submissionTimestamp) {
        UserFeedbackObject.submissionTimestamp = new Date(jsonObject.submissionTimestamp);
      }

      if (jsonObject.submitterName !== undefined) {
        UserFeedbackObject.submitterName = jsonObject.submitterName;
      }

      if (jsonObject.submitterMobilePhone !== undefined) {
        UserFeedbackObject.submitterMobilePhone = jsonObject.submitterMobilePhone;
      }

      if (jsonObject.userFeedbackType !== undefined) {
        UserFeedbackObject.userFeedbackType = enumUserFeedbackTypeConvert.fromString(jsonObject.userFeedbackType);
      }

      if (jsonObject.title !== undefined) {
        UserFeedbackObject.title = jsonObject.title;
      }

      if (jsonObject.description !== undefined) {
        UserFeedbackObject.description = jsonObject.description;
      }

      if (jsonObject.response !== undefined) {
        UserFeedbackObject.response = jsonObject.response;
      }

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // this class doesn't have any contained objects to add
      }

      return UserFeedbackObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
