

export function generateStandardEmailOpeningHtml(userDisplayName: string): string {

  let emailBodyHtml = '';

  // begin emailBody with the app logo
  emailBodyHtml += `<img src='%LOGO_URL%' /><br/>`;

  // salutation/greetings to the recipient 
  emailBodyHtml += `<p>Greetings ${userDisplayName}:</p>`;

  return emailBodyHtml;
}
