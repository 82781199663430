import { Firestore } from 'firebase/firestore';
import { enumFirestoreDataRepositoryDataType } from "../enums";
import { IBaseDbContext } from "../../../../../dataServices/dbContexts/BaseDbContext";
import { IFirestoreBaseRepository } from "../FirestoreBaseRepository";
import { IPersistable, IPersistableAsJson } from '../../../../../../dataObjects/models/persistence/Persistable';
import { FirestoreDigitalMediaRepository } from "../FirestoreDigitalMediaRepository";
import { FirestoreAudioLinkRepository } from "../FirestoreAudioLinkRepository";
import { FirestoreImageLinkRepository } from "../FirestoreImageLinkRepository";
import { FirebaseAppSingleton } from "../../../FirebaseAppSingleton";
import { FirestoreDbContext } from "../FirestoreDbContext";
import { FirestoreTopicRepository } from "../FirestoreTopicRepository";
import { FirestoreTopicItemRepository } from "../FirestoreTopicItemRepository";
import { FirestoreCategoryRepository } from "../FirestoreCategoryRepository";
import { FirestoreChannelRepository } from "../FirestoreChannelRepository";
import { FirestoreUserRepository } from "../FirestoreUserRepository";
import { FirestoreUserCategoriesRepository } from "../FirestoreUserCategoriesRepository";
import { FirestoreVideoLinkRepository } from "../FirestoreVideoLinkRepository";
import { FirestoreHyperLinkRepository } from "../FirestoreHyperLinkRepository";
import { FirestoreNoteRepository } from "../FirestoreNoteRepository";
import { FirestoreObjectSharingRequestTrackerRepository } from '../FirestoreObjectSharingRequestTrackerRepository';
import { FirestoreThemeSpecsRepository } from '../FirestoreThemeSpecsRepository';
import { FirestoreDocumentLinkRepository } from '../FirestoreDocumentLinkRepository';
import { FirestoreEmailRequestRepository } from '../FirestoreEmailRequestRepository';
import { MdbError } from '../../../../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../../../../errorObjects/enums';
import { FirestoreSocialMediaPostRepository } from '../FirestoreSocialMediaPostRepository';
import { FirestoreUserRedemptionCodeRequestRepository, FirestoreUserRedemptionCodeRequestRepository_Ext } from '../FirestoreUserRedemptionCodeRequestRepository';
import { FirestoreMembershipRepository } from '../FirestoreMembershipRepository';
import { FirestoreUserFeedbackRepository } from '../FirestoreUserFeedbackRepository';

export class FirestoreDataRepositoryFactory {
  static CreateDataRepository(repositoryDataType: enumFirestoreDataRepositoryDataType, firestoreDbContext?: IBaseDbContext<Firestore>): IFirestoreBaseRepository<IPersistable, IPersistableAsJson> {

    // either use the firestoreDbContext that was passed, if it was passed, or create a FirestoreDbContext to use
    let dbContext: IBaseDbContext<Firestore>;
    if (firestoreDbContext !== undefined) {
      dbContext = firestoreDbContext;
    } else {
      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;
      // instantiate a FirestoreDbContext
      dbContext = new FirestoreDbContext('firestoreDb', firestoreObj);
    }

    let repository: IFirestoreBaseRepository<IPersistable, IPersistableAsJson>;
    switch (repositoryDataType) {

      case enumFirestoreDataRepositoryDataType.AudioLink:
        repository = new FirestoreAudioLinkRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.Category:
        repository = new FirestoreCategoryRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.Channel:
        repository = new FirestoreChannelRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.DigitalMedia:
        repository = new FirestoreDigitalMediaRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.DocumentLink:
        repository = new FirestoreDocumentLinkRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.EmailRequest:
        repository = new FirestoreEmailRequestRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.ImageLink:
        repository = new FirestoreImageLinkRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.HyperLink:
        repository = new FirestoreHyperLinkRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.Membership:
        repository = new FirestoreMembershipRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.Note:
        repository = new FirestoreNoteRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.ObjectSharingRequestTracker:
        repository = new FirestoreObjectSharingRequestTrackerRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.PersistenceMetadata:
        throw new MdbError(`FirestoreDataRepositoryFactory.CreateDataRepository() cannot be used for FirestorePersistenceMetadataRepository`, enumMdbErrorType.UnsupportedFeature);

      case enumFirestoreDataRepositoryDataType.SocialMediaPost:
        repository = new FirestoreSocialMediaPostRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.ThemeSpecs:
        repository = new FirestoreThemeSpecsRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.Topic:
        repository = new FirestoreTopicRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.TopicItem:
        repository = new FirestoreTopicItemRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.User:
        repository = new FirestoreUserRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.UserCategories:
        repository = new FirestoreUserCategoriesRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.UserFeedback:
        repository = new FirestoreUserFeedbackRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.UserRedemptionCodeRequest:
        repository = new FirestoreUserRedemptionCodeRequestRepository(dbContext);
        break;

      case enumFirestoreDataRepositoryDataType.VideoLink:
        repository = new FirestoreVideoLinkRepository(dbContext);
        break;

      default:
        throw new MdbError(`FirestoreDataRepositoryFactory.CreateDataRepository() not implemented for (${repositoryDataType})`);
    }

    return repository;
  }
}
