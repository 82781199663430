import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  BoxProps,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { IUserFeedback } from '../../../../dataObjects/models/userFeedback';
import { cardGridViewItemStyles } from '../../../styles/views/cardGridViewItemStyles';
import { TooltipStringAssets } from '../../../../assets/stringAssets';
import { enumSharingPermission } from '../../../../dataObjects/enums';
import { styled } from '@mui/styles';

export interface IUserFeedbackCardGridViewItemProps extends PropsWithChildren<unknown> {
  userFeedback: IUserFeedback;
  userPermission: enumSharingPermission;
  onDelete: (userFeedback: IUserFeedback) => void;
}


// a styled Box (equivalent to a <div>), providing an area for the overall view of the application
const StyledDivForTypographySegments = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const UserFeedbackCardGridViewItem: React.FC<IUserFeedbackCardGridViewItemProps> = (props: IUserFeedbackCardGridViewItemProps) => {

  const { onDelete, userPermission, userFeedback } = props;
  const { id, userId, submissionTimestamp, submitterName, submitterMobilePhone, userFeedbackType, title, description, response } = userFeedback;

  const classes = cardGridViewItemStyles();

  // we're going to apply the 'useNavigate' hook to navigate the browser
  const navigate = useNavigate();

  const userHasPermission: boolean = userPermission === enumSharingPermission.Admin;

  return (
    <>
      {/* <Card classes={{ root: classes.card }} elevation={6}> */}
      <Card elevation={6} className={classes.card}>
        {/* Note: Couldn't get proper behavior from <CardHeader>, so will just display a title via <Typography> */}
        <Typography className={classes.cardTitle} variant='h6' align='center'>
          {title}
        </Typography>

        <CardContent>
          <StyledDivForTypographySegments>
            <Typography className={classes.cardFieldLabel} variant="body1">
              {'Type:'}&nbsp;
            </Typography>
            <Typography className={classes.cardGeneralTimestamp} variant="body1">
              {userFeedbackType}
            </Typography>
          </StyledDivForTypographySegments>
          <StyledDivForTypographySegments>
            <Typography className={classes.cardFieldLabel} variant="body1">
              {'Submitted:'}&nbsp;
            </Typography>
            <Typography className={classes.cardGeneralTimestamp} variant="body1">
              {
                moment(submissionTimestamp).calendar()
              }
            </Typography>
          </StyledDivForTypographySegments>
          <div>
            <Typography className={classes.cardFieldLabel} variant="body1">
              {'Description:'}
            </Typography>
          </div>
          <div>
            <Typography className={classes.cardDescription} variant="body1">
              {description}
            </Typography>
          </div>
          <div>
            <Typography className={classes.cardFieldLabel} variant="body1">
              {'Response:'}
            </Typography>
          </div>
          <div>
            <Typography className={classes.cardDescription} variant="body1">
              {response}
            </Typography>
          </div>

        </CardContent>

        <CardActions className={classes.cardActionsContainer}>
          <Tooltip
            // the tooltip for the edit button will either be "Edit" (if user has sufficient permission) or "Edit disallowed" (insufficient permission)
            title={!userHasPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.editIconButton}`}
                size="small"
                aria-label="edit"
                disabled={!userHasPermission}
                onClick={() => navigate(`/userFeedback/${id}`)}>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip
            // the tooltip for the delete button will either be "Delete" (if user has sufficient permission) or "Delete disallowed" (insufficient permission)
            title={!userHasPermission ? TooltipStringAssets.delete_Disallowed : TooltipStringAssets.delete}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.deleteIconButton}`}
                size="small"
                aria-label="delete"
                disabled={!userHasPermission}
                onClick={() => userFeedback && onDelete(userFeedback)} >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CardActions>
      </Card>
    </>
  );

}