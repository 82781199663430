import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IUserFeedback } from '../../../dataObjects/models/userFeedback';

// export interface ISaveUserFeedbackStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

// whether to display console logs (displayConsoleLogs && console.log statements)
const displayConsoleLogs: boolean = true;

export const userFeedbackSaveStatusSlice = createSlice({
  name: 'userFeedbackSaveStatus',
  initialState,
  reducers: {
    // The userFeedbackSaveRequest triggers the saving of a UserFeedback. It's not really associated with the Store State
    // for the 'saveUserFeedbackStatus' store variable; however, it is used to both trigger the UserFeedback Save Request -AND-
    // to call the userFeedbackSaveStatusChange() action to set the state to 'Requested'.
    // If we didn't use this design patter, the most likely alternative would require creating another Store State
    // variable (eg, 'saveUserFeedbackData') and create another slice with a userFeedbackSaveRequest action. 
    userFeedbackSaveRequest: (state, action: PayloadAction<IUserFeedback>) => {

      displayConsoleLogs && console.log(`userFeedbackSaveStatusSlice. Entered userFeedbackSaveRequest`);

      userFeedbackSaveStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with an IUserFeedback object or null
    userFeedbackSaveStatusChange(state, action) {

      displayConsoleLogs && console.log(`userFeedbackSaveStatusSlice. Entered userFeedbackSaveStatusChange`);

      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { userFeedbackSaveRequest, userFeedbackSaveStatusChange } = userFeedbackSaveStatusSlice.actions;

// export the core reducer for the slice
export const userFeedbackSaveStatusReducer = userFeedbackSaveStatusSlice.reducer;

export default userFeedbackSaveStatusSlice.reducer;