import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

// whether to display console logs (displayConsoleLogs && console.log statements)
const displayConsoleLogs: boolean = true;

export const userFeedbackSaveFailureSlice = createSlice({
  name: 'userFeedbackSaveFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    userFeedbackSaveFailureChange(state, action) {

      displayConsoleLogs && console.log(`userFeedbackSaveFailureSlice. Entered userFeedbackSaveFailureChange`);

      return action.payload;
    },
        
    // action has a payload with a null value
    userFeedbackSaveFailureClear(state) {

      displayConsoleLogs && console.log(`userFeedbackSaveFailureSlice. Entered userFeedbackSaveFailureClear`);

      return null; // action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { userFeedbackSaveFailureChange, userFeedbackSaveFailureClear } = userFeedbackSaveFailureSlice.actions;

// export the core reducer for the slice
export const userFeedbackSaveFailureReducer = userFeedbackSaveFailureSlice.reducer;

export default userFeedbackSaveFailureSlice.reducer;