import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTheme, responsiveFontSizes } from '@mui/material/styles';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography, { TypographyProps } from '@mui/material/Typography';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import {
  AccountCircle,
  Backup as BackupDataFilesIcon,
  Chat as UserFeedbackIcon,
  ColorLens as ThemesIcon,
  ExitToApp as LogoutIcon,
  Home as HomeIcon,
  Info as InfoIcon,
  LocalActivity as UserRedemptionCodeRequestsIcon,
  LockOpen as LoginIcon,
  ManageSearch as PopulateSearchDataIcon,
  Menu as MenuIcon,
  PersonRemove as ExpungeUserIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  Storage as DatabaseIcon
} from '@mui/icons-material'
import { Box, Button, Menu, useMediaQuery, Theme, MenuItem, Divider, FormControlLabel, Tooltip, Switch, ButtonProps, SwitchProps, MenuProps, MenuItemProps }
  from '@mui/material';
import Grid, { Grid2Props } from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import { IFirebaseAuthAndUserAuthenticationStatus } from '../../customHooks/hookReturnObjects';
import { useFirebaseAuth } from '../../customHooks';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import { enumThemeMode } from '../../../dataObjects/enums/enumThemeMode';
import { setThemeModeForUserId } from '../../../firebaseServices/dataServices/dataServiceActions/userActions';
import MyDigiBrainLogo from '../../images/MyDigiBrainLogo/MyDigiBrainLogo';
import { HeaderAndFooterStringAssets, MenuItemStringAssets } from '../../../assets/stringAssets';
import { pingSearchMetadataDbViaHttp } from '../../../firebaseServices/dataServices/dataServiceActions/cloudSqlActions/pingSearchMetadataDbViaHttp';
import { Beacon } from '../../../dataObjects/models/alerts/Beacon';
import { enumBeaconType } from '../../../dataObjects/enums';
import { useAppDispatch } from '../../../uiMiddleware-redux/store/configureStore';
import { beaconChange } from '../../../uiMiddleware-redux/slices/beacon/beaconSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: '#ccc',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
}));


// a styled Grid, providing an area to display the overall header
const StyledGridForHeaderArea = styled((props: Grid2Props) => (
  <Grid
    container
    {...props}
  />
))(({ theme }) => ({
  root: {
    flexGrow: 1,
  },
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(2),
  // justifyContent: 'stretch',
  // backgroundColor: 'pink'
  backgroundColor: theme.appHeader.appBar.background,
  // backgroundColor: "#f00",
  [theme.breakpoints.up('xs')]: {
    height: '60px',
  },
  [theme.breakpoints.up('sm')]: {
    height: '65px',
  },
  [theme.breakpoints.up('md')]: {
    height: '70px',
  },
  [theme.breakpoints.up('lg')]: {
    height: '75px',
  },
  [theme.breakpoints.up('xl')]: {
    height: '80px',
  },
}));


// a styled Grid for the Logo & App Title Container
const StyledGridForLogoAndAppTitleContainer = styled((props: Grid2Props) => (
  <Grid
    container
    key="logoAndTitle"
    // size={{ xs: 7, sm: 5, md: 4, lg: 4, xl: 4 }}
    size={{ xs: 7, sm: 5, md: 4 }}
    // justifyContent="flex-start"
    // alignItems="center"
    {...props}
  />
))(({ theme }) => ({
  // justifyContent: "flex-start",
  // alignItems: 'center',
  // backgroundColor: 'green',
  justifyContent: "flex-start",
  alignItems: "center",
}));

// a styled MyDigiBrain Logo (Note: This isn't working for the Logo at this time, but we'll leave the code for future investigations)
const StyledMyDigiBrainLogo = styled((props: any) => (
  <MyDigiBrainLogo
    {...props}
  />
))(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  [theme.breakpoints.up('xs')]: {
    height: '30px',
  },
  [theme.breakpoints.up('sm')]: {
    height: '35px',
  },
  [theme.breakpoints.up('md')]: {
    height: '40px',
  },
  [theme.breakpoints.up('lg')]: {
    height: '45px',
  },
  [theme.breakpoints.up('xl')]: {
    height: '50px',
  },
}));

// a styled Typography object to represent the application title
const StyledTypographyForAppTitle = styled((props: TypographyProps) => (
  <Typography
    variant="h4"
    color="inherit"
    fontFamily="forte"
    {...props}
  />
))(({ theme }) => ({
  color: theme.appHeader.appBar.color,
  // fontWeight: 'bold',

  [theme.breakpoints.up('xs')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.7rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.85rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.0rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '2.125rem',
  },
}));


// a styled Grid for the Logo & App Title Container
const StyledGridForControlsAndMenuArea = styled((props: Grid2Props) => (
  <Grid
    container
    key="controlsAndMenuArea"
    size={{ xs: 5, sm: 7, md: 8 }}
    {...props}
  />
))(({ theme }) => ({
  // display: "flex",
  justifySelf: "flex-end",
  justifyContent: "flex-end",
  alignItems: 'center',
  // backgroundColor: 'yellow',
}));

// a styled Button that will be used for Custom Menu Buttons
const StyledMenuCustomButton = styled((props: ButtonProps) => (
  <Button
    variant="contained"
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(0, 1),
  border: theme.menuButton.border,
  background: theme.menuButton.background,
  color: theme.menuButton.textColor,

  '&:hover': {
    border: theme.menuButton.hoverBorder,
    background: theme.menuButton.hoverBackground,
    color: theme.menuButton.hoverTextColor,
    transform: 'scale(1.10)',
  }
}));

// a styled IconButton that will be used for the Menu Icon (Hamburger Menu and User Menu Icon)
const StyledMenuIconButton = styled((props: IconButtonProps) => (
  <IconButton
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(0, 1),
  color: theme.menuIconButton.textColor,
  transform: 'scale(1.40)',
  '&:hover': {
    color: theme.menuIconButton.hoverTextColor,
    transform: 'scale(1.5)',
  },
}));

// a styled Switch that will be used for the toggling the ThemeMode between Light & Dark
const StyledSwitch = styled((props: SwitchProps) => (
  <Switch
    {...props}
  />
))(({ theme }) => ({
  width: '3.5rem', // 62,
  height: '2.2rem', // 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          `${theme.appHeader.themeModeSwitch.thumb.color}`, // represents the icon color
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: theme.appHeader.themeModeSwitch.track.opacity,
        backgroundColor: theme.appHeader.themeModeSwitch.track.background,
        border: theme.appHeader.themeModeSwitch.track.border
      },
    },
  },
  '& .MuiSwitch-thumb': {
    // the 'color' property here represents the background color of the 'thumb' when the switch is in the 'unchecked' state
    // transform: 'scale(0.9) translateX(-0.2rem)',
    color: theme.appHeader.themeModeSwitch.thumb.background,
    border: theme.appHeader.themeModeSwitch.thumb.border,
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        `${theme.appHeader.themeModeSwitch.thumb.color}`, // represents the icon color
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
    [theme.breakpoints.up('xs')]: {
      transform: 'scale(0.8) translateX(-0.2rem)',
    },
    [theme.breakpoints.up('sm')]: {
      transform: 'scale(0.9) translateX(-0.2rem)',
    },
  },
  '& .MuiSwitch-track': {
    opacity: theme.appHeader.themeModeSwitch.track.opacity,
    backgroundColor: theme.appHeader.themeModeSwitch.track.background,
    borderRadius: 20 / 2,
    [theme.breakpoints.up('xs')]: {
      transform: 'scale(0.9)',
    },
    [theme.breakpoints.up('sm')]: {
      transform: 'scale(1.0)',
    },
  },

}));

// a styled Popover Menu (used for popping up the 'User' menu)
const StyledPopoverUserMenu = styled((props: MenuProps) => (
  <Menu
    elevation={5}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
}));

// a styled menu item in the Popover Menu
const StyledPopoverMenuItem = styled((props: MenuItemProps) => (
  <MenuItem
    {...props}
  />
))(({ theme }) => ({
  '&:focus': {
    //       // '&.Mui-focus': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
  },
}));

// styled text for a menu item in the Popover Menu
const StyledMenuItemText = styled((props: TypographyProps) => (
  <Typography
    {...props}
  />
))(({ theme }) => ({
  marginLeft: '1.0rem'
}));


/**
 * @interface IAppHeaderProps represents properties to be used by the header.
 */
export interface IAppHeaderProps extends PropsWithChildren<unknown> {
  onShowMenuDrawer: (show: boolean) => void,
  onLogout: () => void,
  appTitle?: string,
  headerMessage?: string,
}

// export default function MenuAppBar() {
const AppHeader: React.FC<IAppHeaderProps> = (props: IAppHeaderProps) => {

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const { onLogout, onShowMenuDrawer } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // let theme = createMuiTheme();
  let theme: Theme = useTheme();
  theme = responsiveFontSizes(theme);

  // const [anchorMainMenuEl, setAnchorMainMenuEl] = React.useState(null);
  const [userButtonAnchorEl, setUserButtonAnchorEl] = React.useState(null);
  // const openMainMenu = Boolean(anchorMainMenuEl);
  const openUserMenu = Boolean(userButtonAnchorEl);

  // whether user is authenticated
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  // get the Firebase auth state using a custom hook
  const { auth, userAuthenticated: userIsAuthenticated }: IFirebaseAuthAndUserAuthenticationStatus = useFirebaseAuth();

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c AppHeader. currentUser: \n${JSON.stringify(currentUser)}`, 'background: #600; color: #fff');

  const currentUserIsSuperAdmin: boolean | undefined = currentUser && currentUser.sa;
  displayConsoleLogs && console.log(`%c AppHeader. currentUserIsSuperAdmin: \n${currentUserIsSuperAdmin}`, 'background: #600; color: #fff');

  const displayDarkThemeMode: boolean = ((currentUser === undefined) || (currentUser.userSettings === undefined) ||
    currentUser.userSettings.themeMode === enumThemeMode.Dark);

  // anytime the auth object changes in state, we'll set whether the user is authenticated
  useEffect(() => {
    // set whether user is authenticated
    // setUserAuthenticated(auth.uid !== undefined);
    setUserAuthenticated(userIsAuthenticated);
    // }, [auth]);
  }, [userIsAuthenticated]);

  // const appTheme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('sm'));


  function handleChangeThemeModeSwitch() {
    if (currentUser !== undefined && currentUser.userSettings !== undefined) {
      // call action method to set the ThemeMode for the user
      const newThemeMode: enumThemeMode = currentUser.userSettings.themeMode === enumThemeMode.Light ? enumThemeMode.Dark : enumThemeMode.Light;

      setThemeModeForUserId(currentUser.id, newThemeMode).then(() => {
      }).catch(error => {
        displayConsoleLogs && console.error(`In AppHeader. Error after call to setThemeModeForUserId(). \nError: ${error}`)
      });
    }
  }

  const handleUserMenuButtonClicked = (event: any) => {
    setUserButtonAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setUserButtonAnchorEl(null);
  };

  const handlePageNavigationFromPopupUserMenu = (pageUrl: string) => {
    // navigate to page
    navigate(pageUrl);

    // close the user menu
    handleCloseUserMenu();
  }

  const handlePingSearchMetadataDb = async () => {
    const success: boolean = await pingSearchMetadataDbViaHttp();

    if (success) {
      // notify user via a Beacon notification that the operation has been completed
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, 'Ping Operation', 'Ping of SearchMetadata DB was successful')));
    }

    // close the user menu
    handleCloseUserMenu();
  }

  const handleLogoutFromUserMenu = () => {
    // request a logout
    onLogout();

    // close the user menu
    handleCloseUserMenu();
  }

  const handleMenuButtonClick = (pageUrl: string) => {
    navigate(pageUrl);
  };


  return (
    <>
      <StyledGridForHeaderArea container alignContent="center" >
        <StyledGridForLogoAndAppTitleContainer>
          <IconButton
            aria-label="app logo"
            aria-controls="menu-applogo"
            aria-haspopup="true"
            color="inherit"
            onClick={() => handleMenuButtonClick('/')}
          >
            <StyledMyDigiBrainLogo />
          </IconButton>

          <IconButton
            aria-label="app title"
            aria-controls="menu-apptitle"
            aria-haspopup="true"
            color="inherit"
            onClick={() => handleMenuButtonClick('/')}
          >
            <StyledTypographyForAppTitle>
              {props.appTitle}
            </StyledTypographyForAppTitle>
          </IconButton>

        </StyledGridForLogoAndAppTitleContainer>
        <StyledGridForControlsAndMenuArea container >
          {isSmallMobile ? (
            <Grid container key="compressedMenu" justifyContent="flex-end" alignItems="center" >
              <Grid key="themeModeSwitch" >
                {userAuthenticated &&
                  <StyledSwitch
                    name="themeMode"
                    checked={displayDarkThemeMode}
                    onChange={handleChangeThemeModeSwitch}
                    sx={{ marginRight: '0.5rem' }}
                  />
                }
              </Grid>
              <Grid key="hamburgerMenu" >
                <StyledMenuIconButton
                  size="small"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => onShowMenuDrawer(true)}
                >
                  <MenuIcon />
                </StyledMenuIconButton>
              </Grid>
            </Grid>
          ) : (
            <>
              {userAuthenticated ? (
                // Overall Grid to contain Theme Mode Switch, Menu Buttons, and dropdown Menu
                < Grid container key="expandedMenuOptions" justifyContent="flex-end" alignItems="center" >
                  {/* Insert a spacer to ensure that the background box encases the entire switch control */}
                  <Grid key="spacer" >
                    <div style={{ width: '1rem' }}></div>
                  </Grid>
                  <Grid key="themeModeSwitch" >

                    {/* NOTE: In order to have a Tooltip around a Switch control, the current version of Material UI requires
                                    us to surround the <Switch> (styled or not) with a <FormControlLabel>, and we set the label 
                                    value to an empty string, so no label appears. 
                                    If we don't do this, we will get a runtime error in the console.
                           */}
                    <Tooltip title={displayDarkThemeMode ? 'Switch to Light mode' : 'Switch to Dark mode'} arrow >
                      <FormControlLabel
                        label=''
                        onChange={handleChangeThemeModeSwitch}
                        control={<StyledSwitch
                          name="themeMode"
                          checked={displayDarkThemeMode}
                        // sx={{ marginRight: '2rem' }}
                        />}
                      />
                    </Tooltip>
                  </Grid>

                  {/* Menu buttons presented when user has been authenticated (has logged in) */}
                  <Grid key="home" >
                    <StyledMenuIconButton
                      size='medium'
                      aria-label="home"
                      aria-controls="menu-appbarhome"
                      aria-haspopup="true"
                      onClick={() => handleMenuButtonClick('/')}
                    >
                      <Tooltip title={'Home'} arrow >
                        <HomeIcon />
                      </Tooltip>
                    </StyledMenuIconButton>

                  </Grid>
                  <Grid key="search" >
                    <StyledMenuIconButton
                      size='medium'
                      aria-label="search digital media"
                      aria-controls="menu-appbarsearch"
                      aria-haspopup="true"
                      onClick={() => handleMenuButtonClick('/digitalMediaSearchCriteria')}
                    >
                      <Tooltip title={'Search'} arrow >
                        <SearchIcon />
                      </Tooltip>
                    </StyledMenuIconButton>
                  </Grid>
                  <Grid key="about" >
                    <StyledMenuIconButton
                      size='medium'
                      aria-label="about"
                      aria-controls="menu-appbarabout"
                      aria-haspopup="true"
                      onClick={() => handleMenuButtonClick('/about')}
                    >
                      <Tooltip title={'About'} arrow >
                        <InfoIcon />
                      </Tooltip>
                    </StyledMenuIconButton>

                  </Grid>
                  <Grid key="currentUser" >
                    <StyledMenuIconButton
                      size='medium'
                      aria-label="account of current user"
                      aria-controls="menu-appbaruser"
                      aria-haspopup="true"
                      onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleUserMenuButtonClicked(evt)}
                    >
                      <Tooltip title={'Account'} arrow >
                        <AccountCircle />
                      </Tooltip>
                    </StyledMenuIconButton>
                  </Grid>
                </Grid>
              ) : (
                /* Menu buttons presented when user has NOT been authenticated (has NOT logged in) */
                <Grid container key="expandedMenuOptions" justifyContent="flex-end" alignItems="center" >
                  <Grid key="button1" >
                    <StyledMenuCustomButton startIcon={<LoginIcon />} onClick={() => handleMenuButtonClick('/login')}>
                      {HeaderAndFooterStringAssets.menuButton_Signin}
                    </StyledMenuCustomButton>
                  </Grid>
                  <Grid key="button2" >
                    <StyledMenuCustomButton startIcon={<InfoIcon />} onClick={() => handleMenuButtonClick('/about')}>
                      {HeaderAndFooterStringAssets.menuButton_About}
                    </StyledMenuCustomButton>
                  </Grid>
                </Grid>
              )}
            </>
          )
          }
        </StyledGridForControlsAndMenuArea>

        {/* </Grid> */}
      </StyledGridForHeaderArea>

      {/* Render the Popover User Menu **ONLY** if the userButtonAnchorEl object has been created */}
      {
        userButtonAnchorEl &&
        <StyledPopoverUserMenu
          id="menu-appbar"
          anchorEl={userButtonAnchorEl}
          anchorReference={'anchorEl'}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          open={openUserMenu}
          onClose={handleCloseUserMenu}
        >
          <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupUserMenu('/settings')}>
            <SettingsIcon fontSize="small" />
            <StyledMenuItemText>
              {MenuItemStringAssets.menuItem_Settings}
            </StyledMenuItemText>
          </StyledPopoverMenuItem>
          <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupUserMenu('/setTheme')}>
            <ThemesIcon fontSize="small" />
            <StyledMenuItemText>
              {MenuItemStringAssets.menuItem_SetTheme}
            </StyledMenuItemText>
          </StyledPopoverMenuItem>
          <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupUserMenu('/userFeedbackItems')}>
            <UserFeedbackIcon fontSize="small" />
            <StyledMenuItemText>
              {MenuItemStringAssets.menuItem_UserFeedback}
            </StyledMenuItemText>
          </StyledPopoverMenuItem>
          {currentUserIsSuperAdmin && // if user has SuperAdmin privilege, present a Divider to separate Admin menu items
            <Divider />
          }
          {currentUserIsSuperAdmin && // if user has SuperAdmin privilege, present the "User Redemption Code Requests" menu item
            <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupUserMenu('/userRedemptionCodeRequests')}>
              <UserRedemptionCodeRequestsIcon fontSize="small" />
              <StyledMenuItemText>
                {MenuItemStringAssets.menuItem_UserRedemptionCodeRequests}
              </StyledMenuItemText>
            </StyledPopoverMenuItem>
          }
          {currentUserIsSuperAdmin && // if user has SuperAdmin privilege, present the "Ping Search Metadata Db" menu item
            <StyledPopoverMenuItem selected={false} onClick={() => handlePingSearchMetadataDb()}>
              <DatabaseIcon fontSize="small" />
              <StyledMenuItemText>
                {MenuItemStringAssets.menuItem_PingSearchMetadataDb}
              </StyledMenuItemText>
            </StyledPopoverMenuItem>
          }
          {currentUserIsSuperAdmin && // if user has SuperAdmin privilege, present the "Populate Search Data" menu item
            <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupUserMenu('/populateSearchData')}>
              <PopulateSearchDataIcon fontSize="small" />
              <StyledMenuItemText>
                {MenuItemStringAssets.menuItem_PopulateSearchMetadataDb}
              </StyledMenuItemText>
            </StyledPopoverMenuItem>
          }
          {currentUserIsSuperAdmin && // if user has SuperAdmin privilege, present the "Backup Data Files" menu item
            <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupUserMenu('/backupDataFiles')}>
              <BackupDataFilesIcon fontSize="small" />
              <StyledMenuItemText>
                {MenuItemStringAssets.menuItem_BackupDataFiles}
              </StyledMenuItemText>
            </StyledPopoverMenuItem>
          }
          {currentUserIsSuperAdmin && // if user has SuperAdmin privilege, present the "Expunge User Account" menu item
            <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupUserMenu('/expungeUser')}>
              <ExpungeUserIcon fontSize="small" />
              <StyledMenuItemText>
                {MenuItemStringAssets.menuItem_ExpungeUser}
              </StyledMenuItemText>
            </StyledPopoverMenuItem>
          }
          <Divider />
          <StyledPopoverMenuItem selected={false} onClick={() => handleLogoutFromUserMenu()}>
            <LogoutIcon fontSize="small" />
            <StyledMenuItemText>
              {MenuItemStringAssets.menuItem_Logout}
            </StyledMenuItemText>
          </StyledPopoverMenuItem>
        </StyledPopoverUserMenu>
      } {/* end of 'userButtonAnchorEl && ...' */}
    </>
  );
};

export default AppHeader;