import { generateEmailBodyHtmlForUserEmailAddressChangedFrom, generateEmailBodyPlainTextForUserEmailAddressChangedFrom, generateEmailSubjectForUserEmailAddressChangedFrom } from ".";
import { enumAppPlatformType, enumEmailRequestType, enumLocale, enumObjectPersistenceState } from "../../../../../dataObjects/enums";
import { EmailRequest, IEmailRequest } from "../../../../../dataObjects/models/emailRequests";
import { IUserPersistenceData, UserPersistenceData } from "../../../../../dataObjects/models/persistence/UserPersistenceData";
import { typeUniqueId } from "../../../../../dataObjects/types";
import { generateIdForEmailRequest } from "../generateIdForEmailRequest";

/**
 * @function generateEmailRequestForUserEmailAddressChangedFrom Generates an email request with a notification email indicating that the user's email address setting has been changed
 * @param {typeUniqueId} userId The Id of the user who is the target of the email.
 * @param {string} oldEmailAddress The old (prior) email address of the user (and the target of the email message).
 * @param {string} firstName The first name of the target of the email.
 * @param {string} lastName The last name of the target of the email.
 * @param {string} newEmailAddress The new email address of the user.
 * @returns {Promise<IEmailRequest>} A Promise with the generated Email Request, providing for an asynchronous process.
 */
export function generateEmailRequestForUserEmailAddressChangedFrom(userId: typeUniqueId, oldEmailAddress: string, firstName: string, lastName: string, newEmailAddress: string): Promise<IEmailRequest> {
  return new Promise<IEmailRequest>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    const requestorName: string = `${firstName} ${lastName}`;

    displayConsoleLogs && console.log(`%c Entered generateEmailRequestForUserEmailAddressChangedFrom`, 'background: #550; color: #fff');

    try {
      // get the base domain of the running application
      const baseDomain: string = window.location.hostname;

      displayConsoleLogs && console.log(`%c generateEmailRequestForUserEmailAddressChangedFrom. Ready to create objects to submit an email request.`, 'background: #550; color: #fff');

      // create User Persistence data
      const userPersistenceData: IUserPersistenceData =
        new UserPersistenceData(userId, requestorName, enumAppPlatformType.Web, enumLocale.English);

      // call methods to generate the email subject, html body, and plain text body
      const emailSubject: string = generateEmailSubjectForUserEmailAddressChangedFrom(oldEmailAddress, newEmailAddress);
      const emailBodyHtml: string = generateEmailBodyHtmlForUserEmailAddressChangedFrom(oldEmailAddress, firstName, lastName, newEmailAddress);
      const emailBodyPlainText: string = generateEmailBodyPlainTextForUserEmailAddressChangedFrom(oldEmailAddress, firstName, lastName, newEmailAddress);

      // the Email Request Id will be a concatenation of the userId, the Request Type, and a unique Id
      const emailRequestId: typeUniqueId = await generateIdForEmailRequest(userId, enumEmailRequestType.UserEmailAddressChangedFrom);
      const emailRequestSubmittedTimestamp: string = new Date().toUTCString();
      const emailRequest: IEmailRequest = new EmailRequest(userId, emailRequestId, undefined, undefined, enumObjectPersistenceState.New,
        enumEmailRequestType.UserEmailAddressChangedFrom, userId, requestorName, userId, oldEmailAddress, requestorName,
        newEmailAddress, enumAppPlatformType.Web, emailSubject, emailBodyHtml, emailBodyPlainText, baseDomain, undefined, emailRequestSubmittedTimestamp, userPersistenceData);

      displayConsoleLogs && console.log(`%c generateEmailRequestForAccountVerification. Finished creating email request.`, 'background: #550; color: #fff');

      resolve(emailRequest);
    } catch (error: any) {
      displayConsoleLogs && console.log(`%c generateEmailRequestForAccountVerification. Error encountered: ${error}`, 'background: #550; color: #fff');

      reject(error);
    }
  });
}