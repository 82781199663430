import React, { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { IDocumentLink } from '../../../../dataObjects/models/digitalMedia/DocumentLink';
import { listViewStyles } from '../../../styles';
import DocumentLinksListViewItem from './DocumentLinksListViewItem';
import { IDocumentLinkViewModel } from '../../../../dataObjects/viewModels/documentLinkViewModel';


export interface IDocumentLinksListViewProps extends PropsWithChildren<unknown> {
  documentLinkViewModels: Array<IDocumentLinkViewModel>;
  onDelete: (DocumentLink: IDocumentLink) => void;
}

export const DocumentLinksListView: React.FC<IDocumentLinksListViewProps> = (props: IDocumentLinksListViewProps) => {
  DocumentLinksListView.displayName = 'DocumentLinks List View';

  // eslint-disable-next-line no-console
  // console.info(`Render DocumentLinksListView`);

  const classes: Record<string, string> = listViewStyles();

  const { documentLinkViewModels, onDelete } = props;

  return (
    <>
      {/* overall grid for list. There will be a header row and either one or two data rows, based on a media query: 
          - Header Row
          - Data Row 1: Document & Action Buttons (also, the Description for larger screen sizes)
          - Data Row 2: Description (this row is only shown for smaller screen sizes)
      */}
      <Grid container direction="column" >
        {/* Header Row */}
        <Grid key='tableHeader' container className={classes.tableHeader} size={12} direction="row">
          <Grid key='nameAndDescriptionLabel' size={{ xs: 12, md: 10 }} >
            <Typography variant='h6'>
              Name / Description
            </Typography>
          </Grid>
          <Grid container size={{ md: 2 }} direction="row" justifyContent="center" alignContent="flex-start">
            <Grid key='editLabel' container className={classes.actionButtonLabelContainer} size={{ md: 6 }} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Edit
                </Typography>
              </Box>
            </Grid>
            <Grid key='deleteLabel' container className={classes.actionButtonLabelContainer} size={{ md: 6 }} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Delete
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {
          documentLinkViewModels.map((documentLinkViewModel, idx) => (
            <DocumentLinksListViewItem
              key={documentLinkViewModel.documentLink.id}
              documentLinkViewModel={documentLinkViewModel}
              onDelete={onDelete}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default DocumentLinksListView;