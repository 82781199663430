import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";

/**
 * @function generateEmailSubjectForAccountVerification Generates the Subject of a notification email for new user account verification
 * @returns {string} A string with the Subject of the email message to be sent.
 */
export function generateEmailSubjectForAccountVerification(): string {

  // *** Compose the email Subject ***
  const emailSubject = `(${ServerSubstitutionKeywordsStringAssets.ProjectName} registration) Please verify your email address (required)`;

  // return the email content
  return emailSubject;
}
