import { RandomId } from '../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName, enumEmailRequestType, enumAppPlatformType } from '../../enums';
import { IEmailRequest, IEmailRequestAsJson } from '.';
import { IUserPersistenceData } from '../persistence/UserPersistenceData';
import { VersionAwarePersistable } from '../persistence/VersionAwarePersistable';

/** 
 * @class EmailRequest A request to have an email sent. Serves as both a message queue and an audit trail
 */
export class EmailRequest extends VersionAwarePersistable implements IEmailRequest {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {enumEmailRequestType} requestType The type of email request being made (eg, Email Verification, Login via Email Link, etc.)
   * @param {typeUniqueId} requestorUserId Id of the user requesting the Email Request to be sent
   * @param {string | undefined} requestorUserName (optional) Name of the user requesting the Email Request to be sent
   * @param {typeUniqueId | undefined} recipientUserId (optional) userId Id of the intended email recipient
   * @param {string} recipientEmailAddress The email address of the user who is the target of the email request 
   * @param {string | undefined} recipientUserName (optional) Name of the target user (for use in email salutation, etc.)
   * @param {enumAppPlatformType} appPlatformType The type of platform on which the application is running (Android, iOS, Web)
   * @param {string} messageSubject The subject of the messsage (with any substitution keywords to be replaced on the backend server)
   * @param {string} messageBodyHtml The body of the messsage in HTML format (with any substitution keywords to be replaced on the backend server)
   * @param {string} messageBodyPlainText The body of the messsage in Plain Text format (with any substitution keywords to be replaced on the backend server)
   * @param {string} originatingDomain The web domain from which the request is being made (domain on which the client is running)
   * @param {string | undefined} continueUrl If given, represents the URL to which the user is to be forwarded (applies to certain verifications or logins)
   * @param {string | undefined} sharingObjectName The name of the object (eg, "My XYZ Channel") being shared (in the case of an "Object Sharing" email request)
   * @param {string | undefined} sharingObjectType The type of object (Channel, etc.) being shared (in the case of an "Object Sharing" email request)
   * @param {string | undefined} otherEmailAddress Another email address (used in notification of email address changes from & to)
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    requestType: enumEmailRequestType,
    requestorUserId: typeUniqueId,
    requestorUserName: string | undefined,
    recipientUserId: typeUniqueId | undefined,
    recipientEmailAddress: string,
    recipientUserName: string | undefined,
    otherEmailAddress: string | undefined,
    appPlatformType: enumAppPlatformType,
    messageSubject: string,
    messageBodyHtml: string,
    messageBodyPlainText: string,
    originatingDomain: string,
    continueUrl: string | undefined,
    emailRequestSubmittedTimestamp: string,
    userPersistenceData?: IUserPersistenceData,
  ) {
    super(ownerId, enumPersistableObjectClassName.EmailRequest, enumPersistableObjectType.EmailRequest, id, parentObjectType, parentId, objectState, userPersistenceData);

    this._requestType = requestType;
    this._requestorUserId = requestorUserId;
    this._recipientEmailAddress = recipientEmailAddress;
    this._recipientUserName = recipientUserName;
    this._appPlatformType = appPlatformType;
    this._messageSubject = messageSubject;
    this._messageBodyHtml = messageBodyHtml;
    this._messageBodyPlainText = messageBodyPlainText;
    this._originatingDomain = originatingDomain;
    this._continueUrl = continueUrl;
    this._emailRequestSubmittedTimestamp = emailRequestSubmittedTimestamp;

    if (requestorUserName) {
      this._requestorUserName = requestorUserName;
    }

    if (recipientUserId) {
      this.recipientUserId = recipientUserId;
    }

    if (otherEmailAddress) {
      this._otherEmailAddress = otherEmailAddress;
    }
  }

  /*-----------------------------------------------*/
  /**
   * @property {enumEmailRequestType} _requestType The type of email request being made (eg, Email Verification, Login via Email Link, etc.)
   */
  private _requestType: enumEmailRequestType;

  /**
   * @method requestType Getter method for _requestType
   */
  get requestType(): enumEmailRequestType {
    return this._requestType;
  }

  /**
   * @method requestType Setter method for _requestType
   * @param {enumEmailRequestType} value The input value for setting _requestType
   */
  set requestType(value: enumEmailRequestType) {
    this._requestType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _requestorUserId Id of the user for which the EmailRequestis made
   */
  private _requestorUserId: typeUniqueId;

  /**
   * @method title Getter method for _title
   */
  get requestorUserId(): typeUniqueId {
    return this._requestorUserId;
  }

  /**
   * @method title Setter method for _requestorUserId
   * @param {string} value The input value for setting _requestorUserId
   */
  set requestorUserId(value: typeUniqueId) {
    this._requestorUserId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _requestorUserName Name of the requestor user
   */
  private _requestorUserName: string | undefined;

  /**
   * @method requestorUserName Getter method for _requestorUserName
   */
  get requestorUserName(): string | undefined {
    return this._requestorUserName;
  }

  /**
   * @method requestorUserName Setter method for _requestorUserName
   * @param {string | undefined} value The input value for setting _requestorUserName
   */
  set requestorUserName(value: string | undefined) {
    this._requestorUserName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId | undefined} _recipientUserId userId Id of the user who is the target of the email request
   */
  private _recipientUserId: typeUniqueId | undefined;

  /**
   * @method recipientUserId Getter method for _recipientUserId
   */
  get recipientUserId(): typeUniqueId | undefined {
    return this._recipientUserId;
  }

  /**
   * @method recipientUserId Setter method for _recipientUserId
   * @param {typeUniqueId | undefined} value The input value for setting _recipientUserId
   */
  set recipientUserId(value: typeUniqueId | undefined) {
    this._recipientUserId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _recipientEmailAddress The email address of the user who is the target of the email request
   */
  private _recipientEmailAddress: string;

  /**
   * @method recipientEmailAddress Getter method for _recipientEmailAddress
   */
  get recipientEmailAddress(): string {
    return this._recipientEmailAddress;
  }

  /**
   * @method recipientEmailAddress Setter method for _recipientEmailAddress
   * @param {string} value The input value for setting _recipientEmailAddress
   */
  set recipientEmailAddress(value: string) {
    this._recipientEmailAddress = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _recipientUserName If given, the name of the target user (for use in email salutation, etc.)
   */
  private _recipientUserName: string | undefined = undefined;

  /**
   * @method recipientUserName Getter method for _recipientUserName
   */
  get recipientUserName(): string | undefined {
    return this._recipientUserName;
  }

  /**
   * @method recipientUserName Setter method for _recipientUserName
   * @param {string | undefined} value The input value for setting _recipientUserName
   */
  set recipientUserName(value: string | undefined) {
    this._recipientUserName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _otherEmailAddress An additional email address to be used for some use cases
   */
  private _otherEmailAddress: string | undefined = undefined;

  /**
   * @method otherEmailAddress Getter method for _otherEmailAddress
   */
  get otherEmailAddress(): string | undefined {
    return this._otherEmailAddress;
  }

  /**
   * @method otherEmailAddress Setter method for _otherEmailAddress
   * @param {string | undefined} value The input value for setting _otherEmailAddress
   */
  set otherEmailAddress(value: string | undefined) {
    this._otherEmailAddress = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumAppPlatformType} _appPlatformType The type of platform on which the application is running (Android, iOS, Web)
   */
  private _appPlatformType: enumAppPlatformType;

  /**
   * @method appPlatformType Getter method for _appPlatformType
   */
  get appPlatformType(): enumAppPlatformType {
    return this._appPlatformType;
  }

  /**
   * @method appPlatformType Setter method for _appPlatformType
   * @param {enumAppPlatformType} value The input value for setting _appPlatformType
   */
  set appPlatformType(value: enumAppPlatformType) {
    this._appPlatformType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _messageSubject The subject of the messsage (with any substitution keywords to be replaced on the backend server)
   */
  private _messageSubject: string;

  /**
   * @method messageSubject Getter method for _messageSubject
   */
  get messageSubject(): string {
    return this._messageSubject;
  }

  /**
   * @method messageSubject Setter method for _messageSubject
   * @param {string} value The input value for setting _messageSubject
   */
  set messageSubject(value: string) {
    this._messageSubject = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _messageBodyHtml The body of the messsage in HTML format (with any substitution keywords to be replaced on the backend server)
   */
  private _messageBodyHtml: string;

  /**
   * @method messageBodyHtml Getter method for _messageBodyHtml
   */
  get messageBodyHtml(): string {
    return this._messageBodyHtml;
  }

  /**
   * @method messageBodyHtml Setter method for _messageBodyHtml
   * @param {string} value The input value for setting _messageBodyHtml
   */
  set messageBodyHtml(value: string) {
    this._messageBodyHtml = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _messageBodyPlainText The body of the messsage in Plain Text format (with any substitution keywords to be replaced on the backend server)
   */
  private _messageBodyPlainText: string;

  /**
   * @method messageBodyPlainText Getter method for _messageBodyPlainText
   */
  get messageBodyPlainText(): string {
    return this._messageBodyPlainText;
  }

  /**
   * @method messageBodyPlainText Setter method for _messageBodyPlainText
   * @param {string} value The input value for setting _messageBodyPlainText
   */
  set messageBodyPlainText(value: string) {
    this._messageBodyPlainText = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _originatingDomain The web domain from which the request is being made (domain on which the client is running)
   */
  private _originatingDomain: string;

  /**
   * @method originatingDomain Getter method for _originatingDomain
   */
  get originatingDomain(): string {
    return this._originatingDomain;
  }

  /**
   * @method originatingDomain Setter method for _originatingDomain
   * @param {string} value The input value for setting _originatingDomain
   */
  set originatingDomain(value: string) {
    this._originatingDomain = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _continueUrl If given, represents the URL to which the user is to be forwarded (applies to certain verifications or logins)
   */
  private _continueUrl: string | undefined = undefined;

  /**
   * @method continueUrl Getter method for _continueUrl
   */
  get continueUrl(): string | undefined {
    return this._continueUrl;
  }

  /**
   * @method continueUrl Setter method for _continueUrl
   * @param {string | undefined} value The input value for setting _continueUrl
   */
  set continueUrl(value: string | undefined) {
    this._continueUrl = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _sharingObjectType The type of object (Channel, etc.) being shared
   */
  private _sharingObjectType: string | undefined = undefined;

  /**
   * @method sharingObjectType Getter method for _sharingObjectType
   */
  get sharingObjectType(): string | undefined {
    return this._sharingObjectType;
  }

  /**
   * @method sharingObjectType Setter method for _sharingObjectType
   * @param {string | undefined} value The input value for setting _sharingObjectType
   */
  set sharingObjectType(value: string | undefined) {
    this._sharingObjectType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _emailRequestSubmittedTimestamp Indicates the timestamp when the Email Request was submitted
   */
  private _emailRequestSubmittedTimestamp: string;

  /**
   * @method emailRequestSubmittedTimestamp Getter method for _emailRequestSubmittedTimestamp
   */
  get emailRequestSubmittedTimestamp(): string {
    return this._emailRequestSubmittedTimestamp;
  }

  /**
   * @method emailRequestSubmittedTimestamp Setter method for _emailRequestSubmittedTimestamp
   * @param {string} value The input value for setting _emailRequestSubmittedTimestamp
   */
  set emailRequestSubmittedTimestamp(value: string) {
    this._emailRequestSubmittedTimestamp = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _emailSentTimestamp Indicates the timestamp when the email, in response to the request
   */
  private _emailSentTimestamp: string | undefined = undefined;

  /**
   * @method emailSentTimestamp Getter method for _emailSentTimestamp
   */
  get emailSentTimestamp(): string | undefined {
    return this._emailSentTimestamp;
  }

  /**
   * @method emailSentTimestamp Setter method for _emailSentTimestamp
   * @param {string | undefined} value The input value for setting _emailSentTimestamp
   */
  set emailSentTimestamp(value: string | undefined) {
    this._emailSentTimestamp = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IEmailRequest} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IEmailRequest {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IEmailRequest = Object.create(EmailRequest.prototype);
    Object.assign(copyOfObject, this);

    // copy contained objects
    // there are no contained objects at this time

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IEmailRequestAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IEmailRequestAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.requestType = this.requestType;
      jsonObject.requestorUserId = this.requestorUserId;
      jsonObject.requestorUserName = this.requestorUserName === undefined ? 'undefined' : this.requestorUserName;
      jsonObject.recipientEmailAddress = this.recipientEmailAddress;
      jsonObject.recipientUserId = this.recipientUserId === undefined ? 'undefined' : this.recipientUserId;
      jsonObject.recipientUserName = this.recipientUserName === undefined ? 'undefined' : this.recipientUserName;
      jsonObject.appPlatformType = this.appPlatformType;
      jsonObject.messageSubject = this.messageSubject;
      jsonObject.messageBodyHtml = this.messageBodyHtml;
      jsonObject.messageBodyPlainText = this.messageBodyPlainText;
      jsonObject.originatingDomain = this.originatingDomain;
      jsonObject.continueUrl = this.continueUrl === undefined ? 'undefined' : this.continueUrl;
      jsonObject.emailRequestSubmittedTimestamp = this.emailRequestSubmittedTimestamp;
      jsonObject.emailSentTimestamp = this.emailSentTimestamp === undefined ? 'undefined' : this.emailSentTimestamp;
      jsonObject.otherEmailAddress = this.otherEmailAddress === undefined ? 'undefined' : this.otherEmailAddress;
    
      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // this class is currently designed to have no contained objects
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IEmailRequestAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns An instance of 'this' class with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IEmailRequestAsJson, includeContainedObjects: boolean = true): IEmailRequest {
    try {
      // create a new instance of this class
      let emailRequestObject: EmailRequest = Object.create(EmailRequest.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      emailRequestObject = super.fromJSONProtected(emailRequestObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      emailRequestObject.requestType = (jsonObject.requestType === undefined) ? enumEmailRequestType.Unknown : jsonObject.requestType;

      if (jsonObject.requestorUserId !== undefined) {
        emailRequestObject.requestorUserId = jsonObject.requestorUserId;
      }

      emailRequestObject.requestorUserName = (jsonObject.requestorUserName === 'undefined') ? undefined : jsonObject.requestorUserName;

      if (jsonObject.recipientEmailAddress !== undefined) {
        emailRequestObject.recipientEmailAddress = jsonObject.recipientEmailAddress;
      }

      emailRequestObject.recipientUserId = (jsonObject.recipientUserId === 'undefined') ? undefined : jsonObject.recipientUserId;

      emailRequestObject.recipientUserName = (jsonObject.recipientUserName === 'undefined') ? undefined : jsonObject.recipientUserName;

      emailRequestObject.appPlatformType = (jsonObject.appPlatformType === undefined) ? enumAppPlatformType.Unknown : jsonObject.appPlatformType;

      if (jsonObject.messageSubject !== undefined) {
        emailRequestObject.messageSubject = jsonObject.messageSubject;
      }

      if (jsonObject.messageBodyHtml !== undefined) {
        emailRequestObject.messageBodyHtml = jsonObject.messageBodyHtml;
      }

      if (jsonObject.messageBodyPlainText !== undefined) {
        emailRequestObject.messageBodyPlainText = jsonObject.messageBodyPlainText;
      }

      if (jsonObject.originatingDomain !== undefined) {
        emailRequestObject.originatingDomain = jsonObject.originatingDomain;
      }

      emailRequestObject.continueUrl = (jsonObject.continueUrl === 'undefined') ? undefined : jsonObject.continueUrl;

      emailRequestObject.emailRequestSubmittedTimestamp = jsonObject.emailRequestSubmittedTimestamp ?? 'undefined';

      emailRequestObject.emailSentTimestamp = (jsonObject.emailSentTimestamp === 'undefined') ? undefined : jsonObject.emailSentTimestamp;

      emailRequestObject.otherEmailAddress = (jsonObject.otherEmailAddress === 'undefined') ? undefined : jsonObject.otherEmailAddress;

      // if request is to include contained objects, copy additional fields
      // there are not contained objects at this time

      return emailRequestObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
