import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid2';
import { IImageLink } from '../../../../dataObjects/models/digitalMedia/ImageLink';
import { ImageLinksCardGridViewItem } from './ImageLinksCardGridViewItem';
import { IImageLinkViewModel } from '../../../../dataObjects/viewModels/imageLinkViewModel';


export interface IImageLinksCardGridViewProps extends PropsWithChildren<unknown> {
  imageLinkViewModels: Array<IImageLinkViewModel>;
  onDelete: (imageLink: IImageLink) => void;
}


export const ImageLinksCardGridView: React.FC<IImageLinksCardGridViewProps> = (props: IImageLinksCardGridViewProps) => {
  ImageLinksCardGridView.displayName = 'Image Links Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render ImageLinksCardGridView`);

  const { imageLinkViewModels, onDelete } = props;

  return (
    <>
      <Grid container spacing={2} >
        {
          imageLinkViewModels.map(imageLinkViewModel =>
            <Grid key={imageLinkViewModel.imageLink.id} size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
              <ImageLinksCardGridViewItem imageLinkViewModel={imageLinkViewModel} onDelete={onDelete} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}