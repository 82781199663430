import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";
import { generateStandardEmailClosingHtml, generateStandardEmailOpeningHtml } from "../commonEmailBodyFragments";

/**
 * @function generateEmailBodyHtmlForPasswordReset. Generates the Html body of a notification email for a password reset request
 * @param {string} emailAddress The email address of the user requesting the password reset.
 * @param {string} firstName The first name of the user requesting the password reset.
 * @param {string} firstName The last name of the user requesting the password reset.
 * @returns {string} A string with the Html body of the email message to be sent.
 */
export function generateEmailBodyHtmlForPasswordReset(emailAddress: string, firstName: string, lastName: string): string {

  const recipientUserName: string = `${firstName} ${lastName}`;

  // *** Compose the email Body in HTML format ***
  let emailBodyHtml = generateStandardEmailOpeningHtml(recipientUserName);

  // user's password reset request has been received
  emailBodyHtml += `<p>We have received a request to reset your ${ServerSubstitutionKeywordsStringAssets.ProjectName} password for your (${emailAddress}) email account. `;
  emailBodyHtml += `You may proceed to reset your password by <a href='${ServerSubstitutionKeywordsStringAssets.PasswordResetLink}'>clicking on this password reset link</a> that will `;
  emailBodyHtml += `re-direct you to a browser and allow you to reset your ${ServerSubstitutionKeywordsStringAssets.ProjectName} password. </p>`;

  emailBodyHtml += `<p>If you did not request this password reset, you can safely ignore this email.</p>`;

  emailBodyHtml += `<p>We hope you continue to enjoy using ${ServerSubstitutionKeywordsStringAssets.ProjectName}! </p>`;

  emailBodyHtml += generateStandardEmailClosingHtml();

  // return the email content
  return emailBodyHtml;
}
