import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";
import { IObjectSharingRequestTracker } from "../../../../../dataObjects/models/collaboration/ObjectSharingTracker";

/**
 * @function generateEmailSubjectForObjectSharingRequest Generates the Subject of a notification email indicating that an object (eg, a Channel) has been shared
 * @param {IObjectSharingRequestTracker} objectSharingRequestTracker Object Sharing information with details needed for an email message
 * @returns {string} A string with the Subject of the email message to be sent.
 */
export function generateEmailSubjectForObjectSharingRequest(objectSharingRequestTracker: IObjectSharingRequestTracker): string {

  // *** Compose the email Subject ***
  const emailSubject = `Channel Sharing Request (${objectSharingRequestTracker.requestorName} has shared the "${objectSharingRequestTracker.sharingObjectName}" ${objectSharingRequestTracker.sharingObjectType})`;

  // return the email content
  return emailSubject;
}
