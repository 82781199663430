import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets/ServerSubstitutionKeywordsStringAssets";


export function generateStandardEmailClosingHtml(): string {

  let emailBodyHtml = `<p>Sincerely,</br>`;
  emailBodyHtml += `Your friends at ${ServerSubstitutionKeywordsStringAssets.ProjectName}</p>`;

  emailBodyHtml += `<p style="font-size: x-small">(This email has been sent from an unmonitored email address. If you require support, please send an email to 
      <a href="mailto:support@mydigibrain.com">support@mydigibrain.com</a>.)</p>`;

  emailBodyHtml += `<p style="font-size: x-small">${ServerSubstitutionKeywordsStringAssets.ProjectName} Copyright &copy; 2020-${new Date().getFullYear()}.</p>`;

  return emailBodyHtml;
}
