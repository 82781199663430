import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, IconButton, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  Clear as ClearIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { listViewItemStyles } from '../../../styles';
import { KeyValuePairsStringAssets, TooltipStringAssets } from '../../../../assets/stringAssets';
import { enumSharingPermission } from '../../../../dataObjects/enums';
import { IObjectSharingRequestTracker } from '../../../../dataObjects/models/collaboration/ObjectSharingTracker';
import { ICurrentUserChannelSharingViewModel } from '../../../../dataObjects/viewModels/channelSharing/CurrentUserChannelSharingViewModel';


export interface IChannelSharingRequestListViewItemProps extends PropsWithChildren<unknown> {
  channelSharingRequest: IObjectSharingRequestTracker;
  currentUserChannelSharingViewModel: ICurrentUserChannelSharingViewModel;
  onRevoke: (channelSharingRequest: IObjectSharingRequestTracker) => void;
}

export const ChannelSharingRequestListViewItem: React.FC<IChannelSharingRequestListViewItemProps> = (props: IChannelSharingRequestListViewItemProps) => {
  ChannelSharingRequestListViewItem.displayName = 'Channel Sharing Request List View Item';

  // eslint-disable-next-line no-console
  // console.info(`Render ChannelSharingUsersListViewItem`);

  const classes: Record<string, string> = listViewItemStyles();

  const { channelSharingRequest, currentUserChannelSharingViewModel, onRevoke } = props;

  // will hold setting indicating whether the currentUser has Admin privilege to the channel
  const [currentUserHasAdminAccessToChannel, setCurrentUserHasAdminAccessToChannel] = useState<boolean>(false);

  // define an effect that will set whether the currentUser has Admin privilege to the channel
  useEffect(() => {
    setCurrentUserHasAdminAccessToChannel(currentUserChannelSharingViewModel.currentUserChannelSharingPermission === enumSharingPermission.Admin);
  }, [currentUserChannelSharingViewModel]);


  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.tableRow} key={channelSharingRequest.id}>
        {/* Data Row 1: Name & Action Buttons  */}
        <Grid container size={12} direction="row" >
          {/* User Name */}
          <Grid container size={{ xs: 8, sm: 10 }} >
            <Typography className={classes.title} variant="h6">
              {channelSharingRequest.recipientEmail}
            </Typography>
          </Grid>

          {/* Action Buttons */}
          <Grid container size={{ xs: 4, sm: 2 }} direction="row" justifyContent="center" alignContent="flex-start">
            {/* Edit button */}
            <Grid container className={classes.actionButtonContainer} size={6} >
              <Tooltip
                // the tooltip for the edit button will either be "Edit sharing" (if the current user is an Admin of the channel) or "Edit disallowed" 
                // (the current user is NOT an Admin of the channel)
                title={currentUserHasAdminAccessToChannel ? TooltipStringAssets.channelSharingRequest_EditRequest : TooltipStringAssets.channelSharingRequest_EditDisallowedForInsufficientPermissions}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.editIconButton}`}
                    // this button will be disabled if the displayed user is the owner of the channel
                    disabled={!currentUserHasAdminAccessToChannel}
                    onClick={() => channelSharingRequest && navigate(`/channelSharingRequest/${channelSharingRequest.id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {/* Delete button */}
            <Grid container className={classes.actionButtonContainer} size={6} >
              <Tooltip
                // the tooltip for the revoke button will either be "Revoke sharing" (if the current user is an Admin of the channel) or "Revoke disallowed" 
                // (if the current user is NOT an Admin of the channel)
                title={currentUserHasAdminAccessToChannel ? TooltipStringAssets.channelSharingRequest_RevokeSharing : TooltipStringAssets.channelSharingRequest_RevokeDisallowedForInsufficientPermissions}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.deleteIconButton}`}
                    // this button will be disabled if the displayed user is the owner of the channel
                    disabled={!currentUserHasAdminAccessToChannel}
                    onClick={() => onRevoke(channelSharingRequest)}
                  >
                    <ClearIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* Data Row 2: Email Address and (Permission)  */}
        <Grid container size={12} >
          <Typography className={classes.description} variant="body1">
            {`${channelSharingRequest.recipientName} (${KeyValuePairsStringAssets.getValueFromKey(KeyValuePairsStringAssets.sharingPermissionKeyValuePairs, channelSharingRequest.sharingPermission)})`}
          </Typography>
        </Grid>

        {/* Add a dividing line after each item */}
        <Divider />
      </div>
      {/* </span> */}
    </>
  );

}

export default ChannelSharingRequestListViewItem;