import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";
import { generateStandardEmailClosingPlainText, generateStandardEmailOpeningPlainText } from "../commonEmailBodyFragments";

/**
 * @function generateEmailBodyPlainTextForPasswordResetCompleted. Generates the Plain Text body of a notification email indicating that a password reset request has been completed
 * @param {string} emailAddress The email address of the user requesting the password reset.
 * @param {string} firstName The first name of the user requesting the password reset.
 * @param {string} firstName The last name of the user requesting the password reset.
 * @returns {string} A string with the Plain Text body of the email message to be sent.
 */
export function generateEmailBodyPlainTextForPasswordResetCompleted(emailAddress: string, firstName: string, lastName: string): string {

  const recipientUserName: string = `${firstName} ${lastName}`;

  // *** Compose the email Body in Plain Text format ***
  // salutation/greetings to the recipient
  let emailBodyPlainText = generateStandardEmailOpeningPlainText(recipientUserName);

  // thank user for registering
  emailBodyPlainText += `Your ${ServerSubstitutionKeywordsStringAssets.ProjectName} password for your (${emailAddress}) email account has been successfully changed.\n\n`;

  emailBodyPlainText += `If you did not request this password change, please go immediately to the ${ServerSubstitutionKeywordsStringAssets.ProjectName} application (${ServerSubstitutionKeywordsStringAssets.AppUrl}) `;
  emailBodyPlainText += `and select the 'Forgot Password?' link to reset your password.\n\n`;

  emailBodyPlainText += `We hope you enjoy using ${ServerSubstitutionKeywordsStringAssets.ProjectName}! \n\n`;

  emailBodyPlainText += generateStandardEmailClosingPlainText();

  // return the email content
  return emailBodyPlainText;
}
