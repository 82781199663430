import { submitEmailRequestToServer } from ".";
import { IEmailRequest } from "../../../../dataObjects/models/emailRequests";
import { IUserFeedback } from "../../../../dataObjects/models/userFeedback";
import { generateEmailRequestForUserFeedback } from "./userFeedback";

/**
 * @function submitEmailRequestForUserFeedback Generates an email request when a user provides feedback, and then submits to an appropriate Firebase Function on the server.
 * @param {IUserFeedback} userFeedback User Feedback information with details needed for an email message
 * @returns {void} No return value
 */
export function submitEmailRequestForUserFeedback(userFeedback: IUserFeedback): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // whether to display console logs (displayConsoleLogs && console.log statements)
      const displayConsoleLogs: boolean = false;

      displayConsoleLogs && console.log(`%c Entered submitEmailRequestForUserFeedback()`, 'background: #055; color: #fff');

      // call method to generate the User Feedback email request, which will return the Email Request
      const emailRequest: IEmailRequest = await generateEmailRequestForUserFeedback(userFeedback);

      displayConsoleLogs && console.log(`%c In submitEmailRequestForUserFeedback. Returned from call to generateEmailRequestForUserFeedback. emailRequest: ${JSON.stringify(emailRequest)}`, 'background: #00B; color: #fff');

      // submit email request to the server
      await submitEmailRequestToServer(emailRequest);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}
