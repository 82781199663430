import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid2';
import { ISocialMediaPost } from '../../../../dataObjects/models/digitalMedia/SocialMediaPost';
import { SocialMediaPostsCardGridViewItem } from './SocialMediaPostsCardGridViewItem';
import { ISocialMediaPostViewModel } from '../../../../dataObjects/viewModels/socialMediaPostViewModel';


export interface ISocialMediaPostsCardGridViewProps extends PropsWithChildren<unknown> {
  socialMediaPostViewModels: Array<ISocialMediaPostViewModel>;
  onDelete: (socialMediaPost: ISocialMediaPost) => void;
}


export const SocialMediaPostsCardGridView: React.FC<ISocialMediaPostsCardGridViewProps> = (props: ISocialMediaPostsCardGridViewProps) => {
  SocialMediaPostsCardGridView.displayName = 'SocialMediaPosts Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render SocialMediaPostsList`);

  const { socialMediaPostViewModels, onDelete } = props;

  return (
    <>
      <Grid container spacing={2} >
        {
          socialMediaPostViewModels.map(socialMediaPostViewModel =>
            <Grid key={socialMediaPostViewModel.socialMediaPost.id} size={{ xs: 12, sm: 6, md: 6, lg: 3, xl: 3 }}>
              <SocialMediaPostsCardGridViewItem socialMediaPostViewModel={socialMediaPostViewModel} onDelete={onDelete} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}