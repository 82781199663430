import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

const initialState: enumWorkflowState | null = null;

export const fetchUserFeedbackStatusSlice = createSlice({
  name: 'fetchUserFeedbackStatus',
  initialState,
  reducers: {
    setFetchUserFeedbackStatus: (state: enumWorkflowState | null, action: PayloadAction<enumWorkflowState>) => {
      state = action.payload;
    },
    clearFetchUserFeedbackStatus: (state: enumWorkflowState | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchUserFeedbackStatus, clearFetchUserFeedbackStatus } = fetchUserFeedbackStatusSlice.actions;

// export the core reducer for the slice
export const fetchUserFeedbackStatusReducer = fetchUserFeedbackStatusSlice.reducer;

export default fetchUserFeedbackStatusSlice.reducer;