import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { IHyperLink, IHyperLinkAsJson } from '.';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';

/** 
 * @class HyperLink A audio link that can be associated with something
 */
export class HyperLink extends VersionAwarePersistable implements IHyperLink {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {string} title The title of the hyperLink
   * @param {string} hyperLinkUrl A full Url for the hyperLink
   * @param {string} description A description of the hyperLink
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    title: string,
    hyperLinkUrl: string,
    description: string,
    userPersistenceData?: IUserPersistenceData
  ) {
    super(ownerId, enumPersistableObjectClassName.HyperLink, enumPersistableObjectType.HyperLink, id, parentObjectType, parentId, objectState, userPersistenceData);

    this.title = title;
    this.hyperLinkUrl = hyperLinkUrl;
    this.description = description;
  }


  /*-----------------------------------------------*/
  /**
   * @property {string} _title The title of the HyperLink
   */
  private _title: string = '';

  /**
   * @method title Getter method for _title
   */
  get title(): string {
    return this._title;
  }

  /**
   * @method title Setter method for _title
   * @param {string} value The input value for setting _title
   */
  set title(value: string) {
    this._title = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _hyperLinkUrl The full Url for the HyperLink
   */
  private _hyperLinkUrl: string = '';

  /**
   * @method hyperLinkUrl Getter method for _hyperLinkUrl
   */
  get hyperLinkUrl(): string {
    return this._hyperLinkUrl;
  }

  /**
   * @method hyperLinkUrl Setter method for _hyperLinkUrl
   * @param {string} value The input value for setting _hyperLinkUrl
   */
  set hyperLinkUrl(value: string) {
    this._hyperLinkUrl = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _description The description for the HyperLink
   */
  private _description: string = '';

  /**
   * @method description Getter method for _description
   */
  get description(): string {
    return this._description;
  }

  /**
   * @method description Setter method for _description
   * @param {string} value The input value for setting _description
   */
  set description(value: string) {
    this._description = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IHyperLink} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IHyperLink {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IHyperLink = Object.create(HyperLink.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IHyperLinkAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IHyperLinkAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.title = this.title;
      jsonObject.hyperLinkUrl = this.hyperLinkUrl;
      jsonObject.description = this.description;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // this class is currently designed to have no contained objects
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IHyperLinkAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns An instance of 'this' class with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IHyperLinkAsJson, includeContainedObjects: boolean = true): IHyperLink {
    try {
      // create a new instance of this class
      let hyperLinkObject: HyperLink = Object.create(HyperLink.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      hyperLinkObject = super.fromJSONProtected(hyperLinkObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.title !== undefined) {
        hyperLinkObject.title = jsonObject.title;
      }

      if (jsonObject.hyperLinkUrl !== undefined) {
        hyperLinkObject.hyperLinkUrl = jsonObject.hyperLinkUrl;
      }

      if (jsonObject.description !== undefined) {
        hyperLinkObject.description = jsonObject.description;
      }
      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // this class doesn't have any contained objects to add
      }

      return hyperLinkObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
