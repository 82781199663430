import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";

/**
 * @function generateEmailSubjectForPasswordResetCompleted Generates the Subject of a notification email indicating that a password reset request has been completed
 * @returns {string} A string with the Subject of the email message to be sent.
 */
export function generateEmailSubjectForPasswordResetCompleted(): string {

  // *** Compose the email Subject ***
  const emailSubject = `(${ServerSubstitutionKeywordsStringAssets.ProjectName}) Password Reset Completed`;

  // return the email content
  return emailSubject;
}
