import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ControlsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import EmailInput from '../../controls/EmailInput/EmailInput';


interface IPopulateSearchDataFormValues {
  email: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  email: yup
    .string()
    .notRequired()
    .email(ControlsStringAssets.emailInvalid)
});

// // declare a yup schema object with details to be set up dynamically
// let schema = yup.object().shape({});

/**
 * @interface IPopulateSearchDataFormProps Properties for the PopulateSearchDataForm component
 */
export interface IPopulateSearchDataFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {string} email The email address of the user account to be expunged
   */
  email: string,
  /**
   * @property {boolean} expungeRequestInProgress Whether an expunge request is in progress
   */
  populateRequestInProgress?: boolean,
  /**
   * @property {(email: string | undefined) => Promise<void>} onSubmit Method to call for submitting the form for an operation
   */
  onSubmit: (email: string | undefined) => Promise<void>,
}

const PopulateSearchDataForm: React.FC<IPopulateSearchDataFormProps> = (props: IPopulateSearchDataFormProps) => {
  PopulateSearchDataForm.displayName = 'Populate Search Data Form';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  // get required arguments from props
  const { email, onSubmit } = props;

  // set up details for ReactHookForm
  // const { control, formState, formState: { errors }, handleSubmit, trigger } = useForm<IPopulateSearchDataFormValues>({
  const { control, formState, formState: { errors }, handleSubmit, trigger } = useForm({
    defaultValues: {
      email: email
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  // const { ref: emailReg, ...emailProps } = register("email", { required: true });

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c PopulateSearchDataForm. currentUser: \n${JSON.stringify(currentUser)}`, 'background: #600; color: #fff');

  // for testing whether the form is in a valid state
  const { isValid } = formState;

  const [formIsValid, setFormIsValid] = useState<boolean>(false);


  useEffect(() => {
    setFormIsValid(isValid);
    // console.info(`UserSettings.useEffect: formIsValid being set to ${isValid}`);
  }, [isValid]);

  // // capture whether the process for populating search metadata is in progress
  // const populateSearchDataInProgress: boolean = props.populateRequestInProgress ?? false;

  // state value indicating whether populating search metadata is in progress
  // const [populateSearchMetadataInProgress, setPopulateSearchMetadataInProgress] = useState<boolean>(populateSearchDataInProgress);
  const [populateSearchMetadataInProgress, setPopulateSearchMetadataInProgress] = useState<boolean>(props.populateRequestInProgress ?? false);

  displayConsoleLogs && console.log(`In PopulateSearchDataForm. populateSearchMetadataInProgress: ${populateSearchMetadataInProgress}; expungeInProgress: ${populateSearchMetadataInProgress}`);

  // // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  // useEffect(() => {
  //   setPopulateSearchMetadataInProgress(populateSearchDataInProgress);
  // }, [populateSearchDataInProgress]);

  // handles a save/submit request from the form
  // const handleSaveSubmit = async (data: IPopulateSearchDataFormValues) => {
  // because the email field is not required, we need to set the type of 'data' to 'any' in order to avoid compiler error
  const handleSaveSubmit = async (data: any) => {

    setPopulateSearchMetadataInProgress(true);

    // call the onSubmit handler passed in, optionally supplying a user's email address
    await onSubmit(data.email);
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={populateSearchMetadataInProgress}
        actionInProgressLabel={MessagesStringAssets.searchData_PopulateSearchMetadataRequested}
        formIsValid={formIsValid}
        submitButtonLabel='Populate'
      >

        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched, isDirty } }) => (
            <>
              <EmailInput
                control={control}
                trigger={trigger}
                autoFocus
                label={ControlsStringAssets.populateSearchMetadataDb}
                margin='normal'
                fullWidth
                // error={!!errors.email}
                helperText={errors?.email?.message}
                slotProps={{
                  inputLabel: {
                    // required: true // this will cause an asterisk ('*') to appear at the end of the label text
                  }
                }}
              />
            </>
          )}
        />

      </FormWithActionBar>
    </>

  );
}

export default PopulateSearchDataForm;