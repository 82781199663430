// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router";
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import { configureStore } from './uiMiddleware-redux/store';
// import { setAppVersion } from './uiMiddleware-redux/actions';
import { AuthIsLoaded } from './components/jsxFragments';

import { CurrentUserProvider } from './components/providersAndContexts/currentUser';
import { UserCategoriesAndChannelsProvider } from './components/providersAndContexts/userCategoriesAndChannels';
import { AppThemesProvider } from './components/providersAndContexts/themes';
import { AppVersionProvider } from './components/providersAndContexts/appVersion';
// import { StoreConfiguratorSingleton } from "./uiMiddleware-redux/store/StoreConfigurator/StoreConfiguratorSingleton";
// import { enumDeploymentPlatformType } from "./dataObjects/enums";
// import { useEffect, useState } from "react";
// import { EnhancedStore } from "@reduxjs/toolkit";
// import { IStoreState } from "./uiMiddleware-redux/store/IStoreState";
// import { Persistor } from "redux-persist";
import { configureStore } from "./uiMiddleware-redux/store";
// import { appVersionChange } from "./uiMiddleware-redux/slices/appVersion/appVersionSlice";

// const { oldStore, oldPersistor } = configureStore();
const { store, persistor } = configureStore();

// let deploymentEnvironment: enumDeploymentPlatformType;
// if (process.env.NODE_ENV === "production") {
//   deploymentEnvironment = enumDeploymentPlatformType.Production;
// } else {
//   deploymentEnvironment = enumDeploymentPlatformType.Development;
// }


// store.dispatch(setAppVersion());
// store.dispatch(appVersionChange('TK App Version'));

// const [store, setStore] = useState<EnhancedStore<IStoreState> | undefined>(undefined);
// const [persistor, setPersistor] = useState<Persistor | undefined>(undefined);

// // useEffect to trigger upon startup
// useEffect(() => {
//   const storeConfigurator: StoreConfiguratorSingleton = StoreConfiguratorSingleton.initialize(deploymentEnvironment);
//   setStore(storeConfigurator.store);
//   setPersistor(storeConfigurator.persistor);
// });

// const storeConfigurator: StoreConfiguratorSingleton = StoreConfiguratorSingleton.initialize(deploymentEnvironment);
// const store: EnhancedStore<IStoreState> | undefined = storeConfigurator.store;
// const persistor: Persistor | undefined = storeConfigurator.persistor;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  // Removed <React.StrictMode> below due to an unresolved React issue that was triggered by changing tab pages/panes in a Bootstrap Tabs control. 
  // Below, see the console warning that gets triggered by the use of <React.StrictMode>
  // NOTE: <React.StrictMode> is only activated in Development mode. In Production mode, it's a no-op.
  // Console Warning:
  //   index.js:1 Warning: findDOMNode is deprecated in StrictMode. findDOMNode was passed an instance of Transition which is inside StrictMode. 
  //   Instead, add a ref directly to the element you want to reference. Learn more about using refs safely here: https://fb.me/react-strict-mode-find-node
  <>
    {/* <React.StrictMode> */}
    {store &&
      <ReduxProvider store={store}>
        {/* <ReactReduxFirebaseProvider {...rrfProps} > */}
        {/* Wrapping the root React component with PersistGate delays rendering of the UI until the persisted state has been retrieved and saved to redux */}
        {persistor &&
          <PersistGate loading={null} persistor={persistor}>
            { /* only display the rest of the tree if the FirebaseAppSingleton has been fully initialized*/
              /* firebaseAppSingleInitialized && */
              // firebaseInitialized &&
              <CurrentUserProvider>
                <UserCategoriesAndChannelsProvider>
                  <BrowserRouter>

                    {/* Wrapping the App component with the AuthIsLoaded component guards against display of the UI until the 
                Firebase Authentication info has been loaded */}
                    <AuthIsLoaded>
                      {/* <CssBaseline> */}
                      <AppVersionProvider>
                        <AppThemesProvider>
                          <App />
                        </AppThemesProvider>
                      </AppVersionProvider>
                      {/* </CssBaseline> */}
                    </AuthIsLoaded>
                  </BrowserRouter>
                </UserCategoriesAndChannelsProvider>
              </CurrentUserProvider>
            }
          </PersistGate>
        }
        {/* </ReactReduxFirebaseProvider> */}
      </ReduxProvider>
    }
    {/* </React.StrictMode> */}
  </>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
