import Color, { ColorInstance, ColorObject } from "color";
import _ from "lodash";
import { RegularExpressions } from "../../../assets/regularExpressionAssets";
import { IThemeColorSpectrum } from "../../models/themes";
import { enumMdbErrorType } from "../../../errorObjects/enums";
import { MdbError } from "../../../errorObjects/MdbError";


/**
 * @function generateThemeColorSpectrumFromSingleColor Given a single color (main color), generates an IThemeColorSpectrum object
 */
export function generateThemeColorSpectrumFromSingleColor(mainColorAsString: string): Promise<IThemeColorSpectrum> {
  return new Promise<IThemeColorSpectrum>(async (resolve, reject) => {
    try {
      // whether to display console logs (displayConsoleLogs && console.log statements)
      const displayConsoleLogs: boolean = false;

      // innocuous transformations of the colorString input may be required, so we'll define a local variable
      // that starts off as an exact copy of the colorString.
      let colorStringTransformed: string = mainColorAsString;

      // Perform some input string validations and/or transformations that the Color class doesn't seem to perform.
      // 1. If the color string begins with a pound sign ('#'), ensure it's a valid hex code (3 or 6 hex characters).
      if (mainColorAsString.startsWith("#")) {
        const colorByHexCodePattern: RegExp = RegularExpressions.ColorByHexCode;
        if (!colorByHexCodePattern.test(mainColorAsString)) {
          throw new MdbError(`'colorString' (${mainColorAsString}) does not conform to a proper hex code pattern (3 or 6 hex characters).`, enumMdbErrorType.ColorValueIsInvalid);
        }
      }

      // 2. If the color string begins with "RGB" in uppercase or lowercase letters, transform to all lowercase, so the Color
      //    class will accept it.
      if (mainColorAsString.toUpperCase().startsWith('RGB')) {
        colorStringTransformed = mainColorAsString.toLowerCase();
      }

      // 3. If the color string begins with "HSL" in uppercase or lowercase letters, transform to all lowercase, so the Color
      //    class will accept it.
      if (mainColorAsString.toUpperCase().startsWith('HSL')) {
        colorStringTransformed = mainColorAsString.toLowerCase();
      }

      let calculatedThemeColorSpectrum: IThemeColorSpectrum;
      // let colorInputParmIsValid: boolean = false;

      // const colorByKeywordPattern: RegExp = RegularExpressions.ColorByKeyword;
      // colorInputParmIsValid = colorByKeywordPattern.test(colorString);

      // if (colorInputParmIsValid) {

      const mainColorAsObject: ColorInstance = new Color(colorStringTransformed);

      if (mainColorAsObject !== undefined) {
        // calculatedThemeColorSpectrum = {
        //   25: '25 Color',
        //   50: '50 Color',
        //   100: '100 Color',
        //   200: '200 Color',
        //   300: '300 Color',
        //   400: '400 Color',
        //   500: '500 Color',
        //   600: '600 Color',
        //   700: '700 Color',
        //   800: '800 Color',
        //   900: '900 Color',
        //   light: 'light Color',
        //   main: 'main Color',
        //   dark: 'dard Color',
        //   lightBackgroundContrastText: 'lightBackgroundContrastText Color',
        //   midRangeBackgroundContrastText: 'midRangeBackgroundContrastText Color',
        //   darkBackgroundContrastText: 'darkBackgroundContrastText Color',
        // };

        calculatedThemeColorSpectrum = createNumericRangeOfSpectrumColors(mainColorAsObject);

        displayConsoleLogs && console.log(`In generateThemeColorSpectrumFromSingleColor. calculatedThemeColorSpectrum -- main: ${calculatedThemeColorSpectrum.main}; dark: ${calculatedThemeColorSpectrum.dark}; light: ${calculatedThemeColorSpectrum.light}`);

        resolve(calculatedThemeColorSpectrum);
      } else {
        reject(new MdbError(`'colorString' (${mainColorAsString}) input parameter is not recognized as a valid color.`, enumMdbErrorType.ColorValueIsInvalid));
      }
    } catch (error: any) {
      // alert(`Error. User registration failed: (${error})`);
      reject(error);
    }
  });
}

function createNumericRangeOfSpectrumColors(mainColor: ColorInstance): IThemeColorSpectrum {

  // whether to display console logs (console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('%c Entered createNumericRangeOfSpectrumColors', 'background: #f0d0d0; color: #000');

  // transform the color into RGB and extract the Red, Green, & Blue components of the color
  const rgbColorsArray: Array<number> = mainColor.rgb().array();
  displayConsoleLogs && console.log(`%c In createNumericRangeOfSpectrumColors. mainColor: ${JSON.stringify(mainColor)}; rgbColorsArray: ${JSON.stringify(rgbColorsArray)}`, 'background: #f0d0d0; color: #000');

  // transform the color into HSL and extract the Hue, Saturation, and Lightness components of the color
  const hslColorsArray: Array<number> = mainColor.hsl().array();
  displayConsoleLogs && console.log(`%c In createNumericRangeOfSpectrumColors. mainColor: ${JSON.stringify(mainColor)}; hslColorsArray: ${JSON.stringify(hslColorsArray)}`, 'background: #f0d0d0; color: #000');

  // Note: '1.0 * ' is used to transform to floating point
  // const mainColor_RedValue: number = 1.0 * _.round(rgbColorsArray[0], 0);
  // const mainColor_GreenValue: number = 1.0 * _.round(rgbColorsArray[1], 0);
  // const mainColor_BlueValue: number = 1.0 * _.round(rgbColorsArray[2], 0);

  // const mainColor_HueValue: number = 1.0 * _.round(hslColorsArray[0], 0);
  // const mainColor_SaturationValue: number = 1.0 * _.round(hslColorsArray[1], 0);
  const mainColor_LightnessValue: number = 1.0 * _.round(hslColorsArray[2], 0);

  // const mainColor_MaxSaturationValue = Math.max(mainColor_SaturationValue, 0.90);
  // const mainColor_MinSaturationValue = Math.min(mainColor_SaturationValue, 0.02);

  // const mainColor_MaxLightnessValue = Math.max(mainColor_LightnessValue, 0.98);
  // const mainColor_MinLightnessValue = Math.min(mainColor_LightnessValue, 0.10);

  // const the25Value: Color = new Color([mainColor_RedValue + (255 - mainColor_RedValue) * 0.025,
  // mainColor_GreenValue + (255 - mainColor_GreenValue) * 0.025],
  //   mainColor_BlueValue + (255 - mainColor_BlueValue) * 0.025);

  let themeColorSpectrum: IThemeColorSpectrum = {
    25: '25 Color',
    50: '50 Color',
    100: '100 Color',
    200: '200 Color',
    300: '300 Color',
    400: '400 Color',
    500: '500 Color',
    600: '600 Color',
    700: '700 Color',
    800: '800 Color',
    900: '900 Color',
    light: 'light Color',
    main: 'main Color',
    dark: 'dark Color',
    contrastTextForLightBackground: 'contrastTextForLightBackground Color',
    contrastTextForMainBackground: 'contrastTextForMainBackground Color',
    contrastTextForDarkBackground: 'contrastTextForDarkBackground Color',
    contrastHighlightForLightBackground: 'contrastHighlightForLightBackground Color',
    contrastHighlightForMainBackground: 'contrastHighlightForMainBackground Color',
    contrastHighlightForDarkBackground: 'contrastHighlightForDarkBackground Color',
  };

  // // If the main color has a lightness that is greater than 15, in an effort to keep the darkest color (900 value) at a 
  // // level that is near 15, use a Shade Adjustment Factor
  // let shadeAdjustmentFactor: number = 1.0;
  // if ((mainColor_LightnessValue < 65.0) && (mainColor_LightnessValue > 15.0)) {
  //   // shadeAdjustmentFactor = ((55.0 - mainColor_LightnessValue)/2.0) / 100.0;
  //   shadeAdjustmentFactor = (65.0 - mainColor_LightnessValue) / 100.0;
  // }

  // When tinting (making lighter color, based on the main color):
  //  o For the "25" value, estimate the lightness value to be 98%, which will be 24/25 of the difference between 100% lightness and the 
  //    main color lightness value.
  //  o For the "50" value, estimate the lightness value to be 95%, which will be 45/50 (9/10) of the difference between 100% lightnessand the 
  //    main color lightness value.
  //  o For the "100", "200", "300", & "400" values, estimate the lightness value to be increments of 20% (1/5) of the difference between 100% lightness and the 
  //    main color lightness value (4x for "100", 3x for "200", 2x for "300", and 1x for "400").
  const tintFactorForColor25Value: number = (100.0 - mainColor_LightnessValue) * 0.96;
  const tintFactorForColor50Value: number = (100.0 - mainColor_LightnessValue) * 0.90;
  const tint20PercentIncrementFactor: number = (100.0 - mainColor_LightnessValue) / 5;
  themeColorSpectrum[25] = mainColor.lightness(mainColor_LightnessValue + tintFactorForColor25Value).toString();
  themeColorSpectrum[50] = mainColor.lightness(mainColor_LightnessValue + tintFactorForColor50Value).toString();
  themeColorSpectrum[100] = mainColor.lightness(mainColor_LightnessValue + (tint20PercentIncrementFactor * 4)).toString();
  themeColorSpectrum[200] = mainColor.lightness(mainColor_LightnessValue + (tint20PercentIncrementFactor * 3)).toString();
  themeColorSpectrum[300] = mainColor.lightness(mainColor_LightnessValue + (tint20PercentIncrementFactor * 2)).toString();
  themeColorSpectrum[400] = mainColor.lightness(mainColor_LightnessValue + (tint20PercentIncrementFactor * 1)).toString();
  themeColorSpectrum[500] = mainColor.rgb().toString();
  // For shading (making the color darker -- reducing the lightness value, based on the main color):
  //  o For the "100", "200", "300", & "400" values, estimate the lightness value to be decrements of 20% (1/5) of the main color lightness value 
  //    (1x for "600", 2x for "700", 3x for "800", and 4x for "900").
  const shade20PercentIncrementFactor: number = mainColor_LightnessValue / 5.0;
  themeColorSpectrum[600] = mainColor.lightness(mainColor_LightnessValue - (shade20PercentIncrementFactor * 1)).toString();
  themeColorSpectrum[700] = mainColor.lightness(mainColor_LightnessValue - (shade20PercentIncrementFactor * 2)).toString();
  themeColorSpectrum[800] = mainColor.lightness(mainColor_LightnessValue - (shade20PercentIncrementFactor * 3)).toString();
  themeColorSpectrum[900] = mainColor.lightness(mainColor_LightnessValue - (shade20PercentIncrementFactor * 4)).toString();

  // next, assign the light, main, and dark colors to the IThemeColorSpectrum object
  themeColorSpectrum.light = themeColorSpectrum[25];
  themeColorSpectrum.main = themeColorSpectrum[500];
  themeColorSpectrum.dark = themeColorSpectrum[900];

  // Next, assign the 'contrastTextXXX' colors. 
  //   o For the Light Background, use the darkest color in the spectrum
  //   o For the Dark Background, use the lightest color in the spectrum
  //   o For the Main Background, determine whether the lightest or darkest color has the best contrast ratio, and then use
  //     the one with the best contrast ratio
  themeColorSpectrum.contrastTextForLightBackground = themeColorSpectrum[900];
  themeColorSpectrum.contrastTextForDarkBackground = themeColorSpectrum[25];
  // themeColorSpectrum.contrastTextForMainBackground = mainColor.isLight() ? themeColorSpectrum[900] : themeColorSpectrum[25];
  const contrastMainWithLightestColor: number = mainColor.contrast(Color(themeColorSpectrum[25]));
  const contrastMainWithDarkestColor: number = mainColor.contrast(Color(themeColorSpectrum[900]));
  displayConsoleLogs && console.log(`%c In createNumericRangeOfSpectrumColors. contrastMainWithLightestColor: \n${JSON.stringify(contrastMainWithLightestColor)}; contrastMainWithDarkestColor: \n${JSON.stringify(contrastMainWithDarkestColor)}`, 'background: #f0d0d0; color: #000');
  if (contrastMainWithLightestColor > contrastMainWithDarkestColor) {
    themeColorSpectrum.contrastTextForMainBackground = themeColorSpectrum[25];
  } else {
    themeColorSpectrum.contrastTextForMainBackground = themeColorSpectrum[900];
  }

  // determine the appropriate 'contrastHightlightXXX' colors
  themeColorSpectrum.contrastHighlightForLightBackground = determineContrastHighlightForLightBackground(themeColorSpectrum).rgb().toString();
  themeColorSpectrum.contrastHighlightForMainBackground = determineContrastHighlightForMainBackground(themeColorSpectrum).rgb().toString();
  themeColorSpectrum.contrastHighlightForDarkBackground = determineContrastHighlightForDarkBackground(themeColorSpectrum).rgb().toString();

  displayConsoleLogs && console.log(`%c In createNumericRangeOfSpectrumColors. themeColorSpectrum after all colors assigned (RGB): \n${JSON.stringify(themeColorSpectrum)}`, 'background: #f0d0d0; color: #000');
  displayConsoleLogs && console.log(`%c In createNumericRangeOfSpectrumColors. themeColorSpectrum after all colors assigned (HSL): \n${JSON.stringify(convertAllSpectrumColorsToHsl(themeColorSpectrum))}`, 'background: #f0d0d0; color: #000');

  // call method to convert all spectrum colors to Hex
  themeColorSpectrum = convertAllSpectrumColorsToHex(themeColorSpectrum);

  displayConsoleLogs && console.log(`%c In createNumericRangeOfSpectrumColors. themeColorSpectrum as Hex colors to be returned: \n${JSON.stringify(themeColorSpectrum)}`, 'background: #f0d0d0; color: #000');
  return themeColorSpectrum;
}

// // tintColor lightens a color by a given percentage
// function tintColor(redValue: number, greenValue: number, blueValue: number, tintPercentage: number): Color {

//   const newRedValue: number = _.round(redValue + (255 - redValue) * tintPercentage);
//   const newGreenValue: number = _.round(greenValue + (255 - greenValue) * tintPercentage);
//   const newBlueValue: number = _.round(blueValue + (255 - blueValue) * tintPercentage);

//   const newColor: Color = Color.rgb(newRedValue, newGreenValue, newBlueValue);

//   return newColor;
// }

// // shadeColor darkens a color by a given percentage
// function shadeColor(redValue: number, greenValue: number, blueValue: number, shadePercentage: number): Color {

//   const newRedValue: number = _.round(redValue * (1 - shadePercentage));
//   const newGreenValue: number = _.round(greenValue * (1 - shadePercentage));
//   const newBlueValue: number = _.round(blueValue * (1 - shadePercentage));

//   const newColor: Color = Color.rgb(newRedValue, newGreenValue, newBlueValue);

//   return newColor;
// }

// determine the color to use as the "Constrast Highlight" color for a Light background
function determineContrastHighlightForLightBackground(themeColorSpectrum: IThemeColorSpectrum): ColorInstance {

  let contrastHighlightColorForLightBackground: ColorInstance = Color('#777');

  // find and return the lightest "Dark" color in the spectrum
  if (Color(themeColorSpectrum[25]).isDark()) {
    contrastHighlightColorForLightBackground = Color(themeColorSpectrum[25]);
  } else if (Color(themeColorSpectrum[50]).isDark()) {
    contrastHighlightColorForLightBackground = Color(themeColorSpectrum[50]);
  } else if (Color(themeColorSpectrum[100]).isDark()) {
    contrastHighlightColorForLightBackground = Color(themeColorSpectrum[100]);
  } else if (Color(themeColorSpectrum[200]).isDark()) {
    contrastHighlightColorForLightBackground = Color(themeColorSpectrum[200]);
  } else if (Color(themeColorSpectrum[300]).isDark()) {
    contrastHighlightColorForLightBackground = Color(themeColorSpectrum[300]);
  } else if (Color(themeColorSpectrum[400]).isDark()) {
    contrastHighlightColorForLightBackground = Color(themeColorSpectrum[400]);
  } else if (Color(themeColorSpectrum[500]).isDark()) {
    contrastHighlightColorForLightBackground = Color(themeColorSpectrum[500]);
  } else if (Color(themeColorSpectrum[600]).isDark()) {
    contrastHighlightColorForLightBackground = Color(themeColorSpectrum[600]);
  } else if (Color(themeColorSpectrum[700]).isDark()) {
    contrastHighlightColorForLightBackground = Color(themeColorSpectrum[700]);
  } else if (Color(themeColorSpectrum[800]).isDark()) {
    contrastHighlightColorForLightBackground = Color(themeColorSpectrum[800]);
  } else if (Color(themeColorSpectrum[900]).isDark()) {
    contrastHighlightColorForLightBackground = Color(themeColorSpectrum[900]);
  }

  return contrastHighlightColorForLightBackground;
}

// determine the color to use as the "Constrast Highlight" color for the Main background
function determineContrastHighlightForMainBackground(themeColorSpectrum: IThemeColorSpectrum): ColorInstance {

  let contrastHighlightColorForMainBackground: ColorInstance = Color('#777');

  // if the Main Color is "Dark", find and return the darkest "Light" color 
  if (Color(themeColorSpectrum[500]).isDark()) {
    if (Color(themeColorSpectrum[400]).isLight()) {
      contrastHighlightColorForMainBackground = Color(themeColorSpectrum[400]);
    } else if (Color(themeColorSpectrum[300]).isLight()) {
      contrastHighlightColorForMainBackground = Color(themeColorSpectrum[300]);
    } else if (Color(themeColorSpectrum[200]).isLight()) {
      contrastHighlightColorForMainBackground = Color(themeColorSpectrum[200]);
    } else if (Color(themeColorSpectrum[100]).isLight()) {
      contrastHighlightColorForMainBackground = Color(themeColorSpectrum[100]);
    } else if (Color(themeColorSpectrum[50]).isLight()) {
      contrastHighlightColorForMainBackground = Color(themeColorSpectrum[50]);
    } else if (Color(themeColorSpectrum[25]).isLight()) {
      contrastHighlightColorForMainBackground = Color(themeColorSpectrum[25]);
    }
  } else {
    // since the Main Color is "Light", find and return the lightest "Dark" color 
    if (Color(themeColorSpectrum[600]).isDark()) {
      contrastHighlightColorForMainBackground = Color(themeColorSpectrum[600]);
    } else if (Color(themeColorSpectrum[700]).isDark()) {
      contrastHighlightColorForMainBackground = Color(themeColorSpectrum[700]);
    } else if (Color(themeColorSpectrum[800]).isDark()) {
      contrastHighlightColorForMainBackground = Color(themeColorSpectrum[800]);
    } else if (Color(themeColorSpectrum[900]).isDark()) {
      contrastHighlightColorForMainBackground = Color(themeColorSpectrum[900]);
    }
  }

  return contrastHighlightColorForMainBackground;
}

// determine the color to use as the "Constrast Highlight" color for a Dark background
function determineContrastHighlightForDarkBackground(themeColorSpectrum: IThemeColorSpectrum): ColorInstance {

  let contrastHighlightColorForDarkBackground: ColorInstance = Color('#777');

  // find and return the darkest "Light" color in the spectrum
  if (Color(themeColorSpectrum[900]).isLight()) {
    contrastHighlightColorForDarkBackground = Color(themeColorSpectrum[900]);
  } else if (Color(themeColorSpectrum[800]).isLight()) {
    contrastHighlightColorForDarkBackground = Color(themeColorSpectrum[800]);
  } else if (Color(themeColorSpectrum[700]).isLight()) {
    contrastHighlightColorForDarkBackground = Color(themeColorSpectrum[700]);
  } else if (Color(themeColorSpectrum[600]).isLight()) {
    contrastHighlightColorForDarkBackground = Color(themeColorSpectrum[600]);
  } else if (Color(themeColorSpectrum[500]).isLight()) {
    contrastHighlightColorForDarkBackground = Color(themeColorSpectrum[500]);
  } else if (Color(themeColorSpectrum[400]).isLight()) {
    contrastHighlightColorForDarkBackground = Color(themeColorSpectrum[400]);
  } else if (Color(themeColorSpectrum[300]).isLight()) {
    contrastHighlightColorForDarkBackground = Color(themeColorSpectrum[300]);
  } else if (Color(themeColorSpectrum[200]).isLight()) {
    contrastHighlightColorForDarkBackground = Color(themeColorSpectrum[200]);
  } else if (Color(themeColorSpectrum[100]).isLight()) {
    contrastHighlightColorForDarkBackground = Color(themeColorSpectrum[100]);
  } else if (Color(themeColorSpectrum[50]).isLight()) {
    contrastHighlightColorForDarkBackground = Color(themeColorSpectrum[50]);
  } else if (Color(themeColorSpectrum[25]).isLight()) {
    contrastHighlightColorForDarkBackground = Color(themeColorSpectrum[25]);
  }

  return contrastHighlightColorForDarkBackground;
}


// converts all spectrum colors to Hex
function convertAllSpectrumColorsToHex(themeColorSpectrum: IThemeColorSpectrum): IThemeColorSpectrum {
  const convertedThemColorSpectrum = {
    25: Color(themeColorSpectrum[25]).hex(),
    50: Color(themeColorSpectrum[50]).hex(),
    100: Color(themeColorSpectrum[100]).hex(),
    200: Color(themeColorSpectrum[200]).hex(),
    300: Color(themeColorSpectrum[300]).hex(),
    400: Color(themeColorSpectrum[400]).hex(),
    500: Color(themeColorSpectrum[500]).hex(),
    600: Color(themeColorSpectrum[600]).hex(),
    700: Color(themeColorSpectrum[700]).hex(),
    800: Color(themeColorSpectrum[800]).hex(),
    900: Color(themeColorSpectrum[900]).hex(),
    light: Color(themeColorSpectrum.light).hex(),
    main: Color(themeColorSpectrum.main).hex(),
    dark: Color(themeColorSpectrum.light).hex(),
    contrastTextForLightBackground: Color(themeColorSpectrum.contrastTextForLightBackground).hex(),
    contrastTextForMainBackground: Color(themeColorSpectrum.contrastTextForMainBackground).hex(),
    contrastTextForDarkBackground: Color(themeColorSpectrum.contrastTextForDarkBackground).hex(),
    contrastHighlightForLightBackground: Color(themeColorSpectrum.contrastHighlightForLightBackground).hex(),
    contrastHighlightForMainBackground: Color(themeColorSpectrum.contrastHighlightForMainBackground).hex(),
    contrastHighlightForDarkBackground: Color(themeColorSpectrum.contrastHighlightForDarkBackground).hex(),
  }

  return convertedThemColorSpectrum;
}

// converts all spectrum colors to HSL
function convertAllSpectrumColorsToHsl(themeColorSpectrum: IThemeColorSpectrum): IThemeColorSpectrum {
  const convertedThemColorSpectrum = {
    25: Color(themeColorSpectrum[25]).hsl().toString(),
    50: Color(themeColorSpectrum[50]).hsl().toString(),
    100: Color(themeColorSpectrum[100]).hsl().toString(),
    200: Color(themeColorSpectrum[200]).hsl().toString(),
    300: Color(themeColorSpectrum[300]).hsl().toString(),
    400: Color(themeColorSpectrum[400]).hsl().toString(),
    500: Color(themeColorSpectrum[500]).hsl().toString(),
    600: Color(themeColorSpectrum[600]).hsl().toString(),
    700: Color(themeColorSpectrum[700]).hsl().toString(),
    800: Color(themeColorSpectrum[800]).hsl().toString(),
    900: Color(themeColorSpectrum[900]).hsl().toString(),
    light: Color(themeColorSpectrum.light).hsl().toString(),
    main: Color(themeColorSpectrum.main).hsl().toString(),
    dark: Color(themeColorSpectrum.light).hsl().toString(),
    contrastTextForLightBackground: Color(themeColorSpectrum.contrastTextForLightBackground).hsl().toString(),
    contrastTextForMainBackground: Color(themeColorSpectrum.contrastTextForMainBackground).hsl().toString(),
    contrastTextForDarkBackground: Color(themeColorSpectrum.contrastTextForDarkBackground).hsl().toString(),
    contrastHighlightForLightBackground: Color(themeColorSpectrum.contrastHighlightForLightBackground).hsl().toString(),
    contrastHighlightForMainBackground: Color(themeColorSpectrum.contrastHighlightForMainBackground).hsl().toString(),
    contrastHighlightForDarkBackground: Color(themeColorSpectrum.contrastHighlightForDarkBackground).hsl().toString(),
  }

  return convertedThemColorSpectrum;
}
