import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid2';
import { ThemeSpecsCardGridViewItem } from './ThemeSpecsCardGridViewItem';
import { IThemeSpecsViewModel } from '../../../../dataObjects/viewModels/themeSpecsViewModel';


export interface IThemeSpecsCardGridViewProps extends PropsWithChildren<unknown> {
  themeSpecsViewModels: Array<IThemeSpecsViewModel>;
  onDelete: (themeSpecs: IThemeSpecsViewModel) => void;
}


export const ThemeSpecsCardGridView: React.FC<IThemeSpecsCardGridViewProps> = (props: IThemeSpecsCardGridViewProps) => {
  ThemeSpecsCardGridView.displayName = 'ThemeSpecs Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render ThemeSpecsList`);

  const { themeSpecsViewModels } = props;

  return (
    <>
      <Grid container spacing={2} >
        {
          themeSpecsViewModels.map(themeSpecs =>
            <Grid key={themeSpecs.id} size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
              <ThemeSpecsCardGridViewItem themeSpecs={themeSpecs} onDelete={props.onDelete} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}