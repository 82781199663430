import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { enumAlertType } from '../../../enums';
import { IUserFeedback, IUserFeedbackAsJson, UserFeedback } from '../../../../dataObjects/models/userFeedback';
import { typeUniqueId } from '../../../../dataObjects/types';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { enumBeaconType, enumCollectionViewType, enumWorkflowState } from '../../../../dataObjects/enums';
import { JsonConverter } from '../../../../dataObjects/utilities/JsonConverter';
import UserFeedbackListView from '../../../views/userFeedback/UserFeedbackListView/UserFeedbackListView';
import { UserFeedbackCardGridView } from '../../../views/userFeedback/UserFeedbackCardGridView/UserFeedbackCardGridView';
import TwoButtonAcceptanceDialog from '../../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { Beacon } from '../../../../dataObjects/models/alerts/Beacon';
import { useReduxWorkflowState } from '../../../customHooks';
import ActivityIndicatorDialog from '../../../dialogs/ActivityIndicatorDialog/ActivityIndicatorDialog';
import { IActivityIndicatorData } from '../../../dialogs/ActivityIndicatorData';
import { AlertInfo, IAlertInfo } from '../../../../dataObjects/models/alerts/AlertInfo';
import DataViewControls from '../../../controls/DataViewControls/DataViewControls';
import { useGetCollectionViewType } from '../../../customHooks/useGetCollectionViewType';
import { BoxProps, ContainerProps, styled } from '@mui/system';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { enumCategoriesAndChannelsDataPreparationStatus } from '../../../enums';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { userFeedbackDeleteRequest, userFeedbackDeleteStatusChange } from '../../../../uiMiddleware-redux/slices/userFeedback/userFeedbackDeleteStatusSlice';
import { beaconChange } from '../../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { getUserFeedbackForOwnerId_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/userFeedbackActions';
// import { useAppDispatch } from '../../../customHooks/useAppDispatch';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area of padding at the top of the page
const StyledBoxForPaddingAtopPage = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

// a styled Box for a Horizontal Divider (a CSS Horizontal Rule <hr/>)
const StyledBoxForHorizontalDivider = styled((props: BoxProps) => (
  <Box
    component='hr'
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.palette.primary.light}`,
}));

// a styled Container for Data View
const StyledContainerForDataView = styled((props: ContainerProps) => (
  <Container
    disableGutters
    maxWidth={false}
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
}));

export interface IUserFeedbackPageProps extends PropsWithChildren<unknown> {
}

const UserFeedbackPage: React.FC<IUserFeedbackPageProps> = (props: IUserFeedbackPageProps) => {

  UserFeedbackPage.displayName = 'UserFeedback Page';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const navigate = useNavigate();
  const params = useParams();

  // call custom hook to get the current view type, so the value can be used to initialize the View Type state variable
  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  // the UserFeedback associcated with the current user account
  const [userFeedback, setUserFeedback] = useState<Array<IUserFeedback>>([]);
  // whether we are currently fetching the current topic
  const [fetchingCurrentTopic, setFetchingCurrentTopic] = useState<boolean>(false);
  // whether data is currently loading
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  // if user is deleting a UserFeedback, the UserFeedback to delete
  const [userFeedbackToDelete, setUserFeedbackToDelete] = useState<IUserFeedback | undefined>(undefined);
  // whether to show the Delete Confirmation dialog
  const [showDeleteConfirmationDlg, setShowDeleteConfirmationDlg] = useState<boolean>(false);

  // to assess the status of data preparation for user categories and channels
  const [userCategoriesAndChannelsDataPrepStatus, SetUserCategoriesAndChannelsDataPrepStatus] = useState<enumCategoriesAndChannelsDataPreparationStatus>(enumCategoriesAndChannelsDataPreparationStatus.None);

  // for dispatching redux actions
  const dispatch = useAppDispatch();

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();
  const userId: typeUniqueId | undefined = currentUser?.id;

  /**
   * @function resetDeleteUserFeedbackStatus Resets the Delete status in Redux state
   */
  const resetDeleteUserFeedbackStatus: () => void = useCallback(() => {
    dispatch(userFeedbackDeleteStatusChange(null));
  }, [dispatch])

  // set up a useEffect() hook to get the userFeedback for the given parentId and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    displayConsoleLogs && console.log(`%c UserFeedbackPage. useEffect for [parentId, userId, resetDeleteUserFeedbackStatus]. Ready to request all User User Feedback`, 'background: #006; color: #fff');

    if (userId) {
      // subscribe to onShapshot updates for the UserFeedback, providing realtime updates to the data, and capture the 'unsubscribe' callback method provided by firestore
      // console.log(`UserFeedbackPage: before initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);
      getUserFeedbackForOwnerId_onSnapshot(userId, onSnapshotCallback_UserFeedback).then((unsubscribe: () => void) => {
        unsubscribeCallback = unsubscribe;
        // console.log(`UserFeedbackPage: after initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);
      });
    }

    // perform cleanup when the component unmounts
    return () => {
      // if the user is still logged in...
      // console.log(`UserFeedbackPage: unmounting (useEffect return). userId: ${userId}`);
      // console.log(`UserFeedbackPage: unmounting (useEffect return). unsubscribeCallback: ${unsubscribeCallback}`);
      if (userId) {
        // Call the unsubscribe() callback to unsubscribe from the onSnapshot . If it was never 
        // console.log(`UserFeedbackPage. Ready to call 'unsubscribeCallback'`);
        unsubscribeCallback();
        // console.log(`UserFeedbackPage. Completed call to 'unsubscribeCallback'`);

        // reset the DeleteTopicStatus in Redux state to return to a clean slate
        resetDeleteUserFeedbackStatus();
      }
    }
  }, [resetDeleteUserFeedbackStatus])

  // use a custom hook to evaluate the redux state for the deleteUserFeedback workflow and any deleteUserFeedback error
  const { workflowState: deleteUserFeedbackStatus, errorState: deleteUserFeedbackFailure } = useReduxWorkflowState((state: IStoreState) => state.deleteUserFeedbackStatus, (state: IStoreState) => state.deleteUserFeedbackFailure);

  /**
   * @function onSnapshotCallback_UserFeedback A callback method to receive firestore UserFeedback data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onSnapshotCallback_UserFeedback(snapshot: QuerySnapshot<DocumentData>): void {
    displayConsoleLogs && console.log(`%c UserFeedbackPage. onSnapshotCallback_UserFeedback: \n${JSON.stringify(snapshot)}`, 'background: #006; color: #fff');
    // console.log(`UserFeedbackPage:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setDataLoading(false);
    const userFeedbackDataAsJson: Array<IUserFeedbackAsJson> = [];
    // console.log(`UserFeedbackPage:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => userFeedbackDataAsJson.push({ ...doc.data(), id: doc.id } as IUserFeedbackAsJson));
    // console.log(`UserFeedbackPage:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of UserFeedback objects from the JSON data
    let userFeedbackData = JsonConverter.arrayFromJSONArray(UserFeedback, userFeedbackDataAsJson);

    // use lodash to sort the array by 'name' in ascending order
    userFeedbackData = _.orderBy(userFeedbackData, [userFeedback => userFeedback.submissionTimestamp], ['asc']);

    setUserFeedback(userFeedbackData);
  }

  /**
   * @method handleUserFeedbackDelete Handles requests to delete a Channel
   * @param userFeedback The UserFeedback to be deleted
   */
  async function handleUserFeedbackDelete(userFeedback: IUserFeedback): Promise<void> {
    // set the userFeedback to be deleted into local state
    setUserFeedbackToDelete(userFeedback);

    // set flag to show the delete confirmation dialog
    setShowDeleteConfirmationDlg(true);
  }

  function handleUserFeedbackDeleteConfirmed(): void {
    // using the userFeedback object to be deleted from the local state, dispatch an action to delete the userFeedback object
    if (userFeedbackToDelete !== undefined) {
      dispatch(userFeedbackDeleteRequest(userFeedbackToDelete));
    }

    // call method to reset the userFeedback delete local state details
    userFeedbackDeleteReset();
  }

  function handleUserFeedbackDeleteCanceled(): void {
    // call method to reset the userFeedback delete local state details
    userFeedbackDeleteReset();
  }

  function userFeedbackDeleteReset() {
    // reset the userFeedback to be deleted to 'undefined' in local state, since we're done with the object
    setUserFeedbackToDelete(undefined);

    // set flag to hide the delete confirmation dialog
    setShowDeleteConfirmationDlg(false);
  }

  // data to indicate whether an activity indicator should be displayed and, if so, the message
  let activityIndicatorData: IActivityIndicatorData = {
    showActivityIndicator: false,
    activityIndicatorMessage: ''
  }

  // data to indicate whether an alert should be displayed
  let alertInfo: IAlertInfo | undefined = undefined;

  // indicates whether the object has been successfully deleted
  const [successfullyDeletedObject, setSuccessfullyDeletedObject] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedPage state changes
  useEffect(() => {
    if (successfullyDeletedObject) {
      // dispatch an action to reset the deleteUserFeedbackStatus
      resetDeleteUserFeedbackStatus();

      // notify user via a Beacon notification that the userFeedback has been deleted
      const beacon: Beacon = new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.userFeedback_DeleteConfirmationHeader, MessagesStringAssets.userFeedback_DeleteSuccess);
      dispatch(beaconChange(beacon));

      // reset the successfullyDeletedObject flag
      setSuccessfullyDeletedObject(false);
    }
  }, [successfullyDeletedObject, dispatch, resetDeleteUserFeedbackStatus]);

  if (dataLoading) {
    // display an activity indicator
    activityIndicatorData.showActivityIndicator = true;
    activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.userFeedback_Loading;
  } else {
    // if the workflow is in the midst of performing deletion of a userFeedback, prepare an alert message fragment to display progress message(s)
    if (deleteUserFeedbackStatus !== undefined && deleteUserFeedbackStatus !== null) {
      if (deleteUserFeedbackStatus === enumWorkflowState.Requested || deleteUserFeedbackStatus === enumWorkflowState.InProgress) {
        // display an activity indicator
        activityIndicatorData.showActivityIndicator = true;
        activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.userFeedback_DeleteRequested;
      } else if (deleteUserFeedbackStatus === enumWorkflowState.Success) {
        // if the successfullyDeletedObject is curently in a 'false' state, we'll want the flag to indicate that the deletion was a success
        if (!successfullyDeletedObject) {
          // set flag to indicate that the object has been successfully deleted, which will force a re-render to allow for cleanup and user notification
          setSuccessfullyDeletedObject(true);
        }
      } else if (deleteUserFeedbackStatus === enumWorkflowState.Failure) {
        // display an alert message
        // alertFragment = generateJsxAlertMessageFragment(MessagesStringAssets.userFeedback_DeleteFailure, enumJsxAlertMessageDisplayVariants.Failure, false, deleteUserFeedbackFailureMessage, MessagesStringAssets.substitutionKeyword);
        let failureMessage = 'Unknown error';
        if (deleteUserFeedbackFailure && deleteUserFeedbackFailure instanceof Error) {
          failureMessage = deleteUserFeedbackFailure.message;
        }
        alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
        dispatch(alertInfoChange(alertInfo));
      }
    }
  }


  // Establish the page title to be displayed. Once we have retrieved the parent Topic, apply the userFeedbackNameAliasPlural; otherwise, show nothing
  const pageTitle: string = PageAndViewTitleStringAssets.pageTitle_UserFeedback;

  // Establish the text to be displayed on the '+ New' button
  const addNewButtonText: string = ControlsStringAssets.userFeedbackNew;

  return (
    <>
      {/* only display the page if we have a valid currentUser */}
      {currentUser &&
        <>
          <StyledBoxForPaddingAtopPage />
          <GenericPageContainer
            // maxWidth="xl"
            showBackButton={true}
            pageTitle={pageTitle}
            onCloseAlert={resetDeleteUserFeedbackStatus}
          >

            {/* Present the Data View Controls ("Add new" button & "View Type" toggle button) */}
            <DataViewControls
              addNewItemButtonText={addNewButtonText}
              onClickAddNewItemButton={() => navigate(`/userFeedback`)}
            >
            </DataViewControls>

            <StyledBoxForHorizontalDivider />

            {/* Container for embedded data view */}
            <StyledContainerForDataView>
              {/* If there are UserFeedback to display and the current view selection is CardsGridView, display the UserFeedbackCardGridView */}
              {userFeedback && currentUser &&
                (collectionViewType === enumCollectionViewType.CardsGridView) &&
                <UserFeedbackCardGridView userFeedback={userFeedback} currentUser={currentUser} onDelete={handleUserFeedbackDelete} />
              }

              {/* If there are UserFeedback to display and the current view selection is ListView, display the UserFeedbackListView */}
              {userFeedback && currentUser &&
                (collectionViewType === enumCollectionViewType.ListView) &&
                <UserFeedbackListView userFeedback={userFeedback} currentUser={currentUser} onDelete={handleUserFeedbackDelete} />
              }
            </StyledContainerForDataView>

            {/* Delete UserFeedback Confirmation Dialog */}
            <TwoButtonAcceptanceDialog
              showDialog={showDeleteConfirmationDlg}
              headerText={MessagesStringAssets.userFeedback_DeleteConfirmationHeader}
              bodyText={MessagesStringAssets.userFeedback_DeleteConfirmation}
              acceptanceButtonText={ControlsStringAssets.confirmButtonText}
              nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
              onAcceptance={handleUserFeedbackDeleteConfirmed}
              onNonAcceptance={handleUserFeedbackDeleteCanceled}
            />

            {activityIndicatorData.showActivityIndicator &&
              <ActivityIndicatorDialog activityLabel={activityIndicatorData.activityIndicatorMessage} />
            }

          </GenericPageContainer>
        </>
      }
    </>
  );
}

export default UserFeedbackPage;
