import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { useForm } from 'react-hook-form';
import { Box, BoxProps, LinearProgress, MenuItem, TextField, TextFieldProps, Typography } from '@mui/material';
import {
  VolumeUp as AudioClipIcon,
} from '@mui/icons-material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ControlsStringAssets, KeyValuePairsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { IAudioLink } from '../../../dataObjects/models/digitalMedia/AudioLink';
import { IFileUploadProgress } from '../../../dataObjects/models/fileUpload/FileUploadProgress';
import { typeUniqueId } from '../../../dataObjects/types';
import { enumFileUploadType, enumAudioLinkType, enumAudioLinkTypeConvert, enumDigitalMediaDisplayEnvironment } from '../../../dataObjects/enums';
import { ISaveAudioLinkRequest } from '../../../dataObjects/models/serviceRequests/audioLink/SaveAudioLinkRequest';
import { IFileUploadRequest } from '../../../dataObjects/models/fileUpload/FileUploadRequest';
import FileInput from '../../controls/FileInput/FileInput';
import { Accept, DropEvent, FileRejection } from 'react-dropzone';
import { FileForUpload, IFileForUpload } from '../../../dataObjects/models/fileUpload/FileForUpload';
import MediaFileUploadList from '../../listsAndListItems/mediaFiles/MediaFileUploadList';
import { enumAlertType } from '../../enums';
import { AlertInfo, IAlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { constructDropZoneFileRejectionsErrorMessage } from '../../utilities/constructDropZoneFileRejectionsErrorMessage';
import { styled } from '@mui/styles';
import { MdbError } from '../../../errorObjects/MdbError';
import { AudioMediaDisplay } from '../../controls/digitalMediaDisplays/AudioMediaDisplay';
import { useAppDispatch } from '../../../uiMiddleware-redux/store/configureStore';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { audioLinkFileUploadProgressUpdate } from '../../../uiMiddleware-redux/slices/audioLink/audioLinkSaveStatusSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled TextField that is serving as a Select control
const StyledTextFieldForSelectControl = styled((props: TextFieldProps) => (
  <TextField
    select
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

// a styled Box (equivalent to a <div>), providing an area to display the Preview Container
const StyledBoxForPreviewContainer = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '10000px', // to give it completely rounded sides
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '3rem',
  width: 'auto',
  transition: '0.5s ease-in',
}));

// a styled Box (equivalent to a <div>), providing an area to display the Preview Item
const StyledBoxForPreviewItemArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}));

interface IAudioLinkFormValues {
  audioLinkType: string;
  audioUrl: string;
  description: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({

  audioLinkType: yup
    .string()
    .required(),

  /**
   * @property {string} audioUrl A URL for the AudioLink IFF the type is NOT Audio Upload
   */
  audioUrl: yup
    .string(), // needs to be required if a Audio site is selected from the AudioLinkType dropdown
  // .required(),

  /**
   * @property {string} description A description for the AudioLink.
   */
  description: yup
    .string()
    .required(ControlsStringAssets.audioLinkDescriptionRequired),
});

export interface IAudioLinkFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {typeUniqueId} userId The id of the current user
   */
  userId: typeUniqueId,

  /**
   * @property {IAudioLink} audioLink The AudioLink details for the form (will have blank properties values if we're creating a new record)
   */
  audioLink: IAudioLink,

  /**
   * @property {boolean} saveRequestInProgress (optional) Whether a save request is in progress (default: false).
   */
  saveRequestInProgress?: boolean,

  /**
   * @property {string} progressMessage (optional) If a save is in progress, display this message to provide progress updates to the user.
   */
  progressMessage?: string,

  /**
   * @property {Array<IFileUploadProgress>} fileUploadProgressCollection (optional) If files are in the process of being uploaded, this array
   *                                        provides the details for the upload progress of each file being uploaded.
   */
  fileUploadProgressCollection?: Array<IFileUploadProgress>,

  /**
   * @property {(saveAudioLinkRequest: ISaveAudioLinkRequest) => void} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (saveAudioLinkRequest: ISaveAudioLinkRequest) => void,
}

const AudioLinkForm: React.FC<IAudioLinkFormProps> = (props: IAudioLinkFormProps) => {
  AudioLinkForm.displayName = 'AudioLink Form';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  // get required arguments from props
  const { userId, audioLink, progressMessage, onSubmit } = props;

  // set up details for ReactHookForm
  // const { register, formState, formState: { errors }, handleSubmit } = useForm<IAudioLinkFormValues>({
  const { register, formState, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      audioLinkType: audioLink.audioLinkType,
      audioUrl: audioLink.downloadUrl,
      description: audioLink.description
    },
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: audioLinkTypeReg } = register("audioLinkType", { required: true });
  const { ref: audioUrlReg, ...audioUrlProps } = register("audioUrl", { required: true });
  const { ref: descriptionReg, ...descriptionProps } = register("description", { required: true });

  // for convenience, use a state variable to indicate whether we are working with an existing AudioLink
  const [isExistingAudioLink, setIsExistingAudioLink] = useState<boolean>(false);

  // files selected by the user
  const [filesSelectedForUpload, setFilesSelectedForUpload] = useState<Array<IFileForUpload> | null>(null);

  const [audioLinkTypeKey, setAudioLinkTypeKey] = useState<string>("");

  const [audioUrlCurrentValue, setAudioUrlCurrentValue] = useState<string>(audioLink.downloadUrl);

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid } = formState;

  const filesAreSelectedForUpload: boolean = (filesSelectedForUpload !== null) ? filesSelectedForUpload.length > 0 : false;

  // determine whether the form is valid for submission, requiring yup validation -AND- either it's an existing AudioLink for 
  // GoogleCloudStorage -OR- files have been selected
  // -OR-
  // it's an external source and a Audio URL has been provided
  const formIsValid: boolean = isValid && (((audioLinkTypeKey === enumAudioLinkType.GoogleCloudStorage) && (isExistingAudioLink || filesAreSelectedForUpload))
    || ((audioLinkTypeKey === enumAudioLinkType.ExternalSource) && (audioUrlCurrentValue.trim().length > 0)));

  const [audioLinkTypesForDropdown, setAudioLinkTypesForDropdown] = useState<Array<React.JSX.Element>>([]);

  displayConsoleLogs && console.log(`In AudioLinkForm. audioLinkTypeKey: ${audioLinkTypeKey}`);

  // useEffect to be executed upon mounting of this component
  useEffect(() => {
    // prepare an array of AudioLinkType values from the enumAudioLinkType enumerator that can be used to populate MenuItem components for the AudioLinkType <Select> component
    let audioLinkTypeMenuItems: Array<React.JSX.Element> = [];
    KeyValuePairsStringAssets.audioLinkTypeValuePairs.forEach((keyValuePair: { key: string, value: string }) => {
      audioLinkTypeMenuItems.push(<MenuItem key={keyValuePair.key} value={keyValuePair.key}>{keyValuePair.value}</MenuItem>);
    });

    setAudioLinkTypesForDropdown(audioLinkTypeMenuItems);
  }, []);

  // execute whenever audioLink.audioLinkType changes
  useEffect(() => {
    // set the audioLinkTypeKey from the audioLink.audioLinkType value
    const defaultAudioLinkTypeKey: string = (audioLink.audioLinkType === undefined) ? enumAudioLinkType.GoogleCloudStorage : audioLink.audioLinkType;
    setAudioLinkTypeKey(defaultAudioLinkTypeKey);

  }, [audioLink.audioLinkType]);

  // displayConsoleLogs && console.log(`formIsValid: ${formIsValid}; filesSelectedForUpload is ${filesSelectedForUpload === null ? 'null' : 'non-null'}`);
  if (filesSelectedForUpload !== null) {
    // displayConsoleLogs && console.log(`filesSelectedForUpload.length: ${filesSelectedForUpload.length}`);
  }

  // // whether a file is in the process of being uploaded
  // const [fileUploading, setFileUploading] = useState<boolean>(false);

  // if uploading a file, the current progress
  const [fileUploadProgress, setFileUploadProgress] = useState<IFileUploadProgress | undefined>(undefined);

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // for capturing the current value of the Description field
  const [descriptionCurrentValue, setDescriptionCurrentValue] = useState<string>(audioLink.description);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);


  // if this is an existing audioLink (audioLink.downloadUrl is non-blank), execute logic based on the audioLink.baseStoragePath
  useEffect(() => {
    if (audioLink.downloadUrl && audioLink.baseStoragePath) {
      // set convenience state variable indicating that we're working with an existing AudioLink
      setIsExistingAudioLink(true);
    } else {
      // set convenience state variable indicating that we're NOT working with an existing AudioLink
      setIsExistingAudioLink(false);
    }
  }, [audioLink]);


  function handleAudioUrlCurrentValueChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setAudioUrlCurrentValue(event.target.value);
  }

  function handleDescriptionCurrentValueChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setDescriptionCurrentValue(event.target.value);
  }


  function handleFilesSelectedForUpload<T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent): void {

    let filesForUpload: Array<IFileForUpload> = new Array<IFileForUpload>();

    displayConsoleLogs && console.log(`audioLinkForm.handleFilesSelectedForUpload. acceptedFiles: ${JSON.stringify(acceptedFiles)}`);

    // capture any files accepted by the file input
    acceptedFiles.forEach(file => {
      filesForUpload.push(new FileForUpload(file, enumFileUploadType.AudioFiles));

      // clear any alerts
      dispatch(alertInfoChange(null));
    });

    displayConsoleLogs && console.log(`audioLinkForm.handleFilesSelectedForUpload. filesForUpload: ${JSON.stringify(filesForUpload)}`);
    displayConsoleLogs && console.log(`audioLinkForm.handleFilesSelectedForUpload. audioLinkTypeKey: ${JSON.stringify(audioLinkTypeKey)}`);

    switch (audioLinkTypeKey) {
      case enumAudioLinkType.GoogleCloudStorage:
        setFilesSelectedForUpload(filesForUpload);

        // if any files were rejected, notify the user with an alert
        if (fileRejections.length > 0) {
          const errorMessage = constructDropZoneFileRejectionsErrorMessage(fileRejections);

          // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
          const alertInfo: IAlertInfo = new AlertInfo(true, enumAlertType.Error, errorMessage);
          dispatch(alertInfoChange(alertInfo));
        }
        break;

      case enumAudioLinkType.ExternalSource:
        if (filesForUpload.length === 1) {
          displayConsoleLogs && console.log(`audioLinkForm.handleFilesSelectedForUpload. filesForUpload[0]: ${JSON.stringify(filesForUpload[0])}`);
          setAudioUrlCurrentValue(filesForUpload[0].file.name);
        }
        break;

      default:
        throw new MdbError(`Unhandled audioLinkTypeKey in AudioLinkForm. key: ${audioLinkTypeKey}`);
    }

  }

  function onFileUploadProgressUpdate(latestFileUploadProgress: IFileUploadProgress): void {
    // alert('Updating the file upload progress');
    setFileUploadProgress(latestFileUploadProgress);

    dispatch(audioLinkFileUploadProgressUpdate(latestFileUploadProgress));
  }


  // handles a save/submit request from the form
  // const handleSaveSubmit = async (data: IAudioLinkFormValues) => {
  const handleSaveSubmit = async (data: any) => {

    setSaveInProgress(true);

    // fill in name & description of the AudioLink object passed in
    audioLink.description = data.description;
    audioLink.audioLinkType = enumAudioLinkTypeConvert.fromString(audioLinkTypeKey);

    // if the audio link is for an external source...
    if (audioLinkTypeKey === enumAudioLinkType.ExternalSource) {
      // set the downloadUrl
      audioLink.downloadUrl = data.audioUrl;
    }

    // prepare a request for saving, starting with just the audioLink
    let saveAudioLinkRequest: ISaveAudioLinkRequest = {
      audioLink: audioLink
    };

    // if this is a new object being saved, along with a file upload...
    if (!audioLink.downloadUrl) {

      // if there are files to be uploaded, proceed
      if (filesSelectedForUpload !== null && filesSelectedForUpload.length > 0) {
        // // set flag indicating that a file upload is in progress
        // setFileUploading(true);

        // prepare the details of the FileUploadRequest
        // for now, only upload the first file in the list
        const fileUploadRequest: IFileUploadRequest = {
          userId: userId,
          fileToUpload: filesSelectedForUpload[0].file,
          fileClass: filesSelectedForUpload[0].fileClass,
          fileUniqueId: filesSelectedForUpload[0].id,
          uploadProgressCallback: onFileUploadProgressUpdate
        };

        // add the FileUploadRequest to the save request object
        saveAudioLinkRequest.fileUploadRequest = fileUploadRequest;

      }
    }

    // call the onSubmit handler passed in, supplying the save request
    await onSubmit(saveAudioLinkRequest);
  }

  const handleDeleteFileForUpload: (fileForUpload: IFileForUpload) => void = (fileForUpload: IFileForUpload) => {
    if (filesSelectedForUpload && filesSelectedForUpload.length > 0) {
      // ...copy the filesSelectedForUpload array to a local array variable, and then remove the specified item from the new array
      let revisedFilesSelectedForUpload: Array<IFileForUpload> = [...filesSelectedForUpload];
      revisedFilesSelectedForUpload.splice(revisedFilesSelectedForUpload.findIndex(fileForUploadElement => fileForUploadElement.id === fileForUpload.id), 1);

      // now, set the revised array into state
      setFilesSelectedForUpload(revisedFilesSelectedForUpload);
    }
  }

  const fileUploadCompletePercent: string = (fileUploadProgress && fileUploadProgress.percentComplete) ?
    fileUploadProgress.percentComplete.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
    '0';


  const [urlForFileSelectedForUpload, setUrlForFileSelectedForUpload] = useState<string | undefined>(undefined);
  // const [urlWithTimeOffsetForFileSelectedForUpload, setUrlWithTimeOffsetForFileSelectedForUpload] = useState<string | undefined>(undefined);

  // Anytime there's a change in values that pertain to whether there's a file selected for upload, establish the URL (or undefined) for a file upload
  useEffect(() => {
    let newUrlForFileSelectedForUpload: string | undefined = undefined;

    // if this is a new AudioLink (isn't an existing AudioLink) -AND- there's a file selected for upload, obtain a URL for the selected file to be uploaded
    // (Note: If it's either an existing Audio Link or there's no file selected for upload, the Url string will be set as undefined.)
    if (!isExistingAudioLink && filesSelectedForUpload && filesSelectedForUpload.length > 0) {
      newUrlForFileSelectedForUpload = URL.createObjectURL(filesSelectedForUpload[0].file);

      setUrlForFileSelectedForUpload(newUrlForFileSelectedForUpload);
    } else {
      setUrlForFileSelectedForUpload(undefined);
    }

  }, [isExistingAudioLink, filesSelectedForUpload, setUrlForFileSelectedForUpload])

  // memoize the rendering of the audio object for upload so that it won't flicker each time this form component is re-rendered (such as when file is being uploaded)
  const audioObjectForUploadOperation = useMemo(() => (
    <>
      {
        (urlForFileSelectedForUpload !== undefined) ?
          <StyledBoxForPreviewContainer>
            <ReactPlayer
              style={{ height: '3rem', position: 'relative' }}
              url={urlForFileSelectedForUpload}
              controls
              width='100%'
              height='100%'
            />
          </StyledBoxForPreviewContainer>
          :
          <>
          </>
      }
    </>
  ), [urlForFileSelectedForUpload]);

  // memoize the rendering of the audio for existing AudioLink so that it won't flicker each time this form component is re-rendered (during update operation)
  const audioObjectForExistingLinkUpdate = useMemo(() => (
    <>
      {
        (isExistingAudioLink) ?
          <AudioMediaDisplay audioLink={audioLink} displayEnvironment={enumDigitalMediaDisplayEnvironment.FormView} />
          :
          <>
          </>
      }
    </>
  ), [isExistingAudioLink, audioLink]);

  // prepare specs for accepted filetypes that will be passed on to the FileInput component
  const acceptedFileTypes: Accept = { 'audio/*': ['audio/m4a', 'audio/mp3', 'audio/mp4', 'audio/mpeg', 'audio/oga', 'audio/ogg', 'audio/opus', 'audio/wav', 'audio/weba', 'audio/webm'] };

  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={progressMessage}
        finiteActionInProgressPercentComplete={fileUploadProgress && fileUploadProgress.percentComplete}
        finiteActionInProgressLabel={fileUploadProgress && MessagesStringAssets.file_Uploading}
        formIsValid={formIsValid}
      >

        {/* We use a TextField with 'select' attribute as a pseudo <Select> (or dropdown) control */}
        {/* Only display the field if the dropdown options have been created */}
        {(audioLinkTypesForDropdown.length > 0) &&
          <StyledTextFieldForSelectControl
            inputRef={audioLinkTypeReg}
            label="Audio Type"
            margin='normal'
            value={audioLinkTypeKey}
            onChange={e => setAudioLinkTypeKey(e.target.value)}
          >
            {audioLinkTypesForDropdown}
          </StyledTextFieldForSelectControl>
        }

        {/* if the selected link type is "GoogleCloudStorage" -AND- we're not editing an existing audioLink -AND-
           no files have been selected for upload,  */}
        {(audioLinkTypeKey === enumAudioLinkType.GoogleCloudStorage) && !isExistingAudioLink && !filesAreSelectedForUpload &&
          <FileInput
            // control={control}
            name="fileInput"
            // Notes: 
            //   1) The acceptedFileTypes must include a fully itemized list of audio mime types; otherwise, when running on iOS (iPhone/iPad), 
            //      the react-dropzone component will disable the files such that they can't be selected.
            //   2) "audio/*" must be included in the list of acceptedFileTypes. If not, the react-dropzone component will reject any selected file.
            // acceptedFileTypes="audio/*,audio/m4a,audio/mp3,audio/mp4,audio/mpeg,audio/oga,audio/ogg,audio/opus,audio/wav,audio/weba,audio/webm"
            acceptedFileTypes={acceptedFileTypes}
            allowMultipleFiles={false}
            onFilesSelectedForUpload={handleFilesSelectedForUpload}
          >
          </FileInput>
        }

        {/* if the currently selected Audio Type is file upload (GoogleCloudStorage) -AND- we're editing 
            an existing AudioLink, display the audio object for the existing audio... */}
        {
          ((audioLinkTypeKey === enumAudioLinkType.GoogleCloudStorage) && !isExistingAudioLink && filesSelectedForUpload && filesSelectedForUpload.length > 0) &&
          <>
            {
              audioObjectForUploadOperation
            }

            <MediaFileUploadList
              filesForUpload={filesSelectedForUpload}
              mediaIcon={<AudioClipIcon />}
              onDelete={handleDeleteFileForUpload}
            />

            {
              fileUploadProgress &&
              <>
                <Box display='flex' flexDirection='column' >
                  <Box display="flex" alignItems="center">
                    <Box
                      width="100%"
                      mr={1}>
                      <LinearProgress
                        variant="determinate"
                        value={fileUploadProgress.percentComplete}
                      />
                    </Box>
                    <Box minWidth={35}>
                      <Typography
                        variant="body2"
                        color="primary">
                        {/* {`${Math.round(fileUploadProgress.percentComplete)}%`} */}
                        {`${fileUploadCompletePercent}%`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' justifyContent='center'>
                    <Typography
                      variant="caption"
                      color="primary">
                      {`Uploading audio...`}
                    </Typography>
                  </Box>
                </Box>
              </>
            }

          </>
        }

        {/* if we're editing an existing AudioLink, display the audio object for the existing audio... */}
        {isExistingAudioLink &&
          audioObjectForExistingLinkUpdate
        }

        {/* if the selected Audio Link Type is NOT "Upload" (GoogleCloudStorage), show the Audio URL text box */}
        {(audioLinkTypeKey !== enumAudioLinkType.GoogleCloudStorage) &&
          <>
            <TextField
              inputRef={audioUrlReg}
              {...audioUrlProps}
              label="Audio URL *"
              margin='normal'
              fullWidth
              onChange={handleAudioUrlCurrentValueChanged}
              error={!!errors.audioUrl}
              helperText={errors?.audioUrl?.message}
            />

            {/* <StyledBoxForPreviewContainer>
              <StyledBoxForPreviewItemArea> */}
            <AudioMediaDisplay
              audioLink={{ ...audioLink, downloadUrl: audioUrlCurrentValue, description: descriptionCurrentValue, audioLinkType: audioLinkTypeKey === enumAudioLinkType.GoogleCloudStorage ? enumAudioLinkType.GoogleCloudStorage : enumAudioLinkType.ExternalSource }}
              displayEnvironment={enumDigitalMediaDisplayEnvironment.FormView}
            />
            {/* </StyledBoxForPreviewItemArea>
            </StyledBoxForPreviewContainer> */}
          </>
        }

        <TextField
          inputRef={descriptionReg}
          {...descriptionProps}
          autoFocus
          label={ControlsStringAssets.topicDescriptionLabel}
          margin='normal'
          fullWidth
          onChange={handleDescriptionCurrentValueChanged}
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.description}
          helperText={errors?.description?.message}
          slotProps={{
            inputLabel: {
              required: true // this will cause an asterisk ('*') to appear at the end of the label text
            }
          }}
        />

      </FormWithActionBar>
    </>

  );
}

export default AudioLinkForm;