import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, BoxProps, TextField, Typography, TypographyProps, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ColorResult } from 'react-color';
import { ControlsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { ICategory } from '../../../dataObjects/models/categories/Category';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ColorSwatch } from '../../controls/colors/ColorSwatch/ColorSwatch';
import { ColorPicker } from '../../controls/colors/ColorPicker/ColorPicker';
import { enumColorPickerType } from '../../enums';
import { styled } from '@mui/styles';

interface ICategoryFormValues {
  name: string;
  description: string;
  displayFontColor: string;
  displayBackground: string;
  displayBorderColor: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  name: yup
    .string()
    .required(ControlsStringAssets.categoryNameRequired),
  description: yup
    .string()
    .required(ControlsStringAssets.categoryDescriptionRequired),
  displayFontColor: yup
    .string()
    .required(),
  displayBackground: yup
    .string()
    .required(),
  displayBorderColor: yup
    .string()
    .required(),
});

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing a fieldset control to surround details for color selections
const StyledBoxForFieldSet = styled((props: BoxProps) => (
  <Box
    component='fieldset'
    {...props}
  />
))(({ theme }) => ({
  border: theme.fieldSet.border,
  borderRadius: theme.fieldSet.borderRadius,
  marginTop: '0.3rem',
}));

// a styled Box (equivalent to a <div>), providing a legend control to associate with the fieldset
const StyledBoxForLegend = styled((props: BoxProps) => (
  <Box
    component='legend'
    {...props}
  />
))(({ theme }) => ({
  color: theme.fieldSet.legendFontColor,
  fontSize: theme.fieldSet.legendFontSize,
}));

// a styled Box (equivalent to a <div>), providing an area for previewing colors
const StyledBoxForPreviewArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: '10px',
  border: '1px solid black',
  flexGrow: 1
}));

// a styled Box (equivalent to a <div>), providing an area for color selection details
const StyledBoxForColorSelectorsArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: '4px 0px',
}));

// a styled Box (equivalent to a <div>), providing an area for displaying a color swatch with a heading
const StyledBoxForColorSwatchWithHeading = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  // Note: The flexGrow, flexShrink, and flexBasis are all needed to have columns occupy equal horizontal
  //       widths. 'flex: 1 1 0' is a shorthand for the same representation.
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 0,

  margin: '5px',
  display: "flex",
  flexDirection: "column",
}));

// a styled Typography for displaying a header for a Color Swatch
const StyledTypographyForColorSwatchHeading = styled((props: TypographyProps) => (
  <Typography
    variant="body1"
    {...props}
  />
))(({ theme }) => ({
  alignSelf: 'center',
  color: theme.labelField.fontColor,
  fontSize: theme.labelField.fontSize,
}));

// a styled Box (equivalent to a <div>), providing an overall area for presenting a Color Picker
const StyledBoxForColorPickerArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
}));

// a styled Box (equivalent to a <div>), providing a space for presenting a Color Picker with its Header
const StyledBoxForColorPickerWithHeader = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "center",
}));

// a styled Typography for displaying a header for the Color Picker
const StyledTypographyForColorPickerHeading = styled((props: TypographyProps) => (
  <Typography
    variant="body1"
    {...props}
  />
))(({ theme }) => ({
  alignSelf: "center",
  color: theme.labelField.fontColor,
  fontSize: theme.labelField.fontSize,
}));


/**
 * @interface ICategoryFormProps Properties for the CategoryForm component
 */
export interface ICategoryFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {ICategory} category The Category details for the form (will have blank properties values if we're creating a new record)
   */
  category: ICategory,
  /**
   * @property {boolean} saveRequestInProgress Whether a save request is in progress
   */
  saveRequestInProgress?: boolean,
  /**
   * @property {(category: ICategory) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (category: ICategory) => Promise<void>,
}

const CategoryForm: React.FC<ICategoryFormProps> = (props: ICategoryFormProps) => {
  CategoryForm.displayName = 'Category Form';

  // get required arguments from props
  const { category, onSubmit } = props;

  console.log(`CategoryForm. After assignment from props. category.name: ${category.name}`);

  // capture whether the user is on a mobile device (anything with size small or below)
  let theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const colorPickerPresetColors = ['#FFFFFF', '#DDDDDD', '#BBBBBB', '#999999',  // shades of WHITE & LIGHT GRAY
    '#777777', '#555555', '#333333', '#000000',  // shades of DARK GRAY & BLACK
    '#FFF0F0', '#FFACAC', '#FF5050', '#FF0000',  // shades of RED
    '#FFF0E8', '#FFC1A1', '#FF8E54', '#FF7400',  // shades of ORANGE
    '#FEFFF0', '#F9FFA8', '#F3FF55', '#EDFF00',  // shades of YELLOW
    '#EDFEE6', '#9BD982', '#599442', '#2B770D',  // shades of GREEN
    '#E6F2FE', '#6CA4E0', '#325A86', '#1B426B',  // shades of BLUE
    '#F2E6FE', '#BD8DEC', '#8743CB', '#4C2178',  // shades of PURPLE
  ];


  // for capturing the current values of the Name and Description fields so that they can be displayed in the Preview area
  const [nameCurrentValue, setNameCurrentValue] = useState<string>(category.name);
  const [descriptionCurrentValue, setDescriptionCurrentValue] = useState<string>(category.description);

  // whether the Color Picker is being shown
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

  const [currentColorPickerColor, setCurrentColorPickerColor] = useState<string>("#ccc");
  const [currentBackgroundColor, setCurrentBackgroundColor] = useState<string>(category.displayBackground);
  const [currentFontColor, setCurrentFontColor] = useState<string>(category.displayFontColor);
  const [currentBorderColor, setCurrentBorderColor] = useState<string>(category.displayBorder);

  const [editingBackgroundColor, setEditingBackgroundColor] = useState<boolean>(false);
  const [editingFontColor, setEditingFontColor] = useState<boolean>(false);
  const [editingBorderColor, setEditingBorderColor] = useState<boolean>(false);

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit } = useForm<ICategoryFormValues>({
    defaultValues: {
      name: category.name,
      description: category.description,
      displayFontColor: category.displayFontColor,
      displayBackground: category.displayBackground,
      displayBorderColor: category.displayBorder
    },
    mode: "all",
    resolver: yupResolver(schema)
  });

  // const { register, formState, formState: {errors}, handleSubmit } = useForm();

  // 
  const { ref: nameReg, ...nameProps } = register("name", { required: true });
  const { ref: descriptionReg, ...descriptionProps } = register("description", { required: true });


  // execute the first time the form comes into being (is mounted)
  useEffect(() => {
    // add a click handler that will be used to cause the Color Picker to be closed if the user clicks anywhere in the 
    // document other than one of the color buttons (the color buttons' click handler(s) will need to stop propagation of their click event)
    document.addEventListener('click', handleClickOnDocument);

    // unmount logic
    return () => {
      document.removeEventListener('click', handleClickOnDocument);
    }
  }, []);

  function handleClickOnDocument() {
    // turn off editing for all colors and hide Color Picker
    setEditingBackgroundColor(false);
    setEditingFontColor(false);
    setEditingBorderColor(false);
    setShowColorPicker(false);
  }

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid: formIsValid } = formState;

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);


  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: ICategoryFormValues) => {

    setSaveInProgress(true);

    console.info(`Preparing to submit for save. data.name: ${data.name}; data.description: ${data.description}; data.displayBackground: ${JSON.stringify(data.displayBackground)}; data.displayFontColor: ${JSON.stringify(data.displayFontColor)}`);

    // fill in name & description of the Category object passed in
    category.name = data.name;
    category.description = data.description;
    category.displayBackground = currentBackgroundColor;
    category.displayFontColor = currentFontColor;
    category.displayBorder = currentBorderColor;

    // call the onSubmit handler passed in, supplying the Category object
    await onSubmit(category);
  }

  function handleNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setNameCurrentValue(event.target.value);
  }

  function handleDescriptionChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setDescriptionCurrentValue(event.target.value);
  }

  function handleClickSetBackgroundColor(event: React.MouseEvent<HTMLElement>) {
    console.info(`In handleClickSetBackgroundColor(). editingBackgroundColor: ${editingBackgroundColor}`);
    event.preventDefault();
    event.stopPropagation(); // don't bubble event up to parent object

    // if we're currently not editing the Background Color, this click event will have us editing it, so set the current Color Picker color to the Background Color
    setCurrentColorPickerColor(!editingBackgroundColor ? currentBackgroundColor : "#000");

    // indicate whether to show the Color Picker based upon whether we are currently editing the Background Color
    setShowColorPicker(!editingBackgroundColor);

    // toggle the flag indicating whether we are currently editing the Background Color
    setEditingBackgroundColor(!editingBackgroundColor);

    // turn off editing for Font & Border colors
    setEditingFontColor(false);
    setEditingBorderColor(false);
  }

  function handleClickSetFontColor(event: React.MouseEvent<HTMLElement>) {
    console.info(`In handleClickSetFontColor(). editingFontColor: ${editingFontColor}`);
    event.preventDefault();
    event.stopPropagation(); // don't bubble event up to parent object

    // if we're currently not editing the Font Color, this click event will have us editing it, so set the current Color Picker color to the Font Color
    setCurrentColorPickerColor(!editingFontColor ? currentFontColor : "#000");

    // indicate whether to show the Color Picker based upon whether we are currently editing the Font Color
    setShowColorPicker(!editingFontColor);

    // toggle the flag indicating whether we are currently editing the Font Color
    setEditingFontColor(!editingFontColor);

    // turn off editing for Background & Border colors
    setEditingBackgroundColor(false);
    setEditingBorderColor(false);
  }

  function handleClickSetBorderColor(event: React.MouseEvent<HTMLElement>) {
    console.info(`In handleClickSetBorderColor(). editingBorderColor: ${editingBorderColor}`);
    event.preventDefault();
    event.stopPropagation(); // don't bubble event up to parent object

    // if we're currently not editing the Border Color, this click event will have us editing it, so set the current Color Picker color to the Border Color
    setCurrentColorPickerColor(!editingBorderColor ? currentBorderColor : "#000");

    // indicate whether to show the Color Picker based upon whether we are currently editing the Border Color
    setShowColorPicker(!editingBorderColor);

    // toggle the flag indicating whether we are currently editing the Border Color
    setEditingBorderColor(!editingBorderColor);

    // turn off editing for Background & Font colors
    setEditingBackgroundColor(false);
    setEditingFontColor(false);
  }

  function handleOnColorPickerChange(color: ColorResult) {
    setCurrentColorPickerColor(color.hex);
  }

  function handleOnColorPickerChangeComplete(color: ColorResult) {
    setCurrentColorPickerColor(color.hex);

    // set the appropriate swatch to the current color, based on which color is currently being edited
    if (editingBackgroundColor) {
      setCurrentBackgroundColor(color.hex);
    } else if (editingFontColor) {
      setCurrentFontColor(color.hex);
    } else if (editingBorderColor) {
      setCurrentBorderColor(color.hex);
    }
  }


  function handleClickInColorPickerArea(event: React.MouseEvent<HTMLElement>) {
    // we merely want to stop propagation of the mouse click as we don't want the color picker to be closed when clicking on it
    event.stopPropagation();
  }

  console.log(`editingBackgroundColor: ${editingBackgroundColor}`);

  function getColorPickerName(): string {
    let name = 'Color Picker';

    if (editingBackgroundColor) {
      name = 'Background Color';
    } else if (editingFontColor) {
      name = 'Font Color';
    } else if (editingBorderColor) {
      name = 'Border Color';
    }

    return name;
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={MessagesStringAssets.category_SaveRequested}
        formIsValid={formIsValid}
      >
        <TextField
          inputRef={nameReg}
          {...nameProps}
          type="text"
          autoFocus
          label={ControlsStringAssets.categoryNameLabel}
          margin='normal'
          fullWidth
          error={!!errors.name}
          helperText={errors?.name?.message}
          // we use a defined onChange handler to display the 'name' value in the preview pane
          onChange={handleNameChanged}
          slotProps={{
            inputLabel: { required: true } // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <TextField
          inputRef={descriptionReg}
          {...descriptionProps}
          type="text"
          label={ControlsStringAssets.categoryDescriptionLabel}
          margin='normal'
          fullWidth
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.description}
          helperText={errors?.description?.message}
          // we use a defined onChange handler to display the 'description' value in the preview pane
          onChange={handleDescriptionChanged}
          slotProps={{
            inputLabel: { required: true } // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <StyledBoxForFieldSet>
          <StyledBoxForLegend>Appearance Preview and Color Settings</StyledBoxForLegend>

          <StyledBoxForPreviewArea sx={{ backgroundColor: currentBackgroundColor, borderColor: currentBorderColor }}>
            <Grid container size={12} direction="row" >
              <Grid container size={12} >
                <Typography variant='h6' align='center' sx={{ color: currentFontColor }}>
                  {nameCurrentValue}
                </Typography>
              </Grid>
              <Grid container size={12} >
                <Typography variant="body1" sx={{ color: currentFontColor }}>
                  {descriptionCurrentValue}
                </Typography>
              </Grid>
            </Grid>
          </StyledBoxForPreviewArea>

          <StyledBoxForColorSelectorsArea>
            <StyledBoxForColorSwatchWithHeading>
              <StyledTypographyForColorSwatchHeading>
                Background
              </StyledTypographyForColorSwatchHeading>

              {/* Color Swatch for the Background Color */}
              <ColorSwatch background={currentBackgroundColor} selected={editingBackgroundColor} onClick={handleClickSetBackgroundColor} />
            </StyledBoxForColorSwatchWithHeading>

            <StyledBoxForColorSwatchWithHeading>
              <StyledTypographyForColorSwatchHeading>
                Font
              </StyledTypographyForColorSwatchHeading>

              {/* Color Swatch for the Font Color */}
              <ColorSwatch background={currentFontColor} selected={editingFontColor} onClick={handleClickSetFontColor} />
            </StyledBoxForColorSwatchWithHeading>

            <StyledBoxForColorSwatchWithHeading>
              <StyledTypographyForColorSwatchHeading>
                Border
              </StyledTypographyForColorSwatchHeading>

              {/* Color Swatch for the Border Color */}
              <ColorSwatch background={currentBorderColor} selected={editingBorderColor} onClick={handleClickSetBorderColor} />
            </StyledBoxForColorSwatchWithHeading>
          </StyledBoxForColorSelectorsArea>

          {
            showColorPicker &&

            <StyledBoxForColorPickerArea onClick={handleClickInColorPickerArea} >
              <StyledBoxForColorPickerWithHeader onClick={handleClickInColorPickerArea}>

                <StyledTypographyForColorPickerHeading>
                  {getColorPickerName()}
                </StyledTypographyForColorPickerHeading>

                <Box>
                  <ColorPicker
                    // if running on a small mobile device, use one type of Color Picker; 
                    // otherwise, use a fuller featured picker on a larger device
                    colorPickerType={isMobile ? enumColorPickerType.TwitterPicker : enumColorPickerType.SketchPicker}
                    currentColor={currentColorPickerColor}
                    colorPalette={colorPickerPresetColors}
                    onChange={handleOnColorPickerChange}
                    onChangeComplete={handleOnColorPickerChangeComplete}
                  />
                </Box>
              </StyledBoxForColorPickerWithHeader>
            </StyledBoxForColorPickerArea>
          }

        </StyledBoxForFieldSet>

      </FormWithActionBar>
    </>

  );
}

export default CategoryForm;