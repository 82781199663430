import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useParams } from "react-router";
import { typeUniqueId } from '../../../../dataObjects/types';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { IUser } from '../../../../dataObjects/models/users/User';
import { IUserFeedback, UserFeedback } from '../../../../dataObjects/models/userFeedback';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { getSingleObjectById } from '../../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { enumObjectPersistenceState, enumPersistableObjectType, enumSubscriptionType, enumUserFeedbackType } from '../../../../dataObjects/enums';
import { IUserSettings } from '../../../../dataObjects/models/users/UserSettings';
import { RandomId } from '../../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../../dataObjects/models/persistence/UserPersistenceData';
import { MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import { NotificationStringAssets } from '../../../../assets/stringAssets';
import { useProcessPerManageObjectPageStatus } from '../../../customHooks';
import { IProcessPerManageObjectPageStatusInput } from '../../../customHooks/hookInputObjects/ProcessPerManageObjectPage/IProcessPerManageObjectPageStatusInput';
import UserFeedbackForm from '../../../forms/UserFeedbackForm/UserFeedbackForm';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { userFeedbackSaveRequest, userFeedbackSaveStatusChange } from '../../../../uiMiddleware-redux/slices/userFeedback/userFeedbackSaveStatusSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';

/**
 * @interface IManageUserFeedbackPageProps Input properties for the ManageTopicPage
 */
export interface IManageUserFeedbackPageProps extends PropsWithChildren<unknown> {
  id?: typeUniqueId  // if the Id is given, it means that the UserFeedback already exists
}

/**
 * @function ManageUserFeedbackPage A React Function Component for manageing (creating or editing) a UserFeedback.
 * @param {IManageUserFeedbackPageProps} props Input properties
 */
const ManageUserFeedbackPage: React.FC<IManageUserFeedbackPageProps> = (props: IManageUserFeedbackPageProps) => {

  ManageUserFeedbackPage.displayName = "Manage UserFeedback Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = true;

  displayConsoleLogs && console.log(`ManageUserFeedbackPage. Entered ManageUserFeedbackPage`);

  const dispatch = useAppDispatch();

  const params = useParams();

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [userFeedbackId, setUserFeedbackId] = useState<typeUniqueId | undefined>(params.id);

  // define an effect that will set the userFeedbackId anytime the params.id changes
  useEffect(() => {
    setUserFeedbackId(params.id);
  }, [params.id]);

  // define an effect based on a change to the userFeedbackId. Having a defined userFeedbackId means that we will 
  // be editing an existing UserFeedback
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageUserFeedbackPage. In useEffect for userFeedbackId`);

    if (userFeedbackId !== undefined) {
      // try to find an UserFeedback object with the Id given in the URL
      getSingleObjectById(userFeedbackId, enumPersistableObjectType.UserFeedback, onUserFeedbackFetched);
    }
  }, [userFeedbackId]);

  // will be set to the userFeedback to be managed, either a new userFeedback or an existing one
  const [userFeedback, setUserFeedback] = useState<IUserFeedback | undefined>(undefined);

  // whether we are going to be managing a new userFeedback
  const [isNewObject, setIsNewObject] = useState<boolean | undefined>(undefined);

  const resetSaveUserFeedbackStatus: () => void = useCallback(() => {
    dispatch(userFeedbackSaveStatusChange(null));
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageUserFeedbackPage. In useEffect for resetAlertInfo, resetSaveUserFeedbackStatus`);

    // clear areas in Redux state to start with a clean slate
    resetSaveUserFeedbackStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveUserFeedbackStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveUserFeedbackStatus]);

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();

  // prepare a data structure that will be used to call a custom hook that will take care of the workflow for the page
  const processPerManageObjectPageStatusInput: IProcessPerManageObjectPageStatusInput = {
    workflowStateObj: (state: IStoreState) => state.saveUserFeedbackStatus,
    failureErrorStateObj: (state: IStoreState) => state.saveUserFeedbackFailure,
    notificationFailureMessageTemplate: MessagesStringAssets.userFeedback_SaveFailure,
    notificationSuccessTitle: NotificationStringAssets.heading_SaveUserFeedback,
    notificationSuccessMessage: MessagesStringAssets.userFeedback_SaveSuccess,
  }

  if (userFeedback !== undefined) {
    processPerManageObjectPageStatusInput.notificationSuccessMessage = `${MessagesStringAssets.userFeedback_SaveSuccess}`;
  }

  // call a custom hook to handle the workflow for the page (Requested, InProgress, Success, Failure)
  // const { saveInProgress, alertInfo } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);
  const { operationInProgress } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);

  /**
   * @function prepareNewUserFeedback Prepares/creates a new userFeedback that will be used if user is requesting to create/add a new userFeedback.
   */
  const prepareNewUserFeedback: (user: IUser, userSettings: IUserSettings) => IUserFeedback = useCallback(
    (user: IUser, userSettings: IUserSettings) => {
      let newUserFeedback: IUserFeedback;

      const newUserFeedbackId: typeUniqueId = RandomId.newId();
      const userName: string = `${userSettings.firstName} ${userSettings.lastName}`;
      const submissionTimestamp: Date = new Date();
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(user.id, userName);
      newUserFeedback = new UserFeedback(user.id, newUserFeedbackId, enumPersistableObjectType.User, undefined, enumObjectPersistenceState.New,
        user.id, submissionTimestamp, userName, user.userSettings.mobilePhone ?? '', enumUserFeedbackType.Compliment, '', '', '', userPersistenceData);

      return newUserFeedback;
    }, []
  );

  // define an effect based on a change to the userFeedbackParentId & currentUser. Having a defined userFeedbackParentId means that we will
  // be creating a new UserFeedback
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageUserFeedbackPage. In useEffect for userFeedbackParentId, currentUser, prepareNewUserFeedback`);

    if (!params.id && currentUser) {
      setUserFeedback(prepareNewUserFeedback(currentUser, currentUser.userSettings));

      setIsNewObject(true);
    }
  }, [currentUser, prepareNewUserFeedback]);

  function onUserFeedbackFetched(userFeedback: IUserFeedback | undefined): void {
    displayConsoleLogs && console.log(`ManageUserFeedbackPage. Entered onUserFeedbackFetched()`);

    if (userFeedback !== undefined) {
      // set the returned UserFeedback to be the one we are editing
      setUserFeedback(userFeedback);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing object (default was set to 'true')
      setIsNewObject(false);
    }
  }

  /**
   * @method handleFormSubmit Handles a submit request from the form in this page
   * @param event A Submit event
   */
  function handleFormSubmit(userFeedback: IUserFeedback): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // if this is a new userFeedback item, set the submission timestamp
        if (isNewObject) {
          // set the submission timestamp to be the current timestamp (via the creation of a new Date object)
          userFeedback.submissionTimestamp = new Date();
        }

        // dispatch an action to request saving the userFeedback
        dispatch(userFeedbackSaveRequest(userFeedback));

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }


  return (
    <>
      <GenericPageContainer
        maxWidth="sm"
        showBackButton={true}
        pageTitle={isNewObject ? PageAndViewTitleStringAssets.pageTitle_ManageUserFeedback_New : PageAndViewTitleStringAssets.pageTitle_ManageUserFeedback_Existing}
        onCloseAlert={resetSaveUserFeedbackStatus}
      >
        {userFeedback &&
          <UserFeedbackForm
            userFeedback={userFeedback}
            saveRequestInProgress={operationInProgress}
            onSubmit={handleFormSubmit} />
        }

      </GenericPageContainer>
    </>
  );


} // const ManageTopicPage...

export default ManageUserFeedbackPage;
