import { typeUniqueId } from "../../../../dataObjects/types"
import { submitEmailRequestToServer } from ".";
import { IEmailRequest } from "../../../../dataObjects/models/emailRequests";
import { generateEmailRequestForRecoverUserEmailAddress } from "./recoverUserEmailAddress";

/**
 * @function submitEmailRequestForRecoverUserEmailAddress Generates amd submitsGenerates an email request to notify that the email address on
 *                            the user's account has been recovered (changed back to the email address before it was changed)
 * @param {typeUniqueId} userId The Id of the user who is the target of the email.
 * @param {string} recoverEmailAddress The email address that will be recovered (reverted to) and the target of the email message.
 * @param {string} firstName The first name of the target of the email.
 * @param {string} lastName The last name of the target of the email.
 * @param {string} priorEmailAddress The email address prior to the recover operation (email address to which it had been changed).
 * @returns {void} A Promise with no return value
 */
export function submitEmailRequestForRecoverUserEmailAddress(userId: typeUniqueId, recoverEmailAddress: string, firstName: string, lastName: string, priorEmailAddress: string): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // whether to display console logs (displayConsoleLogs && console.log statements)
      const displayConsoleLogs: boolean = false;

      displayConsoleLogs && console.log(`%c Entered submitEmailRequestForRecoverUserEmailAddress()`, 'background: #055; color: #fff');

      // call method to generate the Recover Email request, which will return the Email Request
      const emailRequest: IEmailRequest = await generateEmailRequestForRecoverUserEmailAddress(userId, recoverEmailAddress.toLowerCase(), firstName, lastName, priorEmailAddress.toLowerCase());

      displayConsoleLogs && console.log(`%c In submitEmailRequestForRecoverUserEmailAddress. Returned from call to generateEmailRequestForRecoverUserEmailAddress. emailRequest: ${JSON.stringify(emailRequest)}`, 'background: #00B; color: #fff');

      // submit email request to the server
      await submitEmailRequestToServer(emailRequest);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}
