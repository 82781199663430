import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { IUserRedemptionCodeRequest } from '../../../../dataObjects/models/userRedemptionCodeRequest';
import { cardGridViewItemStyles } from '../../../styles/views/cardGridViewItemStyles';
import { TooltipStringAssets } from '../../../../assets/stringAssets';
import { enumSharingPermission } from '../../../../dataObjects/enums';

export interface IUserRedemptionCodeRequestsCardGridViewItemProps extends PropsWithChildren<unknown> {
  userRedemptionCodeRequest: IUserRedemptionCodeRequest;
  userPermission: enumSharingPermission;
  onDelete: (userRedemptionCodeRequest: IUserRedemptionCodeRequest) => void;
}


export const UserRedemptionCodeRequestsCardGridViewItem: React.FC<IUserRedemptionCodeRequestsCardGridViewItemProps> = (props: IUserRedemptionCodeRequestsCardGridViewItemProps) => {

  const { onDelete, userPermission, userRedemptionCodeRequest } = props;
  const { id, redemptionCode, recipientName, recipientEmailAddress, authorizerEmailAddress, startDate, endDate } = userRedemptionCodeRequest;

  const classes = cardGridViewItemStyles();

  // we're going to apply the 'useNavigate' hook to navigate the browser
  const navigate = useNavigate();

  const userHasPermission: boolean = userPermission === enumSharingPermission.Admin;

  return (
    <>
      {/* <Card classes={{ root: classes.card }} elevation={6}> */}
      <Card elevation={6} className={classes.card}>
        {/* Note: Couldn't get proper behavior from <CardHeader>, so will just display a title via <Typography> */}
        <Typography className={classes.cardTitle} variant='h6' align='center'>
          {recipientName}
        </Typography>

        <CardContent>
          <div>
            <Typography className={classes.cardDescription} variant="body1">
              Redemption code: {redemptionCode}
            </Typography>
          </div>
          <div>
            <Typography className={classes.cardDescription} variant="body1">
              Recipient email: {recipientEmailAddress}
            </Typography>
          </div>
          <div>
            <Typography className={classes.cardDescription} variant="body1">
              Authorizer email: {authorizerEmailAddress}
            </Typography>
          </div>
          <div>
            <Typography className={classes.cardDescription} variant="body1">
              Start date:&nbsp;
              {startDate &&
                moment(startDate).format('YYYY-MM-DD')}
            </Typography>
          </div>
          <div>
            <Typography className={classes.cardDescription} variant="body1">
              End date:&nbsp;
              {(endDate !== undefined) ? moment(endDate).format('YYYY-MM-DD') : 'No end date'}
              {/* (endDate !== null || endDate !== undefined) ? 'A date' : 'No end date' */}
            </Typography>
          </div>

        </CardContent>

        <CardActions className={classes.cardActionsContainer}>
          <Tooltip
            // the tooltip for the edit button will either be "Edit" (if user has sufficient permission) or "Edit disallowed" (insufficient permission)
            title={!userHasPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.editIconButton}`}
                size="small"
                aria-label="edit"
                disabled={!userHasPermission}
                onClick={() => navigate(`/userRedemptionCodeRequest/${id}`)}>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip
            // the tooltip for the delete button will either be "Delete" (if user has sufficient permission) or "Delete disallowed" (insufficient permission)
            title={!userHasPermission ? TooltipStringAssets.delete_Disallowed : TooltipStringAssets.delete}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.deleteIconButton}`}
                size="small"
                aria-label="delete"
                disabled={!userHasPermission}
                onClick={() => userRedemptionCodeRequest && onDelete(userRedemptionCodeRequest)} >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CardActions>
      </Card>
    </>
  );

}