import { take, put } from 'redux-saga/effects';
import { saveUserFeedback } from '../../../firebaseServices/dataServices/dataServiceActions/userFeedbackActions';
import { userFeedbackSaveRequest, userFeedbackSaveStatusChange } from '../../slices/userFeedback/userFeedbackSaveStatusSlice';
import { userFeedbackSaveFailureChange } from '../../slices/userFeedback/userFeedbackSaveFailureSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { submitEmailRequestForUserFeedback } from '../../../firebaseServices/dataServices/dataServiceActions/emailRequestActions';

/**
 * @function saveUserFeedbackRequestedSaga A saga workflow pertaining to a user having requested to save a userFeedback.
 */
export function* saveUserFeedbackRequestedSaga() {
  // console.info('(saveUserFeedbackRequestedSaga) entered saveUserFeedbackRequestedSaga');

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = true;

  displayConsoleLogs && console.log(`saveUserFeedbackRequestedSaga. Entered saveUserFeedbackRequestedSaga`);

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {

      displayConsoleLogs && console.log(`saveUserFeedbackRequestedSaga. Ready to wait on userFeedbackSaveRequest`);

      // loop to keep the saga running throughout the life of the application
      // wait on the userFeedbackSaveRequest action to be dispatched
      const userFeedbackSaveRequestData = yield take(userFeedbackSaveRequest);
      const { payload: userFeedback } = userFeedbackSaveRequestData;

      displayConsoleLogs && console.log(`saveUserFeedbackRequestedSaga. userFeedbackSaveRequest triggered. userFeedbackSaveRequestData.userFeedback: ${JSON.stringify(userFeedback)}`);

      displayConsoleLogs && console.log(`saveUserFeedbackRequestedSaga. Ready to call saveUserFeedback()`);

      // make a request to save the userFeedback
      yield saveUserFeedback(userFeedback)

      // call function to generate an Email Request with the user feedback information and submit to the server
      yield submitEmailRequestForUserFeedback(userFeedback);

      displayConsoleLogs && console.log(`saveUserFeedbackRequestedSaga. Ready to dispatch userFeedbackSaveStatusChange(enumWorkflowState.Success)`);

      // dispatch an action to indicate that the save operation was successful
      yield put(userFeedbackSaveStatusChange(enumWorkflowState.Success));
    } catch (error: any) {

      displayConsoleLogs && console.log(`saveUserFeedbackRequestedSaga. Error occurred. error: ${JSON.stringify(error)}`);

      // dispatch an action to indicate that the save operation failed
      yield put(userFeedbackSaveStatusChange(enumWorkflowState.Failure));
      yield put(userFeedbackSaveFailureChange(error));
    }
  }
}
