import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ControlsStringAssets } from '../../../assets/stringAssets';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, BoxProps, TextField } from '@mui/material';
import { styled } from '@mui/styles';
import isEmail from 'validator/lib/isEmail';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display Text for the instructions
const StyledBoxForInstructionalText = styled((props: BoxProps) => (
  <Box
    component='div'
    {...props}
  />
))(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: '0 10px',
}));

interface IEmailEntryFormValues {
  email: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  email: yup
    .string()
    .email(ControlsStringAssets.emailInvalid)
    .required(ControlsStringAssets.emailRequired),
});

export interface IEmailEntryFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {string} emailAddress The EmailAddress of a user
   */
  emailAddress: string,
  /**
   * @property {string} instructions An HTML fragment that provides instructions to display
   */
  instructions?: string,
  /**
 * @property {boolean} actionInProgress Whether an action is in progress
 */
  actionInProgress?: boolean,
  /**
 * @property {string} actionInProgressLabel If there's an action is in progress, the label to be presented for the action
 */
  actionInProgressLabel?: string,
  /**
 * @property {(emailAddress: string) => Promise<void>} onSubmit Method to call for submitting the email address
 */
  onSubmit: (emailAddress: string) => Promise<void>,
  /**
   * @property {() => Promise<void>} onCancel Method to call if the user requests to cancel the operation
   */
  onCancel: () => Promise<void>,
}

const EmailEntryForm: React.FC<IEmailEntryFormProps> = (props: IEmailEntryFormProps) => {
  EmailEntryForm.displayName = 'Email Entry Form';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('%c Entered/Refreshed EmailEntryForm component', 'background: #055; color: #fff');

  const { emailAddress, instructions, actionInProgress, actionInProgressLabel, onSubmit, onCancel } = props;

  // const navigate = useNavigate();

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, getValues, handleSubmit } = useForm<IEmailEntryFormValues>({
    defaultValues: {
      email: emailAddress,
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: emailReg, ...emailProps } = register("email", { required: true });

  // the current email address entered
  // const currentEmailAddress: string = getValues("email");
  const [currentEmailAddress, setCurrentEmailAddress] = useState<string>('');

  // for testing whether the form is in a valid state
  const { isValid } = formState;

  const [formIsValid, setFormIsValid] = useState<boolean>(false);

  useEffect(() => {
    setFormIsValid(isValid);
    // console.info(`EmailEntryForm.useEffect: formIsValid being set to ${isValid}`);
  }, [isValid]);

  // handles a submit request from the form
  const handleEmailAddressSubmit = async (data: IEmailEntryFormValues) => {
    // call the onSubmit handler passed in, supplying the emailAddress
    await onSubmit(data.email);
  }

  // An 'onChange' handler for the Email field
  function handleEmailChange(emailAddressFieldValue: string): void {
    displayConsoleLogs && console.log(`%c EmailEntryForm.handleEmailChange. emailAddressFieldValue: ${emailAddressFieldValue}`, 'background: #055; color: #fff');

    if (isEmail(emailAddressFieldValue)) {
      if (currentEmailAddress !== emailAddressFieldValue) {
        setCurrentEmailAddress(emailAddressFieldValue);
      }

      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }

  }

  function handleCanceled(): void {
    // call onCancel() method
    onCancel();
  }

  displayConsoleLogs && console.log(`%c EmailEntryForm. Before return statement. currentEmailAddress: ${currentEmailAddress}`, 'background: #055; color: #fff');

  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleEmailAddressSubmit)}
        onCancel={handleCanceled}
        actionInProgress={actionInProgress ?? false}
        actionInProgressLabel={actionInProgressLabel ?? ''}
        formIsValid={formIsValid}
        submitButtonLabel={ControlsStringAssets.submitButtonText}
      >
        {
          instructions &&
          <>
            <StyledBoxForInstructionalText>
              {instructions}
            </StyledBoxForInstructionalText>
          </>
        }

        <TextField
          inputRef={emailReg}
          {...emailProps}
          autoFocus
          label={ControlsStringAssets.emailLabel}
          margin='normal'
          fullWidth
          error={!!errors.email}
          helperText={errors?.email?.message}
          slotProps={{
            inputLabel: {
              required: true // this will cause an asterisk ('*') to appear at the end of the label text
            }
          }}
          onChange={e => handleEmailChange(e.target.value)}
        />
      </FormWithActionBar>
    </>

  );
}

export default EmailEntryForm;