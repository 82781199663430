import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";

/**
 * @function generateEmailSubjectForRecoverUserEmailAddress Generates the Subject of a notification email allowing to recover (reverted) from a changed email address
 * @param {string} recoveredToEmailAddress The new (recovered to) email address of the user.
 * @param {string} priorEmailAddress The old (prior) email address prior to recovery (reverting).
 * @returns {string} A string with the Subject of the email message to be sent.
 */
export function generateEmailSubjectForRecoverUserEmailAddress(recoveredToEmailAddress: string, priorEmailAddress: string): string {

  // *** Compose the email Subject ***
  const emailSubject = `(${ServerSubstitutionKeywordsStringAssets.ProjectName}) Account Email Address Recovered (reverted to ${recoveredToEmailAddress} from ${priorEmailAddress})`;

  // return the email content
  return emailSubject;
}
