import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";
import { generateStandardEmailClosingPlainText, generateStandardEmailOpeningPlainText } from "../commonEmailBodyFragments";

/**
 * @function generateEmailBodyPlainTextForAccountVerification Generates the Plain Text body of a notification email to verify a new user account
 * @param {string} firstName The first name of the target of the email.
 * @param {string} lastName The last name of the target of the email.
 * @returns {string} A string with the Plain Text body of the email message to be sent.
 */
export function generateEmailBodyPlainTextForAccountVerification(firstName: string, lastName: string): string {

  const recipientUserName: string = `${firstName} ${lastName}`;

  // *** Compose the email Body in Plain Text format ***
  // salutation/greetings to the recipient
  let emailBodyPlainText = generateStandardEmailOpeningPlainText(recipientUserName);

  // thank user for registering
  emailBodyPlainText += `Thank you for registering with ${ServerSubstitutionKeywordsStringAssets.ProjectName}! \n`;
  emailBodyPlainText += `Before you can sign in to use ${ServerSubstitutionKeywordsStringAssets.ProjectName}, we require that you verify your email address by `;
  emailBodyPlainText += `clicking on the verification link that follows: \n`;
  emailBodyPlainText += `${ServerSubstitutionKeywordsStringAssets.EmailVerificationLink} \n\n`;
  emailBodyPlainText += `Clicking on the link will re-direct you to a browser to verify your email address. Once your email address has been verified, `;
  emailBodyPlainText += `you will be able to sign in to ${ServerSubstitutionKeywordsStringAssets.ProjectName}. \n\n`;

  emailBodyPlainText += `If you did not register to use ${ServerSubstitutionKeywordsStringAssets.ProjectName}, you may ignore this message and no further action will take place.\n\n`;

  emailBodyPlainText += `Assuming that you registered to use ${ServerSubstitutionKeywordsStringAssets.ProjectName}, we hope you will enjoy using ${ServerSubstitutionKeywordsStringAssets.ProjectName}! \n\n`;

  emailBodyPlainText += generateStandardEmailClosingPlainText();

  // return the email content
  return emailBodyPlainText;
}
