import React, { PropsWithChildren } from 'react';
import { useNavigate } from "react-router";
import { FabProps, Fab as FloatingActionButton } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { styled } from '@mui/styles';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled FloatingActionButton object to represent the button
const StyledFloatingActionButton = styled((props: FabProps) => (
  <FloatingActionButton
    {...props}
  />
))(({ theme }) => ({
  // MuiFab theming isn't responding properly, so directly theme the hover properties
  '&:hover': {
    background: theme.floatingActionButton.hover.background,
    border: theme.floatingActionButton.hover.border,
    color: theme.floatingActionButton.hover.color,
  },

  [theme.breakpoints.up('xs')]: {
    transform: 'scale(.80) translate(-20%)',
  },
  [theme.breakpoints.up('sm')]: {
    transform: 'scale(.85) translate(-15%)',
  },
  [theme.breakpoints.up('md')]: {
    transform: 'scale(.90) translate(-10%)',
  },
  [theme.breakpoints.up('lg')]: {
    transform: 'scale(.95) translate(-5%)',
  },
  [theme.breakpoints.up('xl')]: {
    transform: 'scale(1.0)',
  },
}));

/**
 * @interface IPageBackButtonProps declares any input properties required for this component.
 */
export interface IPageBackButtonProps extends PropsWithChildren<unknown> {
  // there are no input properties for this component at this time. Properties will be added to his interface 
  // if input properties for this component are added.
}

/**
 * @function PageBackButton is a React functional component representing a "go back" button that will appear on a page and its only 
 *           responsibility is to navigate to the previous web page if pressed.
 *           (NOTE: Appearance styling for the Fab (FloatingActionButton), but not sizing, is addressed in the global theme.)
 * @param {} props are the properties passed into the component (currently no input properties).
 */
export const PageBackButton: React.FC<{}> = (props: {}) => {
  PageBackButton.displayName = 'Page Back Button';

  const navigate = useNavigate();

  return (
    <>
      <StyledFloatingActionButton onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </StyledFloatingActionButton>
    </>
  )
}
