import { enumAppPlatformType, enumEmailRequestType, enumLocale, enumObjectPersistenceState } from "../../../../../dataObjects/enums";
import { IObjectSharingRequestTracker } from "../../../../../dataObjects/models/collaboration/ObjectSharingTracker";
import { EmailRequest, IEmailRequest } from "../../../../../dataObjects/models/emailRequests";
import { IUserPersistenceData, UserPersistenceData } from "../../../../../dataObjects/models/persistence/UserPersistenceData";
import { typeUniqueId } from "../../../../../dataObjects/types";
import { generateIdForEmailRequest } from "../generateIdForEmailRequest";
import { generateEmailBodyHtmlForObjectSharingRequest } from "./";
import { generateEmailBodyPlainTextForObjectSharingRequest } from "./";
import { generateEmailSubjectForObjectSharingRequest } from "./generateEmailSubjectForObjectSharingRequest";

/**
 * @function generateEmailRequestForObjectSharingRequest Generates an email request with a notification email indicating that an object (eg, a Channel) has been shared
 * @param {IObjectSharingRequestTracker} objectSharingRequestTracker Object Sharing information with details needed for an email message
 * @returns {Promise<IEmailRequest>} A Promise with the generated Email Request, providing for an asynchronous process.
 */
export function generateEmailRequestForObjectSharingRequest(objectSharingRequestTracker: IObjectSharingRequestTracker): Promise<IEmailRequest> {
  return new Promise<IEmailRequest>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log(`%c Entered generateEmailRequestForObjectSharingRequest`, 'background: #550; color: #fff');

    try {
      // get the base domain of the running application
      const baseDomain: string = window.location.hostname;

      displayConsoleLogs && console.log(`%c generateEmailRequestForObjectSharingRequest. Ready to create objects to submit an email request.`, 'background: #550; color: #fff');

      // create User Persistence data
      const userPersistenceData: IUserPersistenceData =
        new UserPersistenceData(objectSharingRequestTracker.requestorUserId, objectSharingRequestTracker.requestorName, enumAppPlatformType.Web, enumLocale.English);

      // call methods to generate the email subject, html body, and plain text body
      const emailSubject: string = generateEmailSubjectForObjectSharingRequest(objectSharingRequestTracker);
      const emailBodyHtml: string = generateEmailBodyHtmlForObjectSharingRequest(objectSharingRequestTracker);
      const emailBodyPlainText: string = generateEmailBodyPlainTextForObjectSharingRequest(objectSharingRequestTracker);

      // the Email Request Id will be a concatenation of the ObjectSharingRequestTracker Id, the Request Type, and a unique Id
      const emailRequestId: typeUniqueId = await generateIdForEmailRequest(objectSharingRequestTracker.id, enumEmailRequestType.ObjectSharingRequest);
      const requestorUserName: string = objectSharingRequestTracker.requestorName;
      const emailRequestSubmittedTimestamp: string = new Date().toUTCString();
      // const emailRequest: IEmailRequest = new EmailRequest(newAccountUserId, emailRequestId, undefined, undefined, enumObjectPersistenceState.New,
      //   enumEmailRequestType.AccountVerification, newAccountUserId, newAccountUserName, newAccountUserId, newAccountEmailAddress, newAccountUserName,
      //   undefined, enumAppPlatformType.Web, emailSubject, emailBodyHtml, emailBodyPlainText, baseDomain, undefined, emailRequestSubmittedTimestamp,
      //   userPersistenceData);

      const emailRequest: IEmailRequest = new EmailRequest(objectSharingRequestTracker.requestorUserId, emailRequestId, undefined, undefined, enumObjectPersistenceState.New,
        enumEmailRequestType.ObjectSharingRequest, objectSharingRequestTracker.requestorUserId, requestorUserName, objectSharingRequestTracker.recipientUserId, objectSharingRequestTracker.recipientEmail, objectSharingRequestTracker.recipientName,
        undefined, enumAppPlatformType.Web, emailSubject, emailBodyHtml, emailBodyPlainText, baseDomain, undefined, emailRequestSubmittedTimestamp, userPersistenceData);


      displayConsoleLogs && console.log(`%c generateEmailRequestForAccountVerification. Finished creating email request.`, 'background: #550; color: #fff');

      resolve(emailRequest);
    } catch (error: any) {
      displayConsoleLogs && console.log(`%c generateEmailRequestForAccountVerification. Error encountered: ${error}`, 'background: #550; color: #fff');

      reject(error);
    }
  });
}