import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid2';
import { IAudioLink } from '../../../../dataObjects/models/digitalMedia/AudioLink';
import { AudioLinksCardGridViewItem } from './AudioLinksCardGridViewItem';
import { IAudioLinkViewModel } from '../../../../dataObjects/viewModels/audioLinkViewModel';


export interface IAudioLinksCardGridViewProps extends PropsWithChildren<unknown> {
  audioLinkViewModels: Array<IAudioLinkViewModel>;
  onDelete: (audioLink: IAudioLink) => void;
}


export const AudioLinksCardGridView: React.FC<IAudioLinksCardGridViewProps> = (props: IAudioLinksCardGridViewProps) => {
  AudioLinksCardGridView.displayName = 'Audio Links Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render AudioLinksCardGridView`);

  const { audioLinkViewModels, onDelete } = props;

  return (
    <>
      <Grid container spacing={2} >
        {
          audioLinkViewModels.map(audioLinkViewModel =>
            <Grid key={audioLinkViewModel.audioLink.id} size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
              <AudioLinksCardGridViewItem audioLinkViewModel={audioLinkViewModel} onDelete={onDelete} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}