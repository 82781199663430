import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid2';
import { IHyperLink } from '../../../../dataObjects/models/digitalMedia/HyperLink';
import { HyperLinksCardGridViewItem } from './HyperLinksCardGridViewItem';
import { IHyperLinkViewModel } from '../../../../dataObjects/viewModels/hyperLinkViewModel';


export interface IHyperLinksCardGridViewProps extends PropsWithChildren<unknown> {
  hyperLinkViewModels: Array<IHyperLinkViewModel>;
  onDelete: (hyperLink: IHyperLink) => void;
}


export const HyperLinksCardGridView: React.FC<IHyperLinksCardGridViewProps> = (props: IHyperLinksCardGridViewProps) => {
  HyperLinksCardGridView.displayName = 'HyperLinks Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render HyperLinksList`);

  const { hyperLinkViewModels, onDelete } = props;

  return (
    <>
      <Grid container spacing={2} >
        {
          hyperLinkViewModels.map(hyperLinkViewModel =>
            <Grid key={hyperLinkViewModel.hyperLink.id} size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
              <HyperLinksCardGridViewItem hyperLinkViewModel={hyperLinkViewModel} onDelete={onDelete} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}