import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";

/**
 * @function generateEmailSubjectForPasswordReset Generates the Subject of a notification email for a password reset request
 * @returns {string} A string with the Subject of the email message to be sent.
 */
export function generateEmailSubjectForPasswordReset(): string {

  // *** Compose the email Subject ***
  const emailSubject = `(${ServerSubstitutionKeywordsStringAssets.ProjectName}) Password Reset Requested`;

  // return the email content
  return emailSubject;
}
