import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { VersionAwarePersistable } from "../../persistence/VersionAwarePersistable";
import { JsonConverter } from "../../../utilities/JsonConverter";
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { IMembershipSubscription, IMembershipSubscriptionAsJson, MembershipSubscription } from '../MembershipSubscription';
import { IMembership, IMembershipAsJson } from '.';

/** 
 * @class Membership Represents a Base Membership object for an Entity (user, group, etc.), along with individual membership details
 *                         (eg, a given user may have records for one or more memberships -- such as historical annual memberships)
 */
export class Membership extends VersionAwarePersistable implements IMembership {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {IMembershipPersistenceData} userPersistenceData User-related persistence data
   * @param {typeUniqueId} userId Id of the user associated with the membership
   * @param {Array<IMembershipSubscription>} membershipSubscriptions The collection of subscriptions for the membership
   */
  /**
   * @method Constructor method
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    userId: typeUniqueId,
    membershipSubscriptions: Array<IMembershipSubscription>,
    userPersistenceData?: IUserPersistenceData
  ) {
    super(ownerId, enumPersistableObjectClassName.Membership, enumPersistableObjectType.Membership, id, parentObjectType, parentId, objectState, userPersistenceData);

    this._userId = userId;

    // associate the provided subscriptions to the membership
    this._membershipSubscriptions = new Array<IMembershipSubscription>();
    this.assignMembershipSubscriptions(membershipSubscriptions);

  }

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _userId Id of the user associated with the membership
   */
  private _userId: typeUniqueId;

  /**
   * @method userId is an optional getter method for _userId
   */
  get userId(): typeUniqueId {
    return this._userId;
  }

  /**
   * @method userId is an optional setter method for _userId
   * @param {typeUniqueId} value The value to be used to set _userId
   */
  set userId(value: typeUniqueId) {
    // ensure that the userSettings object has the correct 'parentObject', 'parentObjectType' and 'parentId'
    this._userId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Array<IMembershipSubscription>} _membershipSubscriptions The collection of subscriptions for the membership
   */
  private _membershipSubscriptions: Array<IMembershipSubscription>;

  /**
   * @method membershipSubscriptions Getter method for _membershipSubscriptions
   */
  get membershipSubscriptions(): Array<IMembershipSubscription> {
    return this._membershipSubscriptions;
  }

  /**
   * @method membershipSubscriptions Setter method for _membershipSubscriptions
   * @param {Array<typeUniqueId>} value is the input value for setting _membershipSubscriptions
   */
  set membershipSubscriptions(value: Array<IMembershipSubscription>) {
    this.assignMembershipSubscriptions(value);
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  private assignMembershipSubscriptions(membershipSubscriptionsToAssign: Array<IMembershipSubscription>) {
    let membershipSubscriptionsCopy: Array<IMembershipSubscription> = new Array<IMembershipSubscription>();
    membershipSubscriptionsToAssign.forEach(subscription => {
      membershipSubscriptionsCopy.push(subscription.copy());
    });
    this._membershipSubscriptions = membershipSubscriptionsCopy;

  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IMembership} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IMembership {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IMembership = Object.create(Membership.prototype);
    Object.assign(copyOfObject, this);

    // copy the contained objects
    if (this.membershipSubscriptions !== undefined) {
      let membershipSubscriptionsCopy: Array<IMembershipSubscription> = new Array<IMembershipSubscription>();
      this.membershipSubscriptions.forEach(subscription => {
        membershipSubscriptionsCopy = Object.create(MembershipSubscription.prototype);
        membershipSubscriptionsCopy.push(subscription.copy());
      });

      copyOfObject.membershipSubscriptions = membershipSubscriptionsCopy;
    }

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IMembershipAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IMembershipAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.userId = this.userId;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        let membershipSubscriptionsAsJsonCopy: Array<IMembershipSubscriptionAsJson> = new Array<IMembershipSubscriptionAsJson>();
        this.membershipSubscriptions.forEach(subscription => {
          membershipSubscriptionsAsJsonCopy.push(subscription.toJSON(includeContainedObjects));
          jsonObject.membershipSubscriptions = membershipSubscriptionsAsJsonCopy;
        });
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IMembershipAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns An instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IMembershipAsJson, includeContainedObjects: boolean = true): IMembership {
    try {
      // create a new instance of this class
      let membershipObject: Membership = Object.create(Membership.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      membershipObject = super.fromJSONProtected(membershipObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.userId !== undefined) {
        membershipObject.userId = jsonObject.userId;
      }

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        if (jsonObject.membershipSubscriptions) {
          let membershipSubscriptions: Array<IMembershipSubscription> = new Array<IMembershipSubscription>();
          jsonObject.membershipSubscriptions.forEach(subscriptionAsJson => {
            membershipSubscriptions.push(JsonConverter.fromJSON(MembershipSubscription, subscriptionAsJson, includeContainedObjects));
            membershipObject.membershipSubscriptions = membershipSubscriptions;
          });
        }
      }

      return membershipObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
