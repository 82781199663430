import { take, put } from 'redux-saga/effects';
import { deleteUserFeedback } from '../../../firebaseServices/dataServices/dataServiceActions/userFeedbackActions';
import { userFeedbackDeleteRequest, userFeedbackDeleteStatusChange } from '../../slices/userFeedback/userFeedbackDeleteStatusSlice';
import { userFeedbackDeleteFailureChange } from '../../slices/userFeedback/userFeedbackDeleteFailureSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';

/**
 * @function deleteUserFeedbackRequestedSaga A saga workflow pertaining to a user having requested to delete a userFeedback.
 */
export function* deleteUserFeedbackRequestedSaga() {
  // console.info('(deleteUserFeedbackRequestedSaga) entered deleteUserFeedbackRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the userFeedbackDeleteRequest action to be dispatched
      const userFeedbackDeleteRequestData = yield take(userFeedbackDeleteRequest);
      const { payload: userFeedback } = userFeedbackDeleteRequestData;

      // make a request to delete the userFeedback
      yield deleteUserFeedback(userFeedback)

      // dispatch an action to indicate that the delete operation was successful
      yield put(userFeedbackDeleteStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the delete operation failed
      yield put(userFeedbackDeleteStatusChange(enumWorkflowState.Failure));
      yield put(userFeedbackDeleteFailureChange(error));
    }
  }
}
