import React, { PropsWithChildren, useState } from 'react';
import { Tooltip } from '@mui/material';
import Grid, {Grid2Props} from '@mui/material/Grid2';
import { FabProps, Fab as FloatingActionButton } from '@mui/material';
import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import {
  Add as AddIcon,
  List as ListIcon,
  ViewComfy as CardsIcon,
} from '@mui/icons-material';
import { useGetCollectionViewType } from '../../customHooks/useGetCollectionViewType';
import { enumCollectionViewType } from '../../../dataObjects/enums';
import { styled } from '@mui/styles';
import { useAppDispatch } from '../../../uiMiddleware-redux/store/configureStore';
import { setCollectionViewType } from '../../../uiMiddleware-redux/slices/collectionViewType/collectionViewTypeSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Grid for the overall container of the Top Action Bar
const StyledGridForTopActionBar = styled((props: Grid2Props) => (
  <Grid
    container
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
}));

// a styled Grid for the Floating Action Button Area
const StyledGridForFloatingActionButtonArea = styled((props: Grid2Props) => (
  <Grid
    container
    size={{ xs: 8, sm: 4 }}
    {...props}
  />
))(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'flex-start'
}));

// a styled Floating Action Button
const StyledFloatingActionButton = styled((props: FabProps) => (
  <FloatingActionButton
    variant="extended"
    size="medium"
    color="primary"
    {...props}
  />
))(({ theme }) => ({
  // MuiFab theming isn't responding properly, so directly theme the backgroundColor upon hover
  '&:hover': {
    background: theme.floatingActionButton.hover.background,
    border: theme.floatingActionButton.hover.border,
    color: theme.floatingActionButton.hover.color,
  },
}));

// a styled Grid for the View Buttons Group Area
const StyledGridForViewButtonsGroupArea = styled((props: Grid2Props) => (
  <Grid
    container
    size={{ xs: 4, sm: 4 }}
    {...props}
  />
))(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center'
}));

// a styled Toggle Button Group
const StyledToggleButtonGroup = styled((props: ToggleButtonGroupProps) => (
  <ToggleButtonGroup
    size="medium"
    exclusive
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid for spacing at the right
const StyledGridForRightSideSpacing = styled((props: Grid2Props) => (
  <Grid
    size={{ xs: 'auto', sm: 4 }}
    {...props}
  />
))(({ theme }) => ({
}));


export interface IDataViewControlsProps extends PropsWithChildren<unknown> {
  /**
   * @property {string} addNewItemButtonText Text to display in the "Add New XXX" button
   */
  addNewItemButtonText?: string;
  /**
   * @property {() => void} onClickAddNewItemButton Handler to call when user clicks on "Add New XXX" button
   */
  onClickAddNewItemButton?: () => void;
}


const DataViewControls: React.FC<IDataViewControlsProps> = (props: IDataViewControlsProps) => {

  const { addNewItemButtonText, onClickAddNewItemButton } = props;

  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  const dispatch = useAppDispatch();

  // the View Type (cards view or list view)
  // const [viewType, setViewType] = useState<enumCollectionViewType>(defaultViewType);
  const [viewType, setViewType] = useState<enumCollectionViewType>(collectionViewType);


  // handles request to change the view type on the Toggle Button
  const handleViewSelectionChange = (event: React.MouseEvent<HTMLElement>, newViewType: enumCollectionViewType) => {
    // require that a toggle button be selected by only setting the new view value if it is non-null
    if (newViewType !== null) {
      setViewType(newViewType);

      // dispatch a call to set the new Collection View Type in Redux state
      dispatch(setCollectionViewType(newViewType));
    }
  };


  return (
    <>
      <StyledGridForTopActionBar>
        <StyledGridForFloatingActionButtonArea key="addNewItemButtonContainer" >
          { /* for the onClick handler in the StyledFloatingActionButton, if an 'onClickAddNewItemButton' function has been provided, 
               call it upon onClick; otherwise, call a no-op function */}
          {addNewItemButtonText && addNewItemButtonText.length > 0 &&
            <StyledFloatingActionButton
              aria-label="add"
              onClick={() => onClickAddNewItemButton ? onClickAddNewItemButton() : {}}
            >
              <AddIcon />
              {addNewItemButtonText}
            </StyledFloatingActionButton>
          }
        </StyledGridForFloatingActionButtonArea>
        <StyledGridForViewButtonsGroupArea key="viewButtonGroupContainer" >
          <StyledToggleButtonGroup
            value={viewType}
            onChange={handleViewSelectionChange} >

            <Tooltip title={`Cards View`} arrow>
              <ToggleButton
                value={enumCollectionViewType.CardsGridView}
                selected={viewType === enumCollectionViewType.CardsGridView}
              >
                <CardsIcon />
              </ToggleButton>
            </Tooltip>

            <Tooltip title={`List View`} arrow>
              <ToggleButton
                value={enumCollectionViewType.ListView}
                selected={viewType === enumCollectionViewType.ListView}
              >
                <ListIcon />
              </ToggleButton>
            </Tooltip>

          </StyledToggleButtonGroup>
        </StyledGridForViewButtonsGroupArea>

        {/* The following Grid item is merely a spacer at the right side of the other items */}
        <StyledGridForRightSideSpacing key="spacer" />
      </StyledGridForTopActionBar>
    </>
  );

}

export default DataViewControls;