import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";
import { generateStandardEmailClosingHtml, generateStandardEmailOpeningHtml } from "../commonEmailBodyFragments";

/**
 * @function generateEmailBodyHtmlForAccountVerification Generates the Html body of a notification email to verify a new user account
 * @param {string} firstName The first name of the target of the email.
 * @param {string} lastName The last name of the target of the email.
 * @returns {string} A string with the Html body of the email message to be sent.
 */
export function generateEmailBodyHtmlForAccountVerification(firstName: string, lastName: string): string {

  const recipientUserName: string = `${firstName} ${lastName}`;

  // *** Compose the email Body in HTML format ***
  let emailBodyHtml = generateStandardEmailOpeningHtml(recipientUserName);

  // thank user for registering
  emailBodyHtml += `<p>Thank you for registering with ${ServerSubstitutionKeywordsStringAssets.ProjectName}! `;
  emailBodyHtml += `Before you can sign in to use ${ServerSubstitutionKeywordsStringAssets.ProjectName}, we require that you verify your email address by `;
  emailBodyHtml += `<a href='${ServerSubstitutionKeywordsStringAssets.EmailVerificationLink}'>clicking on this email verification link</a> that will `;
  emailBodyHtml += `re-direct you to a browser and verify your email address. Once your email address has been verified, `;
  emailBodyHtml += `you will be able to sign in to ${ServerSubstitutionKeywordsStringAssets.ProjectName}. </p>`;

  emailBodyHtml += `<p>If you did not register to use ${ServerSubstitutionKeywordsStringAssets.ProjectName}, you may ignore this message and no further action will take place.</p>`;

  emailBodyHtml += `<p>Assuming that you registered to use ${ServerSubstitutionKeywordsStringAssets.ProjectName}, we hope you will enjoy using ${ServerSubstitutionKeywordsStringAssets.ProjectName}! </p>`;

  emailBodyHtml += generateStandardEmailClosingHtml();

  // return the email content
  return emailBodyHtml;
}
