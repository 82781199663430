import React, { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ICategory } from '../../../../dataObjects/models/categories/Category';
import { listViewStyles } from '../../../styles';
import CategoriesListViewItem from './CategoriesListViewItem';


export interface ICategoriesListViewProps extends PropsWithChildren<unknown> {
  categories: Array<ICategory>;
  onDelete: (category: ICategory) => void;
}

export const CategoriesListView: React.FC<ICategoriesListViewProps> = (props: ICategoriesListViewProps) => {
  CategoriesListView.displayName = 'Categories List View';

  // eslint-disable-next-line no-console
  // console.info(`Render CategoriesListView`);

  const classes: Record<string, string> = listViewStyles();

  const { categories, onDelete } = props;

  return (
    <>
      {/* overall grid for list. There will be a header row and two data rows: 
          - Header Row
          - Data Row 1: Name & Action Buttons
          - Data Row 2: Description
      */}
      <Grid container direction="column" >
        {/* Header Row */}
        <Grid container className={classes.tableHeader} size={12} direction="row">
          <Grid size={{ xs: 12, md: 10 }} >
            <Typography variant='h6'>
              Name / Description
            </Typography>
          </Grid>
          <Grid container className={classes.actionButtonLabelContainer} size={{ md: 1 }} justifyContent='center' >
            {/* Only display the next label if the screen size is Medium or larger */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }} >
              <Typography variant='h6'>
                Edit
              </Typography>
            </Box>
          </Grid>
          <Grid container className={classes.actionButtonLabelContainer} size={{ md: 1 }} justifyContent='center' >
            {/* Only display the next label if the screen size is Medium or larger */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }} >
              <Typography variant='h6'>
                Delete
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {
          categories.map((category, idx) => (
            <CategoriesListViewItem
              key={category.id}
              category={category}
              onDelete={onDelete}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default CategoriesListView;
