import React, { PropsWithChildren, useEffect, useState } from 'react';
// import { FirebaseApp } from 'firebase/app';
// import { Auth } from 'firebase/auth';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { ControlsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { IChannel } from '../../../dataObjects/models/channels/Channel';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { Category, ICategory, ICategoryAsJson } from '../../../dataObjects/models/categories/Category';
import { JsonConverter } from '../../../dataObjects/utilities/JsonConverter';
import { typeUniqueId } from '../../../dataObjects/types';
import { getUserCategoriesForUser_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/userCategoryActions/getUserCategoriesForUser_onSnapshot';
import { getUserCategoriesForUserAndChannel_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/userCategoryActions';
import { styled } from '@mui/styles';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled TextField that is serving as a Select control
const StyledTextFieldForSelectControl = styled((props: TextFieldProps) => (
  <TextField
    select
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

interface IChannelFormValues {
  name: string;
  description: string;
  topicNameAliasSingular: string;
  topicNameAliasPlural: string;
  parentCategoryId: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  name: yup
    .string()
    .required(ControlsStringAssets.channelNameRequired),
  description: yup
    .string()
    .required(ControlsStringAssets.channelDescriptionRequired),
  topicNameAliasSingular: yup
    .string()
    .required(ControlsStringAssets.channelTopicNameAliasSingularRequired),
  topicNameAliasPlural: yup
    .string()
    .required(ControlsStringAssets.channelTopicNameAliasPluralRequired),
  parentCategoryId: yup
    .string().required(),
});

/**
 * @interface IChannelFormProps Properties for the ChannelForm component
 */
export interface IChannelFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {IChannel} channel The Channel details for the form (will have blank properties values if we're creating a new record)
   */
  channel: IChannel,
  /**
   * @property {boolean} saveRequestInProgress Whether a save request is in progress
   */
  saveRequestInProgress?: boolean,
  /**
   * @property {(channel: IChannel) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (channel: IChannel, parentCategoryId: typeUniqueId) => Promise<void>,
}

const ChannelForm: React.FC<IChannelFormProps> = (props: IChannelFormProps) => {
  ChannelForm.displayName = 'Channel Form';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  // get required arguments from props
  const { channel, onSubmit } = props;

  displayConsoleLogs && console.log(`ChannelForm. After assignment from props. channel.name: ${channel.name}`);

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c ChannelForm. currentUser: \n${JSON.stringify(currentUserContextData)}`, 'background: #600; color: #fff');
  const userId: typeUniqueId | undefined = currentUserContextData.currentUser?.id;

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit } = useForm<IChannelFormValues>({
    defaultValues: {
      name: channel.name,
      description: channel.description,
      topicNameAliasSingular: channel.topicNameAliasSingular,
      topicNameAliasPlural: channel.topicNameAliasPlural,
      parentCategoryId: userId ? Category.userUncategorizedCategoryIdFromUserId(userId) : ""
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: nameReg, ...nameProps } = register("name", { required: true });
  const { ref: descriptionReg, ...descriptionProps } = register("description", { required: true });
  const { ref: topicNameAliasSingularReg, ...topicNameAliasSingularProps } = register("topicNameAliasSingular", { required: true });
  const { ref: topicNameAliasPluralReg, ...topicNameAliasPluralProps } = register("topicNameAliasPlural", { required: true });
  const { ref: parentCategoryIdReg } = register("parentCategoryId", { required: true });

  // the Categories associcated with the current user account
  const [categories, setCategories] = useState<Array<ICategory>>([]);

  const [categoriesForDropdown, setCategoriesForDropdown] = useState<Array<React.JSX.Element>>([]);

  const [parentCategoryId, setParentCategoryId] = useState<string>(userId ? Category.userUncategorizedCategoryIdFromUserId(userId) : "");


  useEffect(() => {
    if (categories.length > 0) {

      // create a MenuItem collection that will be bound to the Categories Select control
      let categoriesMenuItems: Array<React.JSX.Element> = [];

      categories.forEach((category: ICategory, index: number) => {
        categoriesMenuItems.push(<MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>)
      });

      setCategoriesForDropdown(categoriesMenuItems);
    }

  }, [categories])

  // set up a useEffect() hook to get the categories for the given userId and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallbackUserCategoriesForUser: () => void = () => { };

    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallbackUserCategoriesForUserAndChannel: () => void = () => { };

    if (userId) {
      // subscribe to onShapshot updates, providing realtime updates to the data, and capture the 'unsubscribe' callback method provided by firestore
      getUserCategoriesForUser_onSnapshot(userId, onSnapshotCallbackUserCategoriesForUser).then((unsubscribe: () => void) => {
        unsubscribeCallbackUserCategoriesForUser = unsubscribe;
      });

      getUserCategoriesForUserAndChannel_onSnapshot(userId, channel.id, onSnapshotCallbackUserCategoriesForUserAndChannel).then((unsubscribe: () => void) => {
        unsubscribeCallbackUserCategoriesForUserAndChannel = unsubscribe;
      });
    }

    // perform cleanup when the component unmounts
    return () => {
      // if the user is still logged in...
      if (userId) {
        // Call the unsubscribe() callback to unsubscribe from the onSnapshots.  
        unsubscribeCallbackUserCategoriesForUser();
        unsubscribeCallbackUserCategoriesForUserAndChannel();
      }
    }
  }, [userId, channel.id])


  /**
   * @function onSnapshotCallbackUserCategoriesForUser A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onSnapshotCallbackUserCategoriesForUser(snapshot: QuerySnapshot<DocumentData>): void {
    const categoryDataAsJson: Array<ICategoryAsJson> = [];
    snapshot.forEach(doc => categoryDataAsJson.push({ ...doc.data(), id: doc.id } as ICategoryAsJson));

    // create an array of Category objects from the JSON data
    let categoriesData = JsonConverter.arrayFromJSONArray(Category, categoryDataAsJson);

    // use lodash to sort the array by 'name' in ascending order, if there are any elements in the array
    if (categoriesData.length > 0) {
      categoriesData = _.orderBy(categoriesData, [category => category.name.toUpperCase()], ['asc']);
    }

    setCategories(categoriesData);

    // find a Category that references this current Channel as one of its children

  }

  /**
   * @function onSnapshotCallbackUserCategoriesForUserAndChannel A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onSnapshotCallbackUserCategoriesForUserAndChannel(snapshot: QuerySnapshot<DocumentData>): void {
    const categoryDataAsJson: Array<ICategoryAsJson> = [];
    snapshot.forEach(doc => categoryDataAsJson.push({ ...doc.data(), id: doc.id } as ICategoryAsJson));

    // create an array of Category objects from the JSON data
    let categoriesData = JsonConverter.arrayFromJSONArray(Category, categoryDataAsJson);

    // // use lodash to sort the array by 'name' in ascending order, if there are any elements in the array
    // if (categoriesData.length > 0) {
    //   categoriesData = _.orderBy(categoriesData, [category => category.name.toUpperCase()], ['asc']);
    // }

    // setCategories(categoriesData);

    // if there is at least one Category that references this current Channel as one of its children, get the first (and hopefully only) 
    // Category and set its Id as the parentCategoryId
    if (categoriesData.length > 0) {
      setParentCategoryId(categoriesData[0].id);
    }
  }

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid: formIsValid } = formState;

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);


  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: IChannelFormValues) => {

    setSaveInProgress(true);

    // fill in name & description of the Channel object passed in
    displayConsoleLogs && console.log(`In ChannelForm.handleSaveSubmit. data values... parentCategoryId: ${parentCategoryId ? parentCategoryId : 'undefined'}; name: ${data.name}; description: ${data.description}; topicNameAliasSingular: ${data.topicNameAliasSingular}; topicNameAliasPlural: ${data.topicNameAliasPlural}`);
    channel.name = data.name;
    channel.description = data.description;
    channel.topicNameAliasSingular = data.topicNameAliasSingular;
    channel.topicNameAliasPlural = data.topicNameAliasPlural;

    // call the onSubmit handler passed in, supplying the Channel object
    await onSubmit(channel, parentCategoryId);
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={MessagesStringAssets.channel_SaveRequested}
        formIsValid={formIsValid}
      >
        <TextField
          inputRef={nameReg}
          {...nameProps}
          autoFocus
          type="text"
          label={ControlsStringAssets.channelNameLabel}
          margin='normal'
          fullWidth
          error={!!errors.name}
          helperText={errors?.name?.message}
          slotProps={{
            inputLabel: { required: true } // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <TextField
          inputRef={descriptionReg}
          {...descriptionProps}
          type="text"
          label={ControlsStringAssets.channelDescriptionLabel}
          margin='normal'
          fullWidth
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.description}
          helperText={errors?.description?.message}
          slotProps={{
            inputLabel: { required: true } // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <TextField
          inputRef={topicNameAliasSingularReg}
          {...topicNameAliasSingularProps}
          type="text"
          label={ControlsStringAssets.channelTopicNameAliasSingularLabel}
          margin='normal'
          fullWidth
          error={!!errors.topicNameAliasSingular}
          helperText={errors?.topicNameAliasSingular?.message}
          slotProps={{
            inputLabel: { required: true } // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <TextField
          inputRef={topicNameAliasPluralReg}
          {...topicNameAliasPluralProps}
          type="text"
          label={ControlsStringAssets.channelTopicNameAliasPluralLabel}
          margin='normal'
          fullWidth
          error={!!errors.topicNameAliasPlural}
          helperText={errors?.topicNameAliasPlural?.message}
          slotProps={{
            inputLabel: { required: true } // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        {/* We use a TextField with 'select' attribute as a pseudo <Select> (or dropdown) control */}
        {/* Only display this Category Select (dropdown) field after we have set the parentCategoryId and we have populated the
            list of categories to be be displayed (categoriesForDropdown) */}
        {
          (parentCategoryId !== undefined) &&
          (categoriesForDropdown.length > 0) &&
          <StyledTextFieldForSelectControl
            inputRef={parentCategoryIdReg}
            label="Category (optionally select one)"
            margin='normal'
            value={parentCategoryId}
            onChange={event => setParentCategoryId(event.target.value)}
          >
            {categoriesForDropdown}
          </StyledTextFieldForSelectControl>
        }

      </FormWithActionBar>
    </>

  );
}

export default ChannelForm;