import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid2';
import { ChannelSharingUserCardGridViewItem } from './ChannelSharingUserCardGridViewItem';
import { IChannelSharingUser } from '../../../../dataObjects/viewModels/channelSharing/ChannelSharingUser';
import { ICurrentUserChannelSharingViewModel } from '../../../../dataObjects/viewModels/channelSharing/CurrentUserChannelSharingViewModel';


export interface IChannelSharingUsersCardGridViewProps extends PropsWithChildren<unknown> {
  users: Array<IChannelSharingUser> | undefined;
  currentUserChannelSharingViewModel: ICurrentUserChannelSharingViewModel;
  onRevoke: (user: IChannelSharingUser) => void;
}


export const ChannelSharingUsersCardGridView: React.FC<IChannelSharingUsersCardGridViewProps> = (props: IChannelSharingUsersCardGridViewProps) => {
  ChannelSharingUsersCardGridView.displayName = 'Channel Sharing Users Card Grid View';

  const { users, currentUserChannelSharingViewModel } = props;

  const countOfUsers: number = users !== undefined ? users.length : 0;
  console.log(`ChannelSharingUsersCardGridView. # of users to display: ${countOfUsers}`)

  return (
    <>
      <Grid container spacing={2} >
        {users &&
          users.map(user =>
            <Grid key={user.userId} size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
              <ChannelSharingUserCardGridViewItem channelSharingUser={user} currentUserChannelSharingViewModel={currentUserChannelSharingViewModel} onRevoke={props.onRevoke} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}