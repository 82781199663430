import { FirebaseApp } from 'firebase/app';
import { DocumentData, Firestore, QuerySnapshot } from 'firebase/firestore';
import { IPersistable, IPersistableAsJson } from '../../../../../../dataObjects/models/persistence/Persistable';
import { IBaseDbContext } from '../../../../../dataServices/dbContexts/BaseDbContext';
import { FirebaseBaseRepository } from '../../firebaseBaseDatabase/FirebaseBaseRepository';
import { IFirestoreBaseRepository } from '.';
import { FirestorePersistenceMetadataRepository } from '../FirestorePersistenceMetadataRepository';
import { typeUniqueId } from '../../../../../../dataObjects/types';

export abstract class FirestoreBaseRepository<
  TEntity extends IPersistable,
  TEntityAsJson extends IPersistableAsJson
  > extends FirebaseBaseRepository<TEntity, Firestore>
  implements IFirestoreBaseRepository<TEntity, TEntityAsJson> {
  /**
   * @method Constructor method
   * @param {IBaseDbContext<Firestore>} firestoreDbContext The Database Context to be used by the derived class
   * @param {FirebaseApp} firebaseApp (Optional) A Firebase Application object that has been initialized, if provided
   * @param {boolean} enableOfflinePersistence (Optional) Whether to enable offline persistence for Firestore
   */
  constructor(
    firestoreDbContext: IBaseDbContext<Firestore>,
    firebaseApp?: FirebaseApp,
    // enableOfflinePersistence?: boolean
  ) {
    // debugger;
    super(firestoreDbContext, firebaseApp);

    // capture the reference to the Firestore DB
    this._firestoreDb = firestoreDbContext.dbReference;

    // // if the 'enableOfflinePersistence' parameter has been provided, ...
    // if (enableOfflinePersistence !== undefined) {
    //   // if 'enableOfflinePersistence' is true, enable Firestore DB offline persistence
    //   if (enableOfflinePersistence) {
    //     this._firestoreDb.enablePersistence();
    //   }
    // } else {
    //   // otherwise, enable offline support/persistence with the Firestore DB if configured to do so
    //   if (
    //     process.env.REACT_APP_GOOGLE_FIREBASE_FIRESTORE_OFFLINE_PERSISTENCE?.toLowerCase() ===
    //     "true"
    //   ) {
    //     this._firestoreDb.enablePersistence();
    //   }
    // }

    // create a FirestorePersistenceMetadataRepository instance that will be used to manage PersistenceMetadata for all derived classes
    this._firestorePersistenceMetadataRepository = new FirestorePersistenceMetadataRepository(
      this._firestoreDb
    );
  }

  /*-----------------------------------------------*/
  /**
   * @property {Firestore} _firestoreDb Reference to an initialized Firestore DB instance
   */
  private _firestoreDb: Firestore;

  /**
   * @method firestoreDb Getter method for _firestoreDb
   */
  get firestoreDb(): Firestore {
    return this._firestoreDb;
  }

  /**
   * @method firestoreDb Setter method for _firestoreDb
   * @param {Firestore} value The value to be used in setting _firestoreDb
   */
  set firestoreDb(value: Firestore) {
    this._firestoreDb = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {FirestorePersistenceMetadataRepository} _firestorePersistenceMetadataRepository PersistenceMetadata repository for tracking version objects
   */
  private _firestorePersistenceMetadataRepository: FirestorePersistenceMetadataRepository;

  /**
   * @method firestorePersistenceMetadataRepository Getter method for _firestorePersistenceMetadataRepository
   */
  get firestorePersistenceMetadataRepository(): FirestorePersistenceMetadataRepository {
    return this._firestorePersistenceMetadataRepository;
  }

  /**
   * @method firestorePersistenceMetadataRepository Setter method for _firestorePersistenceMetadataRepository
   * @param {FirestorePersistenceMetadataRepository} value The value to be used in setting _firestorePersistenceMetadataRepository
   */
  set firestorePersistenceMetadataRepository(
    value: FirestorePersistenceMetadataRepository
  ) {
    this._firestorePersistenceMetadataRepository = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * (NOTE: onSnapshot is only available in Firebase Firestore. That's why the method is declared in IFirestoreBaseRepository.)
   * 
   * @method get_onSnapshot Sets up a snapshot for retrieval of a single object from the database associated with the Id of the object, along 
   *   with dynamic notification of changes to the results of the query.
   * @param {typeUniqueId} id The unique Id of the object to retrieve.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A reference to a callback method on the client 
   *   side that will receive onSnapshot notifications when data on the server changes.
   * @returns {() => void>} A reference to an unsubscribeCallback method that is to be called when the caller/client has completed its work with the onSnapshot
   *   notifications. In React, this callback function will likely be called when a specific React component is unmounted (e.g., in the return statement of a 
   *   useEffect() hook for a React function component or in the ComponentDidUnmount method of a React class component.)
   */
  abstract get_onSnapshot(id: typeUniqueId, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void>;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * (NOTE: onSnapshot is only available in Firebase Firestore. That's why the method is declared in IFirestoreBaseRepository.)
   * 
   * @method getAllForParent_onSnapshot Sets up a snapshot for retrieval of all objects from the database associated with the parent of those objects, along 
   *   with dynamic notification of changes to the results of the query.
   * @param {typeUniqueId} parentId The unique Id of the parent of the objects to retrieve.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A reference to a callback method on the client 
   *   side that will receive onSnapshot notifications when data on the server changes.
   * @returns {() => void>} A reference to an unsubscribeCallback method that is to be called when the caller/client has completed its work with the onSnapshot
   *   notifications. In React, this callback function will likely be called when a specific React component is unmounted (e.g., in the return statement of a 
   *   useEffect() hook for a React function component or in the ComponentDidUnmount method of a React class component.)
   */
  abstract getAllForParent_onSnapshot(parentId: typeUniqueId, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void>;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * (NOTE: onSnapshot is only available in Firebase Firestore. That's why the method is declared in IFirestoreBaseRepository.)
   * 
   * @method getAllForOwner_onSnapshot Sets up a snapshot for retrieval of all objects from the database associated with the owner of those objects, along 
   *   with dynamic notification of changes to the results of the query.
   * @param {typeUniqueId} ownerId The unique Id of the owner of the objects to retrieve.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A reference to a callback method on the client 
   *   side that will receive onSnapshot notifications when data on the server changes.
   * @returns {() => void>} A reference to an unsubscribeCallback method that is to be called when the caller/client has completed its work with the onSnapshot
   *   notifications. In React, this callback function will likely be called when a specific React component is unmounted (e.g., in the return statement of a 
   *   useEffect() hook for a React function component or in the ComponentDidUnmount method of a React class component.)
   */
  abstract getAllForOwner_onSnapshot(ownerId: typeUniqueId, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void>;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method initiateTracking Initiates tracking on a versioned object.
   * @param {TEntityAsJson} trackedObjectAsJson A JSON version of the tracked object.
   * @returns {Promise<void>} A Promise, to provide asynchronicity, with no value (void)
   */
  initiateTracking(trackedObjectAsJson: TEntityAsJson): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        // forward the request to the PersistenceMetadataRepository object
        await this.firestorePersistenceMetadataRepository.initiateTracking(
          trackedObjectAsJson
        );
        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method addNewVersionToTracking Appends a new version of the tracked object to the tracking process.
   * @param {TEntityAsJson} trackedObjectAsJson A JSON version of the tracked object.
   * @returns {Promise<void>} A Promise, to provide asynchronicity, with no value (void)
   */
  addNewVersionToTracking(trackedObjectAsJson: TEntityAsJson): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        // forward the request to the PersistenceMetadataRepository object
        await this.firestorePersistenceMetadataRepository.appendNewVersion(
          trackedObjectAsJson
        );
        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/
}
