import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";
import { generateStandardEmailClosingPlainText, generateStandardEmailOpeningPlainText } from "../commonEmailBodyFragments";

/**
 * @function generateEmailBodyPlainTextForPasswordReset. Generates the Html body of a notification email for a password reset request
 * @param {string} emailAddress The email address of the user requesting the password reset.
 * @param {string} firstName The first name of the user requesting the password reset.
 * @param {string} firstName The last name of the user requesting the password reset.
 * @returns {string} A string with the Html body of the email message to be sent.
 */
export function generateEmailBodyPlainTextForPasswordReset(emailAddress: string, firstName: string, lastName: string): string {

  const recipientUserName: string = `${firstName} ${lastName}`;

  // *** Compose the email Body in Plain Text format ***
  // salutation/greetings to the recipient
  let emailBodyPlainText = generateStandardEmailOpeningPlainText(recipientUserName);

  // user's password reset request has been received
  emailBodyPlainText += `We have received a request to reset your ${ServerSubstitutionKeywordsStringAssets.ProjectName} password for your (${emailAddress}) email account.`;
  emailBodyPlainText += `You may proceed to reset your password by clicking on the password reset link that follows: \n`;
  emailBodyPlainText += `${ServerSubstitutionKeywordsStringAssets.PasswordResetLink} \n\n`;
  emailBodyPlainText += `Clicking on the link will re-direct you to a browser and allow you to reset your ${ServerSubstitutionKeywordsStringAssets.ProjectName} password. \n\n`;

  emailBodyPlainText += `If you did not request this password reset, you can safely ignore this email.\n\n`;

  emailBodyPlainText += `We hope you continue to enjoy using ${ServerSubstitutionKeywordsStringAssets.ProjectName}! \n\n`;

  emailBodyPlainText += generateStandardEmailClosingPlainText();

  // return the email content
  return emailBodyPlainText;
}
