

export function generateStandardEmailOpeningPlainText(userDisplayName: string): string {

  let emailBodyPlainText = '';

  // salutation/greetings to the recipient
  emailBodyPlainText = `Greetings ${userDisplayName}: \n\n`;

  return emailBodyPlainText;
}
