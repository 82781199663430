import { submitEmailRequestToServer } from ".";
import { IEmailRequest } from "../../../../dataObjects/models/emailRequests";
import { IObjectSharingRequestTracker } from "../../../../dataObjects/models/collaboration/ObjectSharingTracker";
import { generateEmailRequestForObjectSharingRequest } from "./objectSharingRequest";

/**
 * @function submitEmailRequestForObjectSharingRequest Generates an email request when a user is sharing an object (eg, a Channel)
 *                                                     with another user and submits to an appropriate Firebase Function on the server.
 * @param {IObjectSharingRequestTracker} objectSharingRequestTracker Object Sharing information with details needed for an email message
 * @returns {void} No return value
 */
export function submitEmailRequestForObjectSharingRequest(objectSharingRequestTracker: IObjectSharingRequestTracker): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // whether to display console logs (displayConsoleLogs && console.log statements)
      const displayConsoleLogs: boolean = false;

      displayConsoleLogs && console.log(`%c Entered submitEmailRequestForObjectSharingRequest()`, 'background: #055; color: #fff');

      // call method to generate the Object Sharing Request email request, which will return the Email Request
      const emailRequest: IEmailRequest = await generateEmailRequestForObjectSharingRequest(objectSharingRequestTracker);

      displayConsoleLogs && console.log(`%c In submitEmailRequestForObjectSharingRequest. Returned from call to generateEmailRequestForObjectSharingRequest. emailRequest: ${JSON.stringify(emailRequest)}`, 'background: #00B; color: #fff');

      // submit email request to the server
      await submitEmailRequestToServer(emailRequest);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}
