import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const fetchUserFeedbackFailureSlice = createSlice({
  name: 'fetchUserFeedbackFailure',
  initialState,
  reducers: {
    setFetchUserFeedbackFailure: (state: IMdbError | null, action: PayloadAction<IMdbError>) => {
      state = action.payload;
    },
    clearFetchUserFeedbackFailure: (state: IMdbError | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchUserFeedbackFailure, clearFetchUserFeedbackFailure } = fetchUserFeedbackFailureSlice.actions;

// export the core reducer for the slice
export const fetchUserFeedbackFailureReducer = fetchUserFeedbackFailureSlice.reducer;

export default fetchUserFeedbackFailureSlice.reducer;