import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { IUserCategories, IUserCategoriesAsJson } from '.';
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';
import { JsonConverter } from '../../../utilities/JsonConverter';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { Category, ICategory } from '../../categories/Category';
import { CategoryAssociation, ICategoryAssociation } from '../../categories/CategoryAssociation';


/**
 * @class UserCategories Represents a collection of channel of information (topics, topic items, digital media, etc.)
 * {@linkcode IUserCategories}
 */
export class UserCategories extends VersionAwarePersistable implements IUserCategories {

  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {Array<ICategory>} userCategories Categories associated with the user
   * @param {Array<ICategoryAssociation>} categoryAssociations Category associations for the user; that is, category objects associated with other object instances
   * @param {IUserPersistenceData} userPersistenceData (optional) User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    userCategories?: Array<ICategory>,
    categoryAssociations?: Array<ICategoryAssociation>,
    userPersistenceData?: IUserPersistenceData,
  ) {
    super(ownerId, enumPersistableObjectClassName.UserCategories, enumPersistableObjectType.UserCategories, id, parentObjectType, parentId, objectState, userPersistenceData);

    if (userCategories && userCategories.length > 0) {
      this._userCategories = [...userCategories];
    }

    if (categoryAssociations && categoryAssociations.length > 0) {
      this._categoryAssociations = [...categoryAssociations];
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Array<ICategory>} _userCategories Categories associated with the user
   */
  private _userCategories: Array<ICategory> = [];

  // getter for _userCategories
  get userCategories(): Array<ICategory> {
    return this._userCategories;
  }

  // setter for _userCategories
  set userCategories(value: Array<ICategory>) {
    this._userCategories = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Array<ICategoryAssociation>} _categoryAssociations Categories associated with the user
   */
  private _categoryAssociations: Array<ICategoryAssociation> = [];

  // getter for _categoryAssociations
  get categoryAssociations(): Array<ICategoryAssociation> {
    return this._categoryAssociations;
  }

  // setter for _categoryAssociations
  set categoryAssociations(value: Array<ICategoryAssociation>) {
    this._categoryAssociations = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method saveCategory Saves (adds or updates) a category in the collection of user categories. If the Category.id doesn't already exist, it will be added; 
   *                      otherwise, if it already exists, its information will be updated.
   * @param {ICategory} category The Category object to be saved to the collection of user categories.
   * @returns {boolean} Whether the category was saved to the user's collection of user categories. 
   */
  saveCategory(category: ICategory): boolean {
    let categorySaved = false;

    // determine whether the given category already exists within the userCategories array (by its Id)
    const idxCategoryId: number = this.userCategories.findIndex((existingCategory: ICategory) => existingCategory.id === category.id);
    // if the categoryId was not found in the array (if the returned index value === -1)
    if (idxCategoryId === -1) {
      // add the category to the userCategories array (using the Array.push() method)
      this.userCategories.push(category);
      categorySaved = true;
    } else {
      // the categoryId was found, so update the category object in the array
      this.userCategories[idxCategoryId] = category;
      categorySaved = true;
    }

    return categorySaved;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method removeCategory Removes a category from the collection of user categories.
   * @param {typeUniqueId} categoryId The Id of the category to be removed.
   * @returns {boolean} Whether the category was removed from the collection of user categories. 
   *                    (Note: If the category is not a member before calling this method, 'false' will be returned.)
   */
  removeCategory(categoryId: typeUniqueId): boolean {
    let categoryRemoved = false;

    // determine whether the given categoryId exists within the userCategories array
    const idxCategoryId: number = this.userCategories.findIndex((existingCategory: ICategory) => existingCategory.id === categoryId);
    // if the categoryId was found in the array (if the returned index value !== -1)
    if (idxCategoryId !== -1) {
      // remove the category from the userCategories array (using the Array.splice() method)
      this.userCategories.splice(idxCategoryId, 1);
      categoryRemoved = true;
    }

    return categoryRemoved;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method addCategoryAssociation Adds a category association to the collection of user category associations.
   * @param {ICategoryAssociation} categoryAssociation The category association to be added to the collection.
   * @returns {boolean} Whether the category association was added to the collection. (Note: If the category association is already in the collection, 'false' will be returned.)
   */
  addCategoryAssociation(categoryAssociation: ICategoryAssociation): boolean {
    let categoryAssociationSaved = false;

    // determine whether the given categoryAssociation already exists within the userCategories array (by its Id)
    const idxCategoryId: number = this.categoryAssociations.findIndex((existingCategoryAssociation: ICategoryAssociation) => existingCategoryAssociation.id === categoryAssociation.id);
    // if the categoryId was not found in the array (if the returned index value === -1)
    if (idxCategoryId === -1) {
      // add the categoryAssociation to the categoryAssociations array (using the Array.push() method)
      this.categoryAssociations.push(categoryAssociation);
      categoryAssociationSaved = true;
    } else {
      // the categoryAssociationId was found, so update the categoryAssociation object in the array
      this.categoryAssociations[idxCategoryId] = categoryAssociation;
      categoryAssociationSaved = true;
    }

    return categoryAssociationSaved;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method removeCategoryAssociation Removes a user category association from the collection of user category associations.
   * @param {typeUniqueId} categoryAssociationId The Id of the category association that is to be removed from the collection.
   * @returns {boolean} Whether the category association was removed. (Note: If the category association is not a member of the collection before calling this method, 'false' will be returned.)
   */
  removeCategoryAssociation(categoryAssociationId: typeUniqueId): boolean {
    let categoryAssociationRemoved = false;

    // determine whether the given categoryAssociationId exists within the userCategories array
    const idxCategoryAssociationId: number = this.categoryAssociations.findIndex((existingCategoryAssociation: ICategoryAssociation) => existingCategoryAssociation.id === categoryAssociationId);
    // if the categoryAssociationId was found in the array (if the returned index value !== -1)
    if (idxCategoryAssociationId !== -1) {
      // remove the categoryAssociation from the categoryAssociations array (using the Array.splice() method)
      this.categoryAssociations.splice(idxCategoryAssociationId, 1);
      categoryAssociationRemoved = true;
    }

    return categoryAssociationRemoved;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IUserCategories} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IUserCategories {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IUserCategories = Object.create(UserCategories.prototype);
    Object.assign(copyOfObject, this);

    // copy the contained objects
    if (this.userCategories !== undefined) {
      copyOfObject.userCategories = [...this.userCategories];
    }

    if (this.categoryAssociations !== undefined) {
      copyOfObject.categoryAssociations = [...this.categoryAssociations];
    }

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IUserCategoriesAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IUserCategoriesAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.userCategories = [...this.userCategories];
      jsonObject.categoryAssociations = [...this.categoryAssociations];

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IUserCategoriesAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A ImageLink instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IUserCategoriesAsJson, includeContainedObjects: boolean = true): IUserCategories {
    try {
      // create a new instance of this class
      let userCategoriesObject: UserCategories = Object.create(UserCategories.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      userCategoriesObject = super.fromJSONProtected(userCategoriesObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.userCategories !== undefined) {
        userCategoriesObject.userCategories = JsonConverter.arrayFromJSONArray(Category, jsonObject.userCategories);
      }

      if (jsonObject.categoryAssociations !== undefined) {
        userCategoriesObject.categoryAssociations = JsonConverter.arrayFromJSONArray(CategoryAssociation, jsonObject.categoryAssociations);
      }

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
      }

      return userCategoriesObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
