import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";
import { generateStandardEmailClosingPlainText, generateStandardEmailOpeningPlainText } from "../commonEmailBodyFragments";

/**
 * @function generateEmailBodyPlainTextForRecoverUserEmailAddress. Generates the Plain Text body of a notification email allowing to recover (reverted) from a changed email address
 * @param {string} recoveredToEmailAddress The new (recovered to) email address of the user.
 * @param {string} firstName The first name of the target of the email.
 * @param {string} lastName The last name of the target of the email.
 * @param {string} priorEmailAddress The old (prior) email address prior to recovery (reverting).
 * @returns {string} A string with the Plain Text body of the email message to be sent.
 */
export function generateEmailBodyPlainTextForRecoverUserEmailAddress(recoveredToEmailAddress: string, firstName: string, lastName: string, priorEmailAddress: string): string {

  const recipientUserName: string = `${firstName} ${lastName}`;

  // *** Compose the email Body in Plain Text format ***
  // salutation/greetings to the recipient
  let emailBodyPlainText = generateStandardEmailOpeningPlainText(recipientUserName);

  // user's email address has been recovered (reverted)...
  emailBodyPlainText += `We have processed a request to recover the email address for your ${ServerSubstitutionKeywordsStringAssets.ProjectName} account, reverting it to ${recoveredToEmailAddress} from ${priorEmailAddress}.`;

  emailBodyPlainText += `${recoveredToEmailAddress} is now the email address for your account.\n\n`;

  emailBodyPlainText += `We hope you continue to enjoy using ${ServerSubstitutionKeywordsStringAssets.ProjectName}! \n\n`;

  emailBodyPlainText += generateStandardEmailClosingPlainText();

  // return the email content
  return emailBodyPlainText;
}
