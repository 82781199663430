import { IUserFeedback } from "../../../../../dataObjects/models/userFeedback";

/**
 * @function generateEmailSubjectForUserFeedback Generates the Subject of a notification email based on a user submitting feedback
 * @param {IUserFeedback} userFeedback UserFeedback information.
 * @returns {string} A string with the Subject of the email message to be sent.
 */
export function generateEmailSubjectForUserFeedback(userFeedback: IUserFeedback): string {

  // *** Compose the email Subject ***
  const emailSubject = `User Feedback (from ${userFeedback.submitterName} -- UserId: ${userFeedback.userId}))`;

  // return the email content
  return emailSubject;
}
