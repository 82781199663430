import React, { useState, useEffect, ChangeEvent, PropsWithChildren } from 'react';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { useParams } from "react-router";
import { typeUniqueId } from '../../../dataObjects/types';
import { useTheme } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, BoxProps, Tab, Tabs, TabsProps, useMediaQuery } from '@mui/material';
import { ControlsStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import {
  Description as DocumentIcon,
  Image as ImageIcon,
  Link as HyperLinkIcon,
  Note as NoteIcon,
  ConnectWithoutContact as SocialMediaIcon,
  VideoLibrary as VideoLibraryIcon,
  VolumeUp as AudioClipIcon,
} from '@mui/icons-material';
import { IDigitalMedia, DigitalMedia, IDigitalMediaAsJson } from '../../../dataObjects/models/digitalMedia/DigitalMedia';
import { getDigitalMediaBaseForParentId_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/digitalMediaActions';
import { JsonConverter } from '../../../dataObjects/utilities/JsonConverter';
import AudioLinksView from '../../views/audioLinks/AudioLinksView/AudioLinksView';
import HyperLinksView from '../../views/hyperLinks/HyperLinksView/HyperLinksView';
import NotesView from '../../views/notes/NotesView/NotesView';
import ImageLinksView from '../../views/imageLinks/ImageLinksView/ImageLinksView';
import VideoLinksView from '../../views/videoLinks/VideoLinksView/VideoLinksView';
import { enumDigitalMediaType, enumPersistableObjectType } from '../../../dataObjects/enums';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { styled } from '@mui/styles';
import { getImageLinksForParentId_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/imageLinkActions';
import { IImageLink, IImageLinkAsJson, ImageLink } from '../../../dataObjects/models/digitalMedia/ImageLink';
import _ from 'lodash';
import { IVideoLink, IVideoLinkAsJson, VideoLink } from '../../../dataObjects/models/digitalMedia/VideoLink';
import { getVideoLinksForParentId_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/videoLinkActions';
import { AudioLink, IAudioLink, IAudioLinkAsJson } from '../../../dataObjects/models/digitalMedia/AudioLink';
import { getAudioLinksForParentId_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/audioLinkActions';
import { INote, INoteAsJson, Note } from '../../../dataObjects/models/digitalMedia/Note';
import { getNotesForParentId_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/noteActions';
import { HyperLink, IHyperLink, IHyperLinkAsJson } from '../../../dataObjects/models/digitalMedia/HyperLink';
import { getHyperLinksForParentId_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/hyperLinkActions';
import { AccessPermissions, IAccessPermissions, IUserAccessPermissionsForObject, UserAccessPermissionsForObject } from '../../../dataObjects/models/collaboration/ObjectUserPermissions';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import { IUser } from '../../../dataObjects/models/users/User';
import { IUserCategoriesAndChannelsContextData, useUserCategoriesAndChannelsContext } from '../../providersAndContexts/userCategoriesAndChannels';
import { IChannel } from '../../../dataObjects/models/channels/Channel';
import { enumCategoriesAndChannelsDataPreparationStatus } from '../../enums';
import { DocumentLink, IDocumentLink, IDocumentLinkAsJson } from '../../../dataObjects/models/digitalMedia/DocumentLink';
import { getDocumentLinksForParentId_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/documentLinkActions';
import DocumentLinksView from '../../views/documentLinks/DocumentLinksView/DocumentLinksView';
import { IImageLinkViewModel, ImageLinkViewModel } from '../../../dataObjects/viewModels/imageLinkViewModel';
import { ITopicItem, ITopicItemAsJson, TopicItem } from '../../../dataObjects/models/topics/TopicItem';
import { ITopic, ITopicAsJson, Topic } from '../../../dataObjects/models/topics/Topic';
import { getSingleObjectById_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { IVideoLinkViewModel, VideoLinkViewModel } from '../../../dataObjects/viewModels/videoLinkViewModel';
import { AudioLinkViewModel, IAudioLinkViewModel } from '../../../dataObjects/viewModels/audioLinkViewModel';
import { DocumentLinkViewModel, IDocumentLinkViewModel } from '../../../dataObjects/viewModels/documentLinkViewModel';
import { NoteViewModel, INoteViewModel } from '../../../dataObjects/viewModels/noteViewModel';
import { HyperLinkViewModel, IHyperLinkViewModel } from '../../../dataObjects/viewModels/hyperLinkViewModel';
import SocialMediaPostsView from '../../views/socialMediaPosts/SocialMediaPostsView/SocialMediaPostsView';
import { ISocialMediaPost, ISocialMediaPostAsJson, SocialMediaPost } from '../../../dataObjects/models/digitalMedia/SocialMediaPost';
import { ISocialMediaPostViewModel, SocialMediaPostViewModel } from '../../../dataObjects/viewModels/socialMediaPostViewModel';
import { getSocialMediaPostsForParentId_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/socialMediaPostActions';
import { useAppDispatch, useAppSelector } from '../../../uiMiddleware-redux/store/configureStore';
import { setSelectedDigitalMediaType } from '../../../uiMiddleware-redux/slices/digitalMedia/selectedDigitalMediaTypeSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area of padding at the top of the page
const StyledBoxForPaddingAtopPage = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

// a styled Tabs object for displaying different Digital Media options
const StyledTabsForDigitalMediaOptions = styled((props: TabsProps) => (
  <Tabs
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
}));

/**
 * @interface IDigitalMediaPageProps Input properties for the DigitalMediaPage
 */
export interface IDigitalMediaPageProps extends PropsWithChildren<unknown> {
  parentId?: typeUniqueId  // the Id of the parent for the DigitalMedia
}

const DigitalMediaPage: React.FC<IDigitalMediaPageProps> = (props: IDigitalMediaPageProps) => {

  DigitalMediaPage.displayName = 'Digital Media Page';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  // console.log(`Render DigitalMediaPage`);

  const dispatch = useAppDispatch();

  const params = useParams();

  // determine if the screen width is currently small ('sm') or smaller, and then prepare display information accordingly
  const theme: Theme = useTheme();
  const onlySmallScreen: boolean = useMediaQuery(theme.breakpoints.down('sm'));
  const tabsDisplayFormat: 'scrollable' | 'fullWidth' | 'standard' | undefined = onlySmallScreen ? 'scrollable' : 'fullWidth'; // if small screen, make Tabs scrollable; otherwise, full width for Tabs
  // if small screen, don't show a label for the Tabs; if larger, include a label for Tabs
  const tabLabelForImages: string = onlySmallScreen ? '' : ControlsStringAssets.digitalMediaTab_Images;
  const tabLabelForVideos: string = onlySmallScreen ? '' : ControlsStringAssets.digitalMediaTab_Videos;
  const tabLabelForAudioClips: string = onlySmallScreen ? '' : ControlsStringAssets.digitalMediaTab_AudioClips;
  const tabLabelForDocuments: string = onlySmallScreen ? '' : ControlsStringAssets.digitalMediaTab_Documents;
  const tabLabelForNotes: string = onlySmallScreen ? '' : ControlsStringAssets.digitalMediaTab_Notes;
  const tabLabelForSocialMedia: string = onlySmallScreen ? '' : ControlsStringAssets.digitalMediaTab_SocialMedia;
  const tabLabelForHyperlinks: string = onlySmallScreen ? '' : ControlsStringAssets.digitalMediaTab_Hyperlinks;

  const [digitalMediaBaseObj, setDigitalMediaBaseObj] = useState<IDigitalMedia | undefined>(undefined);

  const [ancestorTopicItem, setAancestorTopicItem] = useState<ITopicItem | undefined>(undefined);

  const [ancestorTopic, setAancestorTopic] = useState<ITopic | undefined>(undefined);

  // get which Digital Media type is currently set in state, so that we'll know which tab to select in the UI
  const selectedDigitalMediaTab: enumDigitalMediaType = useAppSelector((state: IStoreState) => state.selectedDigitalMediaType);

  const parentId = params.parentId; // from the path '/:parentId/digitalMedia

  // the AudioLinks associated with the current Digital Media
  const [audioLinks, setAudioLinks] = useState<Array<IAudioLink>>([]);
  // the AudioLinkViewModels associated with the current Digital Media
  const [audioLinkViewModels, setAudioLinkViewModels] = useState<Array<IAudioLinkViewModel>>([]);
  // whether AudioLinks data is currently loading
  const [audioLinksDataLoading, setAudioLinksDataLoading] = useState<boolean>(true);

  // the DocumentLinks associated with the current Digital Media
  const [documentLinks, setDocumentLinks] = useState<Array<IDocumentLink>>([]);
  // the DocumentLinkViewModels associated with the current Digital Media
  const [documentLinkViewModels, setDocumentLinkViewModels] = useState<Array<IDocumentLinkViewModel>>([]);
  // whether DocumentLinks data is currently loading
  const [documentLinksDataLoading, setDocumentLinksDataLoading] = useState<boolean>(true);

  // the HyperLinks associated with the current Digital Media
  const [hyperLinks, setHyperLinks] = useState<Array<IHyperLink>>([]);
  // the HyperLinkViewModels associated with the current Digital Media
  const [hyperLinkViewModels, setHyperLinkViewModels] = useState<Array<IHyperLinkViewModel>>([]);
  // whether HyperLinks data is currently loading
  const [hyperLinksDataLoading, setHyperLinksDataLoading] = useState<boolean>(true);

  // the ImageLinks associated with the current Digital Media
  const [imageLinks, setImageLinks] = useState<Array<IImageLink>>([]);
  // the ImageLinkViewModels associated with the current Digital Media
  const [imageLinkViewModels, setImageLinkViewModels] = useState<Array<IImageLinkViewModel>>([]);
  // whether ImageLinks data is currently loading
  const [imageLinksDataLoading, setImageLinksDataLoading] = useState<boolean>(true);

  // the Notes associated with the current Digital Media
  const [notes, setNotes] = useState<Array<INote>>([]);
  // the NoteViewModels associated with the current Digital Media
  const [noteViewModels, setNoteViewModels] = useState<Array<INoteViewModel>>([]);
  // whether NoteLinks data is currently loading
  const [notesDataLoading, setNotesDataLoading] = useState<boolean>(true);

  // the SocialMediaPosts associated with the current Digital Media
  const [socialMediaPosts, setSocialMediaPosts] = useState<Array<ISocialMediaPost>>([]);
  // the SocialMediaPostViewModels associated with the current Digital Media
  const [socialMediaPostViewModels, setSocialMediaPostViewModels] = useState<Array<ISocialMediaPostViewModel>>([]);
  // whether SocialMediaPosts data is currently loading
  const [socialMediaPostsDataLoading, setSocialMediaPostsDataLoading] = useState<boolean>(true);

  // the VideoLinks associated with the current Digital Media
  const [videoLinks, setVideoLinks] = useState<Array<IVideoLink>>([]);
  // the VideoLinkViewModels associated with the current Digital Media
  const [videoLinkViewModels, setVideoLinkViewModels] = useState<Array<IVideoLinkViewModel>>([]);
  // whether VideoLinks data is currently loading
  const [videoLinksDataLoading, setVideoLinksDataLoading] = useState<boolean>(true);

  // to assess the status of data preparation for user categories and channels
  const [userCategoriesAndChannelsDataPrepStatus, SetUserCategoriesAndChannelsDataPrepStatus] = useState<enumCategoriesAndChannelsDataPreparationStatus>(enumCategoriesAndChannelsDataPreparationStatus.None);

  const [userPermissionsForCurrentChannel, setUserPermissionsForCurrentChannel] = useState<IUserAccessPermissionsForObject | undefined>(undefined);

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c DigitalMediaPage. currentUser: \n${JSON.stringify(currentUserContextData)}`, 'background: #006; color: #fff');
  const currentUser: IUser | undefined = currentUserContextData.currentUser;
  const userId: typeUniqueId | undefined = currentUser?.id;

  // get UserCategoriesAndChannelsContextData from the UserCategoriesAndChannelsProvider using a custom hook (useUserCategoriesAndChannelsContext)
  const userCategoriesAndChannelsContext: IUserCategoriesAndChannelsContextData = useUserCategoriesAndChannelsContext();
  const currentChannel: IChannel | undefined = userCategoriesAndChannelsContext.state.currentChannel;
  displayConsoleLogs && console.log(`%c DigitalMediaPage. currentChannel: \n${JSON.stringify(currentChannel)}`, 'background: #006; color: #fff');
  if (userCategoriesAndChannelsDataPrepStatus !== userCategoriesAndChannelsContext.state.status) {
    SetUserCategoriesAndChannelsDataPrepStatus(userCategoriesAndChannelsContext.state.status);
  }

  // a useEffect hook for to evaluate changes to the userCategoriesAndChannelsDataPrepStatus state variable
  useEffect(() => {
    // If there is no currentChannel specified in the Categories & Channels context, inform the context
    // to resolve the current channel from the Id of the Channel that has been passed to this component as the parentId.
    // (The currentChannel in the context could be undefined if the browser was refreshed after landing on this page.)
    if ((userCategoriesAndChannelsContext.state.currentChannel === undefined) && (userCategoriesAndChannelsContext.actions.resolveCurrentChannel !== undefined) && (parentId !== undefined)) {
      userCategoriesAndChannelsContext.actions.resolveCurrentChannel(parentId, enumPersistableObjectType.TopicItem);
    }
  }, [userCategoriesAndChannelsDataPrepStatus]);

  // a useEffect hook for to evaluate changes to the currentChannel variable
  useEffect(() => {
    // Determine and set the user's permissions relative to the current channel
    setUserPermissionsForCurrentChannel(currentUser && currentChannel ? new UserAccessPermissionsForObject(currentUser.id, currentChannel) : undefined);
  }, [currentChannel, currentUser]);

  // a useEffect hook for to evaluate changes to the userPermissionsForCurrentChannel & audioLinkViewModels state variables
  useEffect(() => {
    // prepare an IAccessPermissions object that correlates to the userPermissionsForCurrentChannel
    let accessPermissions: IAccessPermissions = new AccessPermissions(); // start with default of no permissions

    if (userPermissionsForCurrentChannel) {
      accessPermissions = { ...userPermissionsForCurrentChannel };
      accessPermissions.hasEditPermission = userPermissionsForCurrentChannel.hasEditPermission;
      accessPermissions.hasAdminPermission = userPermissionsForCurrentChannel.hasAdminPermission;
    }

    // associate the permissions with each of the audioLinksViewModel objects
    audioLinkViewModels.forEach(viewModel => {
      viewModel.accessPermissionToAncestorChannel = { ...accessPermissions };
    });
  }, [userPermissionsForCurrentChannel, audioLinkViewModels]);

  // a useEffect hook for to evaluate changes to the userPermissionsForCurrentChannel & documentLinkViewModels state variables
  useEffect(() => {
    // prepare an IAccessPermissions object that correlates to the userPermissionsForCurrentChannel
    let accessPermissions: IAccessPermissions = new AccessPermissions(); // start with default of no permissions

    if (userPermissionsForCurrentChannel) {
      accessPermissions = { ...userPermissionsForCurrentChannel };
      accessPermissions.hasEditPermission = userPermissionsForCurrentChannel.hasEditPermission;
      accessPermissions.hasAdminPermission = userPermissionsForCurrentChannel.hasAdminPermission;
    }

    // associate the permissions with each of the documentLinksViewModel objects
    documentLinkViewModels.forEach(viewModel => {
      viewModel.accessPermissionToAncestorChannel = { ...accessPermissions };
    });
  }, [userPermissionsForCurrentChannel, documentLinkViewModels]);

  // a useEffect hook for to evaluate changes to the userPermissionsForCurrentChannel & hyperLinkViewModels state variables
  useEffect(() => {
    // prepare an IAccessPermissions object that correlates to the userPermissionsForCurrentChannel
    let accessPermissions: IAccessPermissions = new AccessPermissions(); // start with default of no permissions

    if (userPermissionsForCurrentChannel) {
      accessPermissions = { ...userPermissionsForCurrentChannel };
      accessPermissions.hasEditPermission = userPermissionsForCurrentChannel.hasEditPermission;
      accessPermissions.hasAdminPermission = userPermissionsForCurrentChannel.hasAdminPermission;
    }

    // associate the permissions with each of the hyperLinksViewModel objects
    hyperLinkViewModels.forEach(viewModel => {
      viewModel.accessPermissionToAncestorChannel = { ...accessPermissions };
    });
  }, [userPermissionsForCurrentChannel, hyperLinkViewModels]);

  // a useEffect hook for to evaluate changes to the userPermissionsForCurrentChannel & imageLinkViewModels state variables
  useEffect(() => {
    // prepare an IAccessPermissions object that correlates to the userPermissionsForCurrentChannel
    let accessPermissions: IAccessPermissions = new AccessPermissions(); // start with default of no permissions

    if (userPermissionsForCurrentChannel) {
      accessPermissions = { ...userPermissionsForCurrentChannel };
      accessPermissions.hasEditPermission = userPermissionsForCurrentChannel.hasEditPermission;
      accessPermissions.hasAdminPermission = userPermissionsForCurrentChannel.hasAdminPermission;
    }

    // associate the permissions with each of the imageLinksViewModel objects
    imageLinkViewModels.forEach(viewModel => {
      viewModel.accessPermissionToAncestorChannel = { ...accessPermissions };
    });
  }, [userPermissionsForCurrentChannel, imageLinkViewModels]);

  // a useEffect hook for to evaluate changes to the userPermissionsForCurrentChannel & noteViewModels state variables
  useEffect(() => {
    // prepare an IAccessPermissions object that correlates to the userPermissionsForCurrentChannel
    let accessPermissions: IAccessPermissions = new AccessPermissions(); // start with default of no permissions

    if (userPermissionsForCurrentChannel) {
      accessPermissions = { ...userPermissionsForCurrentChannel };
      accessPermissions.hasEditPermission = userPermissionsForCurrentChannel.hasEditPermission;
      accessPermissions.hasAdminPermission = userPermissionsForCurrentChannel.hasAdminPermission;
    }

    // associate the permissions with each of the notesViewModel objects
    noteViewModels.forEach(viewModel => {
      viewModel.accessPermissionToAncestorChannel = { ...accessPermissions };
    });
  }, [userPermissionsForCurrentChannel, noteViewModels]);

  // a useEffect hook for to evaluate changes to the userPermissionsForCurrentChannel & videoLinkViewModels state variables
  useEffect(() => {
    // prepare an IAccessPermissions object that correlates to the userPermissionsForCurrentChannel
    let accessPermissions: IAccessPermissions = new AccessPermissions(); // start with default of no permissions

    if (userPermissionsForCurrentChannel) {
      accessPermissions = { ...userPermissionsForCurrentChannel };
      accessPermissions.hasEditPermission = userPermissionsForCurrentChannel.hasEditPermission;
      accessPermissions.hasAdminPermission = userPermissionsForCurrentChannel.hasAdminPermission;
    }

    // associate the permissions with each of the videoLinkViewModel objects
    videoLinkViewModels.forEach(viewModel => {
      viewModel.accessPermissionToAncestorChannel = { ...accessPermissions };
    });
  }, [userPermissionsForCurrentChannel, videoLinkViewModels]);

  // set up a useEffect() hook to get the digitalMedia object for the given parentId and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    if (parentId) {
      // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
      getDigitalMediaBaseForParentId_onSnapshot(parentId, onSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallback = unsubscribe;
        // console.log(`DigitalMediaPage: after initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);
      });
    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the onSnapshot 
      unsubscribeCallback();
    }
  }, [parentId])


  /**
   * @function onSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // get the documents that changed (there should one, and only one, DigitalMedia document)
    const digitalMediaChangesAsJson: Array<IDigitalMediaAsJson> = [];
    snapshot.forEach(doc => digitalMediaChangesAsJson.push({ ...doc.data(), id: doc.id } as IDigitalMediaAsJson));

    if (digitalMediaChangesAsJson.length === 1) {
      const digitalMedia: IDigitalMedia = JsonConverter.fromJSON(DigitalMedia, digitalMediaChangesAsJson[0], false);
      setDigitalMediaBaseObj(digitalMedia);
    }
    // if !==1, we need to do something to flag a problem, since we need the DigitalMedia base object to proceed
  }

  // set up a useEffect() hook to get the Ancestor TopicItem for the given digitalMediaBaseObj and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    if (digitalMediaBaseObj && digitalMediaBaseObj.id && digitalMediaBaseObj.parentId) {

      // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
      getSingleObjectById_onSnapshot(digitalMediaBaseObj.parentId, enumPersistableObjectType.TopicItem, onAncestorTopicItemSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallback = unsubscribe;
      });
    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the onSnapshot 
      unsubscribeCallback();
    }
  }, [digitalMediaBaseObj])

  /**
   * @function onAncestorTopicItemSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onAncestorTopicItemSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // get the TopicItem document (there should one, and only one, TopicItem document)
    const topicItemDataAsJson: Array<ITopicItemAsJson> = [];

    snapshot.forEach(doc => topicItemDataAsJson.push({ ...doc.data(), id: doc.id } as ITopicItemAsJson));

    if (topicItemDataAsJson.length === 1) {
      const topicItem: ITopicItem = JsonConverter.fromJSON(TopicItem, topicItemDataAsJson[0], false);
      setAancestorTopicItem(topicItem);
    }
    // if !==1, we need to do something to flag a problem, since we need the DigitalMedia base object to proceed
  }

  // set up a useEffect() hook to get the Ancestor TopicItem for the given digitalMediaBaseObj and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    if (ancestorTopicItem && ancestorTopicItem.parentId) {

      // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
      getSingleObjectById_onSnapshot(ancestorTopicItem.parentId, enumPersistableObjectType.Topic, onAncestorTopicSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallback = unsubscribe;
      });
    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the onSnapshot 
      unsubscribeCallback();
    }
  }, [ancestorTopicItem])

  /**
   * @function onAncestorTopicSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onAncestorTopicSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // get the TopicItem document (there should one, and only one, TopicItem document)
    const topicDataAsJson: Array<ITopicItemAsJson> = [];

    snapshot.forEach(doc => topicDataAsJson.push({ ...doc.data(), id: doc.id } as ITopicAsJson));

    if (topicDataAsJson.length === 1) {
      const topic: ITopic = JsonConverter.fromJSON(Topic, topicDataAsJson[0], false);
      setAancestorTopic(topic);
    }
    // if !==1, we need to do something to flag a problem, since we need the DigitalMedia base object to proceed
  }


  // set up a useEffect() hook to get the AudioLinks for the given digitalMediaBaseObj and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    if (digitalMediaBaseObj && digitalMediaBaseObj.id) {

      // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
      getAudioLinksForParentId_onSnapshot(digitalMediaBaseObj.id, onAudioLinksSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallback = unsubscribe;
      });

      // set indicator that data is being loaded
      setAudioLinksDataLoading(true);
    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the onSnapshot 
      unsubscribeCallback();
    }
  }, [digitalMediaBaseObj])

  /**
   * @function onAudioLinksSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onAudioLinksSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // console.log(`AudioLinksView:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setAudioLinksDataLoading(false);
    const audioLinkDataAsJson: Array<IAudioLinkAsJson> = [];
    // console.log(`AudioLinksView:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => audioLinkDataAsJson.push({ ...doc.data(), id: doc.id } as IAudioLinkAsJson));
    // console.log(`AudioLinksView:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of AudioLink objects from the JSON data
    let audioLinksData: Array<IAudioLink> = JsonConverter.arrayFromJSONArray(AudioLink, audioLinkDataAsJson);

    // use lodash to sort the array by 'description' in ascending order
    audioLinksData = _.orderBy(audioLinksData, [audioLink => audioLink.description.toUpperCase()], ['asc']);

    setAudioLinks(audioLinksData);
  }


  // set up a useEffect() hook to get the DocumentLinks for the given digitalMediaBaseObj and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    if (digitalMediaBaseObj && digitalMediaBaseObj.id) {

      // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
      getDocumentLinksForParentId_onSnapshot(digitalMediaBaseObj.id, onDocumentLinksSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallback = unsubscribe;
      });

      // set indicator that data is being loaded
      setDocumentLinksDataLoading(true);
    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the onSnapshot 
      unsubscribeCallback();
    }
  }, [digitalMediaBaseObj])

  /**
   * @function onDocumentLinksSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onDocumentLinksSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // console.log(`DocumentLinksView:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setDocumentLinksDataLoading(false);
    const documentLinkDataAsJson: Array<IDocumentLinkAsJson> = [];
    // console.log(`DocumentLinksView:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => documentLinkDataAsJson.push({ ...doc.data(), id: doc.id } as IDocumentLinkAsJson));
    // console.log(`DocumentLinksView:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of DocumentLink objects from the JSON data
    let documentLinksData: Array<IDocumentLink> = JsonConverter.arrayFromJSONArray(DocumentLink, documentLinkDataAsJson);

    // use lodash to sort the array by 'description' in ascending order
    documentLinksData = _.orderBy(documentLinksData, [documentLink => documentLink.description.toUpperCase()], ['asc']);

    setDocumentLinks(documentLinksData);
  }

  // set up a useEffect() hook to get the HyperLinks for the given digitalMediaBaseObj and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    if (digitalMediaBaseObj && digitalMediaBaseObj.id) {

      // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
      getHyperLinksForParentId_onSnapshot(digitalMediaBaseObj.id, onHyperLinksSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallback = unsubscribe;
      });

      // set indicator that data is being loaded
      setHyperLinksDataLoading(true);
    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the onSnapshot 
      unsubscribeCallback();
    }
  }, [digitalMediaBaseObj])


  /**
   * @function onHyperLinksSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onHyperLinksSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // console.log(`HyperLinksView:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setHyperLinksDataLoading(false);
    const hyperLinkDataAsJson: Array<IHyperLinkAsJson> = [];
    // console.log(`HyperLinksView:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => hyperLinkDataAsJson.push({ ...doc.data(), id: doc.id } as IHyperLinkAsJson));
    // console.log(`HyperLinksView:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of HyperLink objects from the JSON data
    let hyperLinksData: Array<IHyperLink> = JsonConverter.arrayFromJSONArray(HyperLink, hyperLinkDataAsJson);

    // use lodash to sort the array by 'description' in ascending order
    hyperLinksData = _.orderBy(hyperLinksData, [hyperLink => hyperLink.description.toUpperCase()], ['asc']);

    setHyperLinks(hyperLinksData);
  }

  // set up a useEffect() hook to get the ImageLinks for the given digitalMediaBaseObj and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    if (digitalMediaBaseObj && digitalMediaBaseObj.id) {

      // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
      getImageLinksForParentId_onSnapshot(digitalMediaBaseObj.id, onImageLinksSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallback = unsubscribe;
      });

      // set indicator that data is being loaded
      setImageLinksDataLoading(true);
    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the onSnapshot 
      unsubscribeCallback();
    }
  }, [digitalMediaBaseObj])


  /**
   * @function onImageLinksSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onImageLinksSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // console.log(`ImageLinksView:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setImageLinksDataLoading(false);
    const imageLinkDataAsJson: Array<IImageLinkAsJson> = [];
    // console.log(`ImageLinksView:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => imageLinkDataAsJson.push({ ...doc.data(), id: doc.id } as IImageLinkAsJson));
    // console.log(`ImageLinksView:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of ImageLink objects from the JSON data
    let imageLinksData: Array<IImageLink> = JsonConverter.arrayFromJSONArray(ImageLink, imageLinkDataAsJson);

    // use lodash to sort the array by 'description' in ascending order
    imageLinksData = _.orderBy(imageLinksData, [imageLink => imageLink.description.toUpperCase()], ['asc']);

    setImageLinks(imageLinksData);
  }
  
  // set up a useEffect() hook to get the SocialMediaPosts for the given digitalMediaBaseObj and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    if (digitalMediaBaseObj && digitalMediaBaseObj.id) {

      // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
      getSocialMediaPostsForParentId_onSnapshot(digitalMediaBaseObj.id, onSocialMediaPostsSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallback = unsubscribe;
      });

      // set indicator that data is being loaded
      setSocialMediaPostsDataLoading(true);
    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the onSnapshot 
      unsubscribeCallback();
    }
  }, [digitalMediaBaseObj])


  /**
   * @function onSocialMediaPostsSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onSocialMediaPostsSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // console.log(`SocialMediaPostsView:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setSocialMediaPostsDataLoading(false);
    const socialMediaPostDataAsJson: Array<ISocialMediaPostAsJson> = [];
    // console.log(`SocialMediaPostsView:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => socialMediaPostDataAsJson.push({ ...doc.data(), id: doc.id } as ISocialMediaPostAsJson));
    // console.log(`SocialMediaPostsView:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of SocialMediaPost objects from the JSON data
    let socialMediaPostsData: Array<ISocialMediaPost> = JsonConverter.arrayFromJSONArray(SocialMediaPost, socialMediaPostDataAsJson);

    // use lodash to sort the array by 'description' in ascending order
    socialMediaPostsData = _.orderBy(socialMediaPostsData, [socialMediaPost => socialMediaPost.description.toUpperCase()], ['asc']);

    setSocialMediaPosts(socialMediaPostsData);
  }


  // set up a useEffect() hook to prepare the AudioLinksViewModel data when source data becomes available or changes
  useEffect(() => {
    let audioLinkViewModelsData: Array<IAudioLinkViewModel> = [];

    // ensure that all necessary data is available to set up the viewModel data
    if ((audioLinks && (audioLinks.length > 0)) &&
      (digitalMediaBaseObj && digitalMediaBaseObj.parentId) &&
      (ancestorTopicItem && ancestorTopicItem.parentId) &&
      (ancestorTopic && (ancestorTopic.parentId !== undefined)) &&
      (userPermissionsForCurrentChannel)
    ) {
      const accessPermissions: IAccessPermissions = new AccessPermissions(userPermissionsForCurrentChannel.hasViewPermission,
        userPermissionsForCurrentChannel.hasEditPermission,
        userPermissionsForCurrentChannel.hasAdminPermission);
      const ancestorChannelId: typeUniqueId = ancestorTopic.parentId;
      audioLinks.forEach((audioLink: IAudioLink) => {
        const audioLinkViewModel: IAudioLinkViewModel = new AudioLinkViewModel(audioLink, ancestorTopicItem.id, ancestorTopic.id, ancestorChannelId, accessPermissions);
        audioLinkViewModelsData.push(audioLinkViewModel);
      });
    }

    setAudioLinkViewModels(audioLinkViewModelsData);
  }, [audioLinks, digitalMediaBaseObj, ancestorTopicItem, ancestorTopic, userPermissionsForCurrentChannel]);

  // set up a useEffect() hook to prepare the DocumentLinksViewModel data when source data becomes available or changes
  useEffect(() => {
    let documentLinkViewModelsData: Array<IDocumentLinkViewModel> = [];

    // ensure that all necessary data is available to set up the viewModel data
    if ((documentLinks && (documentLinks.length > 0)) &&
      (digitalMediaBaseObj && digitalMediaBaseObj.parentId) &&
      (ancestorTopicItem && ancestorTopicItem.parentId) &&
      (ancestorTopic && (ancestorTopic.parentId !== undefined)) &&
      (userPermissionsForCurrentChannel)
    ) {
      const accessPermissions: IAccessPermissions = new AccessPermissions(userPermissionsForCurrentChannel.hasViewPermission,
        userPermissionsForCurrentChannel.hasEditPermission,
        userPermissionsForCurrentChannel.hasAdminPermission);
      const ancestorChannelId: typeUniqueId = ancestorTopic.parentId;
      documentLinks.forEach((documentLink: IDocumentLink) => {
        const documentLinkViewModel: IDocumentLinkViewModel = new DocumentLinkViewModel(documentLink, ancestorTopicItem.id, ancestorTopic.id, ancestorChannelId, accessPermissions);
        documentLinkViewModelsData.push(documentLinkViewModel);
      });
    }

    setDocumentLinkViewModels(documentLinkViewModelsData);
  }, [documentLinks, digitalMediaBaseObj, ancestorTopicItem, ancestorTopic, userPermissionsForCurrentChannel]);

  // set up a useEffect() hook to prepare the HyperLinksViewModel data when source data becomes available or changes
  useEffect(() => {
    let hyperLinkViewModelsData: Array<IHyperLinkViewModel> = [];

    // ensure that all necessary data is available to set up the viewModel data
    if ((hyperLinks && (hyperLinks.length > 0)) &&
      (digitalMediaBaseObj && digitalMediaBaseObj.parentId) &&
      (ancestorTopicItem && ancestorTopicItem.parentId) &&
      (ancestorTopic && (ancestorTopic.parentId !== undefined)) &&
      (userPermissionsForCurrentChannel)
    ) {
      const accessPermissions: IAccessPermissions = new AccessPermissions(userPermissionsForCurrentChannel.hasViewPermission,
        userPermissionsForCurrentChannel.hasEditPermission,
        userPermissionsForCurrentChannel.hasAdminPermission);
      const ancestorChannelId: typeUniqueId = ancestorTopic.parentId;
      hyperLinks.forEach((hyperLink: IHyperLink) => {
        const hyperLinkViewModel: IHyperLinkViewModel = new HyperLinkViewModel(hyperLink, ancestorTopicItem.id, ancestorTopic.id, ancestorChannelId, accessPermissions);
        hyperLinkViewModelsData.push(hyperLinkViewModel);
      });
    }

    setHyperLinkViewModels(hyperLinkViewModelsData);
  }, [hyperLinks, digitalMediaBaseObj, ancestorTopicItem, ancestorTopic, userPermissionsForCurrentChannel]);

  // set up a useEffect() hook to prepare the ImageLinksViewModel data when source data becomes available or changes
  useEffect(() => {
    let imageLinkViewModelsData: Array<IImageLinkViewModel> = [];

    // ensure that all necessary data is available to set up the viewModel data
    if ((imageLinks && (imageLinks.length > 0)) &&
      (digitalMediaBaseObj && digitalMediaBaseObj.parentId) &&
      (ancestorTopicItem && ancestorTopicItem.parentId) &&
      (ancestorTopic && (ancestorTopic.parentId !== undefined)) &&
      (userPermissionsForCurrentChannel)
    ) {
      const accessPermissions: IAccessPermissions = new AccessPermissions(userPermissionsForCurrentChannel.hasViewPermission,
        userPermissionsForCurrentChannel.hasEditPermission,
        userPermissionsForCurrentChannel.hasAdminPermission);
      const ancestorChannelId: typeUniqueId = ancestorTopic.parentId;
      imageLinks.forEach((imageLink: IImageLink) => {
        const imageLinkViewModel: IImageLinkViewModel = new ImageLinkViewModel(imageLink, ancestorTopicItem.id, ancestorTopic.id, ancestorChannelId, accessPermissions);
        imageLinkViewModelsData.push(imageLinkViewModel);
      });
    }

    setImageLinkViewModels(imageLinkViewModelsData);
  }, [imageLinks, digitalMediaBaseObj, ancestorTopicItem, ancestorTopic, userPermissionsForCurrentChannel]);

  // set up a useEffect() hook to prepare the NotesViewModel data when source data becomes available or changes
  useEffect(() => {
    let noteViewModelsData: Array<INoteViewModel> = [];

    // ensure that all necessary data is available to set up the viewModel data
    if ((notes && (notes.length > 0)) &&
      (digitalMediaBaseObj && digitalMediaBaseObj.parentId) &&
      (ancestorTopicItem && ancestorTopicItem.parentId) &&
      (ancestorTopic && (ancestorTopic.parentId !== undefined)) &&
      (userPermissionsForCurrentChannel)
    ) {
      const accessPermissions: IAccessPermissions = new AccessPermissions(userPermissionsForCurrentChannel.hasViewPermission,
        userPermissionsForCurrentChannel.hasEditPermission,
        userPermissionsForCurrentChannel.hasAdminPermission);
      const ancestorChannelId: typeUniqueId = ancestorTopic.parentId;
      notes.forEach((note: INote) => {
        const noteViewModel: INoteViewModel = new NoteViewModel(note, ancestorTopicItem.id, ancestorTopic.id, ancestorChannelId, accessPermissions);
        noteViewModelsData.push(noteViewModel);
      });
    }

    setNoteViewModels(noteViewModelsData);
  }, [notes, digitalMediaBaseObj, ancestorTopicItem, ancestorTopic, userPermissionsForCurrentChannel]);

  // set up a useEffect() hook to prepare the SocialMediaPostsViewModel data when source data becomes available or changes
  useEffect(() => {
    let socialMediaPostViewModelsData: Array<ISocialMediaPostViewModel> = [];

    // ensure that all necessary data is available to set up the viewModel data
    if ((socialMediaPosts && (socialMediaPosts.length > 0)) &&
      (digitalMediaBaseObj && digitalMediaBaseObj.parentId) &&
      (ancestorTopicItem && ancestorTopicItem.parentId) &&
      (ancestorTopic && (ancestorTopic.parentId !== undefined)) &&
      (userPermissionsForCurrentChannel)
    ) {
      const accessPermissions: IAccessPermissions = new AccessPermissions(userPermissionsForCurrentChannel.hasViewPermission,
        userPermissionsForCurrentChannel.hasEditPermission,
        userPermissionsForCurrentChannel.hasAdminPermission);
      const ancestorChannelId: typeUniqueId = ancestorTopic.parentId;
      socialMediaPosts.forEach((socialMediaPost: ISocialMediaPost) => {
        const socialMediaPostViewModel: ISocialMediaPostViewModel = new SocialMediaPostViewModel(socialMediaPost, ancestorTopicItem.id, ancestorTopic.id, ancestorChannelId, accessPermissions);
        socialMediaPostViewModelsData.push(socialMediaPostViewModel);
      });
    }

    setSocialMediaPostViewModels(socialMediaPostViewModelsData);
  }, [socialMediaPosts, digitalMediaBaseObj, ancestorTopicItem, ancestorTopic, userPermissionsForCurrentChannel]);

  // set up a useEffect() hook to prepare the VideoLinksViewModel data when source data becomes available or changes
  useEffect(() => {
    let videoLinkViewModelsData: Array<IVideoLinkViewModel> = [];

    // ensure that all necessary data is available to set up the viewModel data
    if ((videoLinks && (videoLinks.length > 0)) &&
      (digitalMediaBaseObj && digitalMediaBaseObj.parentId) &&
      (ancestorTopicItem && ancestorTopicItem.parentId) &&
      (ancestorTopic && (ancestorTopic.parentId !== undefined)) &&
      (userPermissionsForCurrentChannel)
    ) {
      const accessPermissions: IAccessPermissions = new AccessPermissions(userPermissionsForCurrentChannel.hasViewPermission,
        userPermissionsForCurrentChannel.hasEditPermission,
        userPermissionsForCurrentChannel.hasAdminPermission);
      const ancestorChannelId: typeUniqueId = ancestorTopic.parentId;
      videoLinks.forEach((videoLink: IVideoLink) => {
        const videoLinkViewModel: IVideoLinkViewModel = new VideoLinkViewModel(videoLink, ancestorTopicItem.id, ancestorTopic.id, ancestorChannelId, accessPermissions);
        videoLinkViewModelsData.push(videoLinkViewModel);
      });
    }

    setVideoLinkViewModels(videoLinkViewModelsData);
  }, [videoLinks, digitalMediaBaseObj, ancestorTopicItem, ancestorTopic, userPermissionsForCurrentChannel]);

  // set up a useEffect() hook to get the Notes for the given digitalMediaBaseObj and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    if (digitalMediaBaseObj && digitalMediaBaseObj.id) {

      // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
      getNotesForParentId_onSnapshot(digitalMediaBaseObj.id, onNotesSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallback = unsubscribe;
      });

      // set indicator that data is being loaded
      setNotesDataLoading(true);
    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the onSnapshot 
      unsubscribeCallback();
    }
  }, [digitalMediaBaseObj])


  /**
   * @function onNotesSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onNotesSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // console.log(`NotesView:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setNotesDataLoading(false);
    const noteDataAsJson: Array<INoteAsJson> = [];
    // console.log(`NotesView:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => noteDataAsJson.push({ ...doc.data(), id: doc.id } as INoteAsJson));
    // console.log(`NotesView:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of Note objects from the JSON data
    let notesData: Array<INote> = JsonConverter.arrayFromJSONArray(Note, noteDataAsJson);

    // use lodash to sort the array by 'description' in ascending order
    notesData = _.orderBy(notesData, [note => note.noteContent.toUpperCase()], ['asc']);

    setNotes(notesData);
  }

  // set up a useEffect() hook to get the VideoLinks for the given digitalMediaBaseObj and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    if (digitalMediaBaseObj && digitalMediaBaseObj.id) {

      // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
      getVideoLinksForParentId_onSnapshot(digitalMediaBaseObj.id, onVideoLinksSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallback = unsubscribe;
      });

      // set indicator that data is being loaded
      setVideoLinksDataLoading(true);
    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the onSnapshot 
      unsubscribeCallback();
    }
  }, [digitalMediaBaseObj])


  /**
   * @function onVideoLinksSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onVideoLinksSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // console.log(`VideoLinksView:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setVideoLinksDataLoading(false);
    const videoLinkDataAsJson: Array<IVideoLinkAsJson> = [];
    // console.log(`VideoLinksView:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => videoLinkDataAsJson.push({ ...doc.data(), id: doc.id } as IVideoLinkAsJson));
    // console.log(`VideoLinksView:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of VideoLink objects from the JSON data
    let videoLinksData: Array<IVideoLink> = JsonConverter.arrayFromJSONArray(VideoLink, videoLinkDataAsJson);

    // use lodash to sort the array by 'description' in ascending order
    videoLinksData = _.orderBy(videoLinksData, [videoLink => videoLink.description.toUpperCase()], ['asc']);

    setVideoLinks(videoLinksData);
  }

  const handleSelectedTabChange = (event: ChangeEvent<{}>, newSelectedDigitalMediaTab: enumDigitalMediaType) => {
    dispatch(setSelectedDigitalMediaType(newSelectedDigitalMediaTab));
  };

  function a11yProps(index: number) {
    return {
      id: `digital-media-tab-${index}`,
      'aria-controls': `digital-media-tabpanel-${index}`,
    };
  }

  // // Determine the user's permissions relative to the current channel
  // const userPermissionsForCurrentChannel: IUserAccessPermissionsForObject | undefined = currentUser && currentChannel ? new UserAccessPermissionsForObject(currentUser.id, currentChannel) : undefined;

  displayConsoleLogs && console.log(`%c DigitalMediaPage. \n digitalMediaBaseObj: ${JSON.stringify(digitalMediaBaseObj)}\n userPermissions: ${JSON.stringify(userPermissionsForCurrentChannel)}`, 'background: #006; color: #fff');

  const countOfObjectsDisplayed: number = audioLinkViewModels.length + documentLinkViewModels.length + hyperLinkViewModels.length +
    imageLinkViewModels.length + noteViewModels.length + socialMediaPostViewModels.length + videoLinkViewModels.length;

  const pageSubtitle: string = `(${countOfObjectsDisplayed} objects displayed)`;

  return (
    <>
      <StyledBoxForPaddingAtopPage />
      <GenericPageContainer
        showBackButton={true}
        objectSpecificPageHeaderCategory={ancestorTopic ? ancestorTopic.topicItemNameAliasSingular : undefined}
        objectSpecificPageHeaderText={ancestorTopicItem ? ancestorTopicItem.name : undefined}
        pageTitle={PageAndViewTitleStringAssets.pageTitle_DigitalMedia}
        pageSubtitle={pageSubtitle}
      >

        {digitalMediaBaseObj && userPermissionsForCurrentChannel &&
          <>
            {/* For smaller views, display only an icon on each tab; for larger views  display a label and icon on each tab */}
            <StyledTabsForDigitalMediaOptions value={selectedDigitalMediaTab} variant={tabsDisplayFormat} onChange={handleSelectedTabChange} aria-label="Digital Media">
              <Tab value={enumDigitalMediaType.Image} label={tabLabelForImages + ` (${imageLinkViewModels.length})`} icon={<ImageIcon />} {...a11yProps(0)} color='inherit' />
              <Tab value={enumDigitalMediaType.Video} label={tabLabelForVideos + ` (${videoLinkViewModels.length})`} icon={<VideoLibraryIcon />} {...a11yProps(1)} />
              <Tab value={enumDigitalMediaType.SocialMedia} label={tabLabelForSocialMedia + ` (${socialMediaPostViewModels.length})`} icon={<SocialMediaIcon />} {...a11yProps(2)} />
              <Tab value={enumDigitalMediaType.Audio} label={tabLabelForAudioClips + ` (${audioLinkViewModels.length})`} icon={<AudioClipIcon />} {...a11yProps(2)} />
              <Tab value={enumDigitalMediaType.Document} label={tabLabelForDocuments + ` (${documentLinkViewModels.length})`} icon={<DocumentIcon />} {...a11yProps(3)} />
              <Tab value={enumDigitalMediaType.Note} label={tabLabelForNotes + ` (${noteViewModels.length})`} icon={<NoteIcon />} {...a11yProps(4)} />
              <Tab value={enumDigitalMediaType.HyperLink} label={tabLabelForHyperlinks + ` (${hyperLinkViewModels.length})`} icon={<HyperLinkIcon />} {...a11yProps(5)} />
            </StyledTabsForDigitalMediaOptions>

            {/* {selectedDigitalMediaTab === enumDigitalMediaType.Image && <ImageLinksView parentId={digitalMediaBaseObj.id} imageLinksToDisplay={imageLinks} imageLinksDataLoading={imageLinksDataLoading} userPermissions={userPermissionsForCurrentChannel} />} */}
            {selectedDigitalMediaTab === enumDigitalMediaType.Image && <ImageLinksView parentId={digitalMediaBaseObj.id} imageLinksToDisplay={imageLinkViewModels} imageLinksDataLoading={imageLinksDataLoading} allowAddingNewItems={true} />}
            {selectedDigitalMediaTab === enumDigitalMediaType.Video && <VideoLinksView parentId={digitalMediaBaseObj.id} videoLinksToDisplay={videoLinkViewModels} videoLinksDataLoading={videoLinksDataLoading} allowAddingNewItems={true} />}
            {selectedDigitalMediaTab === enumDigitalMediaType.SocialMedia && <SocialMediaPostsView parentId={digitalMediaBaseObj.id} socialMediaPostsToDisplay={socialMediaPostViewModels} socialMediaPostsDataLoading={socialMediaPostsDataLoading} allowAddingNewItems={true} />}
            {/* {selectedDigitalMediaTab === enumDigitalMediaType.SocialMedia && <SocialMediaView parentId={digitalMediaBaseObj.id} socialMediaDataLoading={false} allowAddingNewItems={true} />} */}
            {selectedDigitalMediaTab === enumDigitalMediaType.Audio && <AudioLinksView parentId={digitalMediaBaseObj.id} audioLinksToDisplay={audioLinkViewModels} audioLinksDataLoading={audioLinksDataLoading} allowAddingNewItems={true} />}
            {selectedDigitalMediaTab === enumDigitalMediaType.Document && <DocumentLinksView parentId={digitalMediaBaseObj.id} documentLinksToDisplay={documentLinkViewModels} documentLinksDataLoading={documentLinksDataLoading} allowAddingNewItems={true} />}
            {selectedDigitalMediaTab === enumDigitalMediaType.Note && <NotesView parentId={digitalMediaBaseObj.id} notesToDisplay={noteViewModels} notesDataLoading={notesDataLoading} allowAddingNewItems={true} />}
            {selectedDigitalMediaTab === enumDigitalMediaType.HyperLink && <HyperLinksView parentId={digitalMediaBaseObj.id} hyperLinksToDisplay={hyperLinkViewModels} hyperLinksDataLoading={hyperLinksDataLoading} allowAddingNewItems={true} />}
          </>
        }

      </GenericPageContainer>
    </>
  );

}

export default DigitalMediaPage;