import { ServerSubstitutionKeywordsStringAssets } from "../../../../../assets/stringAssets";
import { generateStandardEmailClosingHtml, generateStandardEmailOpeningHtml } from "../commonEmailBodyFragments";

/**
 * @function generateEmailBodyHtmlForRecoverUserEmailAddress. Generates the Html body of a notification email allowing to recover (reverted) from a changed email address
 * @param {string} recoveredToEmailAddress The new (recovered to) email address of the user.
 * @param {string} firstName The first name of the target of the email.
 * @param {string} lastName The last name of the target of the email.
 * @param {string} priorEmailAddress The old (prior) email address prior to recovery (reverting).
 * @returns {string} A string with the Html body of the email message to be sent.
 */
export function generateEmailBodyHtmlForRecoverUserEmailAddress(recoveredToEmailAddress: string, firstName: string, lastName: string, priorEmailAddress: string): string {

  const recipientUserName: string = `${firstName} ${lastName}`;

  // *** Compose the email Body in HTML format ***
  let emailBodyHtml = generateStandardEmailOpeningHtml(recipientUserName);

  // user's email address has been recovered (reverted)...
  emailBodyHtml += `<p>We have processed a request to recover the email address for your ${ServerSubstitutionKeywordsStringAssets.ProjectName} account, reverting it to ${recoveredToEmailAddress} from ${priorEmailAddress}. </p>`;

  emailBodyHtml += `<p>${recoveredToEmailAddress} is now the email address for your account.</p>`;

  emailBodyHtml += `<p>We hope you continue to enjoy using ${ServerSubstitutionKeywordsStringAssets.ProjectName}! </p>`;

  emailBodyHtml += generateStandardEmailClosingHtml();

  // return the email content
  return emailBodyHtml;
}
