import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router";
import { typeUniqueId } from '../../../dataObjects/types';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import { IUser } from '../../../dataObjects/models/users/User';
import { IHyperLink, HyperLink } from '../../../dataObjects/models/digitalMedia/HyperLink';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { getSingleObjectById } from '../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { enumBeaconType, enumObjectPersistenceState, enumPersistableObjectType, enumWorkflowState } from '../../../dataObjects/enums';
import { IUserSettings } from '../../../dataObjects/models/users/UserSettings';
import { RandomId } from '../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../dataObjects/models/persistence/UserPersistenceData';
import { MessagesStringAssets, NotificationStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import HyperLinkForm from '../../forms/HyperLinkForm/HyperLinkForm';
import { ISaveHyperLinkWorkflowState } from '../../../uiMiddleware-redux/store/SaveHyperLinkWorkflowState';
import { ISaveHyperLinkRequest } from '../../../dataObjects/models/serviceRequests/hyperLink/SaveHyperLinkRequest';
import { AlertInfo, IAlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { enumAlertType } from '../../enums';
import { Beacon } from '../../../dataObjects/models/alerts/Beacon';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import { useAppDispatch, useAppSelector } from '../../../uiMiddleware-redux/store/configureStore';
import { beaconChange } from '../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { hyperLinkSaveRequest, hyperLinkSaveStatusClear, hyperLinkSaveWorkflowStateChange } from '../../../uiMiddleware-redux/slices/hyperLink/hyperLinkSaveStatusSlice';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';

/**
 * @interface IManageHyperLinkPageProps Input properties for the ManageHyperLinkPage
 */
export interface IManageHyperLinkPageProps extends PropsWithChildren<unknown> {
  id?: typeUniqueId,  // if the Id is given, it means that the HyperLink already exists
  parentId?: typeUniqueId  // if the parentId is given, it means that a new HyperLink is to be created
}

/**
 * @function ManageHyperLinkPage A React Function Component for manageing (creating or editing) a HyperLink.
 * @param {IManageHyperLinkPageProps} props Input properties
 */
const ManageHyperLinkPage: React.FC<IManageHyperLinkPageProps> = (props: IManageHyperLinkPageProps) => {

  ManageHyperLinkPage.displayName = "Manage HyperLink Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const params = useParams();

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [hyperLinkId, setHyperLinkId] = useState<typeUniqueId | undefined>(params.id);

  // define an effect that will set the hyperLinkId anytime the params.id changes
  useEffect(() => {
    setHyperLinkId(params.id);
  }, [params.id]);

  // define an effect based on a change to the hyperLinkId. Having a defined hyperLinkId means that we will 
  // be editing an existing HyperLink
  useEffect(() => {
    if (hyperLinkId !== undefined) {
      // try to find an HyperLink object with the Id given in the URL
      getSingleObjectById(hyperLinkId, enumPersistableObjectType.HyperLink, onHyperLinkFetched);
    }
  }, [hyperLinkId]);

  // will be set to the 'parentId' provided in the URL parms. If a parentId was provided, it means that we will be creating a new object.
  const [hyperLinkParentId, setHyperLinkParentId] = useState<typeUniqueId | undefined>(params.parentId);

  // define an effect that will set the hyperLinkParentId anytime the params.parentId changes
  useEffect(() => {
    setHyperLinkParentId(params.parentId);
  }, [params.parentId]);

  // will be set to the hyperLink to be managed, either a new hyperLink or an existing one
  const [hyperLink, setHyperLink] = useState<IHyperLink | undefined>(undefined);

  // whether we are going to be managing a new hyperLink
  const [isNewObject, setIsNewObject] = useState<boolean>(true);

  const resetSaveHyperLinkStatus: () => void = useCallback(() => {
    dispatch(hyperLinkSaveStatusClear());
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetSaveHyperLinkStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveHyperLinkStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveHyperLinkStatus]);

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();

  // workflow state related to saving a HyperLink
  const [saveHyperLinkWorkflowState, setSaveHyperLinkWorkflowState] = useState<ISaveHyperLinkWorkflowState | null | undefined>(undefined);

  const [successfullyCompletedPage, setSuccessfullyCompletedPage] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedPage state changes
  useEffect(() => {
    if (successfullyCompletedPage) {
      // return to previous page
      navigate(-1);

      // dispatch to clear the hyperLink save status store state variable
      dispatch(hyperLinkSaveWorkflowStateChange(null));

      // notify user of the successful operation
      // ensuring that it's not null or undefined, append the HyperLink Title to the end of the success message
      const successMessage: string = MessagesStringAssets.hyperLink_SaveSuccess + (hyperLink ? ` (${hyperLink.title})` : '');
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, NotificationStringAssets.heading_SaveHyperLink, successMessage)));
    }
  }, [successfullyCompletedPage, dispatch, hyperLink, navigate]);

  // get the Redux workflow state for saving a HyperLink
  const currentSaveHyperLinkWorkflowState: ISaveHyperLinkWorkflowState | null | undefined = useAppSelector((state: IStoreState) => state.saveHyperLinkStatus);

  // whenever the workflow state changes, set the object's value into the local state
  useEffect(() => {
    setSaveHyperLinkWorkflowState(currentSaveHyperLinkWorkflowState);
  }, [currentSaveHyperLinkWorkflowState]);

  /**
   * @function prepareNewHyperLink Prepares/creates a new hyperLink that will be used if user is requesting to create/add a new hyperLink.
   * @param parentChannel 
   */
  const prepareNewHyperLink: (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => IHyperLink = useCallback(
    (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => {
      let newHyperLink: IHyperLink;

      const newHyperLinkId: typeUniqueId = RandomId.newId();
      const userName: string = `${userSettings.firstName} ${userSettings.lastName}`;
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(user.id, userName);
      newHyperLink = new HyperLink(user.id, newHyperLinkId, enumPersistableObjectType.DigitalMedia, parentId, enumObjectPersistenceState.New,
        '', '', '', userPersistenceData);

      return newHyperLink;
    }, []
  );

  // define an effect based on a change to the hyperLinkParentId & currentUser. Having a defined hyperLinkParentId means that we will
  // be creating a new HyperLink
  useEffect(() => {
    if (hyperLinkParentId !== undefined && currentUser) {
      setHyperLink(prepareNewHyperLink(hyperLinkParentId, currentUser, currentUser.userSettings));
    }
  }, [hyperLinkParentId, currentUser, prepareNewHyperLink]);

  /**
   * @function onHyperLinkFetched A callback function that will be used when requesting a data object to be managed
   * @param {IHyperLink | undefined} hyperLink The data object to be managed
   */
  function onHyperLinkFetched(hyperLink: IHyperLink | undefined): void {
    if (hyperLink !== undefined) {
      // set the returned HyperLink to be the one we are editing
      setHyperLink(hyperLink);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing object (default was set to 'true')
      setIsNewObject(false);
    }
  }

  /**
   * @method handleFormSubmit Handles a submit request from the form in this page
   * @param {ISaveHyperLinkRequest} saveHyperLinkRequest A Submit event
   */
  function handleFormSubmit(saveHyperLinkRequest: ISaveHyperLinkRequest): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch an action to request saving the hyperLink
        dispatch(hyperLinkSaveRequest(saveHyperLinkRequest));

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  // prepare a variable that may hold alert info
  let alertInfo: IAlertInfo | null = null;

  // prepare a variable to indicate whether a save is in progress
  let saveInProgress: boolean = false;
  if (saveHyperLinkWorkflowState !== null && saveHyperLinkWorkflowState !== undefined) {

    if (saveHyperLinkWorkflowState.workflowState === enumWorkflowState.Success) {
      if (!successfullyCompletedPage) {
        // set flag to indicate that the page has been successfully completed, which will force a re-render to allow for finalizing work for the page
        setSuccessfullyCompletedPage(true);
      }
    } else if (saveHyperLinkWorkflowState.workflowState === enumWorkflowState.InProgress || saveHyperLinkWorkflowState.workflowState === enumWorkflowState.Requested) {
      // indicate that a save is in progress
      saveInProgress = true;
    } else if (saveHyperLinkWorkflowState.workflowState === enumWorkflowState.Failure) {
      let failureMessage = 'Unknown error';
      if (saveHyperLinkWorkflowState.workflowError && saveHyperLinkWorkflowState.workflowError instanceof Error) {
        failureMessage = saveHyperLinkWorkflowState.workflowError.message;
      }
      // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
      alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
      dispatch(alertInfoChange(alertInfo));
    }
  }


  return (
    <GenericPageContainer
      maxWidth="sm"
      showBackButton={true}
      pageTitle={isNewObject ? PageAndViewTitleStringAssets.pageTitle_ManageHyperLink_New : PageAndViewTitleStringAssets.pageTitle_ManageHyperLink_Existing}
      onCloseAlert={resetSaveHyperLinkStatus}
    >
      {hyperLink &&
        <HyperLinkForm
          hyperLink={hyperLink}
          saveRequestInProgress={saveInProgress}
          onSubmit={handleFormSubmit}
        />
      }

    </GenericPageContainer>
  );

} // const ManageHyperLinkPage...

export default ManageHyperLinkPage;
