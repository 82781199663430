import { IFirestoreUserFeedbackRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreUserFeedbackRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IUserFeedback, IUserFeedbackAsJson } from "../../../../dataObjects/models/userFeedback";


export function saveUserFeedback(userFeedback: IUserFeedback): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a UserFeedback repository from the repository factory
      const firestoreUserFeedbackRepository: IFirestoreUserFeedbackRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.UserFeedback) as
        IFirestoreBaseRepository<IUserFeedback, IUserFeedbackAsJson>;

      // determine if the UserFeedback already exists by calling the repository get() method to try and get the object
      const existingUserFeedback: IUserFeedback | undefined = await firestoreUserFeedbackRepository.get(userFeedback.id);

      // If the UserFeedback wasn't found, we will create a new instance. If it exists, we'll update the existing instance.
      if (existingUserFeedback === undefined) {
        // call repository method to create the UserFeedback
        await firestoreUserFeedbackRepository.create(userFeedback);
      } else {
        // call repository method to update the UserFeedback
        await firestoreUserFeedbackRepository.update(userFeedback);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}