import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  BoxProps,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
  Tooltip,
  Typography,
  TypographyProps
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  NearMe as NavigationIcon,
} from '@mui/icons-material';
import { IImageLink } from '../../../../dataObjects/models/digitalMedia/ImageLink';
import { listViewItemStyles } from '../../../styles';
import { enumDigitalMediaDisplayEnvironment, enumImageFileSize } from '../../../../dataObjects/enums';
import { MenuItemStringAssets, TooltipStringAssets } from '../../../../assets/stringAssets';
import { IImageLinkViewModel } from '../../../../dataObjects/viewModels/imageLinkViewModel';
import { Channel } from '../../../../dataObjects/models/channels/Channel';
import { ImageMediaDisplay } from '../../../controls/digitalMediaDisplays/ImageMediaDisplay';


// a styled Popover Menu (used for popping up the 'User' menu)
const StyledPopoverUserMenu = styled((props: MenuProps) => (
  <Menu
    elevation={5}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  // transform: 'translate(0, 40px)',  // move the court left by 50% of its width
}));

// a styled menu item in the Popover Menu
const StyledPopoverMenuItem = styled((props: MenuItemProps) => (
  <MenuItem
    {...props}
  />
))(({ theme }) => ({
  '&:focus': {
    //       // '&.Mui-focus': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
  },
  '&:hover': {
    //       // '&.Mui-hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
  },
}));

// styled text for a menu item in the Popover Menu
const StyledMenuItemText = styled((props: TypographyProps) => (
  <Typography
    {...props}
  />
))(({ theme }) => ({
  marginLeft: '1.0rem'
}));

// a styled Box (equivalent to a <div>), representing a background area for controls on the AppHeader
const StyledNavigateToButtonArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  justifyItems: 'flex-start',
  marginTop: theme.spacing(0.50),
}));

export interface ImageLinksListViewItemProps extends PropsWithChildren<unknown> {
  imageLinkViewModel: IImageLinkViewModel;
  onDelete: (imageLink: IImageLink) => void;
}

export const ImageLinksListViewItem: React.FC<ImageLinksListViewItemProps> = (props: ImageLinksListViewItemProps) => {
  ImageLinksListViewItem.displayName = 'ImageLinks List View Item';

  // eslint-disable-next-line no-console
  // console.info(`Render ImageLinksListViewItem`);

  const classes: Record<string, string> = listViewItemStyles();

  const { imageLinkViewModel, onDelete } = props;
  const { imageLink, accessPermissionToAncestorChannel } = imageLinkViewModel;
  const { id, description } = imageLink;

  // we're going to apply the 'useNavigate' hook to navigate the browser
  const navigate = useNavigate();

  // specifies the anchor position for the "Navigate To" popup menu
  const [navigateToButtonAnchorEl, setUserButtonAnchorEl] = React.useState(null);

  // controls whether the "Navigate To" popup menu is open (showing)
  const openNavigateToMenu = Boolean(navigateToButtonAnchorEl);


  function handleNavigateToButtonClicked(event: any) {
    setUserButtonAnchorEl(event.currentTarget);
  };

  const handleCloseNavigateToMenu = () => {
    setUserButtonAnchorEl(null);
  };

  const handlePageNavigationFromPopupMenu = (pageUrl: string) => {
    // navigate to page
    navigate(pageUrl);

    // close the user menu
    handleCloseNavigateToMenu();
  }

  return (
    <>
      <div className={classes.tableRow} key={id}>
        <StyledNavigateToButtonArea>
          <IconButton
            className={`${classes.navigationIconButton}`}
            size="small"
            aria-label="navigate to"
            aria-controls="navigate-to"
            aria-haspopup="true"
            onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleNavigateToButtonClicked(evt)}
          >
            <Tooltip title={'Navigate to...'} arrow >
              <NavigationIcon fontSize='small' />
            </Tooltip>
          </IconButton>

          {/* Render the Popover User Menu **ONLY** if the navigateToButtonAnchorEl object has been created */}
          {
            navigateToButtonAnchorEl &&
            <StyledPopoverUserMenu
              id="menu-appbar"
              anchorEl={navigateToButtonAnchorEl}
              anchorReference={navigateToButtonAnchorEl && 'anchorEl'}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              open={openNavigateToMenu}
              onClose={handleCloseNavigateToMenu}
            >
              <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupMenu(`/${Channel.coreChannelIdFromChannelId(imageLinkViewModel.ancestorChannelId)}/topics`)}>
                <StyledMenuItemText>
                  {MenuItemStringAssets.menuItem_NavigateToAncestorChannel}
                </StyledMenuItemText>
              </StyledPopoverMenuItem>
              <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupMenu(`/${imageLinkViewModel.ancestorTopicId}/topicItems`)}>
                <StyledMenuItemText>
                  {MenuItemStringAssets.menuItem_NavigateToAncestorTopic}
                </StyledMenuItemText>
              </StyledPopoverMenuItem>
              <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupMenu(`/${imageLinkViewModel.parentTopicItemId}/digitalMedia`)}>
                <StyledMenuItemText>
                  {MenuItemStringAssets.menuItem_NavigateToParentTopicItem}
                </StyledMenuItemText>
              </StyledPopoverMenuItem>
            </StyledPopoverUserMenu>
          } {/* end of 'navigateToButtonAnchorEl && ...' */}

        </StyledNavigateToButtonArea>

        {/* Data Row 1 for Medium & Small Sizes: Image & Action Buttons. */}
        {/* Data Row 1 for Larger Sizes: Image, Description & Action Buttons */}
        <Grid container size={12} direction="row" >
          {/* Image */}
          <Grid container size={{ xs: 9, sm: 8, md: 6, lg: 4, xl: 4 }} >
            <ImageMediaDisplay
              imageLink={imageLink}
              displayEnvironment={enumDigitalMediaDisplayEnvironment.ListView}
              displayImageSize={enumImageFileSize.Small}
              displayImageSizeWhenImageClicked={enumImageFileSize.Large}
            />
          </Grid>
          {/* Description (Hide for Small or smaller size, since description will be on row two for that size) */}
          <Grid container size={{ sm: 2, md: 4, lg: 6, xl: 6 }} alignItems='center' >
            {/* Only display the next field if the screen size is Medium or larger */}
            <Box sx={{ display: { xs: 'none', md: 'block' }, padding: '0px 10px' }} >
              <Typography className={classes.description} variant="body1">
                {description}
              </Typography>
            </Box>
          </Grid>
          {/* Action Buttons */}
          <Grid container size={{ xs: 3, sm: 2 }} direction="row" justifyContent="center" alignContent="flex-start">
            {/* Edit button */}
            <Grid container className={classes.actionButtonContainer} size={6} justifyContent='center' >
              <Tooltip
                // the tooltip for the edit button will either be "Edit" (if user has sufficient permission) or "Edit disallowed" (insufficient permission)
                title={!accessPermissionToAncestorChannel.hasEditPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.editIconButton}`}
                    aria-label="edit"
                    disabled={!accessPermissionToAncestorChannel.hasEditPermission}
                    onClick={() => id && navigate(`/imageLink/${id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {/* Delete button */}
            <Grid container className={classes.actionButtonContainer} size={6} justifyContent='center' >
              <Tooltip
                // the tooltip for the delete button will either be "Delete" (if user has sufficient permission) or "Delete disallowed" (insufficient permission)
                title={!accessPermissionToAncestorChannel.hasEditPermission ? TooltipStringAssets.delete_Disallowed : TooltipStringAssets.delete}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.deleteIconButton}`}
                    aria-label="delete"
                    disabled={!accessPermissionToAncestorChannel.hasEditPermission}
                    onClick={() => imageLink && onDelete(imageLink)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* Data Row 2: Description (Hide for Medium or greater size, since description will be on row one for that size)  */}
        <Grid container size={12} >
          {/* Hide the next field if the screen size is Medium or larger */}
          <Box sx={{ display: { xs: 'block', md: 'none' } }} >
            <Typography className={classes.description} variant="body1">
              {description}
            </Typography>
          </Box>
        </Grid>
        {/* Add a dividing line after each item */}
        <Divider />
      </div>
    </>
  );

}

export default ImageLinksListViewItem;