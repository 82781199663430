import { typeUniqueId } from '../../../../dataObjects/types';
import { IBaseRepository } from ".";
import { IPersistable } from "../../../../dataObjects/models/persistence/Persistable";
import { IBaseDbContext } from "../../dbContexts/BaseDbContext";

/** 
 * @class BaseRepository serves as a base class for repositories
 */
export abstract class BaseRepository<TEntity extends IPersistable, DbType> implements IBaseRepository<TEntity, DbType> {
  /**
   * @method Constructor method
   * @param {IBaseDbContext<DbType>} dbContext (complete the description)
   */
  constructor(dbContext: IBaseDbContext<DbType>) {
    this._dbContext = dbContext;
  }

  /*-----------------------------------------------*/
  /**
   * @property {IBaseDbContext} _dbContext The underlying DB Context to be used
   */
  private _dbContext: IBaseDbContext<DbType>;

  /**
   * @method dbContext Getter method for the DB Context
   */
  get dbContext(): IBaseDbContext<DbType> {
    return this._dbContext;
  }

  /**
   * @method dbContext Setter method for the DB Context
   * @param {IBaseDbContext<DbType>} value Value for setting the DB Context
   */
  set dbContext(value: IBaseDbContext<DbType>) {
    this._dbContext = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method create Creates (persists) an object in the database.
   * @param {TEntity} persistenceObject The object to be persisted in the database.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void).
   */
  abstract create(persistenceObject: TEntity): Promise<void>;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method update Updates an object in the database.
   * @param {TEntity} persistenceObject The object to be updated in the database. An object of the same
   * type and with the same unique Id must already exist in the database.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). An error is thrown
   * if a current object (to be updated) could not be found in the database or if the update operation failed. 
   */
  abstract update(persistenceObject: TEntity): Promise<void>;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method get Retrieves a single object from the database.
   * @param {typeUniqueId} id The unique Id of the object to retrieve.
   * @returns {Promise<TEntity | undefined>} A Promise (to provide asynchrounous capability) with an object instance (if
   * an object was found with the given Id) or 'undefined' (if an object wasn't found with the given Id).
   */
  abstract get(id: typeUniqueId): Promise<TEntity | undefined>;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getAllForParent Retrieves all objects from the database associated with the parent of those objects.
   * @param {typeUniqueId} parentId The unique Id of the parent of the objects to retrieve.
   * @returns {Promise<TEntity | undefined>} A Promise (to provide asynchrounous capability) with an array of object instances (if
   * one or more objects were found to be associated with the given parentId) or 'undefined' (if no objects were found to
   * be associated with the given parentId).
   */
  abstract getAllForParent(parentId: typeUniqueId): Promise<Array<TEntity> | undefined>;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getAllForOwner Retrieves all objects from the database associated with the owner of those objects.
   * @param {typeUniqueId} ownerId The unique Id of the owner of the objects to retrieve.
   * @returns {Promise<TEntity | undefined>} A Promise (to provide asynchrounous capability) with an array of object instances (if
   * one or more objects were found to be associated with the given ownerId) or 'undefined' (if no objects were found to
   * be associated with the given ownerId).
   */
  abstract getAllForOwner(ownerId: typeUniqueId): Promise<Array<TEntity> | undefined>;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getAllIdsForParent Retrieves just the Ids for all objects from the database associated with the parent of those objects.
   * @param {typeUniqueId} parentId The unique Id of the parent of the objects to retrieve.
   * @returns {Promise<TEntity | undefined>} A Promise (to provide asynchrounous capability) with an array of typeUniqueIds (if
   * one or more objects were found to be associated with the given parentId) or 'undefined' (if no objects were found to
   * be associated with the given parentId).
   */
  abstract getAllIdsForParent(parentId: typeUniqueId): Promise<Array<typeUniqueId> | undefined>;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getAllIdsForOwner Retrieves just the Ids for all objects from the database associated with the owner (user) of those objects.
   * @param {typeUniqueId} ownerId The unique Id of the owner of the objects to retrieve.
   * @returns {Promise<TEntity | undefined>} A Promise (to provide asynchrounous capability) with an array of typeUniqueIds (if
   * one or more objects were found to be associated with the given ownerId) or 'undefined' (if no objects were found to
   * be associated with the given ownerId).
   */
   abstract getAllIdsForOwner(ownerId: typeUniqueId): Promise<Array<typeUniqueId> | undefined>;
   /*-----------------------------------------------*/
 
  /*-----------------------------------------------*/
  /**
   * @method delete Performs a "soft delete" on an object in the database. A "soft delete" marks the object state as 
   * "Deleted", and doesn't expunge the object from the database.
   * @param {typeUniqueId} id The unique Id of the object to be soft-deleted from the database.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). No error is 
   * thrown if an object is not found with the given Id.
   */
  abstract delete(id: typeUniqueId): Promise<void>;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method deleteAllForParent Performs a "soft delete" on all objects in the database related to a given parent. 
   * @param {typeUniqueId} parentId The unique Id of the parent for the objects to be soft-deleted.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). No error is 
   * thrown if no objects are found to be related to the given parentId.
   */
  abstract deleteAllForParent(parentId: typeUniqueId): Promise<void>;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method deleteAllForOwner Performs a "soft delete" on all objects in the database related to a given owner. 
   * @param {typeUniqueId} ownerId The unique Id of the owner for the objects to be soft-deleted.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). No error is 
   * thrown if no objects are found to be related to the given ownerId.
   */
   abstract deleteAllForOwner(ownerId: typeUniqueId): Promise<void>;
   /*-----------------------------------------------*/
 
  /*-----------------------------------------------*/
  /**
   * @method expunge Expunges (completely removes) an object from the database.
   * @param {typeUniqueId} id The unique Id of the object to be expunged from the database.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). No error is 
   * thrown if an object is not found with the given Id.
   */
  abstract expunge(id: typeUniqueId): Promise<void>;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method expungeAllForParent Expunges (completely removes) all objects in the database related to a given parent. 
   * @param {typeUniqueId} parentId The unique Id of the parent for the objects to be expunged.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). No error is 
   * thrown if no objects are found to be related to the given parentId.
   */
  abstract expungeAllForParent(parentId: typeUniqueId): Promise<void>;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method expungeAllForOwner Expunges (completely removes) all objects in the database related to a given owner. 
   * @param {typeUniqueId} ownerId The unique Id of the owner for the objects to be expunged.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). No error is 
   * thrown if no objects are found to be related to the given ownerId.
   */
   abstract expungeAllForOwner(ownerId: typeUniqueId): Promise<void>;
   /*-----------------------------------------------*/
 
}
