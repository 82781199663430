import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid2';
import { ChannelSharingRequestCardGridViewItem } from './ChannelSharingRequestCardGridViewItem';
import { IObjectSharingRequestTracker } from '../../../../dataObjects/models/collaboration/ObjectSharingTracker';
import { ICurrentUserChannelSharingViewModel } from '../../../../dataObjects/viewModels/channelSharing/CurrentUserChannelSharingViewModel';


export interface IChannelSharingRequestsCardGridViewProps extends PropsWithChildren<unknown> {
  channelSharingRequests: Array<IObjectSharingRequestTracker> | undefined;
  currentUserChannelSharingViewModel: ICurrentUserChannelSharingViewModel;
  onRevoke: (channelSharingRequest: IObjectSharingRequestTracker) => void;
}


export const ChannelSharingRequestsCardGridView: React.FC<IChannelSharingRequestsCardGridViewProps> = (props: IChannelSharingRequestsCardGridViewProps) => {
  ChannelSharingRequestsCardGridView.displayName = 'Channel Sharing Requests Card Grid View';

  const { channelSharingRequests, currentUserChannelSharingViewModel, onRevoke } = props;


  return (
    <>
      <Grid container spacing={2} >
        {channelSharingRequests &&
          channelSharingRequests.map(channelSharingRequest =>
            <Grid key={channelSharingRequest.id} size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
              <ChannelSharingRequestCardGridViewItem channelSharingRequest={channelSharingRequest} currentUserChannelSharingViewModel={currentUserChannelSharingViewModel} onRevoke={props.onRevoke} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}