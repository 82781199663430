import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { typeUniqueId } from '../../../../dataObjects/types';
import { Divider, IconButton, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  List as DrillDownIcon,
  Share as ShareIcon,
} from '@mui/icons-material';
import { listViewItemStyles } from '../../../styles';
import { IChannel, Channel } from '../../../../dataObjects/models/channels/Channel';
import { IUser } from '../../../../dataObjects/models/users/User';
import { ICategory } from '../../../../dataObjects/models/categories/Category';
import { IChannelViewModel } from '../../../../dataObjects/viewModels/channelViewModel';
import { IUserCategoriesAndChannelsContextData, useUserCategoriesAndChannelsContext } from '../../../providersAndContexts/userCategoriesAndChannels';
import { IUserSettings } from '../../../../dataObjects/models/users/UserSettings';
import { IUserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';
import { TooltipStringAssets } from '../../../../assets/stringAssets';


export interface ChannelsListViewItemProps extends PropsWithChildren<unknown> {
  currentUser: IUser;
  userPermissions: IUserAccessPermissionsForObject;
  channelViewModel: IChannelViewModel;
  onDelete: (channel: IChannel) => void;
}

export const ChannelsListViewItem: React.FC<ChannelsListViewItemProps> = (props: ChannelsListViewItemProps) => {
  ChannelsListViewItem.displayName = 'Channels List View Item';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const classes: Record<string, string> = listViewItemStyles();

  const { currentUser, userPermissions, channelViewModel, onDelete } = props;
  const { id, name, description } = channelViewModel.channel;

  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  // get UserCategoriesAndChannelsContextData from the UserCategoriesAndChannelsProvider using a custom hook (useUserCategoriesAndChannelsContext)
  // we'll end up using it to set the current channel
  const userCategoriesAndChannelsContext: IUserCategoriesAndChannelsContextData = useUserCategoriesAndChannelsContext();

  // will hold the name of the channel's owner to be displayed ("Me" if the channel is owned by the current user)
  const [channelOwnerName, setChannelOwnerName] = useState<string>("");

  // will be set to the either the Category associated with the Channel or to a new "Uncategorized" Category
  const [category, setCategory] = useState<ICategory | undefined>(undefined);

  // will be set to the either the Category associated with the Channel or to a new "Uncategorized" Category
  const [categories, setCategories] = useState<Array<ICategory>>([]);

  // code to execute whenever the value of either the 'categories' object or 'channelViewModel.channel' object changes
  useEffect(() => {
    if (categories && channelViewModel) {
      // determine which category indicates that is the parent category of this channel
      // loop through the categories...
      let associatedCategory: ICategory | undefined = undefined;
      for (let idxCategory = 0; idxCategory < categories.length; idxCategory++) {
        // in the current category in the loop, search for a child with the channel.id value
        let idxChannel = categories[idxCategory].children.findIndex(childChannelId => channelViewModel.channel.id === childChannelId);

        // if a non-private channel wasn't found, check for a match for a private channel id
        if (idxChannel === -1) {
          // try to find a channel in the children collection that matches the formulated privateChannelId
          idxChannel = categories[idxCategory].children.findIndex(childChannelId => channelViewModel.channel.id === Channel.userPrivateChannelIdFromNonPrivateId(childChannelId));
        }

        // if a child was found with the channel.id, we have found the category to which the channel is associated, so set this as the category in state 
        // and break from the 'for' loop
        if (idxChannel !== -1) {
          associatedCategory = categories[idxCategory];
          break;
        }
      }

      // set the associated category to be either the default of 'undefined' or a category that was found to have the channel as a child
      setCategory(associatedCategory);

      // set the Channel Owner's Name
      let deducedChannelOwnerName = '';
      if (channelViewModel.ownerUser && channelViewModel.ownerUser.userSettings) {
        const channelOwnerUserSettings: IUserSettings = channelViewModel.ownerUser?.userSettings;
        deducedChannelOwnerName = `${channelOwnerUserSettings.firstName} ${channelOwnerUserSettings.lastName}`
      }
      setChannelOwnerName(deducedChannelOwnerName);
      displayConsoleLogs && console.log(`%c ChannelsListViewItem. useEffect for [categories, channelViewModel, setChannelOwnerName]. deducedChannelOwnerName: ${deducedChannelOwnerName}`, 'background: #0f0; color: #000');
    }
  }, [categories, channelViewModel, setChannelOwnerName])

  // memoized function to prepare the ChannelId for linking to a collection of topics that can be selected for drilldown
  const prepareChannelIdForTopicsLink: () => typeUniqueId = useCallback((): typeUniqueId => {
    // we need to prepare the Id that will be used with links, since a Channel could be a Private Channel and 
    // would need to have its Id converted to the UserId. So, if the Id is for a Private Channel, convert to a UserId.
    const channelIdForTopicsLink: typeUniqueId = Channel.isChannelIdForPrivateChannel(channelViewModel.channel.id) ? Channel.userIdFromUserPrivateChannelId(channelViewModel.channel.id) : channelViewModel.channel.id;

    return channelIdForTopicsLink;
  }, [channelViewModel.channel]);

  // memoized function that handles click for drilldown to topics
  const handleTopicsDrilldownClicked: () => void = useCallback((): void => {
    // first, set the current channel within the userCategoriesAndChannelsContext
    if (userCategoriesAndChannelsContext.actions.setCurrentChannel) {
      userCategoriesAndChannelsContext.actions.setCurrentChannel(channelViewModel.channel);
    }

    // then, use the navigate object to navigate to the Topics page
    navigate(`/${prepareChannelIdForTopicsLink()}/topics`)
  },
    [navigate, prepareChannelIdForTopicsLink, channelViewModel.channel],
  );

  return (
    <>
      {/* Table data rows: 
          - Data Row 1: Name & Action Buttons
          - Data Row 2: Description
          - Data Row 3: Owner
      */}

      {/* <span className={classes.tableRow} key={id}> */}
      <div className={classes.tableRow} key={id}>
        {/* Data Row 1: Name & Action Buttons  */}
        <Grid container size={12} direction="row" >
          {/* Category associated with the Channel */}
          <Grid container size={{ xs: 8, sm: 9 }} >
            <div style={{
              margin: '5px 5px 0px 0px', paddingLeft: '5px', paddingRight: '5px',
              backgroundColor: `${category?.displayBackground}`, border: `1px solid ${category?.displayBorder}`, color: `${category?.displayFontColor}`
            }}>
              <Typography variant='caption' align='left'>
                {category?.name}
              </Typography>
            </div>

          </Grid>
          {/* Name */}
          <Grid container size={{ xs: 8, sm: 9 }} >
            <Typography className={classes.title} variant="h6">
              {name}
            </Typography>
          </Grid>
          {/* Action Buttons */}
          <Grid container size={{ xs: 8, sm: 3 }} direction="row" justifyContent="center" alignContent="flex-start">
            {/* Topics button */}
            <Grid container className={classes.actionButtonContainer} size={4} >
              {/* only display the drill-down button if user has View permission */}
              {/* the tooltip for the drilldown button will either be "Drill down" (if user has sufficient permission) or "View XXX" (insufficient permission) */}
              <Tooltip title={!userPermissions.hasEditPermission ? TooltipStringAssets.drillDown_Disallowed : `View ${channelViewModel.channel.topicNameAliasPlural}`} arrow>
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.drillDownIconButton}`}
                    disabled={!userPermissions.hasViewPermission}
                    onClick={() => handleTopicsDrilldownClicked()}
                  >
                    <DrillDownIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {/* Edit button */}
            <Grid container className={classes.actionButtonContainer} size={4} >
              <Tooltip
                // the tooltip for the edit button will either be "Edit" (if user has sufficient permission) or "Edit disallowed" (insufficient permission)
                title={!userPermissions.hasEditPermission ? TooltipStringAssets.delete_Disallowed : TooltipStringAssets.delete}
                arrow>
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.editIconButton}`}
                    // this button will be disabled if the user doesn't have Edit permissions
                    disabled={!userPermissions.hasEditPermission}
                    onClick={() => id && navigate(`/channel/${id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {/* Delete button */}
            <Grid container className={classes.actionButtonContainer} size={4} >
              <Tooltip
                // the tooltip for the delete button will either be "Delete" (for a non-private Channel) or "Delete disallowed" (for a private Channel)
                title={(!userPermissions.hasEditPermission || Channel.isChannelIdForPrivateChannel(channelViewModel.channel.id)) ? "Delete disallowed" : "Delete"}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.deleteIconButton}`}
                    // this button will be disabled if the user doesn't have Edit permissions -OR- the Channel is a private Channel
                    disabled={!userPermissions.hasEditPermission || Channel.isChannelIdForPrivateChannel(channelViewModel.channel.id)}
                    onClick={() => channelViewModel.channel && onDelete(channelViewModel.channel)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* Data Row 2: Description  */}
        <Grid container size={12} >
          <Typography className={classes.description} variant="body1">
            {description}
          </Typography>
        </Grid>

        {/* Data Row 3: Owner  */}
        <Grid container size={12} >
          <Typography className={classes.ownerSection} variant="body2">
            {`Owner: ${channelOwnerName}`}
            {/* If the channel's ownerId is the same as the current user's Id -AND-
              if this isn't the user's Private Channel and the channel's owner is the current user, display the Share button */}
            {/* {(currentUser?.id === channel.ownerId && !Channel.isChannelIdForPrivateChannel(channel.id)) && */}
            {(currentUser?.id === channelViewModel.channel.ownerId) && !Channel.isChannelIdForPrivateChannel(channelViewModel.channel.id) &&
              <Tooltip
                // the tooltip for the delete button will either be "Delete" (for a non-private Channel) or "Delete disallowed" (for a private Channel)
                title="Share"
                arrow
              >
                <span>
                  <IconButton
                    className={`${classes.actionButton} ${classes.shareIconButton}`}
                    size="small"
                    aria-label="edit"
                    onClick={() => navigate(`/channelSharing/${channelViewModel.channel.id}`)}>
                    <ShareIcon />
                  </IconButton>
                </span>
              </Tooltip>
            }
          </Typography>
        </Grid>

        {/* Add a dividing line after each item */}
        <Divider />
      </div>
      {/* </span> */}
    </>
  );

}

export default ChannelsListViewItem;