/**
 * @class NotificationStringAssets A collection of string assets that pertain to notifications to be displayed throughout the application
 */
export class NotificationStringAssets {
  // Headings
  static heading_ExpungeUserAccount = 'Expunge User Account';
  static heading_Login = 'Sign in';
  static heading_PopulateSearchMetadata = 'Populate Search Metadata';
  static heading_SaveAudioLink = 'Save Audio';
  static heading_SaveCategory = 'Save Category';
  static heading_SaveChannel = 'Save Channel';
  static heading_SaveChannelSharingRequest = 'Save Channel Sharing Request';
  static heading_SaveChannelSharingUser = 'Save Channel Sharing User';
  static heading_SaveDocumentLink = 'Save Document';
  static heading_SaveHyperLink = 'Save HyperLink';
  static heading_SaveImageLink = 'Save Image';
  static heading_SaveNote = 'Save Note';
  static heading_SaveSocialMediaPost = 'Save Post';
  static heading_SaveThemeSpecs = 'Save Custom Theme';
  static heading_SaveTopic = 'Save Topic';
  static heading_SaveTopicItem = 'Save Topic Item';
  static heading_SaveUserFeedback = 'Save Feedback';
  static heading_SaveUserRedemptionCodeRequest = 'Save User Redemption Code Request';
  static heading_SaveUserSettings = 'Save User Settings';
  static heading_SaveVideoLink = 'Save Video';
  static heading_UpdateUserEmailAddress = 'Update Email Address';
  static heading_UserRegistration = 'Create New Account';
}