import { IUserFeedback } from "../../../../../dataObjects/models/userFeedback";
import { generateStandardEmailClosingPlainText, generateStandardEmailOpeningPlainText } from "../commonEmailBodyFragments";

/**
 * @function generateEmailBodyPlainTextForUserFeedback Generates the Plain Text body of a notification email based on a user submitting feedback
 * @param {IUserFeedback} userFeedback UserFeedback information.
 * @returns {string} A string with the Plain Text body of the email message to be sent.
 */
export function generateEmailBodyPlainTextForUserFeedback(userFeedback: IUserFeedback): string {

  // *** Compose the email Body in Plain Text format ***
  // who submitted the feedback (and UserId)
  let emailBodyPlainText = `${userFeedback.submitterName}(UserId: ${userFeedback.userId}) has submitted user feedback.\n\n`;

  // type of feedback
  emailBodyPlainText += `Feeback type: ${userFeedback.userFeedbackType}\n\n`;

  // submission timestamp
  emailBodyPlainText += `Submission timestamp: ${userFeedback.submissionTimestamp.toLocaleDateString()}\n\n`;

  // title provided by user
  emailBodyPlainText += `Title: ${userFeedback.title}\n\n`;

  // description provided by user
  emailBodyPlainText += `Description:\n`;
  emailBodyPlainText += `${userFeedback.description}\n\n`;

  emailBodyPlainText += generateStandardEmailClosingPlainText();

  // return the email content
  return emailBodyPlainText;
}
