import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useParams } from "react-router";
import { typeUniqueId } from '../../../../dataObjects/types';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { IChannel, Channel, IChannelAndParentCategoryId } from '../../../../dataObjects/models/channels/Channel';
import { IUser } from '../../../../dataObjects/models/users/User';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { getSingleObjectById } from '../../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { enumNoParentObjectType, enumObjectPersistenceState, enumPersistableObjectType, enumSharingPermission, enumWorkflowState } from '../../../../dataObjects/enums';
import { IUserSettings } from '../../../../dataObjects/models/users/UserSettings';
import { RandomId } from '../../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../../dataObjects/models/persistence/UserPersistenceData';
import { PageAndViewTitleStringAssets, MessagesStringAssets } from '../../../../assets/stringAssets';
import ChannelForm from '../../../forms/ChannelForm/ChannelForm';
import { useProcessPerManageObjectPageStatus } from '../../../customHooks';
import { NotificationStringAssets } from '../../../../assets/stringAssets';
import { IProcessPerManageObjectPageStatusInput } from '../../../customHooks/hookInputObjects/ProcessPerManageObjectPage';
import { IUserSharingPermissions, UserSharingPermissions } from '../../../../dataObjects/models/collaboration/UserSharingPermissions';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { channelSaveRequest, channelSaveStatusChange } from '../../../../uiMiddleware-redux/slices/channel/channelSaveStatusSlice';


/**
 * @interface IManageChannelPageProps Input properties for the ManageChannelPage
 */
export interface IManageChannelPageProps extends PropsWithChildren<unknown> {
  id?: typeUniqueId  // if the Id is given, it means that the Channel already exists
}

// const ManageChannelPageNew: React.FC<IManageChannelPageProps> = (props: IManageChannelPageProps) => {
const ManageChannelPage: React.FC<IManageChannelPageProps> = (props: IManageChannelPageProps) => {

  ManageChannelPage.displayName = "Manage Channel Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  const params = useParams();

  displayConsoleLogs && console.log(`ManageChannelPage. Entering component. params.id. params.id value: ${params.id}`);

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [channelId, setChannelId] = useState<typeUniqueId | undefined>(params.id);

  // define an effect that will set the channelId anytime the params.id changes
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageChannelPage. Entered useEffect() for [channelId, params.id]. params.id value: ${params.id}`);
    if (channelId !== params.id) {
      displayConsoleLogs && console.log(`ManageChannelPage. In useEffect() for [channelId, params.id]. params.id value: ${params.id}. Ready to call setChannelId()`);
      setChannelId(params.id);
    }

    return () => {
      // process for 'unmounting'
      displayConsoleLogs && console.log(`ManageChannelPage. In 'unmounting' area of useEffect() for [channelId, params.id]`);
    }

  }, [channelId, params.id]);

  // will be set to the Channel to be managed, either a new Channel or an existing one
  const [channel, setChannel] = useState<IChannel | undefined>(undefined);

  // whether we are going to be managing (creating) a new Channel
  const [isNewObject, setIsNewObject] = useState<boolean>(true);

  const resetSaveChannelStatus: () => void = useCallback(() => {
    // dispatch(saveChannelStatusReset());
    dispatch(channelSaveStatusChange(null));
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetSaveChannelStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveChannelStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveChannelStatus]);

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  const currentUser: IUser | undefined = currentUserContextData.currentUser;

  // prepare a data structure that will be used to call a custom hook that will take care of the workflow for the page
  const processPerManageObjectPageStatusInput: IProcessPerManageObjectPageStatusInput = {
    workflowStateObj: (state: IStoreState) => state.saveChannelStatus,
    failureErrorStateObj: (state: IStoreState) => state.saveChannelFailure,
    notificationFailureMessageTemplate: MessagesStringAssets.channel_SaveFailure,
    notificationSuccessTitle: NotificationStringAssets.heading_SaveChannel,
    notificationSuccessMessage: MessagesStringAssets.channel_SaveSuccess,
  }


  // if there is a defined channel, append the channel name to the message for success
  if (channel !== undefined) {
    displayConsoleLogs && console.log(`ManageChannelPage. 'channel' IS defined. name: ${channel.name}. params.id: ${params.id}`);
    processPerManageObjectPageStatusInput.notificationSuccessMessage = `${MessagesStringAssets.channel_SaveSuccess} (${channel.name})`;
  } else {
    displayConsoleLogs && console.log(`ManageChannelPage. 'channel' is NOT yet defined. params.id: ${params.id}`);
  }

  // call a custom hook to handle the workflow for the page (Requested, InProgress, Success, Failure)
  // const { saveInProgress, alertInfo } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);
  const { operationInProgress } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);

  /**
   * @function prepareNewChannel Prepares/creates a new Channel that will be used if user is requesting to create/add a new Channel.
   * @param  
   */
  const prepareNewChannel: (user: IUser, userSettings: IUserSettings) => IChannel = useCallback(
    (user: IUser, userSettings: IUserSettings) => {
      let newChannel: IChannel;

      const newChannelId: typeUniqueId = RandomId.newId();
      const userName: string = `${userSettings.firstName} ${userSettings.lastName}`;
      const topicNameAliasSingular: string = 'Topic';
      const topicNameAliasPlural: string = 'Topics';
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(user.id, userName);
      const userSharingPermissions: Array<IUserSharingPermissions> = [new UserSharingPermissions(user.id, enumSharingPermission.Admin)];
      newChannel = new Channel(user.id, newChannelId, enumNoParentObjectType.NoParent, undefined, enumObjectPersistenceState.New,
        '', '', userSharingPermissions, userPersistenceData, topicNameAliasSingular, topicNameAliasPlural, undefined);

      return newChannel;
    }, []
  );

  // define an effect based on a change to the currentUser, channelId, or prepareNewChannel. 
  // Having a defined channelId means that we will be editing an existing Channel
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageChannelPage. Entered useEffect() for [currentUser, channelId, prepareNewChannel]. currentUser: ${JSON.stringify(currentUser)}; channelId value: ${channelId}`);

    // if currentUser information has been established...
    if (currentUser !== null && currentUser !== undefined) {
      // if an Id was provided, we will be editing an existing channel; therefore, if we haven't already fetched the channel...
      if ((channel === undefined) && (channelId !== undefined)) {
        displayConsoleLogs && console.log(`ManageChannelPage. In useEffect() for [currentUser, channelId, prepareNewChannel] (${channelId}). Ready to call getSingleObjectById().`);
        // try to find a Channel object with the Id given in the URL
        getSingleObjectById(channelId, enumPersistableObjectType.Channel, onChannelFetched);
      } else {
        // if we haven't already created a Channel object...
        if (channel === undefined) {
          displayConsoleLogs && console.log(`ManageChannelPage. In useEffect() for [currentUser, channelId, prepareNewChannel] & channelId is undefined). Ready to call prepareNewChannel().`);
          // otherwise, we will be creating a new Channel, so prepare a new one
          setChannel(prepareNewChannel(currentUser, currentUser.userSettings));
        }
      }
    }
  }, [currentUser, channel, channelId, prepareNewChannel]);

  // callback function for fetching the current Channel for editing
  function onChannelFetched(channelFetched: IChannel | undefined): void {
    displayConsoleLogs && console.log(`ManageChannelPage. Entered onChannelFetched()`);
    if (channelFetched !== undefined) {
      displayConsoleLogs && console.log(`ManageChannelPage. In onChannelFetched(). channelFetched.name: ${channelFetched.name}`);
      // set the returned Channel to be the one we are editing
      setChannel(channelFetched);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing channel (default was set to 'true')
      setIsNewObject(false);
    }
  }

  // function that embedded form component is to call when submitting for a save operation
  function handleFormSubmit(channelToSubmit: IChannel, parentCategoryId: typeUniqueId): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // if the Channel's categoryId isn't already set to the parentCategoryId and the parentCategoryId is a valid value, set the Channel's categoryId
        if ((channelToSubmit.categoryId !== parentCategoryId) && (parentCategoryId !== undefined) && (parentCategoryId.length > 0)) {
          channelToSubmit.categoryId = parentCategoryId;
        }

        displayConsoleLogs && console.log(`ManageChannelPage. Entered handleFormSubmit(). channelToSubmit.name: ${channelToSubmit.name}. params.id: ${params.id}`);
        // dispatch an action to request saving the channel
        // dispatch(saveChannelRequested(channelToSubmit, parentCategoryId));
        const channelAndParentCategoryId: IChannelAndParentCategoryId = {channel: channelToSubmit, parentCategoryId};
        dispatch(channelSaveRequest(channelAndParentCategoryId));

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  displayConsoleLogs && console.log(`ManageChannelPage. Before 'return' call, channel: ${channel === undefined ? 'undefined' : 'id: ' + channel.id + '; name: ' + channel.name}. params.id: ${params.id}`);

  return (
    <GenericPageContainer
      maxWidth="sm"
      showBackButton={true}
      pageTitle={isNewObject ? PageAndViewTitleStringAssets.pageTitle_ManageChannel_New : PageAndViewTitleStringAssets.pageTitle_ManageChannel_Existing}
      onCloseAlert={resetSaveChannelStatus}
    >
      {channel &&
        <ChannelForm
          channel={channel}
          saveRequestInProgress={operationInProgress}
          onSubmit={handleFormSubmit}
        />
      }

    </GenericPageContainer>
  )

}

export default ManageChannelPage;